/* eslint-disable import/named */
import React, { useState } from 'react';
// eslint-disable-next-line import/named
import { Theme } from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import CheckBox from '../CheckBox';
import BasicModal, { IModalProps } from './BasicModal';
import BasicButton from '../BasicButton';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      margin: theme.spacing(1),
    },
    customClass: {},
    buttonClass: {
      float: 'right',
    },
    actionButtonClass: {},
    checkBoxClass: {
      float: 'left',
      paddingLeft: '14px',
    },
  });
};

export interface IActionModalProps
  extends Omit<IModalProps, 'classes'>,
    WithStyles<typeof styles> {
  isSingleButton?: boolean;
  actinButtonLabel?: string;
  actinButtonLabel2?: string;
  actionButtonStartIcon?: any;
  actinButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  skipButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  disableBackdropClick?: boolean;
  closeIcon?: boolean;
  buttonsCenter?: boolean;
  isSkipButtonInsteadCancelButton?: boolean;
  disabledActionButton?: boolean;
  checkBoxText?: string;
  onClickCheckBox?: Function;
  basicButtonNoMargin?: boolean;
}

export const ActionModal: React.FC<IActionModalProps> = ({
  classes,
  id,
  open,
  // type,
  modalTitle,
  children,
  handleClose,
  fullWidth = true,
  maxWidth = 'sm',
  actinButtonLabel = 'Continue',
  actinButtonLabel2 = 'Cancel',
  actionButtonStartIcon,
  actinButtonOnClick,
  skipButtonOnClick,
  isSingleButton = false,
  disableBackdropClick = false,
  closeIcon = false,
  buttonsCenter = false,
  isSkipButtonInsteadCancelButton = false,
  disabledActionButton = false,
  displayCheckBox = false,
  checkBoxText,
  onClickCheckBox,
  checkBoxNote,
  basicButtonNoMargin,
}: IActionModalProps) => {
  const [checkBoxValue, setCheckBoxValue] = useState(false);
  return (
    <BasicModal
      id={id}
      handleClose={handleClose}
      open={open}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      modalTitle={modalTitle}
      disableBackdropClick={disableBackdropClick}
      titleSize="1.5em"
      closeIcon={closeIcon}
      buttonsCenter={buttonsCenter}
      customClass={classes.customClass}
      displayCheckBox={displayCheckBox}
      checkBoxValue={checkBoxValue}
      checkBoxNote={checkBoxNote}
      actionButtons={
        <div className={classes.root}>
          {displayCheckBox && (
            <div className={classes.checkBoxClass}>
              <CheckBox
                id={`${id}-action-checkbox`}
                checked={checkBoxValue}
                label={checkBoxText}
                onChange={(e) => {
                  setCheckBoxValue(e.target.checked);
                  onClickCheckBox?.(e.target.checked);
                }}
              />
            </div>
          )}
          <div className={classes.buttonClass}>
            {!isSingleButton && (
              <BasicButton
                label={actinButtonLabel2}
                id={`${id}-action-button`}
                filled={false}
                roundBorder
                onClick={
                  isSkipButtonInsteadCancelButton
                    ? skipButtonOnClick
                    : handleClose
                }
              />
            )}
            <BasicButton
              label={actinButtonLabel}
              id={`${id}-action-button-2`}
              filled
              roundBorder
              onClick={actinButtonOnClick}
              startIcon={actionButtonStartIcon}
              disabled={disabledActionButton}
              noMargin={basicButtonNoMargin}
              classes={{ button: classes.actionButtonClass }}
            />
          </div>
        </div>
      }
    >
      {children}
    </BasicModal>
  );
};

export default withStyles(styles)(ActionModal);
