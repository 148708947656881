import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { CameraAlt as Camera } from '@material-ui/icons';
import debug from 'debug';
import {
  createStyles,
  makeStyles,
  MenuItem,
  Select as MuiSelect,
  FormControl,
} from '@material-ui/core';
import Select from '../components/StorybookComponents/Select/Select';
import { webcamSelect } from '../features/recorder/recorderSlice';
import { State } from '../types/state';

const log = debug('app:containers:WebcamDeviceContainer');

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '15px 15px 10px 15px',
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      margin: '0 0 10px',
      marginLeft: '0.3rem',
      color: '#000000',
    },
    select: {
      fontSize: '12px',
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
      },
    },
    menuItemIcon: {
      fontSize: '15px',
      paddingRight: '4px',
    },
  })
);

const WebcamDeviceContainer = () => {
  const classes = useStyles();
  const { webcamDeviceId = '', pluginMode } = useSelector((state: State) => ({
    webcamDeviceId: state.recorder.settings.deviceSettings?.webcamDeviceId,
    pluginMode: state.plugin.pluginMode || false,
  }));

  const [webcamDevice, setWebcamDevice] = useState<string>(webcamDeviceId);
  const [webcamInputs, setWebcamInputs] = useState<
    { label: string; deviceId: string }[]
  >([]);
  const dispatch = useDispatch();

  const fetchDevices = async () => {
    if (
      !navigator.mediaDevices ||
      !navigator.mediaDevices.enumerateDevices ||
      !navigator.mediaDevices.getUserMedia
    ) {
      log('GUM not supported.');
      return;
    }

    try {
      await navigator.mediaDevices.getUserMedia({ video: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices
        .filter((device) => device.kind === 'videoinput')
        .map(({ label, deviceId }) => ({ label, deviceId }));
      setWebcamInputs(videoDevices);

      const selectedDevice = videoDevices?.filter(
        (device) => device.deviceId === webcamDeviceId
      );
      // check whether selected device available. if available set the device otherwise set the first available device
      if (selectedDevice && selectedDevice.length > 0) {
        setWebcamDevice(selectedDevice[0].deviceId);
      } else {
        setWebcamDevice(
          videoDevices.length > 0 ? videoDevices[0].deviceId : ''
        );
      }
    } catch (error) {
      dispatch(webcamSelect({ webcamEnable: false, webcamOnlyEnable: false }));
      log('Cannot set video input devices', error);
    }
  };

  navigator.mediaDevices.ondevicechange = (event: Event) => {
    log(event);
    fetchDevices();
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleChange = (event) => {
    setWebcamDevice(event.target.value);
  };

  return pluginMode ? (
    <div className={classes.root}>
      <FormControl variant="outlined">
        <MuiSelect
          value={webcamDevice}
          onChange={handleChange}
          className={classes.select}
        >
          {webcamInputs.map((device: { label: string; deviceId: string }) => {
            return (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                <Camera className={classes.menuItemIcon} />
                {device.label}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
    </div>
  ) : (
    <Select
      value={webcamDevice}
      onChange={handleChange}
      menuItems={webcamInputs.map(
        (input: { label: string; deviceId: string }) => ({
          id: input.deviceId,
          label: input.label,
          icon: <Camera />,
        })
      )}
    />
  );
};

export default WebcamDeviceContainer;
