/* eslint-disable */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import getHistory from '../src/history';

import { store } from '../src/store';
import { KeyContext } from '../src/components/Plugin';
import PluginRecordingContainer from '../src/containers/Plgin/PluginRecordingContainer';

declare global {
  interface Window {
    ScreenApp: any;
  }
}
class ScreenApp {
  apiKey: string;
  parentElementSelector: string;
  finishRecordingCallback: (...args: any[]) => any;

  constructor(
    apiKey: string,
    finishRecordingCallback: (...args: any[]) => any
  ) {
    this.apiKey = apiKey;
    this.finishRecordingCallback = finishRecordingCallback;
  }
  mount(parentElementSelector: string) {
    this.parentElementSelector = parentElementSelector;

    const _parentElementSelector = parentElementSelector || '#screenapp-plugin';
    try {
      const parentElement = document.querySelector(_parentElementSelector);
      const history = getHistory();
      ReactDOM.render(
        <React.StrictMode>
          <KeyContext.Provider value={this.apiKey}>
            <Provider store={store}>
              <Router history={history}>
                {/* @ts-ignore TODO: Fix this*/}
                <PluginRecordingContainer
                  onClose={() => this.unMount(_parentElementSelector)}
                  finishRecordingCallback={this.finishRecordingCallback}
                />
              </Router>
            </Provider>
          </KeyContext.Provider>
        </React.StrictMode>,
        parentElement
      );
    } catch (error) {
      console.error(
        `Could not mount ScreenAppPlugin to: ${_parentElementSelector}`,
        error
      );
    }
  }
  unMount(parentElementSelector: string) {
    this.parentElementSelector = parentElementSelector;

    const _parentElementSelector = parentElementSelector || 'screenapp-plugin';

    try {
      let parentElement = document.querySelector(_parentElementSelector);
      if (!parentElement) {
        parentElement = document.createElement('div');
        parentElement.id = _parentElementSelector;
        document.body.appendChild(parentElement);
      }
      ReactDOM.render(<div />, parentElement);
    } catch (error) {
      console.error(
        `Could not unmount screenappPlugin to: ${_parentElementSelector}`,
        error
      );
    }
  }
}

window.ScreenApp = ScreenApp;
