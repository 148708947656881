import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.SHARED_SPACE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CHECK_REGISTERED_USER_SUCCESS:
      return { ...state, isRegisteredUser: true };
    case REDUX_ACTIONS.CHECK_REGISTERED_USER_FAIL:
      return { ...state, isRegisteredUser: false };
    case REDUX_ACTIONS.ADD_TO_SHARED_SPACE_SUCCESS:
      return { ...state, sharedSpaceUsers: action.data };
    case REDUX_ACTIONS.ADD_TO_SHARED_SPACE_FAIL:
      return state;
    case REDUX_ACTIONS.GET_SHARED_USER_SUCCESS:
      return { ...state, sharedSpaceUsers: action.data };
    case REDUX_ACTIONS.GET_SHARED_USER_FAIL:
      return { ...state, sharedSpaceUsers: [] };
    case REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS:
    case REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_FAIL:
      return { ...state, sendEmail: action.data };
    case REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS:
    case REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL:
      return { ...state, sendEmail: action.data };
    case REDUX_ACTIONS.CLOSE_INVITATION_NOTIFICATION:
      return { ...state, sendEmail: { success: undefined } };
    case REDUX_ACTIONS.CHANGE_PLUGIN_STATUS_SUCCESS:
      return { ...state, sharedSpaceUsers: action.data };
    case REDUX_ACTIONS.CHANGE_PLUGIN_STATUS_FAIL:
      return state;
    case REDUX_ACTIONS.DELETE_SHARED_SPACE_MEMBER_SUCCESS:
      return { ...state, sharedSpaceUsers: action.data };
    case REDUX_ACTIONS.DELETE_SHARED_SPACE_MEMBER_FAIL:
      return state;
    default:
      return state;
  }
};
