import React from 'react';
import NewRecorder from './newRecorder';
import RecorderSettingsProvider, {
  RecorderOptions,
} from './RecorderOptionsProvider';

export const Recorder = (props: RecorderOptions) => {
  return (
    <RecorderSettingsProvider {...props}>
      <NewRecorder />
    </RecorderSettingsProvider>
  );
};

export default Recorder;
