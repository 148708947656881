/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import WebcamSelection from '../../Recorder/WebcamSelection';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      padding: '0 15px',
      marginBottom: '6px',
    },
    div: {
      textAlign: 'center',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '15px 0',
    },
    image: {
      width: '4rem',
      marginBottom: '8px',
      aspectRatio: '465/311',
    },
    text: {
      margin: '0',
      fontWeight: 'bold',
      fontSize: '10px',
    },
    heading: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      margin: '0 0 10px 15px',
      color: '#000000',
    },
  })
);

type selectionType = 'screen_only' | 'screen_and_webcam' | 'webcam_only';

interface WebcamSelectionProps {
  onClick: Function;
  recorder: any;
}

const PluginWebcamSelection = ({ onClick, recorder }: WebcamSelectionProps) => {
  const classes = useStyles();

  const onClickHandle = (selection: selectionType) => {
    onClick(selection);
  };

  return (
    <>
      <div className={classes.heading}>Recording type</div>
      <WebcamSelection
        onClick={onClickHandle}
        recorder={recorder}
        customClasses={{
          root: classes.root,
          div: classes.div,
          image: classes.image,
          text: classes.text,
        }}
      />
      <div className={classes.heading}>Recording settings</div>
    </>
  );
};

export default PluginWebcamSelection;
