// eslint-disable-next-line import/named
import { createHashHistory, createBrowserHistory, History as H } from 'history';

import { config } from '../config';

interface LocationState {
  [key: string]: unknown;
}

let history: H<LocationState>;
let historySet = false;

// Workaround for URL additional hash issue https://screenapp.atlassian.net/browse/SA-2021
export const getHistory = (): H<unknown> => {
  if (historySet) {
    return history;
  }

  // https://screenapp.atlassian.net/browse/SA-2021
  if (
    !config.appBaseUrl.startsWith(window?.location?.origin) ||
    window?.location?.pathname.endsWith('plugin-test.html')
  ) {
    history = createBrowserHistory();
  } else {
    history = createHashHistory();
  }
  historySet = true;

  return history;
};

export default getHistory;
