// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (
  state = '',
  action: { type: string; data: { id: string; view: '' } }
) => {
  switch (action.type) {
    case REDUX_ACTIONS.PAUSE_OTHER_LIBRARY_VIDEOS:
      return action.data;
    default:
      return state;
  }
};
