export const STREAM = {
  STREAM_FINISH: 'finish',
  STREMAING: 'streaming',
  TRIM_REQUEST: 'trimRequest',
};
export const WS_TYPE = {
  STREAM: 'stream',
  PROCESSED_VIDEO: 'processedVideo',
  TRIM_VIDEO: 'trimVideo',
};
export const WS_ACTION_TYPE = {
  STREAM_WEBM_AVAILABLE: 'STREAM_WEBM_AVAILABLE',
  STREAM_MP4_AVAILABLE: 'STREAM_MP4_AVAILABLE',
  TRIM_VIDEO_AVAILABLE: 'TRIM_VIDEO_AVAILABLE',
};
