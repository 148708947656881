import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIAL_VALUE.PUBLIC_SHARED_FILES, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.GET_SHARE_PUBLIC_FILES_SUCCESS: {
      const { fileSystem } = action.data;
      return fileSystem;
    }
    case REDUX_ACTIONS.GET_SHARE_PUBLIC_FILES_FAIL:
      return [];
    default:
      return state;
  }
};
