import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import {
  changeCompanySharedSpaceUserRole,
  deleteCompanySharedSpaceUser,
  enableSharedSpace,
  getCompanySharedSpaceUsersURL,
  sendEmailURL,
  toggleCompanySharedSpaceUserStatus,
  sendEmailToNotRegisteredURL,
  getTeamSpaces,
  getTeamSpaceDetails,
  createTeamSpace,
  getLastSelectedTeamSpace,
  saveLastSelectedTeamSpace,
  getTeamSpaceDeletionStatusURL,
  addToSpace,
  removeFromSpace,
  changeSpaceUserStatus,
  renameTeam,
  // changePluginStatusURL,
  // deleteSharedSpaceUsersURL,
} from '../../services/companySharedSpaceService';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { checkRegisteredUsersURL } from '../../services/pluginService';
import { goToTeamUrl } from '../../lib/routingUtils';
import {
  fetchTeamSpaceInfoSuccess,
  updatePackageConfig,
} from '../../features/auth/authSlice';

function* addToCompanySharedSpace(action) {
  try {
    const response = yield call(enableSharedSpace, action.data);

    yield put({
      type: REDUX_ACTIONS.ADD_TO_COMPANY_SHARED_SPACE_SUCCESS,
      data: response?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.ADD_TO_COMPANY_SHARED_SPACE_FAIL,
      data: { message: error.message },
    });
  }
}

function* addMembersToSpace(action) {
  try {
    const response = yield call(addToSpace, action.data);

    yield put({
      type: REDUX_ACTIONS.ADD_TO_SPACE_SUCCESS,
      data: response?.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.ADD_TO_SPACE_FAIL,
      data: { message: error.message },
    });
  }
}

function* getCompanySharedSpaceUsers() {
  try {
    const response = yield call(getCompanySharedSpaceUsersURL);
    yield put({
      type: REDUX_ACTIONS.GET_COMPANY_SHARED_USER_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_COMPANY_SHARED_USER_FAIL,
      data: { message: error.message },
    });
  }
}
function* sendSharedSpaceEmail(action) {
  try {
    const { emails, teamId, registeredMembers } = action.data;
    const response = yield call(sendEmailURL, emails, teamId);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS,
        data: response?.data,
      });
      if (registeredMembers && registeredMembers.length > 0) {
        yield* addToCompanySharedSpace({
          data: { teamId, companySharedSpaceUsers: registeredMembers },
        });
      }
    } else {
      yield put({
        type: REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_FAIL,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_FAIL,
      data: { success: false },
    });
  }
}

function* sendSharedSpaceEmailToNotRegistered(action) {
  try {
    const { data, ownerId, teamId } = action.data;
    const response = yield call(
      sendEmailToNotRegisteredURL,
      data,
      ownerId,
      teamId
    );
    if (response?.data?.success) {
      yield put({
        type:
          REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type:
          REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_FAIL,
      data: { success: false },
    });
  }
}

function* sendTeamInvitationEmail(action) {
  try {
    const { data, teamId } = action.data;
    const response = yield call(checkRegisteredUsersURL, data[0].email);
    if (response?.data?.success) {
      yield* sendSharedSpaceEmail({
        data: { emails: [data[0].email], teamId },
      });
    }
  } catch (error) {
    yield* sendSharedSpaceEmailToNotRegistered(action);
  }
}

function* changeUserRole(action) {
  try {
    const response = yield call(changeCompanySharedSpaceUserRole, action.data);

    yield put({
      type: REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_FAIL,
      data: { message: error.message },
    });
  }
}

function* toggleSpaceUserStatus(action) {
  try {
    const response = yield call(changeSpaceUserStatus, action.data);

    yield put({
      type: REDUX_ACTIONS.CHANGE_SPACE_USER_STATUS_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CHANGE_SPACE_USER_STATUS_FAIL,
      data: { message: error.message },
    });
  }
}

function* toggleCompanyUserStatus(action) {
  try {
    const { userId, teamId } = action.data;
    const response = yield call(
      toggleCompanySharedSpaceUserStatus,
      userId,
      teamId
    );

    yield put({
      type: REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_FAIL,
      data: { message: error.message },
    });
  }
}
function* deleteCompanyUser(action) {
  try {
    const response = yield call(deleteCompanySharedSpaceUser, action.data);

    yield put({
      type: REDUX_ACTIONS.DELETE_COMPANY_SHARED_SPACE_USER_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.DELETE_COMPANY_SHARED_SPACE_USER_FAIL,
      data: { message: error.message },
    });
  }
}

function* removeSpaceUser(action) {
  try {
    const response = yield call(removeFromSpace, action.data);

    yield put({
      type: REDUX_ACTIONS.REMOVE_FROM_SPACE_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REMOVE_FROM_SPACE_FAIL,
      data: { message: error.message },
    });
  }
}

function* renameTeamSpace(action) {
  try {
    const response = yield call(renameTeam, action.data);

    yield put({
      type: REDUX_ACTIONS.RENAME_TEAM_SPACE_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.RENAME_TEAM_SPACE_FAIL,
      data: { message: error.message },
    });
  }
}

function* getUserTeamSpaceDetails(action) {
  try {
    const response = yield call(getTeamSpaceDetails, action.data);
    const currentPackageConfig = response.data?.data?.packageConfig;
    yield put(fetchTeamSpaceInfoSuccess(response.data?.data));
    yield put(
      updatePackageConfig({
        // Both are the same now
        packageConfig: currentPackageConfig,
        limitedPackageConfig: currentPackageConfig,
      })
    );
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.FETCH_TEAM_SPACE_INFO_ERROR,
      data: { message: error.message },
    });
  }
}

function* getTeamSpaceDeletionStatus() {
  try {
    const response = yield call(getTeamSpaceDeletionStatusURL);
    yield put({
      type: REDUX_ACTIONS.GET_TEAM_SPACES_DELETION_STATUS_SUCCESS,
    });
    if (response.data?.data?.haveAdmin) return;
    yield put({
      type: REDUX_ACTIONS.OPEN_BANNER,
      data: {
        deleteAccountAlertType: 'deleteAccountWarning',
      },
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_TEAM_SPACES_DELETION_STATUS_ERROR,
      data: { message: error.message },
    });
  }
}

function* getAllTeamSpaces() {
  try {
    const selectedTeamSpaceResponse = yield call(getLastSelectedTeamSpace);
    if (
      selectedTeamSpaceResponse.data &&
      selectedTeamSpaceResponse.data.data &&
      selectedTeamSpaceResponse.data.data.teamId
    ) {
      yield put({
        type: REDUX_ACTIONS.FETCH_LAST_SELECTED_TEAM_SPACE_SUCCESS,
        data: selectedTeamSpaceResponse.data.data,
      });
    }

    const teamSpaces = yield call(getTeamSpaces);
    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_TEAM_SPACES_SUCCESS,
      data: teamSpaces.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_TEAM_SPACES_ERROR,
      data: { message: error.message },
    });
  }
}

function* saveSelectedTeamSpace(action) {
  try {
    const response = yield call(saveLastSelectedTeamSpace, action.data);
    yield put({
      type: REDUX_ACTIONS.SAVE_LAST_SELECTED_TEAM_SPACE_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SAVE_LAST_SELECTED_TEAM_SPACE_ERROR,
      data: { message: error.message },
    });
  }
}

function* createNewTeamSpace(action) {
  try {
    const response = yield call(createTeamSpace, action.data);
    const { teamId } = response.data?.data;
    if (!response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.CREATE_TEAM_SPACE_ERROR,
        data: { success: false, message: response.data.message },
      });
      return;
    }
    yield* getAllTeamSpaces();
    goToTeamUrl({
      teamId,
    });
    yield put({
      type: REDUX_ACTIONS.CREATE_TEAM_SPACE_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CREATE_TEAM_SPACE_ERROR,
      data: { success: false, message: error.message },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* companySharedSpaceSaga() {
  yield takeLatest(
    REDUX_ACTIONS.ADD_TO_COMPANY_SHARED_SPACE,
    addToCompanySharedSpace
  );
  yield takeLatest(
    REDUX_ACTIONS.GET_COMPANY_SHARED_USER,
    getCompanySharedSpaceUsers
  );
  yield takeLatest(
    REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_SEND,
    sendSharedSpaceEmail
  );
  yield takeLatest(
    REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND,
    sendSharedSpaceEmailToNotRegistered
  );
  yield takeLatest(
    REDUX_ACTIONS.ADD_TO_TEAM_EMAIL_SEND,
    sendTeamInvitationEmail
  );
  yield takeLatest(
    REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_STATUS,
    toggleCompanyUserStatus
  );
  yield takeLatest(
    REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_ROLE,
    changeUserRole
  );
  yield takeLatest(
    REDUX_ACTIONS.DELETE_COMPANY_SHARED_SPACE_USER,
    deleteCompanyUser
  );
  yield takeLatest(REDUX_ACTIONS.FETCH_ALL_TEAM_SPACES, getAllTeamSpaces);
  yield takeLatest(REDUX_ACTIONS.CREATE_TEAM_SPACE, createNewTeamSpace);
  yield takeLatest(
    REDUX_ACTIONS.FETCH_TEAM_SPACE_INFO,
    getUserTeamSpaceDetails
  );
  yield takeEvery(
    REDUX_ACTIONS.GET_TEAM_SPACES_DELETION_STATUS,
    getTeamSpaceDeletionStatus
  );
  yield takeLatest(
    REDUX_ACTIONS.SAVE_LAST_SELECTED_TEAM_SPACE,
    saveSelectedTeamSpace
  );
  yield takeLatest(REDUX_ACTIONS.ADD_TO_SPACE_REQUEST, addMembersToSpace);
  yield takeLatest(REDUX_ACTIONS.REMOVE_FROM_SPACE_REQUEST, removeSpaceUser);
  yield takeLatest(
    REDUX_ACTIONS.CHANGE_SPACE_USER_STATUS,
    toggleSpaceUserStatus
  );
  yield takeLatest(REDUX_ACTIONS.RENAME_TEAM_SPACE_REQUEST, renameTeamSpace);
}
