import debug from 'debug';
import { useSelector, useDispatch } from 'react-redux';
import type { IUsage, NumericLimits } from '../constants/userPackage';
import { getUpdateUsageAction } from '../actions/usageActions';
import { getOpenBannerAction } from '../actions/bannersActions';
import { State } from '../types/state';
import { FileTypeExtended } from '../types';

const log = debug('useLimits');

type LimitedFeatureKeys =
  | keyof IUsage
  | keyof Pick<NumericLimits, 'librarySize'>;

type UseLimitReturnType = (
  feature: LimitedFeatureKeys,
  guardedAction?: Function
) => number;

interface GetUserOwnedRecordingCountProps {
  recordings: FileTypeExtended[];
  userId: string;
}

const getUserOwnedRecordingsCount = ({
  recordings = [],
  userId = '',
}: GetUserOwnedRecordingCountProps) => {
  if (recordings && Array.isArray(recordings)) {
    const userOwnedRecordings = recordings.filter(
      (r) => r?.ownerId === userId || r?.teamId === userId
    );
    return userOwnedRecordings?.length || 0;
  }
  return 0;
};

export const useLimits = () => {
  const packageConfig =
    useSelector((state: State) => state.auth.packageConfig) || {};
  const usage = useSelector((state: State) => state.auth.user.data.usage) || {};
  const recordings = useSelector((state: State) => state.library.team);
  const userId = useSelector((state: State) => state.auth.user.data.id);
  const dispatch = useDispatch();
  const openBanner = getOpenBannerAction(dispatch);
  const updateUsage = getUpdateUsageAction(dispatch);
  const guardLimit = (feature: LimitedFeatureKeys, callback?: Function) => {
    let currentFeatureUsage = 0;
    const maxFeatureUsage = packageConfig[feature] || 0;
    switch (feature) {
      case 'librarySize':
        // TODO: This is a temporary fix for the library size limit. This needs to be moved to the Usage document in backend
        currentFeatureUsage =
          getUserOwnedRecordingsCount({
            recordings,
            userId,
          }) || 0;
        break;
      default:
        currentFeatureUsage = usage[feature] || 0;
        if (maxFeatureUsage - currentFeatureUsage > 0) {
          updateUsage({ [feature]: 1 });
        }
    }
    const remainingFeatureUsage = maxFeatureUsage - currentFeatureUsage;
    if (remainingFeatureUsage <= 0) {
      // If limit is reached, open banner and return remaining usage
      openBanner({
        limitsBanner: { open: true, limit: feature },
      });
      return remainingFeatureUsage;
    }
    // If limit is not reached, update usage and return remaining usage
    if (callback) {
      try {
        callback();
      } catch (error) {
        log(`Error in callback: ${feature} guard`, error);
      }
    }
    return remainingFeatureUsage - 1;
  };
  return guardLimit as UseLimitReturnType;
};

export default useLimits;
