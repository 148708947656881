import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIAL_VALUE.PUBLIC_SHARED_CONTENT, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.GET_PUBLIC_SHARE_CONTENT_SUCCESS:
      return action.data;
    case REDUX_ACTIONS.GET_PUBLIC_SHARE_CONTENT_FAIL:
      return {};
    default:
      return state;
  }
};
