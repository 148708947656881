// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.STORAGE_LIMITATIONS, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.STORAGE_LIMITATIONS_REQUEST:
      return state;
    case REDUX_ACTIONS.STORAGE_LIMITATIONS_RESPONSE:
      return { ...state, ...action.data };
    default:
      return state;
  }
};
