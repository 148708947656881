// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.SUBSCRIPTION, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.API_GET_CHECKOUTSESSION_REQUEST:
      return {
        ...state,
        isLoading: true,
        checkoutSessionId: null,
      };
    case REDUX_ACTIONS.API_GET_CHECKOUTSESSION_RESPONSE:
      return {
        ...state,
        isLoading: false,
        checkoutSessionId: action.data,
      };
    case REDUX_ACTIONS.API_GET_CUSTOMERPORTALURL_RESPONSE:
      return {
        ...state,
        customerPortalUrl: action.data,
      };
    case REDUX_ACTIONS.CLEAR_CUSTOMERPORTALURL:
      return {
        ...state,
        customerPortalUrl: null,
      };
    case REDUX_ACTIONS.RESET_STRIPE_PACKAGE_CHANGED:
      return {
        ...state,
        checkoutSessionStatus: null,
        stripePackageChanged: false,
      };
    case REDUX_ACTIONS.GET_UNSUBSCRIBE_MODAL_SUCCESS:
      return {
        ...state,
        unsubscribeModal: action.data,
      };
    case REDUX_ACTIONS.RESET_UNSUBSCRIBE_MODAL_SUCCESS:
      return {
        ...state,
        unsubscribeModal: null,
      };
    case REDUX_ACTIONS.CHURNKEY_CANCEL_PLAN_RESPONSE:
      return {
        ...state,
        ...action.data,
      };
    case REDUX_ACTIONS.CHANGE_CATEGORY_FAIL:
    case REDUX_ACTIONS.CHURNKEY_CLEAR_DATA:
      return {
        ...state,
        userHash: '',
        customerId: '',
        subscriptionId: '',
      };
    default:
      return state;
  }
};
