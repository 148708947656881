import axios, { AxiosHeaders } from 'axios';
import type { AxiosInstance } from 'axios';
import debug from 'debug';

import { Uploader } from './Uploader';

const log = debug('app:UploadManager');

export interface RecordingArgs {
  recordingId: string;
  recorderName: string;
  recorderEmail: string;
  folderId: string;
  teamId: string;
  profile: string;
  newMetadata?: ArrayBuffer;
  oldMetadataSize?: number;
  title?: string;
  description?: string;
}

type FinalizeArgs = Omit<
  RecordingArgs,
  'recordingId' | 'oldMetadataSize' | 'newMetadata' | 'title' | 'profile'
>;

export default class MultipartUploader extends Uploader {
  private partNumber = 1;

  private firstPart: Blob;

  private recordingArgs: RecordingArgs;

  private base: string;

  private uploadId: string;

  private headers:
    | { headers: { Authorization: string; 'Content-Type': string } }
    | undefined;

  private async initialize() {
    const response = await this.api.post(
      `${this.base}?profile=${this.recordingArgs.profile || ''}`,
      undefined,
      this.headers
    );
    if (response.status <= 299 && response.data.success === true) {
      this.uploadId = response.data.data.uploadId;
      return;
    }

    throw new Error('Cannot initialize');
  }

  private async createUploadUrl() {
    const response = await this.api.put(
      `${this.base}/${this.uploadId}/parts/${this.partNumber}?profile=${
        this.recordingArgs.profile || ''
      }`,
      undefined,
      this.headers
    );
    if (response.status <= 299 && response.data.success === true) {
      return response.data.data.uploadUrl;
    }

    throw new Error('Cannot create uploadUrl');
  }

  private async uploadPart(content: Blob) {
    try {
      return await this.uploadPartDirectly(content);
    } catch (error) {
      return this.uploadPartViaBackend(content);
    }
  }

  private async uploadPartDirectly(content: Blob) {
    const url = await this.createUploadUrl();

    const startTime = Date.now();
    const response = await axios.put(url, content, {
      headers: ([] as unknown) as AxiosHeaders, // axios doesn't help by adding arbitrary headers
    });
    const timeTaken = Date.now() - startTime;
    log(
      `UploadManager/MultipartUploader/uploadPart: ${
        this.partNumber
      }, time taken: ${(timeTaken / 1000).toFixed(2)} s, speed: ${(
        (content.size * 8) /
        1000 /
        timeTaken
      ).toFixed(2)} Mb/s`
    );
    if (response.status <= 299) {
      return response.headers.etag;
    }

    throw new Error(`Part upload (direct) failed ${this.partNumber}`);
  }

  // In case of error, we try rerouting the data via backend
  private async uploadPartViaBackend(data: Blob): Promise<void> {
    const formData = new FormData();
    formData.append('blob', data);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    const startTime = Date.now();
    const response = await this.api.put(
      `${this.base}/${this.uploadId}/parts/${this.partNumber}/data?profile=${
        this.recordingArgs.profile || ''
      }`,
      formData,
      config
    );
    const timeTaken = Date.now() - startTime;
    log(
      `UploadManager/MultipartUploader/uploadPartViaBackend: ${
        this.partNumber
      }, time taken: ${(timeTaken / 1000).toFixed(2)} s, speed: ${(
        (data.size * 8) /
        1000 /
        timeTaken
      ).toFixed(2)} Mb/s`
    );
    if (response.status <= 299) {
      return response.data.data.etag;
    }

    throw new Error(`Part upload (via backend) failed ${this.partNumber}`);
  }

  private async finalize(args: FinalizeArgs) {
    try {
      const response = await this.api.post(
        `${this.base}/${this.uploadId}?profile=${
          this.recordingArgs.profile || ''
        }`,
        args,
        this.headers
      );
      if (response.status <= 299 && response.data.success === true) {
        return response.data.data.file;
      }
    } catch (error) {
      if (
        error?.response?.data?.error?.includes(
          'The file type is not supported.'
        )
      ) {
        throw new Error(`Cannot finalize upload`, {
          cause: 'Unsupported file type',
        });
      }
    }
    throw new Error(`Cannot finalize upload`);
  }

  constructor(
    private api: AxiosInstance,
    private recordingId: string,
    token: string = ''
  ) {
    super();

    this.initialize.bind(this);
    this.createUploadUrl.bind(this);
    this.uploadPart.bind(this);
    this.uploadPartDirectly.bind(this);
    this.uploadPartViaBackend.bind(this);
    this.finalize.bind(this);
    this.init.bind(this);
    this.send.bind(this);
    this.end.bind(this);

    this.base = `/fs/files/${this.recordingId}/multipart-uploads`;
    this.headers = token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      : undefined;
  }

  async init() {
    return this.initialize();
  }

  async send(data: Blob): Promise<void> {
    if (this.partNumber <= 1) {
      this.firstPart = data;
    }
    const etag = await this.uploadPart(data);
    log(etag);
    this.partNumber += 1;
  }

  async end(): Promise<void> {
    // TODO In rare cases (MacOS?), metadata generation still fails, sigh
    if (this.recordingArgs.newMetadata) {
      const body = this.firstPart.slice(this.recordingArgs.oldMetadataSize);
      const firstPartExtended = new Blob([
        this.recordingArgs.newMetadata,
        body,
      ]);

      // Uploading modified (cues added) first part again
      this.partNumber = 1;
      const etag = await this.uploadPart(firstPartExtended);
      log(etag);
    }

    log('recordingArgs at end', this.recordingArgs);

    const args = {
      folderId: this.recordingArgs.folderId,
      name: this.recordingArgs.title,
      description: this.recordingArgs.description,
      recorderName: this.recordingArgs.recorderName,
      recorderEmail: this.recordingArgs.recorderEmail,
      teamId: this.recordingArgs.teamId,
    };
    return this.finalize(args);
  }

  setRecordingArgs(recordingArgs: RecordingArgs) {
    this.recordingArgs = recordingArgs;
  }
}
