import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (
  state: boolean | null = STORE_INITIAL_VALUE.MOVE_STATUS,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.MOVE_RECORDINGS_SUCCESS:
      return action.data.success;
    case REDUX_ACTIONS.MOVE_RECORDINGS_ERROR:
      return false;
    case REDUX_ACTIONS.MOVE_RECORDING_CLOSE_NOTIFICATION:
      return false;
    default:
      return state;
  }
};
