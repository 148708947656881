/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  reprocess,
  statusCheck,
  updateSpeakerRequest,
} from '../../services/fileSystemService';
import { FileTypeExtended, IUpdateSpeakerProps, Speaker } from '../../types';

let intervalId;
const selectId = (state) => state.selectedVideo.recordingId;

const initialState: FileTypeExtended = {} as FileTypeExtended;

export const updateSpeaker = createAsyncThunk(
  'selectedVideo/updateSpeaker',
  async (
    data: IUpdateSpeakerProps
  ): Promise<{
    [key: string]: Speaker;
  }> => {
    const response = await updateSpeakerRequest(data);
    return response.data?.data;
  }
);

export const reprocessSelectedVideo = createAsyncThunk(
  'selectedVideo/reprocessSelectedVideo',
  async (id: string): Promise<{ name: string; description: string }> => {
    const response = await reprocess(id);
    return response.data.data;
  }
);

export const transcriptionStatusCheckStop = createAsyncThunk(
  'selectedVideo/transcriptionStatusCheckStop',
  () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }
);

export const transcriptionStatusCheckStart = createAsyncThunk(
  'selectedVideo/transcriptionStatusCheckStart',
  async (_, { getState, dispatch }): Promise<FileTypeExtended | null> => {
    return new Promise((resolve) => {
      const currentState = getState();
      if (intervalId) {
        clearInterval(intervalId);
      }
      intervalId = setInterval(async () => {
        const response = await statusCheck(selectId(currentState));
        if (response.data?.data !== null) {
          dispatch(transcriptionStatusCheckStop());
          resolve(response.data?.data);
        }
      }, 15000);
    });
  }
);

const selectedVideoSlice = createSlice({
  name: 'selectedVideo',
  initialState,
  reducers: {
    selectVideoFromLibrary: (
      state,
      action: PayloadAction<FileTypeExtended>
    ) => {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSpeaker.fulfilled, (state, action) => {
      return {
        ...state,
        textData: {
          ...state?.textData,
          speakers: action.payload,
        },
      };
    });
    builder.addCase(reprocessSelectedVideo.fulfilled, (state, action) => {
      const { name, description } = action.payload;
      state.name = name;
      state.description = description;
    });
    builder.addCase(
      transcriptionStatusCheckStart.fulfilled,
      (state, action) => {
        if (action.payload != null) {
          return action.payload;
        }
        return state;
      }
    );
  },
});

export const { selectVideoFromLibrary } = selectedVideoSlice.actions;
export default selectedVideoSlice.reducer;
