/* eslint-disable import/named */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
// eslint-disable-next-line import/named
import {
  FormHelperText,
  TextField,
  TextFieldProps,
  Theme,
} from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      // height: 56,
      marginRight: 'unset',
      fontSize: '14px',
      fontFamily: 'Inter',
      '& .MuiInputBase-input': {
        padding: '13px 18px',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '&:hover': {
        borderColor: 'red',
        opacity: 38,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        // color: 'red',
      },
      '& .MuiInputLabel-outlined': {
        fontSize: '14px',
        fontFamily: 'Inter',
      },
      '& .MuiOutlinedInput-root ': {
        paddingLeft: 0,
        paddingTop: 0,
        boderRadius: '5px',
      },
      '& .MuiFormLabel-root': {
        transform: 'translate(18px, 13px) scale(1)',
        fontSize: '14px',
        fontFamily: 'Inter',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(16px, -6px) scale(0.75)',
      },
      margin: theme.spacing(1),
    },
    description: {
      width: '100%',
      height: '3rem !important',
      marginTop: '3vh',
      marginRight: 0,
      '& .MuiInputBase-input': {
        padding: '13px 18px',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        // color: theme.palette.secondary.dark,
      },
    },
    helperText: {
      marginLeft: '-13px',
    },
  });
};

export interface ITextFieldProps
  extends WithStyles<TextFieldProps & typeof styles> {
  id?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value?: unknown;
  label?: React.ReactNode;
  rowsMax?: number;
  helperText?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  autoFocus?: boolean;
  onFocus?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>;
  selectedView?: boolean;
  onKeyPress?: React.KeyboardEventHandler<HTMLDivElement>;
  customClass?: string;
  editorTextField?: boolean;
  rows?: number;
  multiline?: boolean;
  alignHelperText?: boolean;
  recordingTitleAndDescriptionModal?: boolean;
  width?: string;
}

export const TextFieldComponent: React.FC<ITextFieldProps> = ({
  classes,
  id,
  label,
  placeholder,
  customClass,
  value,
  onKeyPress,
  onChange,
  onKeyDown,
  onBlur,
  rowsMax,
  onFocus,
  helperText = '',
  selectedView,
  error = false,
  disabled = false,
  required = false,
  autoFocus = false,
  editorTextField,
  rows,
  multiline,
  alignHelperText,
  recordingTitleAndDescriptionModal,
  width,
  ...otherProps
}: ITextFieldProps) => {
  return (
    <TextField
      {...otherProps}
      id={id}
      variant="outlined"
      label={label}
      placeholder={placeholder}
      className={clsx(
        customClass,
        selectedView ? classes.description : classes.root
      )}
      style={{
        height: `${!recordingTitleAndDescriptionModal && '56px'}`,
        width: `${width || '100%'}`,
      }}
      onChange={onChange}
      value={value}
      helperText={
        error && (
          <FormHelperText
            className={clsx(alignHelperText && classes.helperText)}
          >
            {helperText}
          </FormHelperText>
        )
      }
      error={error}
      disabled={disabled}
      required={required}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      autoFocus={autoFocus}
      rowsMax={rowsMax}
      onFocus={onFocus}
      onKeyPress={onKeyPress}
      rows={rows}
      multiline={multiline}
      // @ts-ignore
      inputProps={editorTextField && { maxLength: 45 }}
    />
  );
};

export default withStyles(styles)(TextFieldComponent);
