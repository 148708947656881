/* eslint-disable import/prefer-default-export */
import { put, takeLatest, call, select } from 'redux-saga/effects';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  create,
  initialize,
  finalize,
  undo,
  sendEmail,
  sendMultipleEmail,
  copyLink,
  getAllRequests,
  toggleRequestRecordingStatus,
  deleteRequestRecording,
} from '../../services/recordingRequestService';
import { setHeader } from '../../lib/baseAxios';
import { requestRecordingInitSuccess } from '../../features/auth/authSlice';

const getRequest = (state) => state.recordingRequest;

// Called by secondary (possibly unregistered) user
function* initSaga(action) {
  try {
    const response = yield call(initialize, action.data);
    if (response?.data?.success) {
      yield call(setHeader, response.data?.data?.token);
      yield put(requestRecordingInitSuccess(response.data));
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_INIT_FAILURE,
        data: response?.data?.error,
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_INIT_FAILURE,
      data: error.message,
    });
  }
}

// Called by secondary (possibly unregistered) user to undo last recording
function* undoSaga() {
  try {
    const { token } = yield select(getRequest);
    const response = yield call(undo, token);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_RETAKE_SUCCESS,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_RETAKE_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_RETAKE_FAILURE,
    });
  }
}

// Called by secondary (possibly unregistered) user
function* finishSaga() {
  try {
    const { token } = yield select(getRequest);
    const response = yield call(finalize, token);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_FINISH_SUCCESS,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_FINISH_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_FINISH_FAILURE,
    });
  }
}

// Called by requester
function* createSaga(action) {
  try {
    const response = yield call(create, action.data);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_CREATE_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_CREATE_FAILURE,
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_CREATE_FAILURE,
    });
  }
}

function* sendEmailSaga(action) {
  try {
    const response = yield call(sendEmail, action?.data);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_EMAIL_SEND_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_EMAIL_SEND_FAILURE,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_EMAIL_SEND_FAILURE,
      data: { success: false },
    });
  }
}

function* sendMultipleEmailSaga(action) {
  try {
    const response = yield call(sendMultipleEmail, action?.data);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_FAILURE,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_FAILURE,
      data: { success: false },
    });
  }
}

function* copyLinkSaga(action) {
  try {
    const response = yield call(copyLink, action?.data);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_COPY_LINK_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.REQUEST_RECORDING_COPY_LINK_FAILURE,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.REQUEST_RECORDING_COPY_LINK_FAILURE,
      data: { success: false },
    });
  }
}

function* getRequests() {
  try {
    const response = yield call(getAllRequests);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.FETCH_ALL_REQUEST_RECORDINGS_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.FETCH_ALL_REQUEST_RECORDINGS_FAILURE,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_REQUEST_RECORDINGS_FAILURE,
      data: { success: false },
    });
  }
}

function* changeRequestRecordingStatus(action) {
  try {
    const response = yield call(toggleRequestRecordingStatus, action.data);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.CHANGE_REQUEST_RECORDING_STATUS_SUCCESS,
        data: response.data?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.CHANGE_REQUEST_RECORDING_STATUS_FAIL,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CHANGE_REQUEST_RECORDING_STATUS_FAIL,
      data: { message: error.message },
    });
  }
}

function* deleteRequest(action) {
  try {
    const response = yield call(deleteRequestRecording, action.data);

    yield put({
      type: REDUX_ACTIONS.DELETE_REQUEST_RECORDING_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.DELETE_REQUEST_RECORDING_FAIL,
      data: { message: error.message },
    });
  }
}

export function* recordingRequestSaga() {
  yield takeLatest(REDUX_ACTIONS.REQUEST_RECORDING_CREATE, createSaga);
  yield takeLatest(REDUX_ACTIONS.REQUEST_RECORDING_INIT, initSaga);
  yield takeLatest(REDUX_ACTIONS.REQUEST_RECORDING_RETAKE, undoSaga);
  yield takeLatest(REDUX_ACTIONS.REQUEST_RECORDING_FINISH, finishSaga);
  yield takeLatest(REDUX_ACTIONS.REQUEST_RECORDING_EMAIL_SEND, sendEmailSaga);
  yield takeLatest(
    REDUX_ACTIONS.REQUEST_RECORDING_MULTIPLE_EMAIL_SEND,
    sendMultipleEmailSaga
  );
  yield takeLatest(REDUX_ACTIONS.REQUEST_RECORDING_COPY_LINK, copyLinkSaga);
  yield takeLatest(REDUX_ACTIONS.FETCH_ALL_REQUEST_RECORDINGS, getRequests);
  yield takeLatest(
    REDUX_ACTIONS.CHANGE_REQUEST_RECORDING_STATUS,
    changeRequestRecordingStatus
  );
  yield takeLatest(REDUX_ACTIONS.DELETE_REQUEST_RECORDING, deleteRequest);
}
