/* eslint-disable brace-style */
/**
 * @param {Blob} blob
 *
 * @returns {Promise<Number>} Blob duration in seconds.
 */
export default async function getBlobDuration(blob) {
  const tempVideoEl = document.createElement('video');

  const durationP = new Promise((resolve) =>
    tempVideoEl.addEventListener('loadedmetadata', () => {
      // Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
      if (tempVideoEl.duration === Infinity) {
        tempVideoEl.currentTime = Number.MAX_SAFE_INTEGER;
        tempVideoEl.ontimeupdate = () => {
          tempVideoEl.ontimeupdate = null;
          resolve(tempVideoEl.duration);
          tempVideoEl.currentTime = 0;
        };
      }
      // Normal behavior
      else {
        resolve(tempVideoEl.duration);
      }
    })
  );

  if (typeof blob === 'string' || blob instanceof String) {
    tempVideoEl.src = blob as string;
  } else {
    tempVideoEl.src = window.URL.createObjectURL(blob);
  }

  return durationP;
}

export function makedTwoDigits(time) {
  if (time < 10) {
    return `0${time}`;
  }
  return time;
}

/**
 *  format to hh:mm:ss
 * @param duration in seconds
 */
export function formatDuration(duration) {
  let formattedDuration = '';
  if (
    typeof duration !== 'undefined' &&
    duration &&
    (typeof duration === 'string' || duration instanceof String)
  ) {
    const seconds = Math.floor(Number(duration)); // TODO make sure this works
    const hh = makedTwoDigits(Math.floor(seconds / 3600));
    const mm = makedTwoDigits(Math.floor((seconds - hh * 3600) / 60));
    const ss = makedTwoDigits(seconds - hh ** 3600 - mm * 60);
    formattedDuration = `${hh}:${mm}:${ss}`;
  }

  return formattedDuration;
}
