/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../../constants/initialstore';

const initialState = STORE_INITIATE_VALUE.REALTIME_UPLOADER;

interface realtimeUploaderProgressData {
  loaded: number;
  total: number;
}

interface realtimeUploaderErrorData {
  message: string;
}

const realtimeUploaderSlice = createSlice({
  name: 'realtimeUploader',
  initialState,
  reducers: {
    realtimeUploaderInit(state, action) {
      noop();
    },
    realtimeUploaderConnected(state) {
      state.state = 'connected';
    },
    realtimeUploaderProgress(
      state,
      action: PayloadAction<realtimeUploaderProgressData>
    ) {
      state.progress = action.payload;
    },
    realtimeUploaderError(
      state,
      action: PayloadAction<realtimeUploaderErrorData>
    ) {
      state.state = 'error';
    },
    realtimeUploaderReset(state) {
      return initialState;
    },
  },
});

export const {
  realtimeUploaderInit,
  realtimeUploaderConnected,
  realtimeUploaderProgress,
  realtimeUploaderError,
  realtimeUploaderReset,
} = realtimeUploaderSlice.actions;

export default realtimeUploaderSlice.reducer;
