import { connect } from 'react-redux';
import React from 'react';
import RecordingTitleAndDescriptionModal from '../components/Modal/RecordingTitleAndDescriptionModal';
import { getUpdateRecordSettingsAction } from '../actions/settingsActions';
import { RecorderSettings } from '../types/state';

export interface surveySubscribeFeedbackContainerProps {
  isOpen: boolean;
  handleClose: Function;
  getStartedButtonOnClick: Function;
  auth: { recorderSettings: RecorderSettings };
  actions: {
    updateRecordSettings: Function;
  };
}

const RecordingTitleAndDescriptionModalContainer = (
  props: surveySubscribeFeedbackContainerProps
) => {
  const {
    isOpen,
    handleClose,
    getStartedButtonOnClick,
    actions,
    auth: { recorderSettings },
  } = props;
  return (
    <RecordingTitleAndDescriptionModal
      isOpen={isOpen}
      handleClose={handleClose}
      getStartedButtonOnClick={getStartedButtonOnClick}
      actions={actions}
      settings={recorderSettings}
    />
  );
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

const mapDispatchToProps = (dispatch) => ({
  actions: {
    updateRecordSettings: getUpdateRecordSettingsAction(dispatch),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordingTitleAndDescriptionModalContainer);
