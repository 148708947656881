import React from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

interface WebcamOverlayProps {
  isPIP: boolean;
  // eslint-disable-next-line react/require-default-props
  userData?: any;
  browser: { name: string };
  onClick: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: { position: 'relative' },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: '10px',
    },
    webcamVideoPIP: {
      display: 'none',
    },
    iconButton: {
      justifyItems: 'center',
      '&:focus': {
        outline: 'none',
      },
      outline: 'none',
    },
    profileImg: {
      width: '64px',
      borderRadius: '100%',
    },
    eventsPassthrough: {
      pointerEvents: 'none',
    },
    webcamOverlay: {
      top: 0,
      left: 0,
      position: 'absolute',
      backgroundColor: 'gray',
      opacity: 0.8,
      width: '320px',
      height: '240px',
    },
  })
);

function WebcamOverlay({
  onClick,
  isPIP,
  userData = {},
  browser,
}: WebcamOverlayProps) {
  const classes = useStyles();

  return (
    <div
      aria-label="webcam"
      role="button"
      tabIndex={0}
      className={[
        classes.webcamOverlay,
        isPIP && classes.webcamVideoPIP,
        browser?.name === 'Firefox' && classes.eventsPassthrough,
      ]
        .filter(Boolean)
        .join(' ')}
      onKeyPress={(ev) => {
        if (ev.key === 'Enter') {
          onClick();
        }
      }}
      onClick={onClick}
    >
      <div className={classes.content}>
        <IconButton
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={onClick}
          className={classes.iconButton}
          id="profile-icon"
        >
          {userData.profile?.picture ? (
            <img
              className={classes.profileImg}
              src={userData.profile.picture}
              alt="profile"
            />
          ) : (
            <AccountCircle />
          )}
        </IconButton>
        {browser.name === 'Firefox' ? (
          <p style={{ textAlign: 'center' }}>
            Select &lsquo;Watch in Picture-in-Picture&lsquo; from context menu
            to detach camera preview
          </p>
        ) : (
          <p style={{ textAlign: 'center' }}>
            Tap here to detach the camera preview
          </p>
        )}
      </div>
    </div>
  );
}

export default WebcamOverlay;
