import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  FileCopyOutlined,
} from '@material-ui/icons';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { IconButton } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import ShareYourScreenButtonContainer from '../../../containers/ShareYourScreenButtonContainer';
import AnotherRecordingButtonContainer from '../../../containers/AnotherRecordingButtonContainer';
import ScreenContainer from '../Screen/ScreenContainer';
import {
  RECORDER_STATUS,
  PLUGIN_RECORDING_STATUS,
} from '../../../constants/apiSagaConstant';
import './RecorderComponent.scss';
import AudioSettingContainer from '../../../containers/Plgin/PluginAudioSettingContainer';
import RecordingInfo from '../RecordingInfo';
import { config } from '../../../../config';
import BasicButton from '../../StorybookComponents/BasicButton';
import PluginMicrophoneDeviceContainer from '../AudioSetting/PluginMicrophoneDeviceContainer';
import PluginWebcamSelection from './PluginWebcamSelection';
import { IconTextField } from '../../StorybookComponents/TextField';
import { getCheckBrowserCompatibility } from '../../../actions/pluginActions';
import NewRecorder from '../../../features/recorder';
import { webcamSelect } from '../../../features/recorder/recorderSlice';
import { getSharePluginVideoAction } from '../../../actions/libraryActions';
import { getToken } from '../../../lib/localStorageHandler';
import { State } from '../../../types/state';
import WebcamDeviceContainer from '../../../containers/WebcamDeviceContainer';

const useStyles = makeStyles(() =>
  createStyles({
    proceedBtn: {
      '& .MuiButton-contained': {
        '&:hover': {
          backgroundColor: '#363739 !important',
          color: '#FAFAFA',
        },
        borderRadius: '20px',
        border: '1px solid #363739',
        fontFamily: 'Poppins, Helvetica, sans-serif',
        fontWeight: 400,
        fontSize: '13px',
        color: '#363739',
        margin: 0,
        backgroundColor: '#FAFAFA !important',
        textTransform: 'none',
        boxShadow: 'none',
      },
      '& .Mui-disabled': {
        backgroundColor: '#E0E0E0 !important',
        color: '#888888 !important',
        border: '1px solid #E0E0E0',
      },
    },
  })
);

export interface PluginRecorderProps {
  finishRecordingCallback: ({ id, url }: { id: string; url: string }) => void;
  onClose: () => void;
}

const PluginRecorder = (props: PluginRecorderProps) => {
  const { finishRecordingCallback, onClose } = props;

  const [isExpand, setIsExpand] = useState(true);
  const classes = useStyles();
  const dispatch = useDispatch();

  const checkBrowserCompatibility = getCheckBrowserCompatibility(dispatch);

  const {
    recorder,
    app,
    plugin,
    pluginRecording,
    browser,
    teamId,
    shareLink,
  } = useSelector((state: State) => ({
    recorder: state.recorder,
    app: state.app,
    plugin: state.plugin,
    pluginRecording: state.pluginRecording,
    browser: state.browser,
    teamId: state.companySharedSpace.selectedTeamSpaceInfo?.teamId || '',
    shareLink: state.shareLink,
    realtimeUploader: state.realtimeUploader,
    fileUploadProgress: state.fileUploadProgress,
  }));

  const { settings } = recorder;
  const { microphoneAudio } = settings.audioSettings;
  const { webCam } = settings.videoSettings;
  const { generatePublicUrl, formatProfile } = settings.otherSettings;
  const { hideLogo, identificationForm } = settings.uiSettings;
  const {
    status: pluginStatus,
    title = '',
    name = '',
    email = '',
  } = pluginRecording;
  const {
    status,
    pause,
    displayRecording,
    media,
    muteRecorderMic,
    muteRecorderBrowserAudio,
    audioRecorderEnable = false,
  } = recorder;
  const { folderId } = plugin;

  const sharePluginVideo = getSharePluginVideoAction(dispatch);

  const handleClose = async () => {
    onClose();
  };

  useEffect(() => {
    try {
      checkBrowserCompatibility();
    } catch (e) {
      setTimeout(() => {
        checkBrowserCompatibility();
      }, 1000);
    }
  }, []);

  const getWebcamSetting = () => {
    switch (status) {
      case RECORDER_STATUS.NOT_READY:
        return <div>{webCam && <WebcamDeviceContainer />}</div>;

      default:
        return null;
    }
  };

  const getAudioSetting = () => {
    switch (status) {
      case RECORDER_STATUS.NOT_READY:
        if (identificationForm && pluginStatus === PLUGIN_RECORDING_STATUS.INIT)
          return <RecordingInfo pluginRecording={pluginRecording} />;
        return (
          <div>
            <AudioSettingContainer />
            {microphoneAudio && <PluginMicrophoneDeviceContainer />}
          </div>
        );
      default:
        return null;
    }
  };

  const getVisibleButton = () => {
    switch (status) {
      case RECORDER_STATUS.NOT_READY:
        if (identificationForm) {
          if (pluginStatus === PLUGIN_RECORDING_STATUS.NOT_READY)
            return <ShareYourScreenButtonContainer settings={settings} />;
          return null;
        }
        return <ShareYourScreenButtonContainer settings={settings} />;
      case RECORDER_STATUS.READY:
        return null;
      case RECORDER_STATUS.RUNNING:
        return null;
      case RECORDER_STATUS.STOPPED:
        return (
          <div className="another-recording-button">
            <AnotherRecordingButtonContainer />
          </div>
        );
      default:
        return null;
    }
  };

  const toggleExpand = () => {
    setIsExpand(!isExpand);
  };

  const attachUrl = `${config.appBaseUrl}/#/library/${teamId}/plugin/${app.uuid}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(attachUrl);
  };

  const closeHandler = () => {
    const data = {
      id: app.uuid,
      url: shareLink || attachUrl,
    };
    finishRecordingCallback(data);
    handleClose();
  };

  useEffect(() => {
    if (shareLink && status === RECORDER_STATUS.STOPPED && generatePublicUrl) {
      closeHandler();
    }
  }, [shareLink]);

  // callfinishRecordingCallBack function and close the plugin
  const handleOnClick = () => {
    if (generatePublicUrl) {
      sharePluginVideo({
        id: app.uuid,
        teamId,
      });
      return;
    }
    closeHandler();
  };

  // Recording selection
  const onClickRecordingSelection = (selection) => {
    const webcamOnlyEnable = selection === 'webcam_only';
    const webcamEnable = selection === 'screen_and_webcam' || webcamOnlyEnable;

    dispatch(webcamSelect({ webcamEnable, webcamOnlyEnable }));
  };

  const recordingArgs = {
    recordingId: app.uuid,
    recorderName: name,
    recorderEmail: email,
    title,
    folderId: folderId || '',
    teamId,
    profile: formatProfile || 'webm',
  };

  return (
    <div>
      {status !== RECORDER_STATUS.RUNNING && (
        <div className="recorder-root">
          <div
            id="recorder-root"
            className={
              hideLogo &&
              status !== RECORDER_STATUS.READY &&
              status !== RECORDER_STATUS.RUNNING
                ? 'plugin-margin-top'
                : undefined
            }
          >
            {status === RECORDER_STATUS.RUNNING && (
              <div className="expand-buttons">
                <IconButton onClick={toggleExpand} size="small">
                  {isExpand ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowUpIcon />
                  )}
                </IconButton>
              </div>
            )}
            <div className="plugin-logo-wrapper">
              {!hideLogo && (
                <>
                  <span className="red">SCREEN</span>
                  <span>APP</span>
                </>
              )}
              {!plugin?.pluginMode &&
                plugin.errorText &&
                plugin.errorText !== '' && (
                  <>
                    <div className="plugin-error-title">
                      Your free trial is over!
                    </div>
                    <div className="plugin-error-text">
                      To upgrade or extend your trial please contact
                      support@screenapp.io
                    </div>
                  </>
                )}
            </div>
            <Row className="justify-content-center mobile-warning">
              <Col className="text-box-mobile">
                <h2>Sorry</h2>
                <span>We currently don&apos;t support Mobile browsers.</span>
              </Col>
            </Row>
            {(status === RECORDER_STATUS.STOPPED ||
              (status === RECORDER_STATUS.RUNNING && isExpand)) && (
              <Row className="justify-content-center content">
                <div className="screen-wrapper">
                  <ScreenContainer />
                </div>
              </Row>
            )}
            <Row className="justify-content-center">
              {status === 'NOT_READY' &&
                !(
                  identificationForm &&
                  pluginStatus === PLUGIN_RECORDING_STATUS.INIT
                ) && (
                  <Col>
                    <PluginWebcamSelection
                      onClick={onClickRecordingSelection}
                      recorder={recorder}
                    />
                  </Col>
                )}
            </Row>
            {status === RECORDER_STATUS.STOPPED && (
              <div className="button-align-wrapper">
                <div className="copy-link-wrapper">
                  <IconTextField
                    id="share-link"
                    label="Link"
                    placeholder="link"
                    value={attachUrl}
                    icon={<FileCopyOutlined />}
                    iconOnClick={copyToClipboard}
                  />
                </div>
                <div className="link-add-to-form-wrapper">
                  <BasicButton
                    label="Proceed"
                    id="plugin-link-add-to-form"
                    filled
                    roundBorder
                    fullWidth
                    noMargin
                    onClick={() => handleOnClick()}
                    customClass={classes.proceedBtn}
                  />
                </div>
              </div>
            )}
            {getWebcamSetting()}
            {plugin?.pluginMode && getAudioSetting()}
            <div className="button-wrapper">
              {browser.name !== 'Safari' &&
                plugin?.pluginMode &&
                getVisibleButton()}
            </div>
          </div>
        </div>
      )}
      {recorder.showFloatingRecorder && (
        <NewRecorder
          recorderParams={{
            status,
            pause,
            displayRecording,
            media,
            muteRecorderMic,
            muteRecorderBrowserAudio,
            audioRecorderEnable,
          }}
          settings={settings}
          token={getToken() || ''}
          recordingArgs={recordingArgs}
        />
      )}
    </div>
  );
};

export default PluginRecorder;
