import { bindActionCreators } from 'redux';
import { actionCreateStoreUpdateFactory } from './actionCreator';
import {
  ACTION_ATTR as ATTRS,
  REDUX_ACTIONS as ACTION_TYPES,
} from '../constants/apiSagaConstant';

export const getApiGetCustomerPortalUrlAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.API_GET_CUSTOMERPORTALURL_REQUEST,
      ATTRS.PAYLOAD
    ),
    dispatch
  );

export const getClearCustomerPortalUrl = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.CLEAR_CUSTOMERPORTALURL,
      ATTRS.DATA
    ),
    dispatch
  );

export const getUpdateRecordSettingsAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPDATE_RECORD_SETTINGS,
      ATTRS.DATA
    ),
    dispatch
  );
