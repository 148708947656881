// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import { isATeamFSEntity } from '../lib/teamSpaceUtils';

export default (state = STORE_INITIATE_VALUE.FOLDERS, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FETCH_ALL_FOLDERS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case REDUX_ACTIONS.FETCH_ALL_FOLDERS_SUCCESS:
      return {
        ...state,
        personal: { ...state.personal, data: action.data.personalFolders },
        plugin: { ...state.plugin, data: action.data.pluginFolders },
        isLoading: false,
        isSuccess: true,
      };
    case REDUX_ACTIONS.FETCH_TEAMSPACE_FOLDERS_SUCCESS:
      return {
        ...state,
        team: {
          ...state.team,
          data: action.data.teamFolders,
        },
        isLoading: false,
        isSuccess: true,
      };
    case REDUX_ACTIONS.MIGRATE_OLD_FILESYSTEM_SUCCESS:
      return {
        ...state,
        personal: { ...state.personal, data: action.data.personalFolders },
        plugin: { ...state.plugin, data: action.data.pluginFolders },
        isLoading: false,
        isSuccess: true,
      };
    case REDUX_ACTIONS.CREATE_FOLDER_SUCCESS: {
      // eslint-disable-next-line no-nested-ternary
      const location = isATeamFSEntity(action.data)
        ? 'team'
        : action.data.provider === 'S3:plugin'
        ? 'plugin'
        : 'personal';
      return {
        ...state,
        [location]: {
          ...state[location],
          data: [...state[location].data, action.data],
        },
        isLoading: false,
        isSuccess: true,
      };
    }
    case REDUX_ACTIONS.DELETE_FOLDER_SUCCESS:
      return {
        ...state,
        personal: {
          ...(action.data.libraryTab === 'personal'
            ? { selectedFolder: null, expanded: [] }
            : state.personal),
          data: action.data.personalFolders,
        },
        plugin: {
          ...(action.data.libraryTab === 'plugin'
            ? { selectedFolder: null, expanded: [] }
            : state.plugin),
          data: action.data.pluginFolders,
        },
        isLoading: false,
        isSuccess: true,
      };
    case REDUX_ACTIONS.FETCH_ALL_FOLDERS_ERROR:
      return {
        ...state,
        personal: { ...state.personal, data: [] },
        plugin: { ...state.plugin, data: [] },
        isLoading: false,
        isSuccess: false,
      };
    case REDUX_ACTIONS.FETCH_TEAMSPACE_FOLDERS_ERROR:
      return {
        ...state,
        team: { ...state.team, data: [] },
        isLoading: false,
        isSuccess: false,
      };
    case REDUX_ACTIONS.UPDATE_SELECTED_FOLDER: {
      return {
        ...state,
        [action.data.libraryTab]: {
          ...state[action.data.libraryTab],
          selectedFolder: action.data.folder,
        },
      };
    }
    case REDUX_ACTIONS.UPDATE_EXPANDED_FOLDERS: {
      const { location, folders } = action.data;
      return {
        ...state,
        [location]: { ...state[location], expanded: folders },
      };
    }
    case REDUX_ACTIONS.CHANGE_CATEGORY_SUCCESS: {
      return {
        ...state,
        public: {
          ...state.public,
          selectedFolder: action.data,
        },
      };
    }
    default:
      return state;
  }
};
