/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-undef */

import { END, eventChannel } from '@redux-saga/core';
import debug from 'debug';

import { VideoStreamMerger } from './streamMerger';
import { config } from '../../config';
import {
  addUnfinishedVideoRecord,
  deleteChunksByRecordingId,
  extendUnfinishedVideoRecord,
  getEntireRecordingFromChunks,
  mergeChunks,
  saveChunk,
  saveRawVideo,
  saveRecording,
} from './storageUtils';
import { sendBlobStream, tryFinishRecord } from './streamUpload';
import { reportError as errorReporter } from './errorReports';
import { getThumbnail } from './ffmpegWrapper';
import {
  getExtension,
  getMime,
  getMimeWithCodecs,
} from '../constants/mediaFormatProfiles';
import { CueGenerator, createCueGenerator } from './CueGenerator';
import { resetTimer, startTimer } from './recorderTimer';
import { RecorderFeatures, RecorderSettings } from '../types/state';

const log = debug('app:recorderWrapper');

const reportError = (payload: any) =>
  errorReporter('handled.RecorderWrapper', payload);

interface RecorderFeatureArguments {
  screenArgs?: any;
  webcamArgs?: any;
  microphoneArgs?: any;
  browserAudioArgs?: any;
}

export const getDevices = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  return devices.reduce(
    (grouped, device) => ({
      ...grouped,
      [device.kind]: [device, ...(grouped[device.kind] || [])],
    }),
    {}
  );
};

const getMicAudio = async (
  microphoneDeviceId: string,
  constraints?: MediaStreamConstraints | undefined
) => {
  // return navigator.mediaDevices.getUserMedia(constraints || { audio: true });
  return navigator.mediaDevices.getUserMedia(
    constraints || {
      audio: {
        deviceId: microphoneDeviceId,
      },
    }
  );
  // return null;
};

const defaultSystemVideoConstraints = {
  cursor: 'always',
  displaySurface: ['application', 'browser', 'monitor', 'window'],
  logicalSurface: true,
  width: window.screen.width,
  height: window.screen.height,
  frameRate: 30,
  ratio: 16 / 9,
};

const defaultSystemAudioConstraints = {
  echoCancellation: true,
  noiseSuppression: true,
  sampleRate: 48000,
};

const getMediaDevices = (): any => {
  return window.navigator.mediaDevices;
};

const getNavigatorAsAny = (): any => {
  return window.navigator;
};

const getDisplayMedia = (constraints: MediaStreamConstraints) => {
  if (getMediaDevices().getDisplayMedia) {
    return getMediaDevices().getDisplayMedia(constraints);
  }
  if (getNavigatorAsAny().getDisplayMedia) {
    return getNavigatorAsAny().getDisplayMedia(constraints);
  }

  return null;
};

const getSystemMedia = async (
  audio: boolean | MediaTrackConstraints,
  video?: MediaTrackConstraints
): Promise<MediaStream> => {
  const constraints: MediaStreamConstraints = {
    audio: audio === true ? defaultSystemAudioConstraints : audio || false,
    video: video || defaultSystemVideoConstraints,
  };

  return getDisplayMedia(constraints);
};

const mergeExternalAudio = (
  screenStream: MediaStream,
  micStream: MediaStream
) => {
  const audioContext = new AudioContext();
  const dest = audioContext.createMediaStreamDestination();

  [micStream, screenStream].forEach((stream) => {
    audioContext.createMediaStreamSource(stream).connect(dest);
  });

  return new MediaStream([
    ...dest.stream.getAudioTracks(),
    ...screenStream.getVideoTracks(),
  ]);
};

const combineStreams = (
  micStream: MediaStream | null,
  screenStream: MediaStream
) => {
  if (
    micStream &&
    screenStream.getAudioTracks().length > 0 &&
    (micStream.getAudioTracks()?.length || 0) > 0
  ) {
    return mergeExternalAudio(screenStream, micStream);
  }
  if (micStream && (micStream?.getAudioTracks()?.length || 0) > 0) {
    return new MediaStream([
      ...micStream.getAudioTracks(),
      ...screenStream.getVideoTracks(),
    ]);
  }
  return screenStream;
};

const mediaStreamSettingsAsAny = (stream: MediaStream): any => {
  return stream?.getVideoTracks()[0]?.getSettings() || {};
};

const getCameraVideo = async (webcamDeviceId: string) => {
  return navigator.mediaDevices.getUserMedia({
    video: { deviceId: webcamDeviceId },
  });
};

interface CombineVideoStreamsOptions {
  merger: VideoStreamMerger;
  screenStreamWithoutCamera: MediaStream;
  cameraStream: MediaStream;
  featureArgs?: RecorderFeatureArguments;
  settings: RecorderSettings;
}

function combineVideoStreams({
  merger,
  screenStreamWithoutCamera,
  cameraStream,
  featureArgs,
  settings,
}: CombineVideoStreamsOptions) {
  const { browserAudio } = settings?.audioSettings || {};
  merger.addStream(screenStreamWithoutCamera, {
    // @ts-ignore
    draw: (ctx, frame, done) => {
      const screenStreamSettings = mediaStreamSettingsAsAny(
        screenStreamWithoutCamera
      );
      const { width, height } = screenStreamSettings;
      merger.setOutputSize(width, height);
      ctx?.save();
      ctx?.drawImage(
        frame,
        (merger.width - width) / 2 + 1,
        (merger.height - height) / 2,
        width,
        height
      );

      ctx?.restore();
      done();
    },
    // @ts-ignore
    audioEffect: null,
    height: merger.height,
    mute: !(featureArgs?.browserAudioArgs || browserAudio),
    muted: !(featureArgs?.browserAudioArgs || browserAudio),
    width: merger.width,
    x: 0,
    y: 0,
    index: 0,
  });
  let camWidth = 320; // cam preview size
  let camHeight = 240;
  let streamHeight = 0;
  let streamWidth = 0;
  merger.addStream(cameraStream, {
    height: camHeight,
    width: camWidth,
    index: 1,
    draw: (ctx, frame, done) => {
      const screenStreamSettings = mediaStreamSettingsAsAny(
        screenStreamWithoutCamera
      );
      const newStreamHeight = screenStreamSettings.height;
      const newStreamWidth = screenStreamSettings.width;
      if (streamWidth !== newStreamWidth) {
        camWidth = newStreamWidth / 4;
        camHeight = camWidth * 0.75;
      } else if (streamHeight !== newStreamHeight) {
        camHeight = newStreamHeight / 3;
        camWidth = (camHeight * 4) / 3;
      }
      streamHeight = newStreamHeight;
      streamWidth = newStreamWidth;
      ctx?.save();
      // rounded rectangle shape camera preview
      merger.roundedImage(
        ctx,
        (merger.width - newStreamWidth) / 2 + 10,
        (merger.height - newStreamHeight) / 2 +
          screenStreamSettings.height -
          camHeight -
          10,
        camWidth,
        camHeight,
        10
      );
      ctx?.clip();
      ctx?.drawImage(
        frame,
        (merger.width - newStreamWidth) / 2 + 10,
        (merger.height - newStreamHeight) / 2 +
          screenStreamSettings.height -
          camHeight -
          10,
        camWidth,
        camHeight
      );

      // circle shape camera preview
      // this._ctx?.beginPath();
      // this._ctx?.arc(
      //   positionX + width / 2,
      //   positionY + width / 2,
      //   width / 2 - 20,
      //   0,
      //   Math.PI * 2,
      //   false
      // );
      // this._ctx?.stroke();
      // this._ctx?.clip();
      // this._ctx?.drawImage(element, positionX, positionY, width, height);

      ctx?.restore();
      done();
    },
    // @ts-ignore
    audioEffect: null,
    mute: true,
    muted: true,
    x: 10,
    y: merger.height - camHeight - 10,
    // x: merger.width - (merger.width * 6) / 20, // for circle
    // y: merger.height - (merger.width * 6) / 20,
  });

  merger.start(); // merge screen stream and camera stream
}

const getWebCamOnlyStreams = async (
  features: RecorderSettings,
  featureArgs?: RecorderFeatureArguments
) => {
  const { deviceSettings, audioSettings } = features || {};
  const { webcamDeviceId, microphoneDeviceId } = deviceSettings || {};
  const { microphoneAudio } = audioSettings || {};

  const cameraStream = await getCameraVideo(webcamDeviceId);
  const micStream = microphoneAudio
    ? await getMicAudio(microphoneDeviceId, featureArgs?.microphoneArgs)
    : null;

  const combinedStream = combineStreams(micStream, cameraStream);
  const previewStream = new MediaStream(combinedStream.getVideoTracks());

  return {
    micStream,
    screenStream: cameraStream,
    previewStream,
    combinedStream,
    cameraStream,
    screenStreamWithoutCamera: undefined,
  };
};

const getAudioOnlyStreams = async (
  features: RecorderSettings,
  featureArgs?: RecorderFeatureArguments
) => {
  let micStream: MediaStream | null = null;
  const { deviceSettings, audioSettings } = features || {};
  const { microphoneDeviceId } = deviceSettings || {};
  const { microphoneAudio } = audioSettings || {};

  if (microphoneAudio) {
    micStream = await getMicAudio(
      microphoneDeviceId,
      featureArgs?.microphoneArgs
    );
  }

  const previewStream = new MediaStream();
  const screenStream = new MediaStream();

  return {
    micStream,
    screenStream,
    previewStream,
    combinedStream: micStream || new MediaStream(),
    cameraStream: null,
    screenStreamWithoutCamera: undefined,
  };
};

let merger: VideoStreamMerger | undefined;
const createStreams = async (
  recorderFeatures: RecorderFeatures,
  featureArgs?: RecorderFeatureArguments
) => {
  const { settings, audioRecorderEnable } = recorderFeatures;
  const { audioSettings, videoSettings, deviceSettings } = settings || {};
  const { webCam, screen } = videoSettings || {};
  const { browserAudio, microphoneAudio } = audioSettings || {};
  const { webcamDeviceId, microphoneDeviceId } = deviceSettings || {};
  const webCamOnly = webCam && !screen;

  if (webCamOnly) {
    return getWebCamOnlyStreams(settings, featureArgs);
  }
  if (audioRecorderEnable) {
    return getAudioOnlyStreams(settings, featureArgs);
  }

  const screenStreamWithoutCamera = await getSystemMedia(
    featureArgs?.browserAudioArgs || browserAudio,
    featureArgs?.screenArgs
  );

  merger = new VideoStreamMerger();
  const streamSettings = mediaStreamSettingsAsAny(screenStreamWithoutCamera);
  let cameraStream;

  // combine video streams if webcam enable
  if (webCam && ['window', 'browser'].includes(streamSettings.displaySurface)) {
    cameraStream = await getCameraVideo(webcamDeviceId);
    merger.setOutputSize(streamSettings.width, streamSettings.height);
    merger.fps = streamSettings.frameRate;
    combineVideoStreams({
      merger,
      screenStreamWithoutCamera,
      cameraStream,
      featureArgs,
      settings,
    });
  }

  const screenStream =
    merger.result != null ? merger.result : screenStreamWithoutCamera;

  const micStream = microphoneAudio
    ? await getMicAudio(microphoneDeviceId, featureArgs?.microphoneArgs)
    : (null as any);

  const combinedStream = combineStreams(micStream, screenStream);
  const previewStream = new MediaStream(combinedStream.getVideoTracks());

  return {
    micStream,
    screenStream,
    previewStream,
    combinedStream,
    cameraStream,
    screenStreamWithoutCamera,
  };
};

const stopStream = (stream: MediaStream) => {
  stream.getTracks().forEach((track) => track.stop());
  merger?.stop();
};

let recorderWrapped:
  | {
      recorder: MediaRecorder;
      micStream: MediaStream | null;
      screenStream: MediaStream;
      combinedStream: MediaStream;
      previewStream: MediaStream; // final stream
      recordingId?: string;
      chunkIndex?: number;
      chunks: Blob[];
      duration: number;
      emitter?: (input: unknown) => void;
      cameraStream?: MediaStream; // webcam stream
      screenStreamWithoutCamera?: MediaStream;
      blob?: Blob;
      cueGenerator: CueGenerator;
    }
  | undefined;

const saveChunkChecked = async (
  emit: (input: unknown) => void,
  key: string,
  value: any
) => {
  try {
    await saveChunk(key, value);
  } catch (error) {
    emit({
      type: 'ERROR',
      name: 'NotEnoughStorage',
      error:
        'Your local storage space is almost full.' +
        'You need to free up some space in order to continue recording. Videos are stored locally in case the internet goes out, but you can delete any unnecessary files to free up space.',
    });
  }
};

const onRecorderDataAvailable = (
  data: BlobEvent,
  emit: (input: unknown) => void
) => {
  if (!recorderWrapped) return; // TODO Always false
  log('data', data);

  recorderWrapped.cueGenerator.add(data.data);
  recorderWrapped.chunks.push(data.data);

  // Save chunks for recovery purposes
  if (config.videoChunkRecovery) {
    // TODO Chunk check; 0 must have the metadata
    saveChunkChecked(
      emit,
      `${recorderWrapped?.recordingId}_${recorderWrapped?.chunkIndex}`,
      data.data
    );
  }

  // Stream upload
  if (config.ENABLE_STREAM_RECORDER) {
    sendBlobStream(data.data);
  }

  recorderWrapped.chunkIndex = (recorderWrapped.chunkIndex || 0) + 1;
  emit({ type: 'DATA', data: data.data });
};

const addRecorderEventHandlers = (emit: (input: unknown) => void) => {
  log('Attaching event handlers');

  if (!recorderWrapped) return;

  // All tracks/streams should be stopped when screen stream video track ends.
  // So, we only use this one as an event.
  recorderWrapped.screenStreamWithoutCamera
    ?.getVideoTracks()
    .forEach((track) => {
      track.onended = () => emit({ type: 'STREAM_END' });
    });

  recorderWrapped.recorder.ondataavailable = (data) => {
    onRecorderDataAvailable(data, emit);
  };

  // Handle START, PAUSE, RESUME, STOP later if needed
  recorderWrapped.emitter = emit;

  recorderWrapped.recorder.onerror = (ev: MediaRecorderErrorEvent) => {
    switch (ev.error?.name) {
      case 'InvalidStateError':
        emit({
          type: 'ERROR',
          name: 'InvalidStateError',
          error: "You can't record the video right now. Try again later.",
        });
        break;
      case 'SecurityError':
        emit({
          type: 'ERROR',
          name: 'SecurityError',
          error:
            'Recording the specified source is not allowed due to security restrictions.',
        });
        break;
      default:
        emit({
          type: 'ERROR',
          name: 'UnknownError',
          error: 'A problem occurred while trying to record the video.',
        });
        break;
    }
  };
};

const createRecorderEventChannel = () => {
  return eventChannel((emit) => {
    if (!recorderWrapped || !recorderWrapped.screenStream) {
      // Cannot call emitter directly here.
      setTimeout(() => {
        emit({
          type: 'ERROR',
          name: 'PermissionDeniedError',
          error: 'Empty desktop stream.',
        });
        emit(END);
      }, 0);
      return () => {};
    }

    addRecorderEventHandlers(emit);

    // Notify of initialization
    const initData = {
      displaySurface: mediaStreamSettingsAsAny(recorderWrapped.screenStream)
        .displaySurface,
      isBrowserShareAudioEnable:
        recorderWrapped.screenStream.getAudioTracks().length > 0 || false,
    };
    setTimeout(() => emit({ type: 'INIT', data: initData }), 0);

    // The subscriber must return an unsubscribe function
    return () => {
      log('Disposing recorder wrapper');

      // dispose everything
      recorderWrapped = undefined;
    };
  });
};

export function getPreviewStream() {
  return recorderWrapped?.previewStream;
}

export const createRecorder = async (recorderFeatures: RecorderFeatures) => {
  // Create and initialize streams (provide a second argument with streams settings)
  const { settings, audioRecorderEnable } = recorderFeatures;
  const {
    micStream,
    screenStream,
    combinedStream,
    previewStream,
    cameraStream,
    screenStreamWithoutCamera,
  } = await createStreams(recorderFeatures);
  const { audioSettings, otherSettings } = settings || {};
  const { browserAudio, microphoneAudio } = audioSettings || {};
  const { formatProfile } = otherSettings;

  // Not all formats can be used with Recorder
  // Chrome codec support: https://source.chromium.org/chromium/chromium/src/+/main:third_party/blink/web_tests/fast/mediarecorder/
  // Firefox is very serious about mimeType. If you put audio codec in a video only recording,
  // or the other way around, it will fail.
  const mimeType = getMimeWithCodecs(formatProfile, {
    video: !audioRecorderEnable,
    audio: browserAudio || microphoneAudio,
  });

  const cueGenerator = createCueGenerator(formatProfile);
  const stream = audioRecorderEnable ? micStream : combinedStream;

  recorderWrapped = {
    recorder: new MediaRecorder(stream, { mimeType }),
    micStream,
    screenStream,
    combinedStream,
    previewStream,
    cameraStream,
    screenStreamWithoutCamera,
    cueGenerator,
    chunks: [],
    duration: 0,
    emitter: undefined,
  };
  log('Recorder created.', recorderWrapped);

  return createRecorderEventChannel();
};

export async function start({ data }) {
  if (recorderWrapped) {
    resetTimer();
    startTimer();
    log('Recording: start');

    recorderWrapped.recordingId = data?.uuid;
    recorderWrapped.chunkIndex = 0;

    try {
      await addUnfinishedVideoRecord(data?.uuid, {
        recordingId: data?.uuid,
        start: Date.now(),
      });
    } catch (error) {
      // This will be caught again by `saveChunkChecked` method and emitted like below
      // recorderWrapped.emitter?.({
      //   type: 'ERROR', ...
      // });
    }

    recorderWrapped.recorder.start(1000);
  }
}

export function pause() {
  log('Recording: pause');

  if (recorderWrapped) recorderWrapped.recorder.pause();
}

export function resume() {
  log('Recording: resume');

  if (recorderWrapped) recorderWrapped.recorder.resume();
}

export function stop() {
  log('Recording: stop');

  if (recorderWrapped) recorderWrapped.recorder.stop();
}

export const stopAllStreams = () => {
  if (!recorderWrapped) return;
  log('Streams: stop');

  [
    recorderWrapped.micStream,
    recorderWrapped.screenStream,
    recorderWrapped.combinedStream,
    recorderWrapped.previewStream,
    recorderWrapped.cameraStream,
    recorderWrapped.screenStreamWithoutCamera,
  ]
    .filter(Boolean)
    .forEach(stopStream);

  if (recorderWrapped.emitter) recorderWrapped.emitter(END);
};

let downloadableBlob: Blob | undefined | null;
export interface BuildVideoArgsData {
  folderId: string;
  title?: string;
  description: string;
  saveLocally: boolean;
  recorderName: string;
}

export async function buildVideo(data: BuildVideoArgsData) {
  log('Post-recording actions');
  if (!recorderWrapped) {
    throw new Error('No recorder has been created.');
  }

  const meta = await recorderWrapped.cueGenerator.end();
  const { newMetadata, oldMetadataSize, duration } = meta;

  const recordingId = recorderWrapped.recordingId || '';
  let blob: null | Blob = null;
  try {
    // We try to get the recording from the recorder first
    blob = mergeChunks(recorderWrapped.chunks);
  } catch (error) {
    reportError(error);
  }

  try {
    if (!blob || blob.size <= 0) {
      // We check whether the blob is available. If not, we try to get it from chunk backups
      if (config.videoChunkRecovery) {
        blob = await getEntireRecordingFromChunks(recordingId);
      }
    }
  } catch (error) {
    reportError(error);
  }

  // Make sure the blob has data
  if (!blob || blob.size <= 0) {
    throw new Error('Recorder returned an empty blob');
  }
  recorderWrapped.blob = blob;
  if (duration) {
    recorderWrapped.duration = duration;
  }

  try {
    // Save non-seekable video for recovery purposes; ignore if failed
    await saveRawVideo(recordingId, blob);
    await extendUnfinishedVideoRecord(recordingId, {
      folderId: data?.folderId,
      end: Date.now(),
    });
  } catch (error) {
    reportError(error);
  }

  downloadableBlob = blob;
  // By default, the video is not seekable.
  let seekableBlob: null | Blob = null;
  try {
    const body = blob.slice(oldMetadataSize);
    const refinedWebM = new Blob(newMetadata ? [newMetadata, body] : [blob], {
      // TODO: use getMime(data.formatProfile) set mimetype
      type: undefined,
    });
    seekableBlob = refinedWebM;
  } catch (error) {
    reportError(error);
  }

  if (seekableBlob && seekableBlob.size > 0) {
    downloadableBlob = seekableBlob; // store the blob for further usage
  } else {
    log('Error: failed to build seekable blob');
  }

  const timestamp = Date.now();
  const title = data.title || `Recording_${timestamp}`;

  if (data.saveLocally) {
    const thumbBlob = await getThumbnail(downloadableBlob, 500); // generate thumbnail

    try {
      // Save the recording should in IDB; ignore if failed
      await saveRecording({
        video: downloadableBlob,
        recordingId,
        folderId: data.folderId || '',
        timestamp,
        title,
        description: data.description,
        ...(thumbBlob && { thumbnail: thumbBlob }),
      });
    } catch (error) {
      reportError(error);
    }
  }

  if (config.ENABLE_STREAM_RECORDER) {
    tryFinishRecord({
      newMetadata,
      oldMetadataSize,
    });
  }

  if (config.videoChunkRecovery) {
    deleteChunksByRecordingId(recordingId);
  }

  return {
    videoUrl: window.URL.createObjectURL(downloadableBlob),
    duration,
    blob: downloadableBlob,
  };
}

export async function toggleRecoding(action) {
  if (action.payload.pause) {
    pause();
  } else {
    resume();
  }
}

export function setAudioMute(action) {
  if (action.payload.microphoneAudio && recorderWrapped?.micStream) {
    recorderWrapped.micStream.getAudioTracks().forEach((track) => {
      track.enabled = !action.payload.muteRecorderMic;
    });
  }

  if (action.payload.browserAudio && recorderWrapped?.screenStream) {
    recorderWrapped.screenStream.getAudioTracks().forEach((track) => {
      track.enabled = !action.payload.muteRecorderBrowserAudio;
    });
  }
}

export async function getVideoDuration() {
  return recorderWrapped?.duration || 0;
}

export async function getVideoBlob() {
  return recorderWrapped?.blob;
}

export async function getRecordingId() {
  return recorderWrapped?.recordingId || '';
}

export function download(file: File) {
  const url = URL.createObjectURL(file);
  const a = document.createElement('a');
  document.body.appendChild(a);
  // a.style = 'display: none';
  a.href = url;
  a.download = file.name;
  a.click();
  window.URL.revokeObjectURL(url);
}

export async function downloadRecording({ action, title, formatProfile }) {
  if (downloadableBlob && downloadableBlob.size > 0) {
    const recordedVideoFile = new File(
      [downloadableBlob],
      `${title || action.data.uuid}${getExtension(formatProfile)}`,
      {
        type: getMime(formatProfile),
      }
    );

    download(recordedVideoFile);
  } else {
    throw new Error('No downloadable recording available');
  }
}

export const getSelection = (
  microphoneAudioEnable: boolean,
  browserEnable: boolean,
  webcamOnlyEnable: boolean
) => {
  let selected = '';
  if (microphoneAudioEnable && browserEnable && !webcamOnlyEnable) {
    selected = 'sysMic_audio';
  } else if (microphoneAudioEnable || (webcamOnlyEnable && browserEnable)) {
    selected = 'microphone_audio';
  } else if (browserEnable && !webcamOnlyEnable) {
    selected = 'browser_audio';
  } else {
    selected = 'no_audio';
  }
  return selected;
};
