// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.SEARCH_RECORDINGS, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.SEARCH_VIDEOS:
      return {
        ...state,
        searchVideo: action.data.searchName,
        startDate: action.data.startDate,
        endDate: action.data.endDate,
      };

    default:
      return state;
  }
};
