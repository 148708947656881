import debug from 'debug';
/* eslint-disable import/prefer-default-export */
import { call, select, takeEvery } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { getOrCreateLocalTrackingId } from '../../lib/localStorageHandler';
import { track } from '../../services/analyticsService';

const log = debug('app:analyticsSaga');

const packageToPlanCode = {
  FREE: 0,
  FREE_LOGGEDIN: 10,
  STANDARD: 100,
  PREMIUM: 1000,
  GROWTH: 200,
  BUSINESS: 2000,
};

const getUserDetails = (state) => {
  return {
    userId: state?.auth?.user?.data?.id,
    pkg: state?.auth?.user?.data?.package,
  };
};

function* trackGenerator(action) {
  try {
    const { userId, pkg } = yield select(getUserDetails);
    const t = Date.now();
    const record = {
      a: action.data.action,
      d: action.data.data,
      u: userId || getOrCreateLocalTrackingId(),
      p: packageToPlanCode[pkg] || 0,
      t,
    };

    yield call(track, record);
    // No response processing
  } catch (e) {
    log(e);
    // Fail silently
  }
}

export function* trackSaga() {
  yield takeEvery(REDUX_ACTIONS.ANALYTICS, trackGenerator);
}
