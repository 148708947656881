/* eslint-disable import/prefer-default-export */
import { createFFmpeg, fetchFile } from '@ffmpeg/ffmpeg';

const ffmpeg = createFFmpeg({ log: true });

// TODO SA-1930
export async function trimAB(
  video: Blob,
  begin: number,
  end: number
): Promise<Blob> {
  if (!ffmpeg.isLoaded()) {
    await ffmpeg.load();
  }
  // TODO Do we need progress?
  // ffmpeg.setProgress( ... )
  ffmpeg.FS('writeFile', 'trim-input.webm', await fetchFile(video));
  await ffmpeg.run(
    '-ss', // Seek input to here (used before -i flag)
    `${(begin / 1000).toFixed(3)}`, // Format S.mmm
    '-to', // Stops reading input at this position
    `${(end / 1000).toFixed(3)}`,
    '-i', // Input file
    'trim-input.webm',
    '-c:v', // Video codec
    'copy', // Just copy
    '-c:a', // Audio codec
    'copy',
    'trim-output.webm' // Output file
  );
  const data = ffmpeg.FS('readFile', 'trim-output.webm');
  return new Blob([data.buffer], { type: 'video/webm' });
}

export async function getThumbnail(
  video: Blob,
  thumbTime: number
): Promise<Blob | undefined> {
  try {
    if (!ffmpeg.isLoaded()) {
      await ffmpeg.load();
    }

    ffmpeg.FS('writeFile', 'thumb-input.webm', await fetchFile(video));
    await ffmpeg.run(
      '-ss', // Seek input to here (used before -i flag)
      `${(thumbTime / 1000).toFixed(3)}`, // Format S.mmm
      // '00:00:01.000',
      '-i', // Input file
      'thumb-input.webm',
      '-qscale:v',
      '20',
      '-frames:v',
      '1',
      'thumb-output.jpeg' // Output file
    );
    const data = ffmpeg.FS('readFile', 'thumb-output.jpeg');
    return new Blob([data.buffer], { type: 'image/jpeg' });
  } catch (error) {
    return undefined;
  }
}
