import debug from 'debug';
import { BufferManager } from './BufferManager';
import MultipartUploader, { RecordingArgs } from './MultipartUploader';
import { UploadManager } from './UploadManager';

const log = debug('app:UploadManager');

const MiB = 2 ** 20;
const MIN_BACKOFF = 1_000;
const MAX_BACKOFF = 32_000;

function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export enum UploadManagerState {
  CONNECTING = 'connecting',
  CONNECTED = 'connected',
  CLOSING = 'closing',
  CLOSED = 'closed',
}

export type SetMetatdataArgsType = Partial<
  Pick<
    RecordingArgs,
    | 'oldMetadataSize'
    | 'newMetadata'
    | 'description'
    | 'title'
    | 'folderId'
    | 'recorderName'
  >
>;

export default class MultipartUploadManager extends UploadManager {
  public get uploader(): MultipartUploader {
    return this._uploader;
  }

  private bufferManager: BufferManager = new BufferManager();

  private state: UploadManagerState = UploadManagerState.CONNECTING;

  private closeRequested = false;

  private retryTimeout: number = MIN_BACKOFF;

  private reportError: (error: Error) => void;

  private async init() {
    while (this.state === UploadManagerState.CONNECTING) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await this._uploader.init();
        this.state = UploadManagerState.CONNECTED;
        this.retryTimeout = MIN_BACKOFF;
        log('UploadManager/init: Done');
      } catch (error) {
        log('UploadManager/init: Error', error);
        this.reportError(error);
        // eslint-disable-next-line no-await-in-loop
        await sleep(this.retryTimeout);
        this.retryTimeout = Math.min(
          this.retryTimeout * this.options.backoffFactor,
          MAX_BACKOFF
        );
      }
    }
  }

  private async locked(type: 'uploader' | 'bufferManager', callback) {
    return navigator.locks.request(
      `${type}_${this.recordingArgs.recordingId}`,
      callback
    );
  }

  constructor(
    private _uploader: MultipartUploader,
    private recordingArgs: RecordingArgs,
    private options: {
      chunkSize: number;
      backoffFactor: number;
      errorReporter: (source: string, payload: any) => void;
    } = {
      chunkSize: 10 * MiB,
      backoffFactor: 2,
      errorReporter: () => {},
    }
  ) {
    super();

    log('UploadManager/constructor:', recordingArgs, options);

    this.reportError = (error: Error) =>
      this.options.errorReporter('handled.MultiPartUploadManager', error);
    this._uploader.setRecordingArgs(this.recordingArgs);

    this.init.bind(this);
    this.send.bind(this);
    this.end.bind(this);
    this.setMetadata.bind(this);

    if (this.options.chunkSize < 5 * MiB) {
      throw new Error('Minimum allowed chunk size is 5 MB');
    }

    if (this.options.backoffFactor <= 0) {
      this.retryTimeout = 0;
    }

    this.locked('uploader', async () => {
      await this.init();
    });
  }

  async send(data: Blob) {
    if (
      [UploadManagerState.CLOSED, UploadManagerState.CLOSING].includes(
        this.state
      ) ||
      this.closeRequested
    ) {
      log(
        'UploadManager/send: Ignoring chunk sent after stream close invoked.'
      );
      return;
    }

    await this.locked('bufferManager', async () => {
      log('UploadManager/send: Adding to queue');
      this.bufferManager.add(data);
      this.emit('progress', {
        loaded: this.bufferManager.sent,
        total: this.bufferManager.total,
      });

      if (
        this.state === UploadManagerState.CONNECTED &&
        this.bufferManager.remaining > this.options.chunkSize
      ) {
        log('UploadManager/send: Consolidating');
        this.bufferManager.consolidate(this.options.chunkSize);

        const toSend = this.bufferManager.getAll();
        try {
          await this.locked('uploader', async () => {
            for (let i = 0; i < toSend.length; i += 1) {
              const chunk = toSend[i];
              if (chunk.size < this.options.chunkSize) {
                log(
                  'UploadManager/send: breaking',
                  (chunk.size / MiB).toFixed(2),
                  '/',
                  (this.options.chunkSize / MiB).toFixed(2)
                );
                break;
              }

              log('UploadManager/send: Sending', chunk.size);

              // Fail-fast
              // eslint-disable-next-line no-await-in-loop
              await this._uploader.send(chunk);
              this.bufferManager.confirm(chunk.size);

              log('UploadManager/send: Sent chunk');
              this.emit('progress', {
                loaded: this.bufferManager.sent,
                total: this.bufferManager.total,
              });
            }
          });
        } catch (error) {
          // No worries; will be sent the next time
          this.reportError(error);
          log('UploadManager/send: Error:', error);
        }
      }
    });
  }

  async end() {
    if (
      [UploadManagerState.CLOSED, UploadManagerState.CLOSING].includes(
        this.state
      ) ||
      this.closeRequested
    ) {
      log('UploadManager/end: Already closed or closing the connection.');
      return;
    }

    this.closeRequested = true;

    await this.locked('bufferManager', async () => {
      await this.locked('uploader', async () => {
        this.state = UploadManagerState.CLOSING;
        this.bufferManager.consolidate(this.options.chunkSize);

        const toSend = this.bufferManager.getAll();
        for (let i = 0; i < toSend.length; i += 1) {
          const chunk = toSend[i];

          log('UploadManager/end: Sending', (chunk.size / MiB).toFixed(2));

          // Have to retry each in order
          try {
            // eslint-disable-next-line no-await-in-loop
            await this._uploader.send(chunk);
            this.bufferManager.confirm(chunk.size);

            log('UploadManager/end: Sent chunk');
            this.emit('progress', {
              loaded: this.bufferManager.sent,
              total: this.bufferManager.total,
            });
            this.retryTimeout = MIN_BACKOFF;
          } catch (error) {
            log('UploadManager/end: Error:', error);
            this.reportError(error);
            // eslint-disable-next-line no-await-in-loop
            await sleep(this.retryTimeout);
            this.retryTimeout = Math.min(
              this.retryTimeout * this.options.backoffFactor,
              MAX_BACKOFF
            );
            i -= 1; // retry
          }
        }

        this.state = UploadManagerState.CLOSED;
        this.closeRequested = false;

        this._uploader.setRecordingArgs(this.recordingArgs);

        log('UploadManager/end: Finalizing', this.recordingArgs);
        for (;;) {
          try {
            // eslint-disable-next-line no-await-in-loop
            await this._uploader.end();
            this.retryTimeout = MIN_BACKOFF;
            break;
          } catch (error) {
            log('UploadManager/end: Error:', error);
            if (error?.cause === 'Unsupported file type') {
              this.emit('progress', {
                loaded: 0,
                total: this.bufferManager.total,
              });
              this.emit('error', error);
              return;
            }
            this.reportError(error);
            // eslint-disable-next-line no-await-in-loop
            await sleep(this.retryTimeout);
            this.retryTimeout = Math.min(
              this.retryTimeout * this.options.backoffFactor,
              MAX_BACKOFF
            );
          }
        }
      });
    });

    this.emit('close');
  }

  setMetadata(args: SetMetatdataArgsType): void {
    log('UploadManager/setMetadata', args);

    this.recordingArgs = { ...this.recordingArgs, ...args };
  }
}
