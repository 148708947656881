import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { connect } from 'react-redux';
import { ActionModal } from '../../../components/StorybookComponents/Modal';
import UpgradeBannerItem, { PlanType } from './UpgradeBannerItem';
import { goToPricingUrl, goToSignupUrl } from '../../../lib/routingUtils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiDialogContent-root': {
        paddingRight: '24px',
      },
      '& .MuiButton-root': {
        fontFamily: 'Inter',
        textTransform: 'none',
      },
      fontFamily: 'Inter',
    },
    title: {
      fontWeight: 700,
      fontSize: '1.3rem',
    },
    para: {
      color: '#888282',
      marginBottom: 0,
    },
  })
);

interface UpgradeBannerProps {
  onClickClose: React.MouseEventHandler<HTMLButtonElement>;
  upgradePlans: PlanType[];
  isFreeUser: boolean;
}

const UpgradeBanner = (props: UpgradeBannerProps) => {
  const { onClickClose, upgradePlans, isFreeUser } = props;
  const classes = useStyles();

  const onClickLearnMore = () => {
    if (isFreeUser) goToSignupUrl();
    else goToPricingUrl();
  };

  return (
    <div className={classes.root}>
      <ActionModal
        id="upgrade-banner"
        open
        actinButtonLabel={isFreeUser ? 'Try for Free' : 'See More'}
        fullWidth={false}
        closeIcon
        handleClose={onClickClose}
        actinButtonOnClick={onClickLearnMore}
        classes={{ customClass: classes.root }}
      >
        <p className={classes.title}>Unlock premium features!</p>
        <p className={classes.para}>
          Please upgrade your plan to enjoy all premium features!
        </p>
        <UpgradeBannerItem
          upgradePlans={upgradePlans}
          isFreeUser={isFreeUser}
        />
      </ActionModal>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    isFreeUser: state.auth.user.data.package === 'FREE',
  };
}

const mapDispatchToProps = () => ({
  actions: {},
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeBanner);
