import { connect } from 'react-redux';

import WebcamOverlay from '../components/Webcam/WebcamOverlay';

function mapStateToProps(state) {
  return {
    userData: state.auth?.user?.data,
    // We can have this only because a parent component, RecorderComponent, calls
    // checkBrowserCompatibility
    browser: state.browser,
  };
}
const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(WebcamOverlay);
