/* eslint-disable no-unused-vars */
import React from 'react';
// eslint-disable-next-line import/named
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { LabelPlacement, Color } from '../types';

const styles = () => {
  return createStyles({
    root: {
      width: '150px',
      // '& .Mui-checked': {
      //   color: theme.palette.primary.dark,
      // },
    },
    checkBoxLabel: {
      marginBottom: 0,
      color: '#868D96',
      '& .MuiTypography-body1': {
        fontSize: '14px',
        fontFamily: 'Inter',
      },
    },
  });
};

export interface ICheckboxProps
  extends WithStyles<CheckboxProps & typeof styles> {
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  id: string;
  checked: boolean;
  disabled?: boolean;
  label?: string;
  labelPlacement?: LabelPlacement;
  color?: Color;
}

export const RadioButton: React.FC<ICheckboxProps> = ({
  classes,
  id,
  onChange,
  disabled,
  checked,
  label = '',
  labelPlacement = 'end',
  color = 'primary',
}: ICheckboxProps) => {
  return (
    <span>
      <FormControlLabel
        id={id}
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            color={color}
            disabled={disabled}
            size="small"
          />
        }
        label={label}
        className={classes.checkBoxLabel}
        labelPlacement={labelPlacement}
      />
    </span>
  );
};

export default withStyles(styles)(RadioButton);
