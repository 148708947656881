import React from 'react';
import debug from 'debug';

const log = debug('log:AudioIndicatorWave');

const audioTest = async (analyserCanvas) => {
  if (navigator.mediaDevices.getUserMedia !== null) {
    const options = {
      video: false,
      audio: true,
    };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(options);
      const audioCtx = new AudioContext();
      const analyser = audioCtx.createAnalyser();
      analyser.fftSize = 2048;
      const audioSrc = audioCtx.createMediaStreamSource(stream);
      audioSrc.connect(analyser);
      const data = new Uint8Array(analyser.frequencyBinCount / 16);
      const canvas = analyserCanvas.current;
      const ctx = canvas.getContext('2d');
      const draw = (dataParm: any) => {
        // eslint-disable-next-line no-param-reassign
        dataParm = [...dataParm];
        ctx.fillStyle = 'white'; // white background
        ctx.lineWidth = 3; // width of candle/bar
        ctx.lineCap = 'round';
        ctx.strokeStyle = 'black'; // color of candle/bar
        ctx.height = 4096;
        ctx.width = 20;
        const space = canvas.width / dataParm.length;
        dataParm.forEach((value: number, i: number) => {
          if (i % 5 === 0) {
            ctx.beginPath();
            if (i % 2 === 0) {
              ctx.strokeStyle = 'white';
            } else {
              ctx.strokeStyle = 'black';
            }
            ctx.moveTo(space * i * 2, 10);
            ctx.lineTo(space * i * 2, 10 + (value / 2 + 10) / 10);
            ctx.moveTo(space * i * 2, 10);
            ctx.lineTo(space * i * 2, 10 - (value / 2 + 10) / 10);

            ctx.clearRect(0, 0, analyserCanvas.width, analyserCanvas.height);
            ctx.stroke();
          }
        });
      };
      const loopingFunction = () => {
        requestAnimationFrame(loopingFunction);
        analyser.getByteFrequencyData(data);
        ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
        draw(data);
      };
      requestAnimationFrame(loopingFunction);
    } catch (err) {
      log(err);
    }
  }
};
export interface IAudioIndicatorWaveProps {
  paddingLeftValue: string;
}

export const AudioIndicatorWave: React.FC<IAudioIndicatorWaveProps> = ({
  paddingLeftValue,
}: IAudioIndicatorWaveProps) => {
  const analyserCanvas: any = React.useRef(null);
  audioTest(analyserCanvas);
  return (
    <canvas
      height={20}
      width={20}
      ref={analyserCanvas}
      style={{ paddingLeft: paddingLeftValue }}
    />
  );
};

export default AudioIndicatorWave;
