import { put, takeLatest, call } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';

import { PluginDeativate } from '../../services/pluginService';

function* pluginDeactivate() {
  try {
    const response = yield call(PluginDeativate);
    yield put({
      type: REDUX_ACTIONS.DEACTIVATE_PLUGIN_REQUEST_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.DEACTIVATE_PLUGIN_REQUEST_FAILURE,
      data: { message: error.message },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* pluginDeactivateSaga() {
  yield takeLatest(REDUX_ACTIONS.DEACTIVATE_PLUGIN_REQUEST, pluginDeactivate);
}
