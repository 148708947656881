// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (
  state = STORE_INITIATE_VALUE.FREE_RECORDING_LIBRARY,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.UPDATE_FREE_RECORDING_COUNT:
      return {
        ...state,
        count: action.data,
      };
    default:
      return state;
  }
};
