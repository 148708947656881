import { bindActionCreators } from 'redux';
import { actionCreateStoreUpdateFactory } from './actionCreator';
import {
  ACTION_ATTR as ATTRS,
  REDUX_ACTIONS as ACTION_TYPES,
} from '../constants/apiSagaConstant';

// eslint-disable-next-line import/prefer-default-export
export const getCheckBrowserCompatibility = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.CHECK_BROWSER_COMPATIBILITY,
      ATTRS.PAYLOAD
    ),
    dispatch
  );
};

export const getGetStartedButtonClickAction = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.GET_STARTED_BUTTON_CLICK,
      ATTRS.DATA
    ),
    dispatch
  );
};

export const getCancelRecording = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.STOP_SCREEN_SHARING,
      ATTRS.PAYLOAD
    ),
    dispatch
  );
};

export const getStopScreenSharing = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.STOP_SCREEN_SHARING,
      ATTRS.PAYLOAD
    ),
    dispatch
  );
};

export const getTryAgain = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.TRY_RECORDING_AGAIN,
      ATTRS.DATA
    ),
    dispatch
  );
};
