// @flow
/**
 * RecordRTC has the ability to save the recordings to the local storage.
 * But, it can store one recording at a time. Therefor we use a seperate library to avoid code changes in RecordRTC
 */

import { v1 as uuid } from 'uuid';
import _ from 'lodash';
import debug from 'debug';
import {
  recordedVideoLibraryEntry,
  ITagEntry,
  FileTypeExtended,
} from '../types';
import { getSeekableVideo } from './mediaOperationUtils';

import {
  chunks,
  library,
  rawVideos,
  tag,
  unfinishedRecordingsDetails,
} from './baseStorage';
import getBlobDuration from './getBlobDuration';

import { download } from './RecorderWrapper';
import { getThumbnail } from './ffmpegWrapper';

const log = debug('app:storageUtils');

export const getChunkKeysByRecordingId = async (recordingId: string) => {
  // Lets retrive all the keys from chunk store
  const keys = await chunks.keys();

  // Each key has the format : <recordingId>_<chunkId>. eg: d376a1a0-e989-11ea-afba-fbd2a6d1a014_0
  // We can filter the chunks using the recordingId
  const filterById = (id: string) => id.startsWith(recordingId);
  const filteredKeys = keys.filter(filterById);

  return filteredKeys;
};

export function mergeChunks(recordedChunks: Blob[]) {
  return new Blob(recordedChunks, {
    type: recordedChunks[0] ? recordedChunks[0].type : 'video/webm;codecs=vp8',
  });
}

export const getEntireRecordingFromChunks = async (recordingId: string) => {
  const filteredKeys = await getChunkKeysByRecordingId(recordingId);

  // And then we can sort them
  const sortedKeys = filteredKeys.sort();

  // Then we can retrieve the chunks from indexdb
  const recordedChunks: Array<any> = await Promise.all(
    sortedKeys.map(async (key) => chunks.getItem(key))
  );

  // Merge all chunks and return the video
  return mergeChunks(recordedChunks);
};

export const getChunk = async (recordingId: string, chunkId: number) => {
  const video = await chunks.getItem(`${recordingId}_${chunkId}`);
  return video;
};

export const deleteChunksByRecordingId = async (recordingId: string) => {
  const keys = await getChunkKeysByRecordingId(recordingId);
  await Promise.all(
    keys.map(async (key) => {
      await chunks.removeItem(key);
    })
  );
};

export const getVideoFile = async (id: string) => {
  const videoObj: recordedVideoLibraryEntry | null = await library.getItem(id);

  return videoObj?.video;
};

export const getSavedVideoFiles = async (): Promise<
  Array<FileTypeExtended> // start = 0, pageSize = 10
> => {
  // await recover();
  const keys = await library.keys();
  // const paginatedKeys = await keys.slice(start, start + pageSize);
  const videos = await Promise.all(
    keys.map(async (key) => {
      const foundedEntry: recordedVideoLibraryEntry | null = await library.getItem(
        key
      );
      if (foundedEntry) {
        const {
          recordingId,
          userId,
          name,
          description,
          timestamp,
          video,
          thumbnail,
        } = foundedEntry;
        // instead of a blob, lets return a previewable url
        let url = '';
        try {
          url = window.URL.createObjectURL(video);
        } catch (error) {
          log('Error while creating object url', error);
          url = '';
        }
        // url for thumbnail
        let thumbUrl = '';
        try {
          thumbUrl = window.URL.createObjectURL(thumbnail);
        } catch (error) {
          log('Error while creating object url', error);
          thumbUrl = '';
        }
        let videoDuration;
        try {
          videoDuration = await getBlobDuration(video); // get video duration
        } catch (error) {
          //
        }
        // TODO: recorderEmail, recorderName, teamId, spaceId are hardcoded. Fix them
        const file: FileTypeExtended = {
          _id: recordingId,
          type: 'File',
          userId,
          name,
          description,
          createdAt: new Date(Number(timestamp)),
          updatedAt: new Date(Number(timestamp)),
          url,
          provider: 'IDB',
          providerKey: key,
          size: video.size,
          parentId: foundedEntry.folderId || '',
          duration: videoDuration?.toString(),
          thumbUrl,
          teamId: '',
          recorderEmail: '',
          recorderName: '',
          spaceId: '__personal',
        };

        return file;
      }
      return null;
    })
  );

  return videos.filter((f): f is FileTypeExtended => !!f);
};

export const deleteVideo = async (recordingId: string) => {
  await library.removeItem(recordingId);
};

export const deleteManyVideos = async (recordingIds: string[]) => {
  await Promise.all(recordingIds.map(deleteVideo));
};

export const saveRecording = async ({
  video,
  recordingId,
  folderId = '',
  userId = '',
  timestamp,
  title = '',
  description = '',
  thumbnail,
}: {
  video: any;
  recordingId: string;
  folderId?: string;
  userId?: string;
  timestamp: number;
  title?: string;
  description?: string;
  thumbnail?: any;
}) => {
  const entry: recordedVideoLibraryEntry = {
    recordingId,
    userId,
    name: title,
    timestamp: timestamp.toString(),
    description,
    video,
    folderId,
    thumbnail,
  };
  await library.setItem(recordingId, entry);

  // if there are more than 10 videos, lets remove the oldest recording
  return entry;
};

export const replaceRecording = async (recordingId: string, video: any) => {
  const videoObj: recordedVideoLibraryEntry | null = await library.getItem(
    recordingId
  );

  const entry: recordedVideoLibraryEntry = {
    recordingId,
    userId: videoObj?.userId || '',
    name: videoObj?.name || '',
    timestamp: videoObj?.timestamp || Date.now().toString(),
    description: videoObj?.description || '',
    video,
    folderId: videoObj?.folderId || '',
    thumbnail: videoObj?.thumbnail,
  };
  await library.setItem(recordingId, entry);

  return entry;
};

// If we have the chunks in chunk store, but if there is no recording in library
// we can recover them using this method
export const recover = async (userId, folderId) => {
  const allChunkKeys = await chunks.keys();
  const chunkKeysWithoutChunkId = await Promise.all(
    allChunkKeys.map((key) => Promise.resolve(key.split('_')[0]))
  );
  const uniqueKeys = _.uniq(chunkKeysWithoutChunkId);

  await Promise.all(
    uniqueKeys.map(async (recordingId) => {
      let video = await getEntireRecordingFromChunks(recordingId);
      try {
        video = await getSeekableVideo(video);
      } catch (error) {
        log(error);
      }
      const thumbnail = await getThumbnail(video, 500); // generate thumbnail
      const timestamp = Date.now();
      await saveRecording({
        recordingId,
        video,
        userId,
        folderId,
        title: `Recovered_${timestamp}`,
        timestamp,
        ...(thumbnail && { thumbnail }),
      });
    })
  );
};

const GiB = 1024 * 1024 * 1024;

export const recoverUnfinishedVideoFromChunks = async (recordingId: string) => {
  let video = await getEntireRecordingFromChunks(recordingId);
  try {
    const availableMemoryAssumed = ((navigator as any).deviceMemory || 4) / 4;
    if (video.size < availableMemoryAssumed * GiB) {
      video = await getSeekableVideo(video);
    }
  } catch (error) {
    log('Cannot recover:', error);
  }
  return video;
};

// When starting to record video
export const addUnfinishedVideoRecord = async (id: string, details: any) => {
  await unfinishedRecordingsDetails.setItem(id, details);
};

// When finishing the recording but before creating a seekable video
export const extendUnfinishedVideoRecord = async (
  id: string,
  details: {
    folderId: string;
    end: number;
  }
) => {
  const record: any = await unfinishedRecordingsDetails.getItem(id);
  await unfinishedRecordingsDetails.setItem(id, { ...record, ...details });
};

// Delete unnecessary records
export const deleteUnfinishedVideoRecord = async (id: string) => {
  await unfinishedRecordingsDetails.removeItem(id);
};

export const listUnfinishedVideos = async () => {
  const keys = await unfinishedRecordingsDetails.keys();
  const values = await Promise.all(
    keys.map((key) => unfinishedRecordingsDetails.getItem(key))
  );
  return values;
};

const HOUR = 60 * 60 * 1000;

export const recoverFromRawVideo = async (
  recoverData: any
): Promise<Blob | null> => {
  let video: Blob | null = await rawVideos.getItem(recoverData.recordingId);
  const videoStartTs = recoverData?.start || 0;
  const videoEndTs = recoverData?.end || 0;

  try {
    if (videoEndTs - videoStartTs < 1 * HOUR && videoStartTs > 0) {
      video = await getSeekableVideo(video);
    }
  } catch (error) {
    log(error);
  }

  return video;
};

export const saveRawVideo = async (id: string, video: Blob) => {
  await rawVideos.setItem(id, video);
};

export const deleteRawVideo = async (id: string) => {
  await rawVideos.removeItem(id);
};

export const listChunkBackupSets = async (): Promise<{
  [recordingId: string]: number;
}> => {
  const chunkKeys = await chunks.keys();
  // { <id>: <number of chunks> }
  const recoverables = _.countBy(
    chunkKeys.filter(Boolean).map((key) => key.split('_')[0])
  );

  return recoverables;
};

export const saveChunk = async (key: string, value: any) => {
  await chunks.setItem(key, value);
};

export const updateRecordingDetails = async (data) => {
  const foundEntry: recordedVideoLibraryEntry | null = await library.getItem(
    data.recordingId
  );

  if (foundEntry === null) {
    return;
  }

  const entry: recordedVideoLibraryEntry = {
    ...foundEntry,
    name: data.name || foundEntry.name,
    description: data.description || foundEntry.description,
  };

  await library.setItem(foundEntry.recordingId, entry);
};

export const saveNewTag = async (data: ITagEntry) => {
  const tagId = uuid();
  const upData = { ...data, tagId };

  await tag.setItem(tagId, upData);
};

export const getTags = async (
  userId: string
): Promise<Array<ITagEntry | null>> => {
  const userTags: ITagEntry[] = [];
  const keys = await tag.keys();
  await Promise.all(
    keys.map(async (key) => {
      const foundedEntry: ITagEntry | null = await tag.getItem(key);
      if (foundedEntry !== null && foundedEntry.userId === userId) {
        userTags.push(foundedEntry);
      }
    })
  );

  return userTags;
};

export const deleteFreeRecordings = async () => {
  const keys = await library.keys();
  await Promise.all(
    keys.map(async (key) => {
      const foundedEntry: recordedVideoLibraryEntry | null = await library.getItem(
        key
      );
      if (foundedEntry !== null && !foundedEntry.userId) {
        await library.removeItem(foundedEntry.recordingId);
      }
      return null;
    })
  );
};

export const keepFreeRecordings = async (userId: string) => {
  const keys = await library.keys();
  let lastElement;
  let startTime: number = 0;
  const videos = await Promise.all(
    keys.map(async (key) => {
      const localEntry: recordedVideoLibraryEntry | null = await library.getItem(
        key
      );
      if (localEntry === null || localEntry.userId) {
        return undefined;
      }

      const newLibraryEntry: recordedVideoLibraryEntry = {
        ...localEntry,
        userId,
      };
      await library.setItem(localEntry.recordingId, newLibraryEntry);
      const recordedVideoFile = new File(
        [newLibraryEntry.video],
        `${newLibraryEntry.name}.webm`,
        {
          type: 'video/webm',
        }
      );
      // get the last recorded video
      const newTime = Number(newLibraryEntry.timestamp);
      if (startTime < newTime) {
        startTime = newTime;
        lastElement = recordedVideoFile;
      }

      return newLibraryEntry;
    })
  );
  download(lastElement);

  return videos.filter(Boolean);
};

export const moveVideoToDirectory = async (data: {
  recordingId: string;
  parentId: string;
}) => {
  const libraryEntry: recordedVideoLibraryEntry | null = await library.getItem(
    data.recordingId
  );
  if (libraryEntry === null) return;

  const newLibraryEntry: recordedVideoLibraryEntry = {
    ...libraryEntry,
    folderId: data.parentId,
  };
  await library.setItem(libraryEntry.recordingId, newLibraryEntry);
};

export const moveManyVideosToDirectory = async (
  recordingIds: string[],
  folderId: string
) => {
  const recordings = await Promise.all<recordedVideoLibraryEntry | null>(
    recordingIds.map((f) => library.getItem(f))
  );

  await Promise.all(
    recordings
      .filter((f) => !!f)
      .map((f: recordedVideoLibraryEntry) => ({ ...f, folderId }))
      .map((f) => library.setItem(f.recordingId, f))
  );
};

export const downloadFile = async (url: string) => {
  const elemA = document.createElement('a');
  elemA.href = url;
  elemA.target = '_blank';
  if ('download' in elemA) {
    elemA.download = url.split('/').pop() || 'video.webm';
  }
  elemA.click();
};

// Adapted from https://developers.google.com/web/updates/2017/08/estimating-available-storage-space
export async function estimateStorageSpace(): Promise<{
  usage?: number;
  quota?: number;
}> {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    // We've got the real thing! Return its response.
    return navigator.storage.estimate();
  }

  if (
    'webkitTemporaryStorage' in navigator &&
    'queryUsageAndQuota' in navigator.webkitTemporaryStorage
  ) {
    // Return a promise-based wrapper that will follow the expected interface.
    return new Promise((resolve, reject) => {
      navigator.webkitTemporaryStorage.queryUsageAndQuota(
        (usage, quota) => resolve({ usage, quota }),
        reject
      );
    });
  }

  // If we can't estimate the values, return a Promise that resolves with NaN.
  return Promise.resolve({ usage: NaN, quota: NaN });
}

// check if local storage is available
export const isLocalStorageAvailable = async () => {
  try {
    const { usage = 0, quota = 100 } = (await estimateStorageSpace()) || {};
    if (quota === 0) return false;

    // return true if usage is less than 80%
    const usagePercentage = (usage / quota) * 100;
    return usagePercentage <= 80;
  } catch (error) {
    log('Error while estimating storage:', error);
    return false;
  }
};

function getWindow(): any {
  return window;
}

// Recovery using saved chunks via dev. console
getWindow().attemptRecovery = (userId = localStorage.getItem('ltid')) => {
  recover(userId, '').then(() => log('Done'));
};

// delete old(more than 24hrs) recover videos
export const deleteOldRecoverVideos = async () => {
  const recoverVideos = await listUnfinishedVideos();
  if (recoverVideos.length === 0) return;

  recoverVideos.forEach((video: any) => {
    const timeDiff = video.start - Date.now();
    const diffInHours = Math.abs(timeDiff / HOUR);
    const id = video.recordingId;

    if (diffInHours >= 24) {
      if (video?.end) {
        deleteRawVideo(id);
      } else {
        deleteChunksByRecordingId(id);
      }
      deleteUnfinishedVideoRecord(id);
    }
  });
};

export default getSavedVideoFiles;
