import 'regenerator-runtime/runtime';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import rootReducer from './reducers';

import rootSaga from './saga/rootSaga';
import { config } from '../config';

const initialState = {};
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, ReduxThunk];
if (config.debug) {
  middleware.push(
    createLogger({
      diff: true,
    })
  );
}
const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      trace: true,
      traceLimit: 25,
    })) ||
  compose;
const initiateStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );
  sagaMiddleware.run(rootSaga);
  return store;
};
// eslint-disable-next-line import/prefer-default-export
export const store = initiateStore();
export default store;
// export default(initialState) => {
//   const store = createStore(rootReducer, initialState, composeEnhancers(applyMiddleware(...middleware)));
//   sagaMiddleware.run(rootSaga);
//   return store;
// };
