// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import {
  getUserProfileRequest,
  userResendEmailVerificationResponse,
  userSignUpResponse,
} from '../features/auth/authSlice';
import { getAggregatedDataRequest } from '../features/app/appSlice';

export default (state = STORE_INITIATE_VALUE.USER_ADMISSION, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.USER_SIGN_IN_REQUEST:
      return {
        ...state,
        data: null,
        isLoading: true,
        isSignIn: true,
      };

    case REDUX_ACTIONS.USER_SIGN_IN_RESPONSE:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isSignIn: true,
      };
    case REDUX_ACTIONS.USER_SIGN_UP_REQUEST:
    case REDUX_ACTIONS.USER_RESEND_EMAIL_VERIFICATION_REQUEST:
    case REDUX_ACTIONS.FORGET_PASSWORD_REQUEST:
    case REDUX_ACTIONS.RESET_PASSWORD_REQUEST:
      return {
        ...state,
        data: null,
        isLoading: true,
        isSignIn: false,
      };
    case userSignUpResponse.type:
    case userResendEmailVerificationResponse.type:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
        isSignIn: false,
      };
    case REDUX_ACTIONS.FORGET_PASSWORD_RESPONSE:
    case REDUX_ACTIONS.RESET_PASSWORD_RESPONSE:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isSignIn: false,
      };
    case REDUX_ACTIONS.RESET_USER_ADMISSION:
      return {
        ...state,
        data: null,
        isLoading: false,
        isSignIn: false,
      };
    case REDUX_ACTIONS.POST_USER_PROFILE_REQUEST:
      return {
        ...state,
        data: null,
        isLoadingProfile: true,
      };
    case REDUX_ACTIONS.POST_USER_PROFILE_RESPONSE:
      return {
        ...state,
        data: action.data,
        isLoadingProfile: false,
      };
    case getUserProfileRequest.type:
      return {
        ...state,
        data: action.payload,
        isLoadingProfile: false,
      };
    case getAggregatedDataRequest.type:
      return {
        ...state,
        isLoadingProfile: false,
      };
    default:
      return state;
  }
};
