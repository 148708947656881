// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.FILTERED_RECORDINGS, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FILTER_REQUEST_RECORDINGS:
      return {
        ...state,
        date: action.data.date,
        status: action.data.status,
        title: action.data.title,
      };

    default:
      return state;
  }
};
