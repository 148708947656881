import getHistory from '../history';
import { LibraryTabType, SettingsType, ErrorPageTypes } from '../types';
import { FolderItem } from '../types/state';
import { pushModal } from '../features/globalModals/globalModalsSlice';
import store from '../store';
import { config } from '../../config';

const history = getHistory();

interface WithBasePathType {
  withBasePath?: boolean;
}

const nullStringToNull = (str: string | null) => {
  if (str === 'null' || !str) {
    return null;
  }
  return str;
};

// We use hash router. By default, we append hash to the url.
const mayBeAppendHash = (url: string, withBasePath = false): string => {
  if (withBasePath) {
    const basePath = window.location.pathname;
    return `${basePath}#${url}`;
  }
  return url;
};

export const getPluginDemoUrl = (token: string) => {
  return `${config.appBaseUrl}/plugin-test.html?token=${token}`;
};
interface GetTabUrlType {
  libraryTab: LibraryTabType;
}
export const getTabUrl = ({
  libraryTab,
  withBasePath,
}: GetTabUrlType & WithBasePathType): string =>
  mayBeAppendHash(`/library/${libraryTab}`, withBasePath);

export const goToTabUrl = (options: GetTabUrlType) =>
  history.push(getTabUrl({ ...options }));

interface GetTeamUrlType {
  teamId: string | string[] | null;
}
export const getTeamUrl = ({
  teamId,
  withBasePath,
}: GetTeamUrlType & WithBasePathType): string =>
  mayBeAppendHash(`/library/${teamId}`, withBasePath);

export const goToTeamUrl = (options: GetTeamUrlType) =>
  history.push(getTeamUrl(options));

interface GetFolderUrlType {
  teamId: string;
  folderId: string;
}
export const getFolderUrl = ({
  teamId,
  folderId,
  withBasePath,
}: GetFolderUrlType & WithBasePathType) =>
  mayBeAppendHash(`/library/${teamId}/${folderId}`, withBasePath);

export const goToFolderUrl = (options: GetFolderUrlType) => {
  history.push(getFolderUrl(options));
};

interface GetFileUrlType {
  teamId: string;
  parentId: string | null;
  fileId: string;
}
export const getFileUrl = ({
  teamId,
  parentId = 'default',
  fileId,
  withBasePath,
}: GetFileUrlType & WithBasePathType) => {
  return mayBeAppendHash(
    `/library/${teamId}/${nullStringToNull(parentId) || 'default'}/${fileId}`,
    withBasePath
  );
};

export const goToFileUrl = (options: GetFileUrlType) => {
  history.push(getFileUrl({ ...options }));
};

interface GetBreadcrumbsPathType {
  teamId: string;
  folderData: FolderItem[];
  selectedFileId: string;
}

interface GetBreadcrumbsPathReturnType {
  name: string;
  url: string;
}

export const getBreadcrumbsPath = ({
  teamId,
  folderData,
  selectedFileId,
}: GetBreadcrumbsPathType): GetBreadcrumbsPathReturnType[] => {
  const path: GetBreadcrumbsPathReturnType[] = [];
  let folderId = selectedFileId;
  if (!folderId)
    return [
      {
        name: 'Personal',
        url: getFolderUrl({ teamId, folderId, withBasePath: true }),
      },
    ];
  while (folderId) {
    // eslint-disable-next-line no-loop-func
    const folder = folderData.find((f) => f._id === folderId);
    if (!folder) {
      break;
    }
    path.unshift({
      name: folder.name,
      url: getFolderUrl({ teamId, folderId, withBasePath: true }),
    });
    if (!folder.parentId) {
      break;
    }
    folderId = folder.parentId;
  }
  return path;
};

export const goToPricingUrl = () => {
  store.dispatch(pushModal({ type: 'stripe-pricing-table-upgrade-modal' }));
};

export const goToManageSpaceUrl = () => {
  history.push('/settings/spaces');
};

export const goToSignupUrl = () => {
  history.push('/signup');
};

export const goToSinginUrl = () => {
  history.push('/signin');
};

export const goToDashboardUrl = () => {
  history.push('/dashboard');
};

export const goToRecordingUrl = () => {
  history.push('/recording');
};

export const goToContactUsUrl = () => {
  goToContactUsUrl();
};

export const goToForgotPassword = () => {
  history.push('/forgotpassword');
};

export const goToSettingsUrl = ({ setting, subSetting }: SettingsType) => {
  let url = setting ? `/settings/${setting}` : '/settings';
  if (subSetting) {
    url += `/${subSetting}`;
  }
  history.push(url);
  return url;
};

export const goToResetPasswordUrl = (token: string) => {
  history.push(`/resetpassword?token=${token}`);
};

interface GoToErrorPageType {
  errorType: ErrorPageTypes;
}
export const goToErrorPage = ({ errorType }: GoToErrorPageType) => {
  switch (errorType) {
    case 'resetPasswordTokenExpiredError':
      history.push('/resetpasswordtoken_expired');
      break;
    case 'verificationTokenExpiredError':
      history.push('/verificationtoken_expired');
      break;
    case 'requestExpiredError':
      history.push('/expired-request');
      break;
    default:
      history.push('/');
  }
};
