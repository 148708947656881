// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import { updateFsSettingsSuccess } from '../features/auth/authSlice';

export default (state = STORE_INITIATE_VALUE.FILESYSTEM_SETTINGS, action) => {
  switch (action.type) {
    case updateFsSettingsSuccess.type:
      return action.payload;
    case REDUX_ACTIONS.GET_FS_SETTINGS_SUCCESS:
      return action.data;
    default:
      return state;
  }
};
