import React, { createContext } from 'react';
import { RecordingArgs } from '../../lib/Uploader/MultipartUploader';
import { Media, RecorderSettings } from '../../types/state';

const defaultSettings: RecorderSettings = {
  audioSettings: {
    browserAudio: true,
    microphoneAudio: true,
    systemAudio: true,
  },
  videoSettings: {
    webCam: true,
    screen: true,
  },
  deviceSettings: {
    webcamDeviceId: '',
    microphoneDeviceId: '',
  },
  recordingControlSettings: {
    startRecordingAuto: false,
    autoDownload: false,
    defaultRecordingFormat: 'mp4',
    maxRecordingTimeMins: 0,
  },
  uiSettings: {
    hideLogo: false,
    recorderButtonText: 'Record',
    position: 'BOTTOM_RIGHT',
    helpText: 'Need help?',
    identificationForm: true,
  },
  otherSettings: {
    webhookUrl: '',
    hideTitleAndDescriptionDialog: false,
    defaultTranscriptionLanguage: 'en',
    formatProfile: 'mkv',
    generatePublicUrl: false,
    downloadSharedVideos: false,
  },
};

const defaultRecordingArgs: RecordingArgs = {
  recordingId: '',
  recorderName: '',
  recorderEmail: '',
  folderId: '',
  teamId: '',
  profile: '',
  oldMetadataSize: 0,
};

const defaultRecorderParams: RecorderParams = {
  status: '',
  pause: false,
  displayRecording: false,
  media: {
    isVideoStreamAvailable: false,
    isDesktopStreamAvailable: false,
    videoSource: '',
    error: '',
    recordingSize: 0,
  },
  muteRecorderMic: false,
  muteRecorderBrowserAudio: false,
  audioRecorderEnable: false,
};

export interface RecorderParams {
  status: string;
  pause: boolean;
  displayRecording: boolean;
  media: Media;
  muteRecorderMic: boolean;
  muteRecorderBrowserAudio: boolean;
  audioRecorderEnable: boolean;
}

export interface RecorderOptions {
  token: string;
  settings: RecorderSettings;
  recordingArgs: RecordingArgs;
  recorderParams: RecorderParams;
}

type RecordingOptionssProviderProps = RecorderOptions &
  React.PropsWithChildren<{}>;
const RecorderSettingsContext = createContext<RecordingOptionssProviderProps>({
  settings: defaultSettings,
  token: '',
  recordingArgs: defaultRecordingArgs,
  recorderParams: defaultRecorderParams,
});

export const RecorderSettingsProvider: React.FC = ({
  settings,
  token,
  recordingArgs,
  children,
  recorderParams,
}: RecordingOptionssProviderProps) => {
  return (
    <RecorderSettingsContext.Provider
      value={{ settings, token, recordingArgs, recorderParams }}
    >
      {children}
    </RecorderSettingsContext.Provider>
  );
};

export const useRecorderOptions = (): RecorderOptions => {
  const { token, settings, recordingArgs, recorderParams } = React.useContext(
    RecorderSettingsContext
  );
  return { token, settings, recordingArgs, recorderParams };
};

export default RecorderSettingsProvider;
