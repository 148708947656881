import { serializeError } from 'serialize-error';
import debug from 'debug';
import { config } from '../../config';
import { getUserAgentDetails } from './browserCompatibility';
import { getOrCreateLocalTrackingId } from './localStorageHandler';
import { getSessionUserId } from './sessionStorageHandler';
import { sendErrorReport } from '../services/analyticsService';

const log = debug('app:errorReports');

let sentUserAgentDetails = 0;
// To avoid sending the same type of error again in the same session
const errorsSent = new Set();

export const reportError = (source: string, payload: any) => {
  if (!config.reportErrors) {
    return;
  }

  // Avoid sending the user agent details multiple times in the same session
  const userAgentDetails = sentUserAgentDetails || getUserAgentDetails();
  const trackingId = getSessionUserId() || getOrCreateLocalTrackingId();
  const wrappedError = serializeError(payload);

  if (errorsSent.has(wrappedError?.error?.name)) {
    log('Skipping reported error type', wrappedError?.error?.name);
    return;
  }
  errorsSent.add(wrappedError?.error?.name);

  const errorReport = {
    s: source,
    u: trackingId,
    m: userAgentDetails,
    e: wrappedError,
    t: Date.now(),
  };

  log('Reporting error', errorReport);

  sendErrorReport(errorReport)
    .then(() => {
      sentUserAgentDetails = sentUserAgentDetails || errorReport.t;
    })
    .catch((e) => {
      log('Error reporting error', e);
    });
};

export default reportError;
