/**
 * Created by supun on 07/02/18.
 */
import React from 'react';

const getDisplayTimeString = (timeInMilliseconds: number): string => {
  // Convert milliseconds to seconds and initialize time components
  let totalSeconds = Math.floor(timeInMilliseconds / 1000);

  // Calculate hours, minutes, and seconds
  const hours = Math.floor(totalSeconds / 3600);
  totalSeconds %= 3600; // Subtract hours and get the remaining seconds
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60; // Subtract minutes and get the remaining seconds

  // Pad the hours, minutes, and seconds with leading zeros if needed
  const paddedHours = hours.toString().padStart(2, '0');
  const paddedMinutes = minutes.toString().padStart(2, '0');
  const paddedSeconds = seconds.toString().padStart(2, '0');

  // Return the formatted time string
  return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
};

interface RecordingTimerComponentProps {
  time: number;
}

const RecordingTimerComponent = ({ time }: RecordingTimerComponentProps) => {
  return <div className="recording-timer">{getDisplayTimeString(time)} </div>;
};
export default RecordingTimerComponent;
