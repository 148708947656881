import api from '../lib/baseAxios';

export const surveySubscribeFeedback = async (feedback: {
  use: string;
  primaryField: string;
  frequency: string;
}) => {
  return api.post(`/feedback/survey/subscribe`, feedback);
};

export const getFeedbackData = async () => {
  return api.get(`/feedback/paid-user/update-and-show-date`);
};

export const updateFeedbackLastUpdatedAndLastShowedDate = async () => {
  return api.post(`/feedback/paid-user/update-and-show-date`);
};

export const updateFeedbackLastShowedDate = async () => {
  return api.post(`/feedback/paid-user/show-date`);
};
