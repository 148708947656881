import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (
  state: { [x: string]: any } = STORE_INITIAL_VALUE.FILE_UPLOADS,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.MARK_SYNCED_FILES:
      return state;
    case REDUX_ACTIONS.MARK_SYNCED_FILES_SUCCESS: // Initial guessing of synced files, 'working' files preserved
      return action.data;
    case REDUX_ACTIONS.UPLOAD_CHECKED_FILES_PENDING: // All w/ 'pending' status
      return { ...state, ...action.data };
    case REDUX_ACTIONS.UPLOAD_FILE_START:
      return {
        ...state,
        [action.data._id]: 'working',
      };
    case REDUX_ACTIONS.REALTIME_UPLOADER_SUCCESS:
    case REDUX_ACTIONS.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        [action.data._id]: 'complete',
      };
    case REDUX_ACTIONS.UPLOAD_FILE_ERROR:
      return {
        ...state,
        [action.data._id]: 'failed',
      };
    default:
      return state;
  }
};

export const fileUploadProgress = (
  state: { [x: number]: any } = STORE_INITIAL_VALUE.FILE_UPLOAD_PROGRESS,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.UPDATE_UPLOAD_FILE_PROGRESS:
      return {
        ...state,
        [action.data._id]: Math.round(
          (action.data.loaded * 100) / (action.data.total || 1)
        ),
      };
    default:
      return state;
  }
};
