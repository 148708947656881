/* eslint-disable import/prefer-default-export */

import api from '../lib/baseAxios';

export const track = async (payload) => {
  return api.post(`/analytics`, payload);
};

export const sendErrorReport = async (payload) => {
  return api.post(`/analytics/error`, payload);
};

export const surveyUnubscribeReason = async (reasons: {
  reason: string;
  feedback: string;
}) => {
  return api.post(`/feedback/survey/unsubscribe`, reasons);
};
