import { bindActionCreators } from 'redux';
import { actionCreateStoreUpdateFactory } from './actionCreator';
import {
  ACTION_ATTR as ATTRS,
  REDUX_ACTIONS as ACTION_TYPES,
} from '../constants/apiSagaConstant';
import { OpenBanners } from '../types/state';

export type OpenBannerActionType = (data: OpenBanners) => void;

export type CloseBannerActionType = (data?: {
  action: string;
  data: null;
}) => void;

export const getOpenBannerAction = (dispatch): OpenBannerActionType =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.OPEN_BANNER, ATTRS.DATA),
    dispatch
  );

export const getCloseBannerAction = (dispatch): CloseBannerActionType =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.CLOSE_BANNER),
    dispatch
  );
