/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';
import debug from 'debug';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { transcribe } from '../../services/fileSystemService';

const log = debug('app:transcriptionSaga');

function* transcriptionGenerator(action) {
  try {
    yield call(transcribe, action.data);
    // No response processing
  } catch (e) {
    log(e);
    // Fail silently
  } finally {
    yield put({
      // Limitation numbers maybe obsolete now
      type: REDUX_ACTIONS.GET_USAGE_REQUEST,
    });
  }
}

export function* transcriptionSaga() {
  yield takeLatest(REDUX_ACTIONS.START_TRANSCRIPTION, transcriptionGenerator);
}
