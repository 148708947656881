/* eslint-disable import/prefer-default-export */
import { call, put, takeLatest } from 'redux-saga/effects';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { askAI, fetchAISuggestions } from '../../services/fileSystemService';

const DEFAULT_FETCH_SUGGESTIONS_ERROR = 'Unable to fetch suggestions.';
function* fetchAISuggestionsGenerator(action) {
  try {
    const response = yield call(fetchAISuggestions, action.data);
    if (response.data?.success === true) {
      yield put({
        type: REDUX_ACTIONS.FETCH_AI_SUGGESTIONS_SUCCESS,
        data: {
          // Smoothly switch over API change
          suggestions: response.data.data.sessionId
            ? response.data.data.suggestions
            : response.data.data,
          sessionId: response.data.data.sessionId,
          id: action.data.id,
        },
      });
    } else {
      throw new Error();
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: REDUX_ACTIONS.FETCH_AI_SUGGESTIONS_FAIL,
      data: {
        error: error.message || DEFAULT_FETCH_SUGGESTIONS_ERROR,
        id: action.data.id,
      },
    });
  }
}

const DEFAULT_ASK_AI_ERROR =
  'Sorry, due to technical difficulties, we are unable to process your request at this time.';
function* askAIGenerator(action) {
  try {
    const response = yield call(askAI, action.data);
    if (response.data?.success === true) {
      yield put({
        type: REDUX_ACTIONS.ASK_AI_SUCCESS,
        data: {
          // Smoothly switch over API change
          message: response.data.data.sessionId
            ? response.data.data.answer
            : response.data.data,
          sessionId: response.data.data.sessionId,
          id: action.data.id,
        },
      });
    } else {
      throw new Error();
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: REDUX_ACTIONS.ASK_AI_FAIL,
      data: {
        error: error.message || DEFAULT_ASK_AI_ERROR,
        id: action.data.id,
      },
    });
  }
}

export function* askAISaga() {
  yield takeLatest(
    REDUX_ACTIONS.FETCH_AI_SUGGESTIONS,
    fetchAISuggestionsGenerator
  );
  yield takeLatest(REDUX_ACTIONS.ASK_AI, askAIGenerator);
}
