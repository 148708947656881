import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import RecorderComponent, {
  PluginRecorderProps,
} from './Recorder/RecorderComponent';
import { RECORDER_STATUS } from '../../constants/apiSagaConstant';
import { PLUGIN_POSITION } from '../../constants/appConstant';
import { clearRecordingState } from '../../features/recorder/recorderSlice';
import { State } from '../../types/state';

export const KeyContext = React.createContext('');

const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: '1.5vw',
    borderRadius: '5px',
    padding: 8,
    boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.8)',
    backgroundColor: '#fff',
    color: '#000',
    opacity: 1,
    zIndex: 100,
    fontFamily: 'Poppins, sans-serif !important',
    width: 300,
    '@media only screen and (min-width: 1500px)': {
      width: 270,
    },
  },
  bottomLeft: {
    left: '2vw',
    borderRadius: '15px',
  },
  bottomRight: {
    right: '2vw',
    borderRadius: '15px',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});

const Plugin = (props: PluginRecorderProps) => {
  const classes = useStyles();
  const { onClose } = props;
  const dispatch = useDispatch();

  const handleClose = async () => {
    await dispatch(clearRecordingState());
    onClose();
  };
  const { recorder, auth } = useSelector((state: State) => ({
    recorder: state.recorder,
    auth: state.auth,
  }));

  return recorder.status !== RECORDER_STATUS.RUNNING ? (
    <div
      className={clsx(
        classes.root,
        auth?.settings?.recorderSettings?.uiSettings?.position ===
          PLUGIN_POSITION.BOTTOM_RIGHT
          ? classes.bottomRight
          : classes.bottomLeft
      )}
    >
      {!(
        recorder.status === RECORDER_STATUS.READY ||
        recorder.status === RECORDER_STATUS.RUNNING
      ) && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          size="small"
          onClick={() => handleClose()}
          disabled={
            recorder.status === RECORDER_STATUS.READY ||
            recorder.status === RECORDER_STATUS.RUNNING
          }
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      )}
      <RecorderComponent {...props} />
    </div>
  ) : (
    <RecorderComponent {...props} />
  );
};

export default Plugin;
