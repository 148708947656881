export const beforeTrim = {
  status: 'success',
  title: 'Trim your recording file.',
  user: {},
  color: 'success',
  message:
    'Enter your email address below so that we can email you the trimmed video. This usually takes 3-5 minutes. We will never spam you...!',
};
export const trimSuccess = {
  status: 'success',
  user: {},
  color: 'success',
  title: 'Your Video is trimmed successfully',
  // title: 'Video Trimming',
  message:
    'Video trimming was successful.We will you the trimmed video in a few minutes. Thank you.',
};

export const pluginFieldList = ['setting'];

export const PLUGIN_POSITION = {
  BOTTOM_LEFT: 'BOTTOM_LEFT',
  BOTTOM_RIGHT: 'BOTTOM_RIGHT',
};
export const PLUGIN_MODE = { OPEN: 'OPEN', MINIMIZED: 'MINIMIZED' };
