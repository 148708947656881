// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import { FileTypeExtended } from '../types';

function cleanAndSort(files: FileTypeExtended[]) {
  return files
    .map((f) => ({
      ...f,
      createdAt:
        typeof f.createdAt === 'string' ? new Date(f.createdAt) : f.createdAt,
      updatedAt:
        typeof f.updatedAt === 'string' ? new Date(f.updatedAt) : f.updatedAt,
    }))
    .sort((f1, f2) => f2.createdAt.getTime() - f1.createdAt.getTime());
}

function takeLocalPersonalFiles(files: FileTypeExtended[]): FileTypeExtended[] {
  return files.filter((f) => f.provider === 'IDB');
}

/**
 * Do this before sorting
 *
 * @param files personal library
 * @returns deduplicated, S3:* prioritized files
 */
function deduplicatePersonalFiles(
  files: FileTypeExtended[]
): FileTypeExtended[] {
  // Show only the remote files in case if the files are present in both locations
  const prioritized = {};

  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    // S3, S3:request has priority
    if (['', 'IDB'].includes(prioritized[file._id]?.provider || '')) {
      prioritized[file._id] = file;
    }
  }

  return Object.keys(prioritized).map((key) => prioritized[key]);
}

function takeRemotePersonalFiles(
  files: FileTypeExtended[]
): FileTypeExtended[] {
  return files.filter((f) => f.provider !== 'IDB');
}

export default (state = STORE_INITIATE_VALUE.LIBRARY, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.PAST_RECORDINGS_FETCH_SUCCESS:
      return {
        ...state,
        personal: cleanAndSort(
          deduplicatePersonalFiles([
            ...takeRemotePersonalFiles(state.personal),
            ...action.data,
          ])
        ),
      };
    case REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS: {
      const personal = cleanAndSort(
        deduplicatePersonalFiles([
          ...takeLocalPersonalFiles(state.personal),
          ...action.data.personalLibrary,
        ])
      );
      const plugin = cleanAndSort(action.data.pluginLibrary);
      return { ...state, personal, plugin };
    }
    case REDUX_ACTIONS.GET_SHARE_PUBLIC_FILES_SUCCESS: {
      const { fileSystem } = action.data;
      return { ...state, public: cleanAndSort(fileSystem) };
    }
    case REDUX_ACTIONS.GET_COMPANY_SHARED_FILES_SUCCESS: {
      const { fileSystem } = action.data;
      return { ...state, team: cleanAndSort(fileSystem || []) };
    }
    case REDUX_ACTIONS.UPLOAD_FILE_SUCCESS: // Cloud upload
      return {
        ...state,
        personal: cleanAndSort(
          deduplicatePersonalFiles([action.data, ...state.personal])
        ),
      };
    default:
      return state;
  }
};
