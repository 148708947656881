import { ProfileType } from '../constants/mediaFormatProfiles';
import MatroskaCueGenerator from './MatroskaCueGenerator';

export interface CueGenerator {
  add(chunk: Blob): Promise<void>;

  end(): Promise<{
    newMetadata: ArrayBuffer | undefined;
    oldMetadataSize: number;
    duration: number;
  }>;
}

export class DummyCueGenerator implements CueGenerator {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  noop = (..._args: any[]) => {};

  async add(_chunk: Blob): Promise<void> {
    this.noop(_chunk);
  }

  async end(): Promise<{
    newMetadata: ArrayBuffer | undefined;
    oldMetadataSize: number;
    duration: number;
  }> {
    this.noop();

    return {
      newMetadata: undefined,
      oldMetadataSize: 0,
      duration: 0,
    };
  }
}

export function createCueGenerator(profile: ProfileType) {
  if (['mkv', 'webm'].includes(profile)) {
    return new MatroskaCueGenerator();
  }

  return new DummyCueGenerator();
}
