/* eslint-disable import/prefer-default-export */

import { call, fork, put, select, take, takeLatest } from 'redux-saga/effects';
import debug from 'debug';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { RecordingArgsWithToken, createUploader } from '../../lib/streamUpload';
import { FileSystemEntityType } from '../../types';
import { separateFileSystemEntities } from './storageSaga';
import { fetchFileSystem } from '../../services/fileSystemService';
import createAppUnloadEventChannel from '../../lib/appUnloadBlock';
import { audioOnlySelect } from '../../features/recorder/recorderSlice';
import {
  realtimeUploaderConnected,
  realtimeUploaderError,
  realtimeUploaderInit,
  realtimeUploaderProgress,
  realtimeUploaderReset,
} from '../../features/realtimeUploader/realtimeUploaderSlice';

const log = debug('app:realtimeUploaderSaga');

interface StartRealtimeUploaderGeneratorAction {
  type: string;
  payload: RecordingArgsWithToken;
}

function* appUnloadBlockGenerator(channel) {
  try {
    while (true) {
      const ev = yield take(channel);
      switch (ev.type) {
        case 'TRY_UNLOAD': {
          yield put({
            type: REDUX_ACTIONS.ANALYTICS,
            data: {
              action: 'try-exit',
            },
          });
          break;
        }
        default:
          break;
      }
    }
  } catch (error) {
    // should be unreachable
  } finally {
    // done
  }
}

function* updateFileSystemGenerator() {
  const response = yield call(fetchFileSystem);
  const fileSystem = response?.data?.data?.fileSystem?.map(
    (f: Required<FileSystemEntityType>) => ({
      ...f,
      createdAt: new Date(f.createdAt),
      updatedAt: new Date(f.updatedAt),
    })
  );
  const { personalLibrary, pluginLibrary } = separateFileSystemEntities(
    fileSystem || []
  );

  yield put({
    type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
    data: { personalLibrary, pluginLibrary },
  });
}

function* startRealtimeUploaderGenerator(
  action: StartRealtimeUploaderGeneratorAction
) {
  let unblockAppUnload = () => {}; // Allow closing app; assigned later
  const { pluginMode } = yield select((state) => state.plugin);

  try {
    // Add to syncing file list as well (manual upload list)
    // Label as 'working' -- this is enough to avoid websocket uploads if the limit is reached
    yield put({
      type: REDUX_ACTIONS.UPLOAD_FILE_START,
      data: { _id: action.payload.recordingId },
    });
    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'upload-live-start',
        data: {
          id: action.payload.recordingId,
        },
      },
    });

    const [unblock, uploaderEc] = yield call(createAppUnloadEventChannel);
    unblockAppUnload = unblock;
    yield fork(appUnloadBlockGenerator, uploaderEc);

    // Receive events from recorder and streams
    const channel = yield call(createUploader, action.payload);

    log('Realtime uploader started');
    while (true) {
      // take(END) will cause the saga to terminate by jumping to the finally block
      const ev = yield take(channel);
      log(ev);

      switch (ev.type) {
        case 'CONNECTED':
          yield put(realtimeUploaderConnected());
          break;
        case 'PROGRESS':
          yield put(realtimeUploaderProgress(ev.data));
          yield put({
            type: REDUX_ACTIONS.UPDATE_UPLOAD_FILE_PROGRESS,
            data: { _id: action.payload.recordingId, ...ev.data },
          });
          break;
        case 'SUCCESS':
          log('realtime upload success', ev);

          yield put({
            type: REDUX_ACTIONS.REALTIME_UPLOADER_SUCCESS,
            data: { _id: action.payload.recordingId },
          });

          yield put(audioOnlySelect({ audioRecorderEnable: false }));

          // delete chucks after recording is uploaded
          yield put({
            type: REDUX_ACTIONS.RECOVER_DELETE_REQUEST,
            data: { recordingId: action.payload.recordingId },
          });

          yield put({
            type: REDUX_ACTIONS.ANALYTICS,
            data: {
              action: 'upload-live-success',
              data: {
                id: action.payload.recordingId,
              },
            },
          });

          if (!pluginMode) {
            if (action.payload.teamId) {
              yield put({
                type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES,
                data: action.payload.teamId,
              });
            }
            yield* updateFileSystemGenerator();
          }
          break;
        case 'ERROR':
          yield put({
            type: REDUX_ACTIONS.ANALYTICS,
            data: {
              action: 'upload-live-error',
              data: {
                id: action.payload.recordingId,
              },
            },
          });
          throw new Error(ev.data);
        default:
          break;
      }
    }
  } catch (error) {
    yield put(realtimeUploaderError({ message: error.message }));
    // Mark failure in syncing file list as well (manual upload list)
    yield put({
      type: REDUX_ACTIONS.UPLOAD_FILE_ERROR,
      data: { _id: action.payload.recordingId, message: error.message },
    });
  } finally {
    yield call(unblockAppUnload);
    yield put(realtimeUploaderReset());
    if (!pluginMode) {
      yield put({
        // Limitation numbers maybe obsolete now
        type: REDUX_ACTIONS.GET_USAGE_REQUEST,
      });
    }
  }
}

export function* startUploadingSaga() {
  yield takeLatest(realtimeUploaderInit.type, startRealtimeUploaderGenerator);
}
