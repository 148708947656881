/* eslint-disable import/prefer-default-export */
import {
  put,
  takeLatest,
  call,
  select,
  takeEvery,
  take,
  fork,
  delay,
} from 'redux-saga/effects';
import debug from 'debug';

import { groupBy } from 'lodash';
import { config } from '../../../config';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  getSavedVideoFiles,
  deleteVideo,
  updateRecordingDetails,
  saveNewTag,
  getTags,
  deleteFreeRecordings,
  keepFreeRecordings,
  moveVideoToDirectory,
  moveManyVideosToDirectory,
  deleteManyVideos,
  getVideoFile,
  downloadFile,
  estimateStorageSpace,
  deleteOldRecoverVideos,
} from '../../lib/storageUtils';
import {
  createFolder,
  deleteFolder,
  deleteFolderArrayDirectContents,
  fetchFileSystem,
  renameFolder,
  moveFile,
  editPrompt,
  getSettings,
  updateSettings,
  deleteFile,
  updateFile,
  moveManyFiles,
  deleteManyFiles,
  uploadFile,
  shareItems,
  getSharedItems,
  sharePublicFile,
  getSharedPublicFiles,
  unpublishedFile,
  getPublicSharedFile,
  addView,
  addDuration,
  getCompanySharedFiles,
  addRecordingToCompanySharedSpace,
  shareCompanySharedItems,
  summarize,
  chromeExtension,
  getImportFileDetails,
  transcode,
  deleteTeamSpaceFolder,
  sharePlugin,
} from '../../services/fileSystemService';
import {
  ITagEntry,
  IFolderInput,
  FolderType,
  FileSystemEntityType,
  FileTypeExtended,
  FileType,
  LibraryTabType,
} from '../../types';
import { getPluginFiles } from '../../services/pluginService';
import { getThumbnail } from '../../lib/ffmpegWrapper';
import fileToBlob from '../../lib/getBlobFromFile';
import { isATeamFSEntity } from '../../lib/teamSpaceUtils';
import {
  createUploader,
  sendBlobStream,
  tryFinishRecord,
} from '../../lib/streamUpload';
import { guessProfileFromMime } from '../../constants/mediaFormatProfiles';
import { showAlert } from '../../features/globalModals/globalModalsSlice';
import { selectVideoFromLibrary } from '../../features/selectedVideo/selectedVideoSlice';
import {
  importFileRequest,
  importFileSuccess,
  importFileError,
} from '../../features/importVideos/importVideoSlice';
import { updateFsSettingsSuccess } from '../../features/auth/authSlice';
import { recordingError } from '../../features/recorder/recorderSlice';
import {
  realtimeUploaderConnected,
  realtimeUploaderProgress,
  realtimeUploaderReset,
} from '../../features/realtimeUploader/realtimeUploaderSlice';

const log = debug('app:storageSaga');

interface IDeleteVideoActionType {
  type: string;
  data: {
    recordingId: string;
    libraryTab: LibraryTabType;
  };
}
interface IUpdateRecordingActionType {
  type: string;
  data: {
    recordingId: string;
    name: string;
    description: string;
    teamId?: string;
    ownerId?: string;
  };
}
interface IAddNewTagActionType {
  type: string;
  data: ITagEntry;
}

interface ICreateFolderActionType {
  type: string;
  data: IFolderInput;
}
interface IMoveVideoActionType {
  type: string;
  data: any;
}
interface IRemoveDirectoryActionType {
  type: string;
  data: { folderId: string };
}
interface IRenameDirectoryActionType {
  type: string;
  data: { folderId: string; name: string; teamId: string };
}
interface IEditPromptActionType {
  type: string;
  data: { id: string; teamId: string; prompt: string };
}
interface IFileBlob {
  id: string;
  blob: Blob;
}

const getAuth = (state) => state.auth;
const getLibraryTab = (state) => state.libraryTab;
const getLibrary = (state) => state.library;
const getCheckedFiles = (state) => state.checkedFiles;
const getSyncedFiles = (state) => state.fileUploads;
const getCompanySharedSpace = (state) => state.companySharedSpace;
const getSelectedTeamSpace = (state) => state.folders.team.selectedFolder;

const getLocalFiles = (state): any[] =>
  state.library.personal.filter((f) => f.provider === 'IDB');

const getPersonalRemoteFiles = (state): any[] =>
  state.library.personal.filter((f) => f.provider !== 'IDB');

const getRemoteFiles = (state) =>
  [
    ...(state.library?.personal || []),
    ...(state.library?.plugin || []),
    ...(state.library?.team || []),
  ].filter((f) => f.provider !== 'IDB');

const calculateUsedCloudSpace = (state) => {
  const allRemoteFiles = getRemoteFiles(state);

  return allRemoteFiles.map((f) => f.size).reduce((sum, val) => sum + val, 0);
};

const getCheckedFilesByLocation = (state) => {
  const checkedFilesMap = getCheckedFiles(state);

  const allLocal = getLocalFiles(state);
  const local = allLocal.filter((f) => checkedFilesMap[f._id]);

  const allRemote = getRemoteFiles(state);
  const remote = allRemote.filter((f) => checkedFilesMap[f._id]);

  return { remote, local };
};

/**
 * Get all checked files, but omit the remote files if they are in local too.
 * e.g. to download, it's easier to get local files.
 * @param state state
 */
const getCheckedFilesDedupedRemote = (state) => {
  const { remote, local } = getCheckedFilesByLocation(state);

  const localIdsMap = local
    .map((f) => f._id)
    .reduce((acc, id) => ({ ...acc, [id]: true }), {});

  const remoteFiltered = remote.filter((f) => !(f._id in localIdsMap));
  return { local, remote: remoteFiltered };
};

/**
 * Get all checked files, but omit the local files if they are in remote too.
 * e.g. to upload to cloud.
 * @param state state
 */
const getCheckedFilesDedupedLocal = (state) => {
  const { remote, local } = getCheckedFilesByLocation(state);

  const remoteIdsMap = remote
    .map((f) => f._id)
    .reduce((acc, id) => ({ ...acc, [id]: true }), {});

  const localFiltered = local.filter((f) => !(f._id in remoteIdsMap));
  return { local: localFiltered };
};

const getCheckedFileIdsByLocation = (state) => {
  const { remote, local } = getCheckedFilesByLocation(state);

  return { remote: remote.map((f) => f._id), local: local.map((f) => f._id) };
};

const getFilesWithId = (state, id) => {
  return [
    ...(state.library?.personal || []),
    ...(state.library?.plugin || []),
    ...(state.library?.team || []),
  ].filter((f) => f._id === id || f.recordingId === id);
};

const filterVideoLibrary = async (
  library: Array<FileTypeExtended | null>,
  auth
): Promise<Array<FileTypeExtended | null>> => {
  const { user, packageConfig } = auth;
  const currentUserId = user.data.id || ''; //  usersId is available to registered users only

  const filterLibrary =
    library && library.length > 0
      ? library.filter((v) => v && v.userId === currentUserId)
      : [];

  const upperFileIndex =
    filterLibrary.length >= packageConfig.localFileCount
      ? packageConfig.localFileCount
      : filterLibrary.length;

  const sortedLibrary =
    filterLibrary && filterLibrary.length > 0
      ? filterLibrary.sort((a, b) => {
          const keyA = a?.createdAt || '';
          const keyB = b?.createdAt || '';
          // Compare the 2 dates
          if (keyA > keyB) return -1;
          if (keyA < keyB) return 1;
          return 0;
        })
      : [];

  const sliceLibrary =
    sortedLibrary && sortedLibrary.length > 0
      ? sortedLibrary.slice(0, upperFileIndex)
      : [];

  return sliceLibrary;
};

export function separateFileSystemEntities(
  fileSystem: FileSystemEntityType[]
): {
  personalFolders: FileSystemEntityType[];
  pluginFolders: FileSystemEntityType[];
  teamFolders: FileSystemEntityType[];
  personalLibrary: FileSystemEntityType[];
  pluginLibrary: FileSystemEntityType[];
} {
  const personalFolders: FileSystemEntityType[] = [];
  const teamFolders: FileSystemEntityType[] = [];
  const pluginFolders: FileSystemEntityType[] = [];
  const personalLibrary: FileSystemEntityType[] = [];
  const pluginLibrary: FileSystemEntityType[] = [];

  fileSystem.forEach((f) => {
    if (f.type === 'Folder') {
      if (isATeamFSEntity(f)) {
        teamFolders.push(f);
      } else if (f.provider === 'S3:plugin') {
        pluginFolders.push(f);
      } else {
        personalFolders.push(f);
      }
    } else if (f.type === 'File') {
      if (isATeamFSEntity(f)) {
        // teamLibrary.push(f);
        // This is implemented separately
      } else if (f.provider === 'S3:plugin') {
        pluginLibrary.push(f);
      } else {
        personalLibrary.push(f);
      }
    }
  });

  // Backend file system is stored in the chronological order. We need to reverse it.
  return {
    personalFolders,
    personalLibrary: personalLibrary.reverse(),
    pluginFolders,
    pluginLibrary: pluginLibrary.reverse(),
    teamFolders,
  };
}

const PUBLIC_SHARED_CONTENTS_ENABLED = false;

function* getPublicSharedContents() {
  if (!PUBLIC_SHARED_CONTENTS_ENABLED) {
    return;
  }

  try {
    const response = yield call(getSharedPublicFiles);
    yield put({
      type: REDUX_ACTIONS.GET_SHARE_PUBLIC_FILES_SUCCESS,
      data: response?.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_SHARE_PUBLIC_FILES_FAIL,
      data: { message: error.message },
    });
  }
}

function* fetchCompanySharedContents(action) {
  try {
    if (!action.data) {
      return;
    }

    const userFileSystem = yield call(getCompanySharedFiles, action.data);
    yield put({
      type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES_SUCCESS,
      data: {
        fileSystem: userFileSystem?.data?.data?.fileSystem?.filter(
          (f) => f.type === 'File'
        ),
      },
    });
    yield put({
      type: REDUX_ACTIONS.FETCH_TEAMSPACE_FOLDERS_SUCCESS,
      data: {
        teamFolders: userFileSystem?.data?.data?.fileSystem?.filter(
          (f) => f.type === 'Folder'
        ),
      },
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES_FAIL,
      data: { message: error.message },
    });
    yield put({
      type: REDUX_ACTIONS.FETCH_TEAMSPACE_FOLDERS_ERROR,
      data: { message: error.message },
    });
  }
}

function* fetchLibraryGenerator() {
  try {
    const teamSpace = yield select(getCompanySharedSpace);
    yield* getPublicSharedContents();
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });

    let filterLibrary: Array<FileTypeExtended> = [];
    let freeRecordingCount = 0;
    const auth = yield select(getAuth);
    if (auth.user?.data?.id) {
      const library = yield call(getSavedVideoFiles);
      filterLibrary = yield call(filterVideoLibrary, library, auth);
      freeRecordingCount =
        library && library.length > 0
          ? library.filter((v) => v.userId === '').length
          : [];
    }
    yield put({
      type: REDUX_ACTIONS.PAST_RECORDINGS_FETCH_SUCCESS,
      data: filterLibrary || [],
    });

    // update free recordings count
    yield put({
      type: REDUX_ACTIONS.UPDATE_FREE_RECORDING_COUNT,
      data: freeRecordingCount || 0,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.PAST_RECORDINGS_FETCH_FAILURE,
      data: { message: e.message },
    });
    // update free recordings count when error occurred
    yield put({
      type: REDUX_ACTIONS.UPDATE_FREE_RECORDING_COUNT,
      data: 0,
    });
  }
}

export function* fetchLibrarySaga() {
  yield takeLatest(REDUX_ACTIONS.FETCH_PAST_RECORDINGS, fetchLibraryGenerator);
}

function* deleteVideoGenerator(action: IDeleteVideoActionType) {
  try {
    const { recordingId } = action.data;
    if (!recordingId) return;
    const candidates = yield select(getFilesWithId, recordingId);

    if (candidates.find((f) => f.provider === 'IDB')) {
      yield call(deleteVideo, recordingId);
      yield put({
        type: REDUX_ACTIONS.DELETE_VIDEO_SUCCESSFUL,
      });

      // Lets update the video library again
      yield put({
        type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
      });
    }

    if (candidates.find((f) => f.provider?.startsWith('S3'))) {
      const teamSpace = yield select(getCompanySharedSpace);
      const response = yield call(
        deleteFile,
        teamSpace.selectedTeamSpace?.teamId,
        recordingId
      );
      const { fileSystem } = response?.data?.data;
      const { personalLibrary, pluginLibrary } = separateFileSystemEntities(
        fileSystem || []
      );

      // Lets update the video library again
      yield put({
        type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
        // Backend file system is stored in the chronological order. We need to reverse it.
        data: { personalLibrary, pluginLibrary },
      });

      yield* getPublicSharedContents();
      yield* fetchCompanySharedContents({
        data: teamSpace.selectedTeamSpace?.teamId,
      });

      yield put({ type: REDUX_ACTIONS.MARK_SYNCED_FILES });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.DELETE_VIDEO_FAILURE,
      data: { message: e.message },
    });
  }
}

export function* deleteVideoSaga() {
  yield takeLatest(REDUX_ACTIONS.DELETE_VIDEO, deleteVideoGenerator);
}

function* updateRecordingGenerator(action: IUpdateRecordingActionType) {
  try {
    const { recordingId, ...args } = action.data;
    const { teamId } = args;
    const candidates = yield select(getFilesWithId, recordingId);

    if (!recordingId) return;

    const personalLibrary: FileType[] = (yield select(getLibrary)).personal;
    const pluginLibrary: FileType[] = (yield select(getLibrary)).plugin;

    if (candidates.find((f) => f.provider === 'IDB')) {
      yield call(updateRecordingDetails, action.data);
      yield put({
        type: REDUX_ACTIONS.UPDATE_RECORDING_SUCCESS,
      });

      // Lets update the video library again
      yield put({
        type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
      });
    }

    if (candidates.find((f) => f.provider !== 'IDB' && !isATeamFSEntity(f))) {
      const response = yield call(updateFile, recordingId, args);
      const file: FileType = response?.data?.data?.file;

      yield put({
        type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
        data: {
          personalLibrary: [
            file,
            ...personalLibrary.filter((f) => f._id !== recordingId),
          ],
          pluginLibrary: [
            file,
            ...pluginLibrary.filter((f) => f._id !== recordingId),
          ],
        },
      });

      if (teamId) {
        yield* fetchCompanySharedContents({ data: teamId });
      }
    }

    if (candidates.find(isATeamFSEntity)) {
      // TODO Optimistic UI
      yield call(updateFile, recordingId, args);
      // const file: FileType = response?.data?.data?.file;

      if (teamId) {
        yield* fetchCompanySharedContents({ data: teamId });
      }
    }
  } catch (e) {
    yield put(recordingError({ message: e.message }));
  }
}

export function* updateRecordingSaga() {
  yield takeLatest(
    REDUX_ACTIONS.UPDATE_RECORDING_REQUEST,
    updateRecordingGenerator
  );
}

// TODO Remove
function* addNewTag(action: IAddNewTagActionType) {
  try {
    const { data } = action;
    yield call(saveNewTag, data);
    yield put({
      type: REDUX_ACTIONS.ADD_NEW_TAG_SUCCESS,
    });

    // Lets update the tag on the redux store
    yield put({
      type: REDUX_ACTIONS.FETCH_TAG_REQUEST,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.ADD_NEW_TAG_ERROR,
      data: { message: e.message },
    });
  }
}

// TODO Remove
function* fetchTags() {
  try {
    const auth = yield select(getAuth);
    const { user } = auth;
    const currentUserId = user.data.id || ''; //  usersId is available to registered users only

    const tags = yield call(getTags, currentUserId);

    yield put({
      type: REDUX_ACTIONS.FETCH_TAG_SUCCESS,
      data: tags || [],
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.FETCH_TAG_ERROR,
      data: { message: e.message },
    });
  }
}

function* _createFolder(action: ICreateFolderActionType) {
  try {
    const { data } = action;

    // Call remote endpoint and update state.
    const response = yield call(createFolder, data);
    const createdFolder = response?.data?.data?.folder;
    yield put({
      type: REDUX_ACTIONS.CREATE_FOLDER_SUCCESS,
      data: {
        ...createdFolder,
        createdAt: new Date(createdFolder.createdAt),
        updatedAt: new Date(createdFolder.updatedAt),
      },
    });
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.CREATE_FOLDER_ERROR,
      data: {
        message: e.message,
        error: 'Space with same name already exists or something went wrong',
      },
    });
  }
}

function* _getFolders() {
  try {
    const auth = yield select(getAuth);
    const isSharedSpaceUser = auth.user?.data?.features?.sharedSpaceUser;
    const userFileSystem = yield call(fetchFileSystem);
    let pluginFileSystem: any = [];

    if (isSharedSpaceUser) {
      const sharedSpaceFileSystem = yield call(getPluginFiles);
      pluginFileSystem = sharedSpaceFileSystem?.data?.data?.map(
        (f: Required<FileSystemEntityType>) => ({
          ...f,
          createdAt: new Date(f.createdAt),
          updatedAt: new Date(f.updatedAt),
        })
      );
    }

    const fileSystem = userFileSystem?.data?.data?.fileSystem?.map(
      (f: Required<FileSystemEntityType>) => ({
        ...f,
        createdAt: new Date(f.createdAt),
        updatedAt: new Date(f.updatedAt),
      })
    );

    const {
      personalFolders,
      personalLibrary,
      pluginFolders,
      pluginLibrary,
      teamFolders,
    } = separateFileSystemEntities(
      isSharedSpaceUser
        ? [...pluginFileSystem, ...fileSystem] || []
        : fileSystem || []
    );

    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_FOLDERS_SUCCESS,
      data: { personalFolders, pluginFolders, teamFolders },
    });

    yield put({
      type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
      data: { personalLibrary, pluginLibrary },
    });

    yield put({ type: REDUX_ACTIONS.MARK_SYNCED_FILES });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_FOLDERS_ERROR,
      data: { message: e.message },
    });
  }
}

function* markSyncedFiles() {
  try {
    const syncedFiles = {};

    // Preserve working files (ws upload etc.)
    const oldSyncedFiles = yield select(getSyncedFiles);
    const workingFiles = Object.keys(oldSyncedFiles).filter(
      (f) => oldSyncedFiles[f] === 'working'
    );
    workingFiles.forEach((f) => {
      syncedFiles[f] = 'working';
    });

    const remoteFiles: FileType[] = yield select(getPersonalRemoteFiles);
    remoteFiles.forEach((f) => {
      syncedFiles[f._id] = 'complete';
    });

    yield put({
      type: REDUX_ACTIONS.MARK_SYNCED_FILES_SUCCESS,
      data: syncedFiles,
    });
  } catch (e) {
    // Nothing to do
  }
}

function* _moveVideoToDirectory(action: IMoveVideoActionType) {
  try {
    const { recordingId, ...args } = action.data;
    if (!recordingId) return;
    const candidates = yield select(getFilesWithId, recordingId);

    if (candidates.find((f) => f.provider === 'IDB')) {
      yield call(moveVideoToDirectory, action.data);

      yield put({
        type: REDUX_ACTIONS.MOVE_RECORDINGS_SUCCESS,
      });
      yield put(
        showAlert({
          type: 'Success',
          description: 'Video moved successfully',
        })
      );

      // Lets update the library on the redux store
      yield put({
        type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
      });
    }

    if (candidates.find((f) => f.provider !== 'IDB')) {
      const response = yield call(moveFile, recordingId, args);
      const file: FileType = response?.data?.data?.file;

      const personalLibrary: FileType[] = (yield select(getLibrary)).personal;
      const pluginLibrary: FileType[] = (yield select(getLibrary)).plugin;

      yield put({
        type: REDUX_ACTIONS.MOVE_RECORDINGS_SUCCESS,
        data: response.data,
      });
      yield put(
        showAlert({
          type: 'Success',
          description: 'Video moved successfully',
        })
      );

      yield put({
        type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
        data: {
          personalLibrary: action.data.teamId
            ? personalLibrary
            : [file, ...personalLibrary.filter((f) => f._id !== recordingId)],
          pluginLibrary: [
            file,
            ...pluginLibrary.filter((f) => f._id !== recordingId),
          ],
        },
      });

      const teamSpace = yield select(getCompanySharedSpace);
      yield* fetchCompanySharedContents({
        data: teamSpace.selectedTeamSpace?.teamId,
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.MOVE_RECORDINGS_ERROR,
      data: { message: e.message },
    });
    yield put(
      showAlert({
        type: 'Warning',
        description: 'Permission Denied',
      })
    );
  }
}

function* _deleteDirectory(action: IRemoveDirectoryActionType) {
  try {
    const { libraryTab } = yield select(getLibraryTab);
    const response = yield call(deleteFolder, action.data.folderId);
    const { fileSystem: fsRaw, deletedFolders } = response?.data?.data || {};
    const fileSystem = fsRaw?.map((f: Required<FileSystemEntityType>) => ({
      ...f,
      createdAt: new Date(f.createdAt),
      updatedAt: new Date(f.updatedAt),
    }));
    const {
      personalFolders,
      teamFolders,
      personalLibrary,
      pluginFolders,
      pluginLibrary,
    } = separateFileSystemEntities(fileSystem || []);

    yield call(
      deleteFolderArrayDirectContents,
      deletedFolders
        .filter((f: FolderType) => !isATeamFSEntity(f) && f.provider === 'S3')
        .map((f: FolderType) => f._id)
    );

    yield put({
      type: REDUX_ACTIONS.DELETE_FOLDER_SUCCESS,
      data: { personalFolders, pluginFolders, teamFolders, libraryTab },
    });

    yield put({
      type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
      data: { personalLibrary, pluginLibrary },
    });

    yield* getPublicSharedContents();
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });

    // Lets update the library on the redux store
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });

    yield put({ type: REDUX_ACTIONS.MARK_SYNCED_FILES });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.DELETE_FOLDER_ERROR,
      data: { message: e.message },
    });
  }
}

function* _renameDirectory(action: IRenameDirectoryActionType) {
  try {
    const { data } = action;

    yield call(renameFolder, data.folderId, data.name, data.teamId);
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });

    yield put({
      type: REDUX_ACTIONS.RENAME_FOLDER_SUCCESS,
    });
    // Lets update the folders on the redux store
    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_FOLDERS_REQUEST,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.RENAME_FOLDER_ERROR,
      data: {
        message: e.message,
        error: 'Space with same name already exists or something went wrong',
      },
    });
  }
}

function* _editPrompt(action: IEditPromptActionType) {
  try {
    const { data } = action;

    yield call(editPrompt, data.id, data.teamId, data.prompt);
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });

    yield put({
      type: REDUX_ACTIONS.EDIT_FOLDER_PROMPT_SUCCESS,
    });
    // Lets update the folders on the redux store
    yield put({
      type: REDUX_ACTIONS.FETCH_ALL_FOLDERS_REQUEST,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.EDIT_FOLDER_PROMPT_ERROR,
      data: {
        message: e.message,
        error: 'Something went wrong',
      },
    });
  }
}

function* getFileSystemSettings() {
  try {
    const response = yield call(getSettings);
    const fileSystemSettings = response?.data?.data;

    yield put({
      type: REDUX_ACTIONS.GET_FS_SETTINGS_SUCCESS,
      data: fileSystemSettings,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_FS_SETTINGS_ERROR,
      data: { message: error.message },
    });
  }
}

function* watchOnProgress(channel, _id) {
  while (true) {
    const ev = yield take(channel);
    switch (ev.type) {
      case 'PROGRESS':
        yield put({
          type: REDUX_ACTIONS.UPDATE_UPLOAD_FILE_PROGRESS,
          data: ev.data,
        });
        break;
      case 'SUCCESS':
        yield put({
          type: REDUX_ACTIONS.UPLOAD_FILE_SUCCESS,
          data: ev.data?.data?.data?.file,
        });
        yield call(fetchLibraryGenerator);

        // delete chucks after recording is uploaded
        yield put({
          type: REDUX_ACTIONS.RECOVER_DELETE_REQUEST,
          data: { recordingId: ev.data?.data?.data?.file._id },
        });
        break;
      case 'ERROR':
        yield put({
          type: REDUX_ACTIONS.UPLOAD_FILE_ERROR,
          data: { _id, message: ev.data?.message },
        });
        break;
      default:
        break;
    }
  }
}

function* _uploadFile(action) {
  try {
    const syncedFiles = yield select(getSyncedFiles);
    if (syncedFiles[action.data._id] === 'complete') {
      return;
    }

    const uploadedOrUploadingFiles = Object.keys(syncedFiles).filter((f) =>
      ['working', 'complete'].includes(syncedFiles[f])
    );
    const cloudFileCount = uploadedOrUploadingFiles.length;

    const { packageConfig = {} } = yield select(getAuth);
    if (cloudFileCount >= packageConfig.cloudFileCount) {
      throw new Error(
        'You have reached the maximum number of synced files. Please delete some files before uploading more.'
      );
    }

    yield put({
      type: REDUX_ACTIONS.UPLOAD_FILE_START,
      data: action.data,
    });

    const blob: Blob = yield call(getVideoFile, action.data._id);
    const thumbBlob = yield call(getThumbnail, blob, 500); // generate thumbnail
    const channel = yield call(
      uploadFile,
      action.data,
      blob,
      thumbBlob,
      action.libraryTab,
      action.teamId,
      action.parentId
    );
    yield fork(watchOnProgress, channel, action.data._id);
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.UPLOAD_FILE_ERROR,
      data: { _id: action.data._id, message: error.message },
    });
  } finally {
    yield put({
      // Limitation numbers maybe obsolete now
      type: REDUX_ACTIONS.GET_USAGE_REQUEST,
    });
  }
}

function* uploadOneFile(action) {
  yield put({
    type: REDUX_ACTIONS.ANALYTICS,
    data: {
      action: 'upload-one',
      data: {
        local: action.data.isLocal,
      },
    },
  });

  yield* _uploadFile(action);
}

function* uploadCheckedFiles() {
  try {
    const { local: recordings }: { local: FileType[] } = yield select(
      getCheckedFilesDedupedLocal
    );

    const syncedFiles = yield select(getSyncedFiles);
    const uploadCandidates = recordings.filter(
      (f) => syncedFiles[f._id] === undefined || syncedFiles[f._id] === 'failed'
    );

    const uploadedOrUploadingFiles = Object.keys(syncedFiles).filter((f) =>
      ['pending', 'working', 'complete'].includes(syncedFiles[f])
    );
    const cloudFileCount = uploadedOrUploadingFiles.length;

    const { packageConfig = {} } = yield select(getAuth);
    if (
      uploadCandidates.length >
      packageConfig.cloudFileCount - cloudFileCount
    ) {
      throw new Error(
        'You have reached the maximum number of synced files. Please delete some files before uploading more.'
      );
    }

    yield put({
      type: REDUX_ACTIONS.UNCHECK_ALL_FILES,
    });

    yield put({
      type: REDUX_ACTIONS.UPLOAD_CHECKED_FILES_PENDING,
      data: uploadCandidates
        .map((f) => f._id)
        .reduce((obj, id) => ({ ...obj, [id]: 'pending' }), {}),
    });

    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'upload-many',
      },
    });

    for (let i = 0; i < uploadCandidates.length; i += 1) {
      const data = uploadCandidates[i];
      yield* _uploadFile({ data });
    }
  } catch (error) {
    // Cannot upload
    log(error);
  }
}

function* shareFiles(action) {
  try {
    const response = yield call(shareItems, action.data);
    const id = response?.data?.data?.id;

    yield put({
      type: REDUX_ACTIONS.SHARE_FILES_SUCCESS,
      data: `${config.appBaseUrl}/#/shared/${id}`,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARE_FILES_ERROR,
      data: { message: error.message },
    });
  }
}

function* shareCompanySharedFiles(action) {
  try {
    const { entityIds, teamId, isDownloadEnabled = false } = action.data;
    const response = yield call(
      shareCompanySharedItems,
      entityIds,
      teamId,
      isDownloadEnabled
    );
    const id = response?.data?.data?.id;

    yield put({
      type: REDUX_ACTIONS.SHARE_FILES_SUCCESS,
      data: `${config.appBaseUrl}/#/shared/${id}`,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARE_FILES_ERROR,
      data: { message: error.message },
    });
  }
}

function* publishFile(action) {
  try {
    const response = yield call(sharePublicFile, action.data);

    yield put({
      type: REDUX_ACTIONS.SHARE_PUBLIC_FILES_SUCCESS,
      data: response.data?.data,
    });
    yield* getPublicSharedContents();
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARE_PUBLIC_FILES_ERROR,
      data: { message: error.message },
    });
  }
}

function* unpublishFile(action) {
  try {
    const response = yield call(unpublishedFile, action.data.entityId);

    yield put({
      type: REDUX_ACTIONS.UNPUBLISH_SUCCESS,
      data: response.data?.data,
    });
    yield* getPublicSharedContents();
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.UNPUBLISH_ERROR,
      data: { message: error.message },
    });
  }
}

function* _shareFile(action) {
  log(action);

  if (isATeamFSEntity(action.data)) {
    const body = {
      entityId: action.data.recordingId,
      ownerId: action.data.ownerId,
      isCompanyShared: true,
    };
    const teamSpace = yield select(getCompanySharedSpace);
    yield* shareCompanySharedFiles({
      data: {
        entityIds: [body],
        teamId: teamSpace.selectedTeamSpace?.teamId,
        isDownloadEnabled: action.data?.isDownloadEnabled || false,
      },
    });
  } else {
    yield* shareFiles({
      data: {
        entityIds: [action.data._id],
        isDownloadEnabled: action.data?.isDownloadEnabled || false,
        teamId: action.data.teamId,
        spaceId: action.data.spaceId,
      },
    });
  }

  yield put({
    type: REDUX_ACTIONS.ANALYTICS,
    data: {
      action: 'share',
      data: {
        isCompanyShared: isATeamFSEntity(action.data),
      },
    },
  });
}

function* shareCheckedFiles() {
  const { remote } = yield select(getCheckedFileIdsByLocation);
  yield* shareFiles({ data: { entityIds: remote } });
  yield put({
    type: REDUX_ACTIONS.UNCHECK_ALL_FILES,
  });
}

function* sharePluginVideo(action) {
  try {
    const response = yield call(sharePlugin, action.data);
    const id = response?.data?.data?.id;

    yield put({
      type: REDUX_ACTIONS.SHARE_FILES_SUCCESS,
      data: `${config.appBaseUrl}/#/shared/${id}`,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARE_FILES_ERROR,
      data: { message: error.message },
    });
  }
}

function* getShareLinkContents(action) {
  try {
    const id = action.data;

    const response = yield call(getSharedItems, id);
    const sharedContents = response?.data?.data;
    const sharedFile = {
      ...sharedContents.sharedFiles[0],
      createdAt: new Date(sharedContents.sharedFiles[0].createdAt),
      owner: sharedContents.owner,
      isDownloadEnabled: sharedContents.isDownloadEnabled,
    };

    yield put({
      type: REDUX_ACTIONS.GET_SHARE_LINK_CONTENTS_SUCCESS,
      data: sharedFile,
    });

    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'visit-share-link',
      },
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_SHARE_LINK_CONTENTS_FAIL,
      data: { content: null, message: error.message },
    });
  }
}

function* getPublicSharedFileData(action) {
  try {
    const { entityId } = action.data;
    const response = yield call(getPublicSharedFile, entityId);
    yield put({
      type: REDUX_ACTIONS.GET_PUBLIC_SHARE_CONTENT_SUCCESS,
      data: response?.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_PUBLIC_SHARE_CONTENT_FAIL,
      data: { message: error.message },
    });
  }
}

function* addViewToPubliclySharedVideo(action) {
  try {
    const { entityId } = action.data;
    const response = yield call(addView, entityId);
    yield put({
      type: REDUX_ACTIONS.INCREASE_VIEW_COUNT_SUCCESS,
      data: response?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.INCREASE_VIEW_COUNT_FAIL,
      data: { message: error.message },
    });
  }
}

// TODO Remove after ensuring all videos have duration
function* addDurationToVideo(action) {
  try {
    const response = yield call(addDuration, action.data);
    yield put({
      type: REDUX_ACTIONS.ADD_DURATION_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.ADD_DURATION_FAIL,
      data: { message: error.message },
    });
  }
}

function* createSummary(action) {
  try {
    const response = yield call(summarize, action.data);
    const file: FileType = response?.data?.data?.file;

    const library = yield select(getLibrary);
    const filterLibrary = (
      libraryType: 'team' | 'personal' | 'plugin',
      summarizedFile: FileType
    ) => {
      const filteredLibrary = library[libraryType];
      return [
        summarizedFile,
        ...filteredLibrary.filter((f) => f._id !== action.data.id),
      ];
    };

    if (isATeamFSEntity(file)) {
      yield put({
        type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES_SUCCESS,
        data: {
          fileSystem: filterLibrary('team', file),
        },
      });
    } else if (file.provider === 'S3:plugin') {
      yield put({
        type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
        data: {
          pluginLibrary: filterLibrary('plugin', file),
        },
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
        data: {
          personalLibrary: filterLibrary('personal', file),
        },
      });
    }

    yield put({
      type: REDUX_ACTIONS.SUMMARIZE_SUCCESS,
      data: response,
    });

    yield put(selectVideoFromLibrary(file));
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SUMMARIZE_FAIL,
      data: { message: error.message },
    });
  }
}

function* addChromeExtensionTag() {
  try {
    const response = yield call(chromeExtension);
    yield put({
      type: REDUX_ACTIONS.ADD_CHROME_EXTENSION_TAG_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.ADD_CHROME_EXTENSION_TAG_FAIL,
      data: { message: error.message },
    });
  }
}

function* transcodeGenerator(action) {
  try {
    const response = yield call(transcode, action.data);

    yield put({
      type: REDUX_ACTIONS.TRANSCODE_ACCEPT,
      data: response,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.TRANSCODE_REJECT,
      data: { message: error.message },
    });
  }
}

function* downloadCheckedFiles() {
  try {
    const filesToDownload = yield select(getCheckedFilesDedupedRemote);
    const downloadUrls = [
      ...filesToDownload.local,
      ...filesToDownload.remote,
    ].map((file) => file.url);

    for (let i = 0; i < downloadUrls.length; i += 1) {
      yield call(downloadFile, downloadUrls[i]);
    }

    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: { action: 'download-many', data: {} },
    });

    yield put({ type: REDUX_ACTIONS.UNCHECK_ALL_FILES });
  } catch (e) {
    log(e);
  }
}

function* updateFileSystemSettings(action) {
  try {
    // N.B.: If more system settings are added, check for cloud sync before
    // sending the analytics message
    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'cloud-sync-switch',
        data: {
          state: action.data.cloudSync,
        },
      },
    });
    const response = yield call(updateSettings, action.data);
    const fileSystemSettings = response?.data?.data || {};

    yield put(updateFsSettingsSuccess(fileSystemSettings));
  } catch (error) {
    yield put(updateFsSettingsSuccess(error));
  }
}

function setProminentParentId(files: FileTypeExtended[]) {
  let selectedParentId = '';
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    // Give priority to S3 files; files via recording requests, plugin etc. will not appear here (assumption).
    if (file.parentId && (!selectedParentId || file.provider === 'S3')) {
      selectedParentId = file.parentId;
    }
  }

  // Set that id to all files
  for (let i = 0; i < files.length; i += 1) {
    const file = files[i];
    file.parentId = selectedParentId;
  }
}

async function persistUnifiedFsParentMigration(files: FileTypeExtended[]) {
  return Promise.all(
    files.map((f) =>
      moveVideoToDirectory({
        recordingId: f._id,
        parentId: f.parentId || '',
      })
    )
  );
}

function* migrateOldFileSystem() {
  // delete old(more than 24hrs) recover videos
  deleteOldRecoverVideos();
  try {
    const auth = yield select(getAuth);

    const userId = auth?.user?.data?.id;
    if (userId) {
      // Migrate backend file system first.
      const response = yield call(fetchFileSystem);
      const fileSystem: FileSystemEntityType[] =
        response?.data?.data?.fileSystem;

      const localVideos: FileTypeExtended[] = yield call(getSavedVideoFiles);
      const cloudVideos = fileSystem.filter(
        (f): f is FileTypeExtended => f.type === 'File'
      );
      const multipleFilesWithSameId = Object.values(
        groupBy(
          [
            ...localVideos,
            ...cloudVideos.filter(
              (f) => isATeamFSEntity(f) && f.provider !== 'S3:plugin'
            ),
          ],
          (f) => f._id
        )
      ).filter((v) => v.length > 1);

      // Then, if any file exists in both IDB and S3 in different folders, move
      // them to the parent of the S3 file.
      multipleFilesWithSameId.forEach((fs) => {
        setProminentParentId(fs); // Fix in-place
      });

      if (config.permanentUnifiedFs) {
        // Update indexed db if needed
        const filesWithUpdatedParents = multipleFilesWithSameId.flat();
        yield call(
          persistUnifiedFsParentMigration,
          filesWithUpdatedParents.filter((f) => f.provider === 'IDB')
        );
      }

      const {
        personalFolders,
        pluginFolders,
        teamFolders,
      } = separateFileSystemEntities(fileSystem || []);
      yield put({
        type: REDUX_ACTIONS.MIGRATE_OLD_FILESYSTEM_SUCCESS,
        data: { personalFolders, pluginFolders, teamFolders },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.MIGRATE_OLD_FILESYSTEM_ERROR,
      data: { message: e.message },
    });
  }
}

function* moveCheckedFiles(action) {
  try {
    const { data: folderId } = action;
    const { local, remote } = yield select(getCheckedFileIdsByLocation);

    yield call(moveManyVideosToDirectory, local, folderId);
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });

    const response = yield call(moveManyFiles, remote, folderId);
    const { fileSystem } = response?.data?.data;
    const { personalLibrary, pluginLibrary } = separateFileSystemEntities(
      fileSystem || []
    );
    yield put({
      type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
      data: { personalLibrary, pluginLibrary },
    });

    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'move-many',
      },
    });

    yield put({
      type: REDUX_ACTIONS.MOVE_CHECKED_FILES_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.MOVE_CHECKED_FILES_FAIL,
      data: { message: e.message },
    });
  }
}

function* deleteCheckedFiles() {
  try {
    const { local, remote } = yield select(getCheckedFileIdsByLocation);

    yield call(deleteManyVideos, local);
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });

    const response = yield call(deleteManyFiles, remote);
    const { fileSystem } = response?.data?.data;
    const { personalLibrary, pluginLibrary } = separateFileSystemEntities(
      fileSystem || []
    );
    yield put({
      type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
      data: { personalLibrary, pluginLibrary },
    });

    yield* getPublicSharedContents();
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });
    yield put({
      type: REDUX_ACTIONS.DELETE_CHECKED_FILES_SUCCESS,
    });

    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'delete-many',
      },
    });

    yield put({ type: REDUX_ACTIONS.MARK_SYNCED_FILES });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.DELETE_CHECKED_FILES_FAIL,
      data: { message: e.message },
    });
  }
}

function* requestStorageLimitations() {
  const usedCloud = yield select(calculateUsedCloudSpace);
  const { usage, quota } = yield call(estimateStorageSpace);
  yield put({
    type: REDUX_ACTIONS.STORAGE_LIMITATIONS_RESPONSE,
    data: {
      IDB: { used: usage, total: quota },
      S3: { used: usedCloud, total: Infinity },
    },
  });
}

export function* addVideoToCompanySharedSpace(action) {
  try {
    const { id, teamId } = action.data;
    const response = yield call(addRecordingToCompanySharedSpace, id, teamId);
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });
    yield* _getFolders();
    yield put({
      type: REDUX_ACTIONS.ADD_VIDEO_TO_COMPANY_SHARED_SPACE_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.ADD_VIDEO_TO_COMPANY_SHARED_SPACE_FAIL,
      data: { message: error.message },
    });
  }
}

function* _deleteTeamSpaceDirectory(action: IRemoveDirectoryActionType) {
  try {
    yield call(deleteTeamSpaceFolder, action.data.folderId);

    yield put({
      type: REDUX_ACTIONS.DELETE_TEAMSPACE_FOLDER_SUCCESS,
    });
    const teamSpace = yield select(getCompanySharedSpace);
    yield* fetchCompanySharedContents({
      data: teamSpace.selectedTeamSpace?.teamId,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.DELETE_TEAMSPACE_FOLDER_ERROR,
      data: { message: e.message },
    });
  }
}

function* updateFileSystemGenerator() {
  const response = yield call(fetchFileSystem);
  const fileSystem = response?.data?.data?.fileSystem?.map(
    (f: Required<FileSystemEntityType>) => ({
      ...f,
      createdAt: new Date(f.createdAt),
      updatedAt: new Date(f.updatedAt),
    })
  );
  const { personalLibrary, pluginLibrary } = separateFileSystemEntities(
    fileSystem || []
  );

  yield put({
    type: REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS,
    data: { personalLibrary, pluginLibrary },
  });
}

function* _keepFreeRecordings() {
  try {
    const auth = yield select(getAuth);
    if (!auth?.user?.data?.id) {
      return;
    }

    const uploadCandidates = yield call(keepFreeRecordings, auth.user.data.id);
    // Upload recordings created before logging in
    for (let i = 0; i < uploadCandidates.length; i += 1) {
      const { recordingId, ...data } = uploadCandidates[i];
      yield* _uploadFile({ data: { ...data, _id: recordingId } });
    }

    // Lets update the library on the redux store
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });
  }
}

function* removeFreeRecordings() {
  try {
    yield call(deleteFreeRecordings);

    // Lets update the library on the redux store
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
    });
  }
}

function* importFiles(action) {
  try {
    const { files, localFiles, libraryTab, parentId } = action.payload;
    const blobArray: IFileBlob[] = [];
    // Remote files from URLs uploaded via backend
    if (files && files.length > 0) {
      for (let i = 0; i < files.length; i += 1) {
        const { file, id } = files[i];
        const response = yield call(getImportFileDetails, file);
        // TODO Change backend and verify the file type when enabling this feature again
        const blob = new Blob([response.data], { type: 'video/webm' });
        blobArray.push({ id, blob });
      }
    }
    if (localFiles && localFiles.length > 0) {
      for (let k = 0; k < localFiles.length; k += 1) {
        const localFile = yield call(fileToBlob, localFiles[k].file);
        blobArray.push({ id: localFiles[k].id, blob: localFile });
      }
    }
    // 10MB - for file import by url
    if (files && files.length > 0 && blobArray[0]?.blob.size < 10 * 2 ** 20) {
      yield put(
        importFileError({
          message: 'File size should be between 10MB and 5GB',
        })
      );
      return;
    }

    const teamSpace = yield select(getCompanySharedSpace);
    const auth = yield select(getAuth);
    const updatedAction = {
      ...action,
      data: {
        ...action.payload,
        folderId: parentId || null,
        teamId: teamSpace.selectedTeamSpace?.teamId || auth?.user?.data?.id,
        spaceId:
          // eslint-disable-next-line no-nested-ternary
          libraryTab === 'team'
            ? parentId
              ? '__default'
              : '__personal'
            : '__personal',
        recordingId: localFiles[0]?.id || files[0]?.id,
        title: (localFiles[0]?.file?.name || '').replace(/\.[^/.]+$/, ''), // remove .ext
        profile: guessProfileFromMime(localFiles[0]?.file?.type),
        uploadContextType: 'import',
      },
    };
    // Receive events from recorder and streams
    // for now only allow one file import at a time
    const channel = yield call(createUploader, updatedAction.data);
    sendBlobStream(blobArray[0]?.blob);
    tryFinishRecord({});

    log('Realtime uploader started');
    while (true) {
      // take(END) will cause the saga to terminate by jumping to the finally block
      const ev = yield take(channel);
      log(ev);

      switch (ev.type) {
        case 'CONNECTED':
          yield put(realtimeUploaderConnected());
          yield put({
            type: REDUX_ACTIONS.UPLOAD_PROGRESS_BAR_START,
          });
          break;
        case 'PROGRESS':
          yield put(realtimeUploaderProgress(ev.data));
          yield put({
            type: REDUX_ACTIONS.UPDATE_UPLOAD_FILE_PROGRESS,
            data: { _id: action.payload.recordingId, ...ev.data },
          });
          break;
        case 'SUCCESS':
          {
            log('realtime upload success', ev);

            yield put({
              type: REDUX_ACTIONS.REALTIME_UPLOADER_SUCCESS,
              data: { _id: action.payload.recordingId },
            });

            yield put({
              type: REDUX_ACTIONS.ANALYTICS,
              data: {
                action: 'import-file-upload-live-success',
                data: {
                  id: action.payload.recordingId,
                },
              },
            });
            yield put(importFileSuccess());
            yield put({
              type: REDUX_ACTIONS.UPLOAD_PROGRESS_BAR_STOP,
            });
            if (updatedAction.data.recordingId) {
              const selectedTeamSpace = yield select(getSelectedTeamSpace);
              if (selectedTeamSpace) {
                const body = {
                  entityId: updatedAction.data.recordingId,
                  ownerId: selectedTeamSpace.ownerId,
                  isCompanyShared: true,
                };
                yield* shareCompanySharedFiles({
                  data: {
                    entityIds: [body],
                    teamId: selectedTeamSpace?.teamId,
                  },
                });
              } else {
                yield* shareFiles({
                  data: {
                    entityIds: [updatedAction.data.recordingId],
                    teamId: updatedAction.data.teamId,
                    spaceId: updatedAction.data.spaceId,
                  },
                });
              }
            }
            if (updatedAction.data.teamId) {
              yield put({
                type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES,
                data: updatedAction.data.teamId,
              });
            }

            const { pluginMode } = yield select((state) => state.plugin);
            if (!pluginMode) {
              yield* updateFileSystemGenerator();
            }
          }
          break;
        case 'ERROR':
          yield put({
            type: REDUX_ACTIONS.ANALYTICS,
            data: {
              action: 'import-file-upload-live-error',
              data: {
                id: action.payload.recordingId,
              },
            },
          });
          throw new Error(ev.data);
        default:
          break;
      }
    }
  } catch (error) {
    console.error(error);
    yield put(importFileError({ message: error.message }));
  } finally {
    yield put(realtimeUploaderReset());
    yield put({
      // Limitation numbers maybe obsolete now
      type: REDUX_ACTIONS.GET_USAGE_REQUEST,
    });
  }
}

function* showLibrarySekeleton() {
  try {
    yield put({
      type: REDUX_ACTIONS.SHOW_LIBRARY_SKELETON,
    });
    yield delay(2000);
    yield put({
      type: REDUX_ACTIONS.HIDE_LIBRARY_SKELETON,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.HIDE_LIBRARY_SKELETON,
    });
  }
}

export function* storageSaga() {
  yield takeLatest(REDUX_ACTIONS.ADD_NEW_TAG_REQUEST, addNewTag);
  yield takeLatest(REDUX_ACTIONS.FETCH_TAG_REQUEST, fetchTags);
  yield takeLatest(REDUX_ACTIONS.REMOVE_FREE_RECORDINGS, removeFreeRecordings);
  yield takeLatest(REDUX_ACTIONS.KEEP_FREE_RECORDINGS, _keepFreeRecordings);
  yield takeLatest(REDUX_ACTIONS.CREATE_FOLDER_REQUEST, _createFolder);
  yield takeLatest(REDUX_ACTIONS.FETCH_ALL_FOLDERS_REQUEST, _getFolders);
  yield takeLatest(
    REDUX_ACTIONS.MOVE_RECORDINGS_REQUEST,
    _moveVideoToDirectory
  );
  yield takeLatest(REDUX_ACTIONS.DELETE_FOLDER_REQUEST, _deleteDirectory);
  yield takeLatest(REDUX_ACTIONS.RENAME_FOLDER_REQUEST, _renameDirectory);
  yield takeLatest(REDUX_ACTIONS.EDIT_FOLDER_PROMPT_REQUEST, _editPrompt);
  yield takeLatest(REDUX_ACTIONS.MIGRATE_OLD_FILESYSTEM, migrateOldFileSystem);
  yield takeLatest(
    REDUX_ACTIONS.GET_FS_SETTINGS_REQUEST,
    getFileSystemSettings
  );
  yield takeLatest(
    REDUX_ACTIONS.UPDATE_FS_SETTINGS_REQUEST,
    updateFileSystemSettings
  );
  yield takeEvery(REDUX_ACTIONS.TRANSCODE_REQUEST, transcodeGenerator);
  yield takeEvery(REDUX_ACTIONS.UPLOAD_FILE_REQUEST, uploadOneFile);
  yield takeEvery(REDUX_ACTIONS.SHARE_FILE_REQUEST, _shareFile);
  yield takeLatest(REDUX_ACTIONS.SHARE_PLUGIN_VIDEO, sharePluginVideo);
  yield takeEvery(REDUX_ACTIONS.SHARE_PUBLIC_FILE, publishFile);
  yield takeEvery(REDUX_ACTIONS.UNPUBLISH, unpublishFile);
  yield takeEvery(REDUX_ACTIONS.MOVE_CHECKED_FILES, moveCheckedFiles);
  yield takeEvery(REDUX_ACTIONS.DELETE_CHECKED_FILES, deleteCheckedFiles);
  yield takeEvery(REDUX_ACTIONS.UPLOAD_CHECKED_FILES, uploadCheckedFiles);
  yield takeEvery(
    REDUX_ACTIONS.GET_SHARE_PUBLIC_FILES,
    getPublicSharedContents
  );
  yield takeEvery(
    REDUX_ACTIONS.INCREASE_VIEW_COUNT,
    addViewToPubliclySharedVideo
  );
  yield takeEvery(REDUX_ACTIONS.ADD_DURATION, addDurationToVideo);
  yield takeEvery(REDUX_ACTIONS.SUMMARIZE, createSummary);
  yield takeEvery(
    REDUX_ACTIONS.ADD_CHROME_EXTENSION_TAG,
    addChromeExtensionTag
  );
  yield takeLatest(REDUX_ACTIONS.SHARE_CHECKED_FILES, shareCheckedFiles);
  yield takeLatest(REDUX_ACTIONS.DOWNLOAD_CHECKED_FILES, downloadCheckedFiles);
  yield takeLatest(REDUX_ACTIONS.GET_SHARE_LINK_CONTENTS, getShareLinkContents);
  yield takeLatest(
    REDUX_ACTIONS.GET_PUBLIC_SHARE_CONTENT,
    getPublicSharedFileData
  );
  yield takeLatest(REDUX_ACTIONS.MARK_SYNCED_FILES, markSyncedFiles);
  yield takeLatest(
    REDUX_ACTIONS.STORAGE_LIMITATIONS_REQUEST,
    requestStorageLimitations
  );
  yield takeEvery(
    REDUX_ACTIONS.GET_COMPANY_SHARED_FILES,
    fetchCompanySharedContents
  );
  yield takeLatest(
    REDUX_ACTIONS.ADD_VIDEO_TO_COMPANY_SHARED_SPACE,
    addVideoToCompanySharedSpace
  );
  yield takeLatest(
    REDUX_ACTIONS.DELETE_TEAMSPACE_FOLDER_REQUEST,
    _deleteTeamSpaceDirectory
  );
  yield takeLatest(REDUX_ACTIONS.UPDATE_SELECTED_FOLDER, showLibrarySekeleton);
  yield takeLatest(importFileRequest.type, importFiles);
}
