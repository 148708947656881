import { put, takeLatest, call } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  CustomerPortalSessionURL,
  GetUnsubscribeModal,
  ResetUnsubscribeModal,
  ChurnkeyCancelPlan,
} from '../../services/subscriptionServices';
import { getUserProfileRequest } from '../../features/auth/authSlice';

function* apiGetCustomerPortalSession(action) {
  try {
    const response = yield call(CustomerPortalSessionURL, action.data);
    // log(response);

    if (response.data.success && response.data.success === true) {
      yield put({
        type: REDUX_ACTIONS.API_GET_CUSTOMERPORTALURL_RESPONSE,
        data: response.data.data.url,
      });
    } else if (response.data.error) {
      throw Error(response.data.error);
    } else {
      throw Error('invalid response');
    }
  } catch (e) {
    // log(e);
    yield put({
      type: REDUX_ACTIONS.API_GET_CUSTOMERPORTALURL_RESPONSE,
      data: null,
    });
  }
}

function* postStripeSubscriptionChangeGenerator(action) {
  // Refetch user profile with stripe package update success
  if (action.data) {
    yield put(getUserProfileRequest());
  }
}
function* resetUnsubscribeModal() {
  try {
    const response = yield call(ResetUnsubscribeModal);

    if (response.data.success) {
      yield put({
        type: REDUX_ACTIONS.RESET_UNSUBSCRIBE_MODAL_SUCCESS,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.RESET_UNSUBSCRIBE_MODAL_FAILURE,
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.RESET_UNSUBSCRIBE_MODAL_FAILURE,
      data: e,
    });
  }
}

function* getUnsubscribeModal() {
  try {
    const response = yield call(GetUnsubscribeModal);

    if (response.data.success) {
      yield put({
        type: REDUX_ACTIONS.GET_UNSUBSCRIBE_MODAL_SUCCESS,
        data: response.data.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.GET_UNSUBSCRIBE_MODAL_FAIL,
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.GET_UNSUBSCRIBE_MODAL_FAIL,
      data: e,
    });
  }
}

function* churnkeyCancelPlan() {
  try {
    const response = yield call(ChurnkeyCancelPlan);

    if (response.data.success) {
      yield put({
        type: REDUX_ACTIONS.CHURNKEY_CANCEL_PLAN_RESPONSE,
        data: response.data.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.CHURNKEY_CANCEL_PLAN_FAIL,
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.CHURNKEY_CANCEL_PLAN_FAIL,
      data: e,
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* subscriptionSaga() {
  yield takeLatest(
    REDUX_ACTIONS.API_GET_CUSTOMERPORTALURL_REQUEST,
    apiGetCustomerPortalSession
  );
  yield takeLatest(
    REDUX_ACTIONS.RESET_STRIPE_PACKAGE_CHANGED,
    postStripeSubscriptionChangeGenerator
  );
  yield takeLatest(
    REDUX_ACTIONS.RESET_UNSUBSCRIBE_MODAL,
    resetUnsubscribeModal
  );
  yield takeLatest(
    REDUX_ACTIONS.GET_UNSUBSCRIBE_MODAL_REQUEST,
    getUnsubscribeModal
  );
  yield takeLatest(REDUX_ACTIONS.CHURNKEY_CANCEL_PLAN, churnkeyCancelPlan);
}
