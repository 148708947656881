import { HTTP_CODES } from '../constants/apiSagaConstant';

export default (url, options) =>
  new Promise((resolve, reject) => {
    if (options && options.params && Object.keys(options.params).length) {
      const keys = Object.keys(options.params);
      const searchParams = new URLSearchParams();

      // Build searchParams object using query key-value pairs
      for (let i = 0; i < keys.length; i += 1) {
        if (options.params[keys[i]]) {
          searchParams.append(keys[i], options.params[keys[i]]);
        }
      }

      // Extract query string
      /* eslint no-param-reassign : 0 */
      url = `${url}?${searchParams.toString()}`;
    }

    // options.credentials = 'include';
    const token = localStorage.getItem('token');
    if (token === null) {
      options.headers = new Headers({
        'Content-Type': 'application/json',
      });
    } else {
      options.headers = new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      });
    }

    if (typeof options.body === 'object') {
      options.body = JSON.stringify(options.body);
    }
    fetch(url, options)
      .then((res) => {
        // Wait for response data to resolve before resolving the fetch promise
        res
          .json()
          .then((data) => {
            // res.data = data;
            // if (res.status === HTTP_CODES.NOT_AUTHENTICATED) {
            //   window.setTimeout(() => {
            //     // Note: Redirecting automatically only if auth status is already set as 1:authenticated
            //     // if (authService.isAuthenticated()) {
            //     //     log.error("Session is invalid. Reloading the page");
            //     //
            //     //     authService.authenticate();
            //     // }
            //   }, TIMEOUTS.AUTH_REDIRECT);
            // }
            if (res.status !== HTTP_CODES.SUCCESS) {
              return reject(res);
            }
            return resolve({ ...res, data });
          })
          .catch((err) => {
            err = new Error(
              `Error while parsing fetched data from server: ${err}`
            );
            return reject(err);
          });
      })
      .catch(reject);
  });
