import { END, eventChannel } from 'redux-saga';

const blockAppUnload = (onUnblock: () => void, onTryExit: () => void) => {
  window.onbeforeunload = () => {
    onTryExit();
    return '';
  };

  return () => {
    window.onbeforeunload = null;
    onUnblock();
  };
};

const createAppUnloadEventChannel = async () => {
  // Wait until the event handler given to eventChannel is called
  let done: (value: any) => void;

  const promise = new Promise<[() => void, typeof ec]>((resolve) => {
    done = resolve;
  });

  const ec = eventChannel((emit) => {
    const unblock = blockAppUnload(
      () => {
        emit(END);
      },
      () => {
        emit({ type: 'TRY_UNLOAD' });
      }
    );
    // Wait until ec is created
    setTimeout(() => {
      return done([unblock, ec]);
    }, 0);
    return () => {};
  });

  return promise;
};

export default createAppUnloadEventChannel;
