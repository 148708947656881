import {
  REDUX_ACTIONS,
  REQUEST_RECORDING_STATUS,
} from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { requestRecordingInitSuccess } from '../features/auth/authSlice';

export default (
  state = STORE_INITIATE_VALUE.RECORDING_REQUEST,
  action: any
) => {
  switch (action.type) {
    case REDUX_ACTIONS.REQUEST_RECORDING_CREATE_SUCCESS:
      return { ...state, generatedId: action.data._id };

    case REDUX_ACTIONS.REQUEST_RECORDING_INIT:
      return { ...state, initError: '', navigatedId: action.data };
    case requestRecordingInitSuccess.type:
      return {
        ...state,
        initError: '',
        token: action.payload.data.token,
        recorderInfo: {
          ...action.payload.data,
          ...state.recorderInfo,
          email: action.payload?.recorderEmail || '',
          name: action.payload?.recorderName || '',
          teamId: action.payload.data.teamId || '',
        },
      };
    case REDUX_ACTIONS.REQUEST_RECORDING_INIT_FAILURE:
      return { ...state, initError: action.data || 'error', navigatedId: '' };

    case REDUX_ACTIONS.REQUEST_RECORDING_CREATE_CLOSE:
    case REDUX_ACTIONS.REQUEST_RECORDING_FINISH_SUCCESS:
    case REDUX_ACTIONS.REQUEST_RECORDING_FINISH_FAILURE: // Oops.
      return STORE_INITIATE_VALUE.RECORDING_REQUEST;

    case REDUX_ACTIONS.REQUEST_RECORDING_EMAIL_SEND_SUCCESS:
    case REDUX_ACTIONS.REQUEST_RECORDING_EMAIL_SEND_FAILURE:
      return { ...state, sendEmail: action.data };

    case REDUX_ACTIONS.REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_SUCCESS:
    case REDUX_ACTIONS.REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_FAILURE:
      return { ...state, sendMultipleEmail: action.data };

    case REDUX_ACTIONS.REQUEST_RECORDING_COPY_LINK_SUCCESS:
    case REDUX_ACTIONS.REQUEST_RECORDING_COPY_LINK_FAILURE:
      return { ...state, copyLink: action.data };

    case REDUX_ACTIONS.REQUEST_RECORDING_UPDATE_INFO:
      return {
        ...state,
        recorderInfo: { ...state.recorderInfo, ...action.data },
      };

    case REDUX_ACTIONS.REQUEST_RECORDING_RETAKE_SUCCESS:
      return {
        ...state,
        recorderInfo: {
          ...state.recorderInfo,
          status: REQUEST_RECORDING_STATUS.INIT,
        },
      };

    default:
      return state;
  }
};
