/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */

import { EventEmitter } from 'events';

export abstract class Uploader extends EventEmitter {
  abstract send(data: Blob): void;

  abstract end(): void;
}
