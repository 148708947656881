import React from 'react';
import clsx from 'clsx';
// eslint-disable-next-line import/named
import { Button, ButtonProps, Theme, Typography } from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';

type Color = 'primary' | 'secondary';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      '& .MuiButton-contained': {
        backgroundImage: theme.palette.background.default,
        color: theme.palette.primary.contrastText,
        borderWidth: 1,
        borderStyle: 'solid',
        borderImage: theme.palette.background.default,
        '&:hover': {
          backgroundImage: theme.palette.background.paper,
          borderImage: theme.palette.background.paper,
          boxShadow: 'unset',
        },
        '&:active': {
          boxShadow: 'unset',
        },
      },
      '& .MuiButton-outlined': {
        backgroundColor: theme.palette.common.white,
        borderWidth: 1,
        borderColor: theme.palette.common.black,
        color: theme.palette.text.primary,
        '&:hover': {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.primary.contrastText,
        },
      },
      '& .MuiSvgIcon-root': {
        height: theme.typography.body1.fontSize,
        width: theme.typography.body1.fontSize,
      },
      '& .Mui-disabled': {
        backgroundColor: theme.palette.secondary.light,
        color: theme.palette.text.disabled,
        borderColor: 'unset',
      },
    },
    margin: {
      margin: theme.spacing(1),
    },
    round: {
      borderRadius: 1000,
    },
    fullWidth: {
      width: '100%',
    },
    button: {
      fontVariantNumeric: 'lining-nums',
      textTransform: 'capitalize',
      fontSize: '14px',
      fontFamily: 'Inter',
      fontWeight: 500,
      padding: '7px 21px',
      lineHeight: '26px',
      letterSpacing: 1,
      height: 36,
      boxSizing: 'border-box',
      boxShadow: 'none',
      // [theme.breakpoints.down('md')]: {
      //   padding: '6px 12px',
      // },
      '& .MuiButton-startIcon': {
        marginRight: '0px',
      },
    },
    label: {
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: 0,
      fontFamily: 'Inter',
      // fontSize: '0.7rem',
      // [theme.breakpoints.down('md')]: {
      //   fontSize: '0.6rem',
      // },
    },
  });
};

export interface ButtonComponentProps
  extends WithStyles<ButtonProps & typeof styles> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  id: string;
  label?: string;
  fullWidth?: boolean;
  color?: Color;
  filled?: boolean;
  roundBorder?: boolean;
  icon?: boolean;
  disabled?: boolean;
  noMargin?: boolean;
  startIcon?: any;
  endIcon?: any;
  customClass?: any;
  href?: string;
  target?: HTMLAnchorElement['target'];
}

export const BasicButton: React.FC<ButtonComponentProps> = ({
  classes,
  onClick,
  id,
  label,
  fullWidth = false,
  filled = false,
  roundBorder = false,
  icon = false,
  disabled = false,
  noMargin = false,
  startIcon,
  endIcon,
  customClass,
  href,
  target = 'self',
  ...otherProps
}: ButtonComponentProps) => {
  const linkProps = href ? { href, target } : {};
  return (
    <span
      className={clsx(
        classes.root,
        'basic-button-wrapper',
        !noMargin && classes.margin,
        customClass,
        fullWidth && classes.fullWidth
      )}
    >
      <Button
        className={clsx(
          classes.button,
          roundBorder && classes.round,
          'basic-button-class'
        )}
        onClick={onClick}
        id={id}
        disabled={disabled}
        fullWidth={fullWidth}
        variant={filled ? 'contained' : 'outlined'}
        startIcon={startIcon}
        endIcon={endIcon}
        {...linkProps}
        {...otherProps}
      >
        {icon && <AddIcon />}
        {label && <Typography className={classes.label}>{label}</Typography>}
      </Button>
    </span>
  );
};

export default withStyles(styles)(BasicButton);
