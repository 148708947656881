import React, { useEffect, useState } from 'react';
import { ThemeProvider } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import theme from '../../theme';

interface CountdownProps {
  onCountdownEnd: Function;
}

const Countdown = (props: CountdownProps) => {
  const { onCountdownEnd } = props;
  const [count, setCount] = useState(3);

  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount === 0) {
          clearInterval(countdownInterval);
          onCountdownEnd();
          return prevCount;
        }
        return prevCount - 1;
      });
    }, 1000);
    return () => {
      clearInterval(countdownInterval);
    };
  }, []);

  return (
    <div>
      {count > 0 && (
        <Backdrop open style={{ zIndex: 1000, color: '#fff' }}>
          <div style={{ position: 'relative', textAlign: 'center' }}>
            <ThemeProvider theme={theme}>
              <CircularProgress color="primary" size={100} thickness={3} />
            </ThemeProvider>
            <Typography
              variant="h4"
              color="inherit"
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              {count}
            </Typography>
          </div>
        </Backdrop>
      )}
    </div>
  );
};

export default Countdown;
