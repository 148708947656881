// import awsExport from '../../aws_exports';
import { config } from '../../config';

export const REDUX_ACTIONS = {
  // saga action
  FETCHING: 'FETCHING',
  FETCH_LATEST: 'FETCH_LATEST',
  API_GATEWAY_FETCHING: 'API_GATEWAY_FETCHING',
  API_GATEWAY_FETCH_LATEST: 'API_GATEWAY_FETCH_LATEST',
  API_GATEWAY_FETCHING_SUCCESS: 'API_GATEWAY_FETCHING_SUCCESS',
  API_GATEWAY_FETCHING_FAILURE: 'API_GATEWAY_FETCHING_FAILURE',
  FETCHING_SUCCESS: 'FETCHING_SUCCESS',
  FETCHING_FAILURE: 'FETCHING_FAILURE',
  // saga action
  API_SAGA_FETCHING: 'API_SAGA_FETCHING',
  API_SAGA_FETCH_LATEST: 'API_SAGA_FETCH_LATEST',
  API_SAGA_FETCHING_SUCCESS: 'API_SAGA_FETCHING_SUCCESS',
  API_SAGA_FETCHING_FAILURE: 'API_SAGA_FETCHING_FAILURE',

  // record
  RECORDING: 'RECORDING',
  SET_MESSAGE: 'SET_MESSAGE',
  AVAILBLE_DOWNLOAD: 'AVAILBLE_DOWNLOAD',
  UPLOADING: 'UPLOADING',
  VIDEO_AVAILBLE: 'VIDEO_AVAILBLE',
  ANOTHER_RECORDING: 'ANOTHER_RECORDING',
  RECORDING_FINISH: 'RECORDING_FINISH',
  LINK_AVAILABLE: 'LINK_AVAILABLE',
  START_TIME: 'START_TIME',
  EXTENSION_INSTALLED: 'EXTENSION_INSTALLED',
  INITIATING_RECORDER: 'INITIATING_RECORDER',
  INITIATED_RECORDER: 'INITIATED_RECORDER',
  FIRST_TIME_VISIT: 'FIRST_TIME_VISIT',

  SET_CURRENT_RECORDINGS: 'SET_CURRENT_RECORDINGS',
  SET_CURRENT_RECORDINGS_FAILURE: 'SET_CURRENT_RECORDINGS_FAILURE',
  SET_CURRENT_RECORDINGS_SUCCESS: 'SET_CURRENT_RECORDINGS_SUCCESS',

  CREATE_NEW_MODAL_CLEAR_SUCCESS: 'CREATE_NEW_MODAL_CLEAR_SUCCESS',
  CHANGE_ADMIN_ALERT_MESSAGE: 'CHANGE_ADMIN_ALERT_MESSAGE',
  BEFORE_TRIM_VIDEO: 'BEFORE_TRIM_VIDEO',
  COMPLETE_TRIM_VIDEO: 'COMPLETE_TRIM_VIDEO',
  TRIM_RE_TRY: 'TRIM_RE_TRY',

  USER_VIDEO_TRIM_SUCCESS: 'USER_VIDEO_TRIM_SUCCESS',
  USER_VIDEO_TRIM_FAIL: 'USER_VIDEO_TRIM_FAIL',
  GO_BACK_FUNCTION: 'GO_BACK_FUNCTION',

  SET_RECORDING_TITLE_AND_DESCRIPTION: 'SET_RECORDING_TITLE_AND_DESCRIPTION',

  INCREASE_RECORDING_COUNT: 'INCREASE_RECORDING_COUNT',

  GET_RECORDING_COUNT: 'GET_RECORDING_COUNT',

  // user sign up
  USER_SIGN_UP_REQUEST: 'USER_SIGN_UP_REQUEST',

  // user sign in
  USER_SIGN_IN_REQUEST: 'USER_SIGN_IN_REQUEST',
  USER_SIGN_IN_RESPONSE: 'USER_SIGN_IN_RESPONSE',

  // resend email verification
  USER_RESEND_EMAIL_VERIFICATION_REQUEST:
    'USER_RESEND_EMAIL_VERIFICATION_REQUEST',

  // Refresh token
  USER_REFRESH_TOKEN_REQUEST: 'USER_REFRESH_TOKEN_REQUEST',

  // post profile
  POST_USER_PROFILE_REQUEST: 'POST_USER_PROFILE_REQUEST',
  POST_USER_PROFILE_RESPONSE: 'POST_USER_PROFILE_RESPONSE',

  UPDATE_USAGE_REQUEST: 'UPDATE_USAGE_REQUEST',
  UPDATE_USAGE_FAILURE: 'UPDATE_USAGE_FAILURE',

  GET_USAGE_REQUEST: 'GET_USAGE_REQUEST',
  GET_USAGE_FAILURE: 'GET_USAGE_FAILURE',

  OPEN_BANNER: 'OPEN_BANNER',
  CLOSE_BANNER: 'CLOSE_BANNER',

  // forget password
  FORGET_PASSWORD_REQUEST: 'FORGET_PASSWORD_REQUEST',
  FORGET_PASSWORD_RESPONSE: 'FORGET_PASSWORD_RESPONSE',

  // forget password
  RESET_PASSWORD_REQUEST: 'FRESET_PASSWORD_REQUEST',
  RESET_PASSWORD_RESPONSE: 'RESET_PASSWORD_RESPONSE',

  // reset userAdimission
  RESET_USER_ADMISSION: 'RESET_USER_ADMISSION',

  // update last active date
  UPDATE_LAST_ACTIVE_DATE: 'UPDATE_LAST_ACTIVE_DATE',
  UPDATE_LAST_ACTIVE_DATE_SUCCESS: 'UPDATE_LAST_ACTIVE_DATE_SUCCESS',

  // get checkout session
  API_GET_CHECKOUTSESSION_REQUEST: 'API_GET_CHECKOUTSESSION_REQUEST',
  API_GET_CHECKOUTSESSION_RESPONSE: 'API_GET_CHECKOUTSESSION_RESPONSE',

  // get customer portal session
  API_GET_CUSTOMERPORTALURL_REQUEST: 'API_GET_CUSTOMERPORTALURL_REQUEST',
  API_GET_CUSTOMERPORTALURL_RESPONSE: 'API_GET_CUSTOMERPORTALURL_RESPONSE',
  CLEAR_CUSTOMERPORTALURL: 'CLEAR_CUSTOMERPORTALURL',

  RESET_STRIPE_PACKAGE_CHANGED: 'RESET_STRIPE_PACKAGE_CHANGED',

  // Deactivate Plugin
  DEACTIVATE_PLUGIN_REQUEST: 'DEACTIVATE_PLUGIN_REQUEST',
  DEACTIVATE_PLUGIN_REQUEST_SUCCESS: 'DEACTIVATE_PLUGIN_REQUEST_SUCCESS',
  DEACTIVATE_PLUGIN_REQUEST_FAILURE: 'DEACTIVATE_PLUGIN_REQUEST_FAILURE',

  GET_UNSUBSCRIBE_MODAL_REQUEST: 'GET_UNSUBSCRIBE_MODAL_REQUEST',
  GET_UNSUBSCRIBE_MODAL_SUCCESS: 'GET_UNSUBSCRIBE_MODAL_SUCCESS',
  GET_UNSUBSCRIBE_MODAL_FAIL: 'GET_UNSUBSCRIBE_MODAL_FAIL',

  // reset unsubscribeModal to null
  RESET_UNSUBSCRIBE_MODAL: 'RESET_UNSUBSCRIBE_MODAL',
  RESET_UNSUBSCRIBE_MODAL_SUCCESS: 'RESET_UNSUBSCRIBE_MODAL_SUCCESS',
  RESET_UNSUBSCRIBE_MODAL_FAILURE: 'RESET_UNSUBSCRIBE_MODAL_FAILURE',

  // To check the browser compatibility
  CHECK_BROWSER_COMPATIBILITY: 'CHECK_BROWSER_COMPATIBILITY',
  CHECK_BROWSER_COMPATIBILITY_SUCCESS: 'CHECK_BROWSER_COMPATIBILITY_SUCCESS',
  CHECK_BROWSER_COMPATIBILITY_FAILURE: 'CHECK_BROWSER_COMPATIBILITY_FAILURE',
  // Media Stream
  SET_MEDIA_STREAM: 'SET_MEDIA_STREAM',

  // Modal toggle
  TOGGLE_MODAL: 'TOGGLE_MODAL',

  // Media Stream Actions
  GET_STARTED_BUTTON_CLICK: 'GET_STARTED_BUTTON_CLICK', // Get Started Button Action

  AUTO_RECORDING: 'AUTO_RECORDING',
  START_AUTO_RECORDING: 'START_AUTO_RECORDING',
  START_RECORDING_SUCCESS: 'START_RECORDING_SUCCESS',
  START_RECORDING_FAILURE: 'START_RECORDING_FAILURE',

  MACOS_PERMISSION_DENIED: 'MACOS_PERMISSION_DENIED',

  TRACK_RECORDING: 'TRACK_RECORDING',
  TRACK_RECORDING_SUCCESS: 'TRACK_RECORDING_SUCCESS',
  TRACK_RECORDING_FAIL: 'TRACK_RECORDING_FAIL',

  ANALYTICS: 'ANALYTICS',
  ANALYTICS_SUCCESS: 'ANALYTICS_SUCCESS',
  ANALYTICS_FAIL: 'ANALYTICS_FAIL',

  TRACK_PAGE_VIEW: 'TRACK_PAGE_VIEW',

  TRY_RECORDING_AGAIN: 'TRY_RECORDING_AGAIN',

  STOP_SCREEN_SHARING: 'STOP_SCREEN_SHARING',

  ENABLE_TRIM_BUTTON: 'ENABLE_TRIM_BUTTON',

  // Post Record ACtions
  DOWNLOAD_BUTTON_CLICKED: 'DOWNLOAD_BUTTON_CLICKED',
  DOWNLOAD_RECORDING_SUCCESS: 'DOWNLOAD_RECORDING_SUCCESS',
  DOWNLOAD_RECORDING_FAILURE: 'DOWNLOAD_RECORDING_FAILURE',

  TRIM_BUTTON_CLICK: 'TRIM_BUTTON_CLICK',
  SUPPORT_BUTTON_CLICK: 'SUPPORT_BUTTON_CLICK',

  // Will be called if the media stream stoped.
  // Still could not figure out a way to implement this with saga
  MEDIA_STREAM_STOPPED: 'MEDIA_STREAM_STOPPED',

  GOOGLE_AUTHENTICATION_REQUEST: 'GOOGLE_AUTHENTICATION_REQUEST',

  REALTIME_UPLOADER_SUCCESS: 'REALTIME_UPLOADER_SUCCESS',

  TRIM_VIDEO: 'TRIM_VIDEO',
  TRIM_VIDEO_FAIL: 'TRIM_VIDEO_REQUEST_FAIL',
  TRIM_VIDEO_SUCCESS: 'TRIM_VIDEO_REQUEST_SUCCESS',

  TRIM_TO_AVAILABLE_VIDEO: 'TRIM_TO_AVAILABLE_VIDEO',

  SET_AUDIO_MUTE_FAILURE: 'SET_AUDIO_MUTE_FAILURE',

  TOGGLE_RECORDING_FAILURE: 'TOGGLE_RECORDING_FAILURE',

  COPY_RECORD_VIDEO_URL: 'COPY_RECORD_VIDEO_URL',
  SET_FEEDBACK: 'SET_FEEDBACK',
  SET_FEEDBACK_MODAL_OPEN: 'SET_FEEDBACK_MODAL_OPEN',

  SEND_FEEDBACK: 'SEND_FEEDBACK',
  SEND_FEEDBACK_SUCCESS: 'SEND_FEEDBACK_SUCCESS',
  SEND_FEEDBACK_FAIL: 'SEND_FEEDBACK_FAIL',

  // Library
  FETCH_PAST_RECORDINGS: 'FETCH_PAST_RECORDINGS',
  PAST_RECORDINGS_FETCH_SUCCESS: 'PAST_RECORDINGS_FETCH_SUCCESS',
  PAST_RECORDINGS_FETCH_FAILURE: 'PAST_RECORDINGS_FETCH_FAILURE',

  SHOW_LIBRARY_SKELETON: 'SHOW_LIBRARY_SKELETON',
  HIDE_LIBRARY_SKELETON: 'HIDE_LIBRARY_SKELETON',

  CLOUD_RECORDINGS_FETCH_SUCCESS: 'CLOUD_RECORDINGS_FETCH_SUCCESS',

  SEARCH_VIDEOS: 'SEARCH_VIDEOS',

  OPEN_MENU_APPBAR: 'OPEN_MENU_APPBAR',
  DELETE_VIDEO: 'DELETE_VIDEO',
  DELETE_VIDEO_SUCCESSFUL: 'DELETE_VIDEO_SUCCESSFUL',
  DELETE_VIDEO_FAILURE: 'DELETE_VIDEO_FAILURE',

  UPDATE_RECORDING_REQUEST: 'UPDATE_RECORDING_REQUEST',
  UPDATE_RECORDING_SUCCESS: 'UPDATE_RECORDING_SUCCESS',
  UPDATE_RECORDING_ERROR: 'UPDATE_RECORDING_ERROR',

  ADD_VIDEO_TO_COMPANY_SHARED_SPACE: 'ADD_VIDEO_TO_COMPANY_SHARED_SPACE',
  ADD_VIDEO_TO_COMPANY_SHARED_SPACE_SUCCESS:
    'ADD_VIDEO_TO_COMPANY_SHARED_SPACE_SUCCESS',
  ADD_VIDEO_TO_COMPANY_SHARED_SPACE_FAIL:
    'ADD_VIDEO_TO_COMPANY_SHARED_SPACE_FAIL',

  // Only the first time users see pricing page at start
  CLEAR_FIRST_TIME_USER_FLAG: 'CLEAR_FIRST_TIME_USER_FLAG',

  ACKNOWLEDGE_NEW_FEATURES_REQUEST: 'ACKNOWLEDGE_NEW_FEATURES_REQUEST',
  ACKNOWLEDGE_NEW_FEATURES_ERROR: 'ACKNOWLEDGE_NEW_FEATURES_ERROR',

  // add new Tag
  ADD_NEW_TAG_REQUEST: 'ADD_NEW_TAG_REQUEST',
  ADD_NEW_TAG_SUCCESS: 'ADD_NEW_TAG_SUCCESS',
  ADD_NEW_TAG_ERROR: 'ADD_NEW_TAG_ERROR',

  // fetch all Tags
  FETCH_TAG_REQUEST: 'FETCH_TAG_REQUEST',
  FETCH_TAG_SUCCESS: 'FETCH_TAG_SUCCESS',
  FETCH_TAG_ERROR: 'FETCH_TAG_ERROR',

  // assign Tag to recording
  ASSIGN_TAG_REQUEST: 'ASSIGN_TAG_REQUEST',
  ASSIGN_TAG_SUCCESS: 'ASSIGN_TAG_SUCCESS',
  ASSIGN_TAG_ERROR: 'ASSIGN_TAG_ERROR',

  // remove Tag from recording
  UNASSIGN_TAG_REQUEST: 'UNASSIGN_TAG_REQUEST',
  UNASSIGN_TAG_SUCCESS: 'UNASSIGN_TAG_SUCCESS',
  UNASSIGN_TAG_ERROR: 'UNASSIGN_TAG_ERROR',

  // delete Tag
  DELETE_TAG_REQUEST: 'DELETE_TAG_REQUEST',
  DELETE_TAG_SUCCESS: 'DELETE_TAG_SUCCESS',
  DELETE_TAG_ERROR: 'DELETE_TAG_ERROR',

  UPDATE_SELECTED_TAG: 'UPDATE_SELECTED_TAG',

  SIGN_OUT: 'SIGN_OUT',

  UPDATE_FREE_RECORDING_COUNT: 'UPDATE_FREE_RECORDING_COUNT',

  REMOVE_FREE_RECORDINGS: 'REMOVE_FREE_RECORDINGS',

  KEEP_FREE_RECORDINGS: 'KEEP_FREE_RECORDINGS',

  // create folder
  CREATE_FOLDER_REQUEST: 'CREATE_FOLDER_REQUEST',
  CREATE_FOLDER_SUCCESS: 'CREATE_FOLDER_SUCCESS',
  CREATE_FOLDER_ERROR: 'CREATE_FOLDER_ERROR',
  CREATE_RENAME_SPACE_CLOSE_NOTIFICATION:
    'CREATE_RENAME_SPACE_CLOSE_NOTIFICATION',

  // fetch all folders
  FETCH_ALL_FOLDERS_REQUEST: 'FETCH_ALL_FOLDERS_REQUEST',
  FETCH_ALL_FOLDERS_SUCCESS: 'FETCH_ALL_FOLDERS_SUCCESS',
  FETCH_ALL_FOLDERS_ERROR: 'FETCH_ALL_FOLDERS_ERROR',

  UPDATE_SELECTED_FOLDER: 'UPDATE_SELECTED_FOLDER',

  // move recordings
  MOVE_RECORDINGS_REQUEST: 'MOVE_RECORDINGS_REQUEST',
  MOVE_RECORDINGS_SUCCESS: 'MOVE_RECORDINGS_SUCCESS',
  MOVE_RECORDINGS_ERROR: 'MOVE_RECORDINGS_ERROR',
  MOVE_RECORDING_CLOSE_NOTIFICATION: 'MOVE_RECORDING_CLOSE_NOTIFICATION',

  // Upload file
  UPLOAD_FILE_REQUEST: 'UPLOAD_FILE_REQUEST',
  UPLOAD_FILE_START: 'UPLOAD_FILE_START',
  UPLOAD_FILE_SUCCESS: 'UPLOAD_FILE_SUCCESS',
  UPLOAD_FILE_ERROR: 'UPLOAD_FILE_ERROR',

  UPDATE_UPLOAD_FILE_PROGRESS: 'UPDATE_UPLOAD_FILE_PROGRESS',

  TRANSCODE_REQUEST: 'TRANSCODE_REQUEST',
  TRANSCODE_ACCEPT: 'TRANSCODE_ACCEPT',
  TRANSCODE_REJECT: 'TRANSCODE_REJECT',

  // Share file
  SHARE_FILE_REQUEST: 'SHARE_FILE_REQUEST', // Single file
  SHARE_FILES_SUCCESS: 'SHARE_FILES_SUCCESS', // One share link for one or many files
  SHARE_FILES_ERROR: 'SHARE_FILES_ERROR', // Share link generation failed for one or many files
  SHARE_LINK_CLEAR: 'SHARE_LINK_CLEAR', // Upon closing link share dialog

  // delete folders
  DELETE_FOLDER_REQUEST: 'DELETE_FOLDER_REQUEST',
  DELETE_FOLDER_SUCCESS: 'DELETE_FOLDER_SUCCESS',
  DELETE_FOLDER_ERROR: 'DELETE_FOLDER_ERROR',

  // Pause all videos except currently playing
  PAUSE_OTHER_LIBRARY_VIDEOS: 'PAUSE_OTHER_LIBRARY_VIDEOS',

  MOVE_FREE_RECORDINGS: 'MOVE_FREE_RECORDINGS',

  // Email Request/Response
  API_POST_PASSWORD_RESET_REQUEST: 'API_POST_PASSWORD_RESET_REQUEST',
  API_POST_PASSWORD_RESET_RESPONSE: 'API_POST_PASSWORD_RESET_RESPONSE',

  API_POST_ACCOUNT_CONFIRMATION_REQUEST:
    'API_POST_ACCOUNT_CONFIRMATION_REQUEST',
  API_POST_ACCOUNT_CONFIRMATION_RESPONSE:
    'API_POST_ACCOUNT_CONFIRMATION_RESPONSE',

  DELETE_ACCOUNT_REQUEST: 'DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_FAIL: 'DELETE_ACCOUNT_FAIL',

  CLEAR_LOADING_STATE_EMAIL_ROUTE: 'CLEAR_LOADING_STATE_EMAIL_ROUTE',

  API_POST_ACCEPT_SHARED_SPACE_REQUEST: 'API_POST_ACCEPT_SHARED_SPACE_REQUEST',
  API_POST_ACCEPT_SHARED_SPACE_RESPONSE_SUCCESS:
    'API_POST_ACCEPT_SHARED_SPACE_RESPONSE_SUCCESS',
  API_POST_ACCEPT_SHARED_SPACE_RESPONSE_FAIL:
    'API_POST_ACCEPT_SHARED_SPACE_RESPONSE_FAIL',

  API_POST_ACCEPT_COMPANY_SHARED_SPACE_REQUEST:
    'API_POST_ACCEPT_COMPANY_SHARED_SPACE_REQUEST',
  API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_SUCCESS:
    'API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_SUCCESS',
  API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_FAIL:
    'API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_FAIL',

  // rename folders
  RENAME_FOLDER_REQUEST: 'RENAME_FOLDER_REQUEST',
  RENAME_FOLDER_SUCCESS: 'RENAME_FOLDER_SUCCESS',
  RENAME_FOLDER_ERROR: 'RENAME_FOLDER_ERROR',

  EDIT_FOLDER_PROMPT_REQUEST: 'EDIT_FOLDER_PROMPT_REQUEST',
  EDIT_FOLDER_PROMPT_SUCCESS: 'EDIT_FOLDER_PROMPT_SUCCESS',
  EDIT_FOLDER_PROMPT_ERROR: 'EDIT_FOLDER_PROMPT_ERROR',
  EDIT_PROMPT_SPACE_CLOSE_NOTIFICATION: 'EDIT_PROMPT_SPACE_CLOSE_NOTIFICATION',

  UPDATE_EXPANDED_FOLDERS: 'UPDATE_EXPANDED_FOLDERS',

  MIGRATE_OLD_FILESYSTEM: 'MIGRATE_OLD_FILESYSTEM',
  MIGRATE_OLD_FILESYSTEM_ERROR: 'MIGRATE_OLD_FILESYSTEM_ERROR',
  MIGRATE_OLD_FILESYSTEM_SUCCESS: 'MIGRATE_OLD_FILESYSTEM_SUCCESS',

  CHANGE_LIBRARY_TAB: 'CHANGE_LIBRARY_TAB',

  STORAGE_LIMITATIONS_REQUEST: 'STORAGE_LIMITATIONS_REQUEST',
  STORAGE_LIMITATIONS_RESPONSE: 'STORAGE_LIMITATIONS_RESPONSE',

  GET_FS_SETTINGS_REQUEST: 'GET_FS_SETTINGS_REQUEST',
  GET_FS_SETTINGS_SUCCESS: 'GET_FS_SETTINGS_SUCCESS',
  GET_FS_SETTINGS_ERROR: 'GET_FS_SETTINGS_ERROR',

  UPDATE_FS_SETTINGS_REQUEST: 'UPDATE_FS_SETTINGS_REQUEST',

  TOGGLE_CHECK_FILE: 'TOGGLE_CHECK_FILE',
  UNCHECK_ALL_FILES: 'UNCHECK_ALL_FILES',
  CHECK_ALL_FILES: 'CHECK_ALL_FILES',

  MOVE_CHECKED_FILES: 'MOVE_CHECKED_FILES',
  MOVE_CHECKED_FILES_SUCCESS: 'MOVE_CHECKED_FILES_SUCCESS',
  MOVE_CHECKED_FILES_FAIL: 'MOVE_CHECKED_FILES_FAIL',

  DELETE_CHECKED_FILES: 'DELETE_CHECKED_FILES',
  DELETE_CHECKED_FILES_SUCCESS: 'DELETE_CHECKED_FILES_SUCCESS',
  DELETE_CHECKED_FILES_FAIL: 'DELETE_CHECKED_FILES_FAIL',

  UPLOAD_CHECKED_FILES_PENDING: 'UPLOAD_CHECKED_FILES_PENDING',
  UPLOAD_CHECKED_FILES: 'UPLOAD_CHECKED_FILES',
  UPLOAD_CHECKED_FILES_SUCCESS: 'UPLOAD_CHECKED_FILES_SUCCESS',
  UPLOAD_CHECKED_FILES_FAIL: 'UPLOAD_CHECKED_FILES_FAIL',

  SHARE_CHECKED_FILES: 'SHARE_CHECKED_FILES',
  SHARE_PLUGIN_VIDEO: 'SHARE_PLUGIN_VIDEO',

  // Share Public File
  SHARE_PUBLIC_FILE: 'SHARE_PUBLIC_FILE',
  SHARE_PUBLIC_FILES_SUCCESS: 'SHARE_PUBLIC_FILES_SUCCESS',
  SHARE_PUBLIC_FILES_ERROR: 'SHARE_PUBLIC_FILES_ERROR',

  UNPUBLISH: 'UNPUBLISH',
  UNPUBLISH_SUCCESS: 'UNPUBLISH_SUCCESS',
  UNPUBLISH_ERROR: 'UNPUBLISH_ERROR',

  GET_SHARE_PUBLIC_FILES: 'GET_SHARE_PUBLIC_FILES',
  GET_SHARE_PUBLIC_FILES_SUCCESS: 'GET_SHARE_PUBLIC_FILES_SUCCESS',
  GET_SHARE_PUBLIC_FILES_FAIL: 'GET_SHARE_PUBLIC_FILES_FAIL',

  GET_COMPANY_SHARED_FILES: 'GET_COMPANY_SHARED_FILES',
  GET_COMPANY_SHARED_FILES_SUCCESS: 'GET_COMPANY_SHARED_FILES_SUCCESS',
  GET_COMPANY_SHARED_FILES_FAIL: 'GET_COMPANY_SHARED_FILES_FAIL',

  FETCH_TEAMSPACE_FOLDERS_SUCCESS: 'FETCH_TEAMSPACE_FOLDERS_SUCCESS',
  FETCH_TEAMSPACE_FOLDERS_ERROR: 'FETCH_TEAMSPACE_FOLDERS_ERROR',

  DELETE_TEAMSPACE_FOLDER_REQUEST: 'DELETE_TEAMSPACE_FOLDER_REQUEST',
  DELETE_TEAMSPACE_FOLDER_SUCCESS: 'DELETE_TEAMSPACE_FOLDER_SUCCESS',
  DELETE_TEAMSPACE_FOLDER_ERROR: 'DELETE_TEAMSPACE_FOLDER_ERROR',

  CHANGE_CATEGORY_REQUEST: 'CHANGE_CATEGORY_REQUEST',
  CHANGE_CATEGORY_SUCCESS: 'CHANGE_CATEGORY_SUCCESS',
  CHANGE_CATEGORY_FAIL: 'CHANGE_CATEGORY_FAIL',

  GET_SHARE_LINK_CONTENTS: 'GET_SHARE_LINK_CONTENTS',
  GET_SHARE_LINK_CONTENTS_SUCCESS: 'GET_SHARE_LINK_CONTENTS_SUCCESS',
  GET_SHARE_LINK_CONTENTS_FAIL: 'GET_SHARE_LINK_CONTENTS_FAIL',

  GET_PUBLIC_SHARE_CONTENT: 'GET_PUBLIC_SHARE_CONTENT',
  GET_PUBLIC_SHARE_CONTENT_SUCCESS: 'GET_PUBLIC_SHARE_CONTENT_SUCCESS',
  GET_PUBLIC_SHARE_CONTENT_FAIL: 'GET_PUBLIC_SHARE_CONTENT_FAIL',

  INCREASE_VIEW_COUNT: 'INCREASE_VIEW_COUNT',
  INCREASE_VIEW_COUNT_SUCCESS: 'INCREASE_VIEW_COUNT_SUCCESS',
  INCREASE_VIEW_COUNT_FAIL: 'INCREASE_VIEW_COUNT_FAIL',

  ADD_DURATION: 'ADD_DURATION',
  ADD_DURATION_SUCCESS: 'ADD_DURATION_SUCCESS',
  ADD_DURATION_FAIL: 'ADD_DURATION_FAIL',

  SUMMARIZE: 'SUMMARIZE',
  SUMMARIZE_SUCCESS: 'SUMMARIZE_SUCCESS',
  SUMMARIZE_FAIL: 'SUMMARIZE_FAIL',

  ADD_CHROME_EXTENSION_TAG: 'ADD_CHROME_EXTENSION_TAG',
  ADD_CHROME_EXTENSION_TAG_SUCCESS: 'ADD_CHROME_EXTENSION_TAG_SUCCESS',
  ADD_CHROME_EXTENSION_TAG_FAIL: 'ADD_CHROME_EXTENSION_TAG_FAIL',

  DOWNLOAD_CHECKED_FILES: 'DOWNLOAD_CHECKED_FILES',

  MARK_SYNCED_FILES: 'MARK_SYNCED_FILES',
  MARK_SYNCED_FILES_SUCCESS: 'MARK_SYNCED_FILES_SUCCESS',

  SETUP_PLUGIN: 'SETUP_PLUGIN',
  // SUCCESS status is sent with getPluginDetailsSuccess

  GET_PLUGIN_DETAILS: 'GET_PLUGIN_DETAILS',

  // File recovery related actions
  RECOVER_FILES_LIST_REQUEST: 'RECOVER_FILES_LIST_REQUEST',
  RECOVER_FILES_LIST_RESPONSE: 'RECOVER_FILES_LIST_RESPONSE',
  RECOVER_FILE_REQUEST: 'RECOVER_FILE_REQUEST',
  RECOVER_FILE_RESPONSE: 'RECOVER_FILE_RESPONSE',
  RECOVER_CLEAR_FAILED_FLAG: 'RECOVER_CLEAR_FAILED_FLAG',
  RECOVER_DELETE_REQUEST: 'RECOVER_DELETE_REQUEST',
  RECOVER_DELETE_RESPONSE: 'RECOVER_DELETE_RESPONSE',

  // Request Recording
  REQUEST_RECORDING_CREATE: 'REQUEST_RECORDING_CREATE',
  REQUEST_RECORDING_CREATE_SUCCESS: 'REQUEST_RECORDING_CREATE_SUCCESS',
  REQUEST_RECORDING_CREATE_FAILURE: 'REQUEST_RECORDING_CREATE_FAILURE',
  REQUEST_RECORDING_CREATE_CLOSE: 'REQUEST_RECORDING_CREATE_CLOSE',
  REQUEST_RECORDING_INIT: 'REQUEST_RECORDING_INIT',
  REQUEST_RECORDING_INIT_FAILURE: 'REQUEST_RECORDING_INIT_FAILIUE',
  REQUEST_RECORDING_RETAKE: 'REQUEST_RECORDING_RETAKE',
  REQUEST_RECORDING_RETAKE_SUCCESS: 'REQUEST_RECORDING_RETAKE_SUCCESS',
  REQUEST_RECORDING_RETAKE_FAILURE: 'REQUEST_RECORDING_RETAKE_FAILURE',
  REQUEST_RECORDING_FINISH: 'REQUEST_RECORDING_FINISH',
  REQUEST_RECORDING_FINISH_SUCCESS: 'REQUEST_RECORDING_FINISH_SUCCESS',
  REQUEST_RECORDING_FINISH_FAILURE: 'REQUEST_RECORDING_FINISH_FAILURE',
  REQUEST_RECORDING_EMAIL_SEND: 'REQUEST_RECORDING_EMAIL_SEND',
  REQUEST_RECORDING_EMAIL_SEND_SUCCESS: 'REQUEST_RECORDING_EMAIL_SEND_SUCCESS',
  REQUEST_RECORDING_EMAIL_SEND_FAILURE: 'REQUEST_RECORDING_EMAIL_SEND_FAILURE',
  REQUEST_RECORDING_MULTIPLE_EMAIL_SEND:
    'REQUEST_RECORDING_MULTIPLE_EMAIL_SEND',
  REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_SUCCESS:
    'REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_SUCCESS',
  REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_FAILURE:
    'REQUEST_RECORDING_MULTIPLE_EMAIL_SEND_FAILURE',
  REQUEST_RECORDING_UPDATE_INFO: 'REQUEST_RECORDING_UPDATE_INFO',
  REQUEST_RECORDING_COPY_LINK: 'REQUEST_RECORDING_COPY_LINK',
  REQUEST_RECORDING_COPY_LINK_SUCCESS: 'REQUEST_RECORDING_COPY_LINK_SUCCESS',
  REQUEST_RECORDING_COPY_LINK_FAILURE: 'REQUEST_RECORDING_COPY_LINK_FAILURE',
  REQUEST_RECORDING_COPY_LINK_CLOSE: 'REQUEST_RECORDING_COPY_LINK_CLOSE',

  FETCH_ALL_REQUEST_RECORDINGS: 'FETCH_ALL_REQUEST_RECORDINGS',
  FETCH_ALL_REQUEST_RECORDINGS_SUCCESS: 'FETCH_ALL_REQUEST_RECORDINGS_SUCCESS',
  FETCH_ALL_REQUEST_RECORDINGS_FAILURE: 'FETCH_ALL_REQUEST_RECORDINGS_FAILURE',

  CHANGE_REQUEST_RECORDING_STATUS: 'CHANGE_REQUEST_RECORDING_STATUS',
  CHANGE_REQUEST_RECORDING_STATUS_SUCCESS:
    'CHANGE_REQUEST_RECORDING_STATUS_SUCCESS',
  CHANGE_REQUEST_RECORDING_STATUS_FAIL: 'CHANGE_REQUEST_RECORDING_STATUS_FAIL',

  DELETE_REQUEST_RECORDING: 'DELETE_REQUEST_RECORDING',
  DELETE_REQUEST_RECORDING_SUCCESS: 'DELETE_REQUEST_RECORDING_SUCCESS',
  DELETE_REQUEST_RECORDING_FAIL: 'DELETE_REQUEST_RECORDING_FAIL',

  FILTER_REQUEST_RECORDINGS: 'FILTER_REQUEST_RECORDINGS',

  SETTING_STATUS: 'SETTING_STATUS',
  LEFT_PANNEL_STATUS: 'LEFTPANNEL_STATUS',
  LEAVE_PAGE: 'LEAVE_PAGE',
  STAY_PAGE: 'STAY_PAGE',
  LEAVE_SETTING_PAGE: 'LEAVE_SETTING_PAGE',
  GOTO_CLICK_PAGE: 'GOTO_CLICK_PAGE',

  GET_SETTINGS: 'GET_SETTINGS',

  UPDATE_MICROPHONE_DEVICE: 'UPDATE_MICROPHONE_DEVICE',

  UPDATE_WEBCAM_DEVICE: 'UPDATE_WEBCAM_DEVICE',

  UPDATE_SETTINGS_FAIL: 'UPDATE_SETTINGS_FAIL',

  UPDATE_RECORD_SETTINGS: 'UPDATE_RECORD_SETTINGS',

  // shared space
  CHECK_REGISTERED_USER: 'CHECK_REGISTERED_USER',
  CHECK_REGISTERED_USER_SUCCESS: 'CHECK_REGISTERED_USER_SUCCESS',
  CHECK_REGISTERED_USER_FAIL: 'CHECK_REGISTERED_USER_FAIL',
  ADD_TO_SHARED_SPACE: 'ADD_TO_SHARED_SPACE',
  ADD_TO_SHARED_SPACE_SUCCESS: 'ADD_TO_SHARED_SPACE_SUCCESS',
  ADD_TO_SHARED_SPACE_FAIL: 'ADD_TO_SHARED_SPACE_FAIL',
  GET_SHARED_USER: 'GET_SHARED_USER',
  GET_SHARED_USER_SUCCESS: 'GET_SHARED_USER_SUCCESS',
  GET_SHARED_USER_FAIL: 'GET_SHARED_USER_FAIL',
  SHARED_SPACE_ADD_EMAIL_SEND: 'SHARED_SPACE_ADD_EMAIL_SEND',
  SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS: 'SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS',
  SHARED_SPACE_ADD_EMAIL_SEND_FAIL: 'SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS',
  CLOSE_INVITATION_NOTIFICATION: 'CLOSE_INVITATION_NOTIFICATION',
  CHANGE_PLUGIN_STATUS: 'CHANGE_PLUGIN_STATUS',
  CHANGE_PLUGIN_STATUS_SUCCESS: 'CHANGE_PLUGIN_STATUS_SUCCESS',
  CHANGE_PLUGIN_STATUS_FAIL: 'CHANGE_PLUGIN_STATUS_FAIL',
  DELETE_SHARED_SPACE_MEMBER: 'DELETE_SHARED_SPACE_MEMBER',
  DELETE_SHARED_SPACE_MEMBER_SUCCESS: 'DELETE_SHARED_SPACE_MEMBER_SUCCESS',
  DELETE_SHARED_SPACE_MEMBER_FAIL: 'DELETE_SHARED_SPACE_MEMBER_FAIL',

  SEND_SURVEY_SUBSCRIBE_FEEBACK: 'SEND_SURVEY_SUBSCRIBE_FEEBACK',
  SEND_SURVEY_SUBSCRIBE_FEEBACK_SUCCESS:
    'SEND_SURVEY_SUBSCRIBE_FEEBACK_SUCCESS',
  SEND_SURVEY_SUBSCRIBE_FEEBACK_FAIL: 'SEND_SURVEY_SUBSCRIBE_FEEBACK_FAIL',

  SEND_SURVEY_UNSUBSCRIBE_REASON: 'SEND_SURVEY_UNSUBSCRIBE_REASON',
  SEND_SURVEY_UNSUBSCRIBE_REASON_SUCCESS:
    'SEND_SURVEY_UNSUBSCRIBE_REASON_SUCCESS',
  SEND_SURVEY_UNSUBSCRIBE_REASON_FAIL: 'SEND_SURVEY_UNSUBSCRIBE_REASON_FAIL',

  // feedback for paid users
  GET_FEEDBACK_DATA: 'GET_FEEDBACK_DATA',
  GET_FEEDBACK_DATA_SUCCESS: 'GET_FEEDBACK_DATA_SUCCESS',
  GET_FEEDBACK_DATA_FAIL: 'GET_FEEDBACK_DATA_FAIL',

  UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE:
    'UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE',
  UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_SUCCESS:
    'UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_SUCCESS',
  UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_FAIL:
    'UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_FAIL',

  UPDATE_FEEDBACK_LAST_SHOWED_DATE: 'UPDATE_FEEDBACK_LAST_SHOWED_DATE',
  UPDATE_FEEDBACK_LAST_SHOWED_DATE_SUCCESS:
    'UPDATE_FEEDBACK_LAST_SHOWED_DATE_SUCCESS',
  UPDATE_FEEDBACK_LAST_SHOWED_DATE_FAIL:
    'UPDATE_FEEDBACK_LAST_SHOWED_DATE_FAIL',

  // Company shared space
  ADD_TO_COMPANY_SHARED_SPACE: 'ADD_TO_COMPANY_SHARED_SPACE',
  ADD_TO_COMPANY_SHARED_SPACE_SUCCESS: 'ADD_TO_COMPANY_SHARED_SPACE_SUCCESS',
  ADD_TO_COMPANY_SHARED_SPACE_FAIL: 'ADD_TO_COMPANY_SHARED_SPACE_FAIL',

  // add space members
  ADD_TO_SPACE_REQUEST: 'ADD_TO_SPACE_REQUEST',
  ADD_TO_SPACE_SUCCESS: 'ADD_TO_SPACE_SUCCESS',
  ADD_TO_SPACE_FAIL: 'ADD_TO_SPACE_FAIL',

  // remove space members
  REMOVE_FROM_SPACE_REQUEST: 'REMOVE_FROM_SPACE_REQUEST',
  REMOVE_FROM_SPACE_SUCCESS: 'REMOVE_FROM_SPACE_SUCCESS',
  REMOVE_FROM_SPACE_FAIL: 'REMOVE_FROM_SPACE_FAIL',

  // rename team space
  RENAME_TEAM_SPACE_REQUEST: 'RENAME_TEAM_SPACE_REQUEST',
  RENAME_TEAM_SPACE_SUCCESS: 'RENAME_TEAM_SPACE_SUCCESS',
  RENAME_TEAM_SPACE_FAIL: 'RENAME_TEAM_SPACE_FAIL',
  RENAME_TEAM_SPACE_CLOSE_NOTIFICATION: 'RENAME_TEAM_SPACE_CLOSE_NOTIFICATION',

  CHANGE_SPACE_USER_STATUS: 'CHANGE_SPACE_USER_STATUS',
  CHANGE_SPACE_USER_STATUS_SUCCESS: 'CHANGE_SPACE_USER_STATUS_SUCCESS',
  CHANGE_SPACE_USER_STATUS_FAIL: 'CHANGE_SPACE_USER_STATUS_FAIL',

  GET_COMPANY_SHARED_USER: 'GET_COMPANY_SHARED_USER',
  GET_COMPANY_SHARED_USER_SUCCESS: 'GET_COMPANY_SHARED_USER_SUCCESS',
  GET_COMPANY_SHARED_USER_FAIL: 'GET_COMPANY_SHARED_USER_FAIL',

  COMPANY_SHARED_SPACE_ADD_EMAIL_SEND: 'COMPANY_SHARED_SPACE_ADD_EMAIL_SEND',
  COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS:
    'COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS',
  COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_FAIL:
    'COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS',

  FETCH_ALL_TEAM_SPACES: 'FETCH_ALL_TEAM_SPACES',
  FETCH_ALL_TEAM_SPACES_SUCCESS: 'FETCH_ALL_TEAM_SPACES_SUCCESS',
  FETCH_ALL_TEAM_SPACES_ERROR: 'FETCH_ALL_TEAM_SPACES_ERROR',

  SAVE_LAST_SELECTED_TEAM_SPACE: 'SAVE_LAST_SELECTED_TEAM_SPACE',
  SAVE_LAST_SELECTED_TEAM_SPACE_SUCCESS:
    'SAVE_LAST_SELECTED_TEAM_SPACE_SUCCESS',
  SAVE_LAST_SELECTED_TEAM_SPACE_ERROR: 'SAVE_LAST_SELECTED_TEAM_SPACE_ERROR',

  FETCH_LAST_SELECTED_TEAM_SPACE_SUCCESS:
    'FETCH_LAST_SELECTED_TEAM_SPACE_SUCCESS',

  FETCH_TEAM_SPACE_INFO: 'FETCH_TEAM_SPACE_INFO',
  FETCH_TEAM_SPACE_INFO_ERROR: 'FETCH_TEAM_SPACE_INFO_ERROR',

  GET_TEAM_SPACES_DELETION_STATUS: 'GET_TEAM_SPACES_DELETION_STATUS',
  GET_TEAM_SPACES_DELETION_STATUS_SUCCESS:
    'GET_TEAM_SPACES_DELETION_STATUS_SUCCESS',
  GET_TEAM_SPACES_DELETION_STATUS_ERROR:
    'GET_TEAM_SPACES_DELETION_STATUS_ERROR',

  CREATE_TEAM_SPACE: 'CREATE_TEAM_SPACE',
  CREATE_TEAM_SPACE_SUCCESS: 'CREATE_TEAM_SPACE_SUCCESS',
  CREATE_TEAM_SPACE_ERROR: 'CREATE_TEAM_SPACE_ERROR',
  CREATE_TEAM_SPACE_CLOSE_NOTIFICATION: 'CREATE_TEAM_SPACE_CLOSE_NOTIFICATION',

  SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND:
    'SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND',
  SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS:
    'SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS',
  SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL:
    'SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL',

  COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND:
    'COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND',
  COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS:
    'COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS',
  COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL:
    'COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL',

  ADD_TO_TEAM_EMAIL_SEND: 'ADD_TO_TEAM_EMAIL_SEND',

  CLOSE_SHARED_SPACE_INVITATION_NOTIFICATION:
    'CLOSE_SHARED_SPACE_INVITATION_NOTIFICATION',

  CHANGE_COMPANY_SHARED_SPACE_USER_STATUS:
    'CHANGE_COMPANY_SHARED_SPACE_USER_STATUS',
  CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_SUCCESS:
    'CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_SUCCESS',
  CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_FAIL:
    'CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_FAIL',

  DELETE_COMPANY_SHARED_SPACE_USER: 'DELETE_COMPANY_SHARED_SPACE_USER',
  DELETE_COMPANY_SHARED_SPACE_USER_SUCCESS:
    'DELETE_COMPANY_SHARED_SPACE_USER_SUCCESS',
  DELETE_COMPANY_SHARED_SPACE_USER_FAIL:
    'DELETE_COMPANY_SHARED_SPACE_USER_FAIL',

  CHANGE_COMPANY_SHARED_SPACE_USER_ROLE:
    'CHANGE_COMPANY_SHARED_SPACE_USER_ROLE',
  CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_SUCCESS:
    'CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_SUCCESS',
  CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_FAIL:
    'CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_FAIL',

  START_TRANSCRIPTION: 'START_TRANSCRIPTION',
  FILTER_TEAM_MATES: 'FILTER_TEAM_MATES',
  CLOSE_REMOVE_TEAM_VIDEO_ALERT: 'CLOSE_REMOVE_TEAM_VIDEO_ALERT',

  FETCH_NOTICE_BANNER: 'FETCH_NOTICE_BANNER',
  FETCH_NOTICE_BANNER_SUCCESS: 'FETCH_NOTICE_BANNER_SUCCESS',
  FETCH_NOTICE_BANNER_FAIL: 'FETCH_NOTICE_BANNER_FAIL',

  FETCH_AI_SUGGESTIONS: 'FETCH_AI_SUGGESTIONS',
  FETCH_AI_SUGGESTIONS_SUCCESS: 'FETCH_AI_SUGGESTIONS_SUCCESS',
  FETCH_AI_SUGGESTIONS_FAIL: 'FETCH_AI_SUGGESTIONS_FAIL',
  ASK_AI: 'ASK_AI',
  ASK_AI_SUCCESS: 'ASK_AI_SUCCESS',
  ASK_AI_FAIL: 'ASK_AI_FAIL',

  // uploading progress bar
  UPLOAD_PROGRESS_BAR_START: 'UPLOAD_PROGRESS_BAR_START',
  UPLOAD_PROGRESS_BAR_STOP: 'UPLOAD_PROGRESS_BAR_STOP',

  // Churnkey
  CHURNKEY_CANCEL_PLAN: 'CHURNKEY_CANCEL_PLAN',
  CHURNKEY_CANCEL_PLAN_RESPONSE: 'CHURNKEY_CANCEL_PLAN_RESPONSE',
  CHURNKEY_CANCEL_PLAN_FAIL: 'CHURNKEY_CANCEL_PLAN_FAIL',
  CHURNKEY_CLEAR_DATA: 'CHURNKEY_CLEAR_DATA',
};

export const ACTION_KEY = {
  TRIM_VIDEO: 'TRIM_VIDEO',
  SEND_FEEDBACK: 'SEND_FEEDBACK',
  TRACK_RECORDING: 'TRACK_RECORDING',
};
export const ENDPOINTS = {
  CONSULT_API: 'ConsultantApi',
};
export const API_ROUTE = {
  TRIM: '/user/video/trim',
};
export const ACTION_ATTR = {
  PAYLOAD: 'payload',
  DATA: 'data',
};
export const URLS = {
  TRIM: `${config.trimmer}/trim`,
  FEEDBACK: `${config.authBaseUrl}/feedback`,
  TRACK: `${config.authBaseUrl}/recording`,
};
export const HTTP_METHODS = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
};
export const HTTP_CODES = {
  SUCCESS: 200,
  INTERNAL_ERROR: 500,
  BAD_REQUEST: 400,
  NOT_AUTHENTICATED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
};
export const TIMEOUTS = {
  AUTH_REDIRECT: 1000,
  LOCAL_PROXY_SEND_RETRY: 1000,
  SECOND_IN_MILLIS: 1000,
};

export const CLOCK_STATUS = {
  RUNNING: 'RUNNING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPED',
};

export const RECORDER_STATUS = {
  UNSUPPORTED: 'UNSUPPORTED', // Application does not support the browser
  NOT_READY: 'NOT_READY', // Just loaded the page, have not requested media yet
  READY: 'READY', // User has gained media access, but not started
  RUNNING: 'RECORDING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
};

export const RECORDING_BUTTON_STATUS = {
  RUNNING: 'RECORDING',
  PAUSED: 'PAUSED',
  STOPPED: 'STOPPED',
};

export const PLUGIN_RECORDING_STATUS = {
  INIT: 'INIT',
  NOT_READY: 'NOT_READY',
} as const;
export const REQUEST_RECORDING_STATUS = {
  INIT: 'INIT',
  NOT_READY: 'NOT_READY',
};

export const ANALYTICS_PAGE_VIEW_ACTIONS = {
  SIGN_IN_PAGE_VIEW: 'SIGN_IN_PAGE_VIEW',
  SIGN_UP_PAGE_VIEW: 'SIGN_UP_PAGE_VIEW',
  DASHBOARD_PAGE_VIEW: 'DASHBOARD_PAGE_VIEW',
  CHECKOUT_PAGE_VIEW: 'CHECKOUT_PAGE_VIEW',
  EDITOR_PAGE_VIEW: 'EDITOR_PAGE_VIEW',
};
