import { USER_ROLES } from '../constants/companySharedConstants';
import { FileSystemEntityType } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const allowedToTeamSpace = (teamSpaceInfo: any, userId: string) => {
  if (teamSpaceInfo && teamSpaceInfo.teamId) {
    if (userId === teamSpaceInfo.ownerId) {
      return true;
    }
    const teamMember = teamSpaceInfo.teamSpaceMembers?.filter(
      (t) => t._id === userId
    );
    if (
      teamMember &&
      teamMember.length > 0 &&
      (teamMember[0].role === USER_ROLES.EDITOR ||
        teamMember[0].role === USER_ROLES.ADMIN)
    )
      return true;
  }

  return false;
};

export const allowedToCreateRenameFolderInTeamSpace = (
  teamSpaceInfo: any,
  userId: string
) => {
  if (teamSpaceInfo && teamSpaceInfo.teamId) {
    if (userId === teamSpaceInfo.ownerId) {
      return true;
    }
    const teamMember = teamSpaceInfo.teamSpaceMembers?.filter(
      (t) => t._id === userId
    );
    if (
      teamMember &&
      teamMember.length > 0 &&
      (teamMember[0].role === USER_ROLES.EDITOR ||
        teamMember[0].role === USER_ROLES.ADMIN)
    )
      return true;
  }

  return false;
};

export const allowedToRemoveFolderInTeamSpace = (
  teamSpaceInfo: any,
  userId: string
) => {
  if (teamSpaceInfo && teamSpaceInfo.teamId) {
    if (userId === teamSpaceInfo.ownerId) {
      return true;
    }
    const teamMember = teamSpaceInfo.teamSpaceMembers?.filter(
      (t) => t._id === userId
    );
    if (
      teamMember &&
      teamMember.length > 0 &&
      teamMember[0].role === USER_ROLES.ADMIN
    )
      return true;
  }

  return false;
};

export const isATeamFSEntity = <T extends FileSystemEntityType>(fse: T) => {
  return fse.teamId && fse.spaceId !== '__personal';
};
