// constant
import { RECORDING_BUTTON_STATUS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import {
  startRecordingButtonClick,
  stopRecordingButtonClick,
} from '../features/recorder/recorderSlice';
import {
  getRecordingCountSuccess,
  increaseRecordingCountSuccess,
} from '../features/auth/authSlice';

export default (
  state = STORE_INITIATE_VALUE.RECORDING_BUTTON_INITIATE,
  action
) => {
  switch (action.type) {
    case startRecordingButtonClick.type:
      return {
        ...state,
        status: RECORDING_BUTTON_STATUS.RUNNING,
      };
    case stopRecordingButtonClick.type:
      return {
        ...state,
        status: RECORDING_BUTTON_STATUS.STOPPED,
      };
    case increaseRecordingCountSuccess.type:
      return { ...state, recordingCount: action.payload.data };
    case getRecordingCountSuccess.type:
      return { ...state, recordingCount: action.payload.data };
    default:
      return state;
  }
};
