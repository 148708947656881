import { bindActionCreators } from 'redux';

import { actionCreateStoreUpdateFactory } from './actionCreator';
import {
  ACTION_ATTR as ATTRS,
  REDUX_ACTIONS as ACTION_TYPES,
} from '../constants/apiSagaConstant';
import { FileType } from '../types';

export type TranscodeActionType = (data: {
  id: string;
  profile: string;
}) => void;

export const getTranscodeAction = (dispatch): TranscodeActionType =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.TRANSCODE_REQUEST, ATTRS.DATA),
    dispatch
  );

export const getCloseMoveRecordingNotification = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.MOVE_RECORDING_CLOSE_NOTIFICATION,
      ATTRS.DATA
    ),
    dispatch
  );

export const getCloseRemoveTeamVideoAlert = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.CLOSE_REMOVE_TEAM_VIDEO_ALERT,
      ATTRS.DATA
    ),
    dispatch
  );

export const getStopScreenSharingAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.STOP_SCREEN_SHARING,
      ATTRS.DATA
    ),
    dispatch
  );

type UpdateProps = Pick<
  FileType,
  'teamId' | 'spaceId' | 'ownerId' | 'recordingId' | 'name' | 'description'
>;
export type UpdateActionType = (data: Partial<UpdateProps>) => void;

export const getUpdateRecordingAction = (dispatch): UpdateActionType =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPDATE_RECORDING_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );
export const getDeleteVideoAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.DELETE_VIDEO, ATTRS.DATA),
    dispatch
  );

export const getMoveRecordingtoDirectoryAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.MOVE_RECORDINGS_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getPauseOtherVideosAcion = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.PAUSE_OTHER_LIBRARY_VIDEOS,
      ATTRS.DATA
    ),
    dispatch
  );

export const getGetSharedPublicFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.GET_SHARE_PUBLIC_FILES,
      ATTRS.DATA
    ),
    dispatch
  );

export const getClearShareLinkAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.SHARE_LINK_CLEAR, ATTRS.DATA),
    dispatch
  );

export const getGetShareLinkContents = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.GET_SHARE_LINK_CONTENTS,
      ATTRS.DATA
    ),
    dispatch
  );

export const getTrimFileAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.TRIM_VIDEO, ATTRS.DATA),
    dispatch
  );
export const getSummarizeAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.SUMMARIZE, ATTRS.DATA),
    dispatch
  );

export const fetchTreeViewAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.FETCH_ALL_FOLDERS_REQUEST,
      ATTRS.PAYLOAD
    ),
    dispatch
  );

export const closeMoveRecordingNotificationAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.MOVE_RECORDING_CLOSE_NOTIFICATION,
      ATTRS.DATA
    ),
    dispatch
  );

export const increaseRecordingCountAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.INCREASE_RECORDING_COUNT,
      ATTRS.DATA
    ),
    dispatch
  );
