import { put, takeLatest, takeLeading, call } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { setToken } from '../../lib/localStorageHandler';
import { setHeader } from '../../lib/baseAxios';
import {
  resetPasswordURL,
  accountConfirmationURL,
} from '../../services/emailRouteServices';
import { acceptCompanySharedSpaceInvitationLink } from '../../services/companySharedSpaceService';
import { getAggregatedDataRequest } from '../../features/app/appSlice';

function* resetPassword(action) {
  try {
    const response = yield call(resetPasswordURL, action.data);

    if (response.status === 200) {
      yield put({
        type: REDUX_ACTIONS.API_POST_PASSWORD_RESET_RESPONSE,
        data: response.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.API_POST_PASSWORD_RESET_RESPONSE,
        data: response.data || {
          success: false,
          data: null,
          message: 'Reset response not sent',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.API_POST_PASSWORD_RESET_RESPONSE,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Something went wrong.',
      },
    });
  }
}

const setDelay = async (time: number) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

function* accountConfirmation(action) {
  try {
    const response = yield call(accountConfirmationURL, action.data);

    if (response.status === 200) {
      yield call(setToken, response.data.data.accessToken);
      yield call(setHeader, response.data.data.accessToken);
      yield put({
        type: REDUX_ACTIONS.API_POST_ACCOUNT_CONFIRMATION_RESPONSE,
        data: response.data,
      });
      yield put(getAggregatedDataRequest());
      yield call(setDelay, 5000); // to stop calling accountConfirmation multiple times at loading
    } else {
      yield put({
        type: REDUX_ACTIONS.API_POST_ACCOUNT_CONFIRMATION_RESPONSE,
        data: response.data || {
          success: false,
          data: null,
          message: 'Account confirmation response failed.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.API_POST_ACCOUNT_CONFIRMATION_RESPONSE,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Account confirmation response not sent.',
      },
    });
  }
}

function* acceptSharedSpaceInvitation() {
  try {
    yield put({
      type: REDUX_ACTIONS.API_POST_ACCEPT_SHARED_SPACE_RESPONSE_SUCCESS,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.API_POST_ACCEPT_SHARED_SPACE_RESPONSE_FAIL,
      data: {
        success: false,
        data: null,
        message: 'Something went wrong.',
      },
    });
  }
}

function* acceptCompanySharedSpaceInvitation(action) {
  try {
    const queryParams = action.data;
    const response = yield call(
      acceptCompanySharedSpaceInvitationLink,
      queryParams.fromUserId,
      queryParams.toUserId,
      queryParams.teamId
    );
    yield put({
      type: REDUX_ACTIONS.API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_SUCCESS,
      response,
    });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_FAIL,
      data: {
        success: false,
        data: null,
        message: 'Something went wrong.',
      },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* emailRouteSaga() {
  yield takeLatest(
    REDUX_ACTIONS.API_POST_PASSWORD_RESET_REQUEST,
    resetPassword
  );
  yield takeLeading(
    REDUX_ACTIONS.API_POST_ACCOUNT_CONFIRMATION_REQUEST,
    accountConfirmation
  );
  yield takeLatest(
    REDUX_ACTIONS.API_POST_ACCEPT_SHARED_SPACE_REQUEST,
    acceptSharedSpaceInvitation
  );
  yield takeLatest(
    REDUX_ACTIONS.API_POST_ACCEPT_COMPANY_SHARED_SPACE_REQUEST,
    acceptCompanySharedSpaceInvitation
  );
}
