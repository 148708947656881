/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import { PLUGIN_RECORDING_STATUS } from '../../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../../constants/initialstore';
import { clearRecordingState } from '../recorder/recorderSlice';
import { getPluginDetailsSuccess } from '../auth/authSlice';

const initialState = {
  plugin: { ...STORE_INITIATE_VALUE.PLUGIN },
  pluginRecording: { ...STORE_INITIATE_VALUE.PLUGIN_RECORDING },
};

export interface IAuthenticatePluginData {
  key?: string;
}

export interface IAuthenticatePluginSuccessPayload {
  folderId: string;
  teamId: string;
}

const pluginSlice = createSlice({
  name: 'plugin',
  initialState: initialState.plugin,
  reducers: {
    authenticatePlugin(state, action: PayloadAction<IAuthenticatePluginData>) {
      noop(action.payload.key);
    },
    authenticatePluginSuccess(
      state,
      action: PayloadAction<IAuthenticatePluginSuccessPayload>
    ) {
      state.pluginMode = true;
      state.folderId = action.payload?.folderId || '';
    },
    authenticatePluginFail(state, action) {
      state.pluginMode = false;
      state.errorText = action.payload;
    },
    getPluginDetailsFail(state) {
      state._id = '';
      state.website = '';
      state.pluginMode = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPluginDetailsSuccess, (state, action) => {
      Object.assign(state, action.payload);
      state.pluginMode = false;
    });
  },
});

const pluginRecordingSlice = createSlice({
  name: 'pluginRecording',
  initialState: initialState.pluginRecording,
  reducers: {
    initPluginRecording(
      state,
      action: {
        payload: {
          title: string;
          status: typeof PLUGIN_RECORDING_STATUS[keyof typeof PLUGIN_RECORDING_STATUS];
        };
      }
    ) {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearRecordingState, (state) => {
      state.title = '';
      state.status = PLUGIN_RECORDING_STATUS.INIT;
    });
  },
});

// Export actions for use elsewhere
export const {
  authenticatePlugin,
  authenticatePluginSuccess,
  authenticatePluginFail,
  getPluginDetailsFail,
  // Add other exported actions here...
} = pluginSlice.actions;

export const { initPluginRecording } = pluginRecordingSlice.actions;

export const pluginReducer = pluginSlice.reducer;
export const pluginRecordingReducer = pluginRecordingSlice.reducer;
