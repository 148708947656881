import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (
  state = STORE_INITIAL_VALUE.COMPANY_SHARED_REMOVE_FILES,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.CLOSE_REMOVE_TEAM_VIDEO_ALERT:
      return { ...state, isSuccess: false };
    default:
      return state;
  }
};
