/* eslint-disable import/prefer-default-export */
import debug from 'debug';

const log = debug('app:clarity');

interface SetupIdentityOptions {
  email: string;
  _id: string;
}

let clarity;

export const setupIdentity = ({ email, _id }: SetupIdentityOptions) => {
  return Promise.resolve().then(() => {
    try {
      // eslint-disable-next-line no-multi-assign
      clarity = window.clarity = window.clarity || {};
      clarity('identify', email);
      clarity('set', 'email', email);
      clarity('set', 'userId', _id);
    } catch (error) {
      log('Clarity not configured', error);
    }
    return clarity;
  });
};
