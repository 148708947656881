/**
 * This is the first experment to migrate from redux to redux-toolkit
 */
/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { IAlertComponentProps } from '../../components/StorybookComponents/Alerts';
import { FileType } from '../../types';

export type IModalTypes =
  | 'stripe-pricing-table-upgrade-modal'
  | 'import-videos-modal'
  | 'move-files-modal'
  | 'alert'
  | 'link-share-modal'
  | 'delete-files-modal';

export interface MultiFilesActionPayLoadType {
  type: 'move-files-modal' | 'delete-files-modal' | 'link-share-modal';
  sourceRecordings: FileType[];
}

interface OtherActionPayLoadType {
  type: Exclude<
    IModalTypes,
    'move-files-modal' | 'delete-files-modal' | 'link-share-modal'
  >;
}

type ModalActionPayLoadType =
  | MultiFilesActionPayLoadType
  | OtherActionPayLoadType;
type AlertProps = Pick<IAlertComponentProps, 'type' | 'description'>;

interface IMoveFilesState {
  sourceRecordings: FileType[];
}

export interface IGlobalModalsState {
  modalStack: IModalTypes[];
  // This is a global alert to show success or error messages. Needs further fine tuning
  alertProps?: AlertProps;
  actionData: IMoveFilesState;
}

const initialState: IGlobalModalsState = {
  modalStack: [],
  actionData: {
    sourceRecordings: [],
  },
} as IGlobalModalsState;

export const globalModalsSlice = createSlice({
  name: 'globalModals',
  initialState,
  reducers: {
    pushModal: (state, action: PayloadAction<ModalActionPayLoadType>) => {
      state.modalStack.push(action.payload.type);
      switch (action.payload.type) {
        case 'move-files-modal':
          state.actionData.sourceRecordings = action.payload.sourceRecordings;
          break;
        case 'link-share-modal':
          state.actionData.sourceRecordings = action.payload.sourceRecordings;
          break;
        case 'delete-files-modal':
          state.actionData.sourceRecordings = action.payload.sourceRecordings;
          break;
        default:
      }
    },
    popModal: (state) => {
      if (state.modalStack.length) {
        state.modalStack.pop();
        state.actionData.sourceRecordings = [];
        state.alertProps = undefined;
      }
    },
    showAlert: (state, action: PayloadAction<AlertProps>) => {
      state.alertProps = action.payload;
      state.modalStack.push('alert');
    },
  },
});

export const { pushModal, popModal, showAlert } = globalModalsSlice.actions;
export default globalModalsSlice.reducer;
