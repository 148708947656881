import { combineReducers } from 'redux';
import spinner from './spinner';
import recorder from '../features/recorder/recorderSlice';
import recordingButton from './recordingButtonReducer';
import notifications from './notificationRecucer';
import browser from './browserReducer';
import app from '../features/app/appSlice';
import modal from './modalReducer';
import remoteVideo from './remoteVideoReducer';
import auth from '../features/auth/authSlice';
import trim from './trimReducer';
import videoStore from './videoStoreReducer';
import library from './libraryReducer';
import libraryTab from './libraryTabReducer';
import fileSystemSettings from './fileSystemSettingsReducer';
import selectedVideo from '../features/selectedVideo/selectedVideoSlice';
import userAdmission from './userAdmissionReducer';
import tag from './tagReducer';
import subscription from './subscriptionReducer';
import emailRoute from './emailRouteReducer';
import freeRecordingLibrary from './freeRecordingLIbraryReducer';
import folders from './folderReducer';
import popUpMessage from './popupMessageReducer';
import currentlyPlayingVideo from './currentlyPlayingLibraryVideoReducer';
import checkedFiles from './checkedFilesReducer';
import fileUploads, { fileUploadProgress } from './fileUploadsReducer';
import shareLink from './shareLinkReducer';
import moveFileStatus from './moveFileReducer';
import {
  pluginReducer as plugin,
  pluginRecordingReducer as pluginRecording,
} from '../features/plugin/pluginSlice';
import recover from './recoverReducer';
import recordingRequest from './recordingRequestReducer';
import realtimeUploader from '../features/realtimeUploader/realtimeUploaderSlice';
import storageLimitations from './storageLimitationsReducer';

import searchLibraryVideo from './searchLibraryVideoReducer';
import publicSharedContents from './publicSharedContentsReducer';
import publicSharedContent from './publicSharedContentReducer';
import publicSharedFile from './publicSharedFileReducer';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import menuAppbarOpen from './menuAppbarOpenReducer';
import sharedSpace from './sharedSpaceReducer';
import analyticsPageView from './analyticsReducer';
import feedback from './feedbackReducer';
import companySharedSpace from './companySharedSpaceReducer';
import deactivatePlugin from './deactivatePluginReducer';
import noticeBanner from './updateNoticeBannerReducer';
import importFile from '../features/importVideos/importVideoSlice';
import filterTeamMates from './filterTeamMatesReducer';
import companySharedFilesRemove from './companySharedFilesRemoveReducer';
import askAI from './askAIReducer';
import requestRecordings from './requestRecordingsReducer';
import filterRequestRecordings from './filterRequestRecordingsReducer';
import openBanners from './openBannersReducer';
import globalModals from '../features/globalModals/globalModalsSlice';
import trimmer from '../features/trimmer/trimmerSlice';
import sharedFile from '../features/sharedFileView/sharedFileSlice';
import globalSearch from '../features/globalSearch/globalSearchSlice';
import postPayment from '../features/postPayment/postPaymentSlice';

const appReducer = combineReducers({
  auth,
  spinner,
  recorder,
  recordingButton,
  notifications,
  browser,
  app,
  modal,
  remoteVideo,
  trim,
  trimmer,
  realtimeUploader,
  videoStore,
  libraryTab,
  fileSystemSettings,
  library,
  selectedVideo,
  userAdmission,
  tag,
  subscription,
  emailRoute,
  freeRecordingLibrary,
  folders,
  currentlyPlayingVideo,
  checkedFiles,
  fileUploads,
  fileUploadProgress,
  shareLink,
  moveFileStatus,
  plugin,
  pluginRecording,
  recover,
  popUpMessage,
  recordingRequest,
  storageLimitations,
  searchLibraryVideo,
  sharedSpace,
  menuAppbarOpen,
  publicSharedContents,
  publicSharedContent,
  publicSharedFile,
  analyticsPageView,
  feedback,
  companySharedSpace,
  deactivatePlugin,
  noticeBanner,
  importFile,
  filterTeamMates,
  companySharedFilesRemove,
  askAI,
  requestRecordings,
  filterRequestRecordings,
  openBanners,
  globalModals,
  sharedFile,
  globalSearch,
  postPayment,
});

const rootReducer = (state, action) => {
  let upState = { ...state };
  if (action.type === REDUX_ACTIONS.SIGN_OUT) {
    upState = STORE_INITIATE_VALUE;
  }

  return appReducer(upState, action);
};

export default rootReducer;
