/* eslint-disable import/prefer-default-export */
import {
  put,
  takeLatest,
  call,
  take,
  race,
  fork,
  delay,
} from 'redux-saga/effects';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { setToken } from '../../lib/localStorageHandler';
import { getPlugin, initialize, setup } from '../../services/pluginService';
import {
  IUpdateSettingsSuccessData,
  getPluginDetailsSuccess,
  updateSettingsSuccess,
} from '../../features/auth/authSlice';
import {
  authenticatePluginFail,
  authenticatePluginSuccess,
  getPluginDetailsFail,
  authenticatePlugin,
} from '../../features/plugin/pluginSlice';
import { getSettingsSuccess } from '../../features/recorder/recorderSlice';

// Called by plugin end user
function* initSaga(action) {
  try {
    const response = yield call(initialize, action?.payload?.key);
    if (response?.data?.data?.token) {
      // Token set for plugin's host site
      // ScreenApp.io unharmed.
      setToken(response.data.data.token);
      yield put(
        authenticatePluginSuccess({
          teamId: response.data.data?.teamId || null,
          folderId: response.data?.data?.folderId || null,
        })
      );
      const data: IUpdateSettingsSuccessData = {
        settings: response.data?.data?.settings?.recorderSettings || {},
        type: 'plugin',
        message: 'Plugin setting has been updated',
        notChange: true,
      };
      yield put(getSettingsSuccess(response.data?.data?.settings));
      yield put(updateSettingsSuccess(data));
    }
  } catch (error) {
    if (
      error?.response?.data?.message ===
      'Your free trial is over!\n' +
        'To upgrade or extend your trial please contact support@screenapp.io\n'
    ) {
      yield put(authenticatePluginFail(error?.response?.data?.message));
    }
  }
}

// Called by plugin owner
function* setupSaga(action) {
  try {
    const response = yield call(setup, action.data);
    if (response?.data?.success) {
      yield put(getPluginDetailsSuccess(response.data.data));
    }
  } catch (error) {
    yield put(getPluginDetailsFail());
  }
}

// Called by plugin owner
function* getPluginSaga() {
  try {
    const response = yield call(getPlugin);
    if (response?.data?.success) {
      yield put(getPluginDetailsSuccess(response.data.data));
    } else {
      yield put(getPluginDetailsFail());
    }
  } catch (error) {
    yield put(getPluginDetailsFail());
  }
}

const debounce = (ms: number, pattern, task) =>
  fork(function* gen() {
    while (true) {
      let action = yield take(pattern);

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern),
        });

        if (debounced) {
          yield fork(task, action);
          break;
        }

        action = latestAction;
      }
    }
  });

export function* pluginSaga() {
  yield takeLatest(authenticatePlugin.type, initSaga);
  yield debounce(1000, REDUX_ACTIONS.SETUP_PLUGIN, setupSaga);
  yield takeLatest(REDUX_ACTIONS.GET_PLUGIN_DETAILS, getPluginSaga);
}
