// @ts-nocheck
import { END, eventChannel } from '@redux-saga/core';
import api, { BaseResponse } from '../lib/baseAxios';

import {
  FileTypeExtended,
  IFolderEntry,
  IFolderInput,
  IUpdateSpeakerProps,
  recordedVideoLibraryEntry,
  ISharedFileDetails,
  GlobalSearchQuery,
} from '../types';
import { library } from '../lib/baseStorage';

export interface IVFSResponse<T> {
  success: boolean;
  data: T;
  error?: string;
}

export const fetchFileSystem = async () => {
  return api.get<IVFSResponse<{ fileSystem: IFolderEntry[] }>>(
    `/fs?unified=true`
  );
};

export const createFolder = async (details: IFolderInput) => {
  return api.post(`/fs/folders`, details);
};

export const updateFolder = async (
  id: string,
  changes: { name?: string; parentId?: string; teamId?: string }
) => {
  return api.post(`/fs/folders/${id}`, changes);
};

export const renameFolder = (id: string, name: string, teamId: string) =>
  updateFolder(id, { name, teamId });

export const moveFolder = (id: string, parentId: string) =>
  updateFolder(id, { parentId });

export const editPrompt = (id: string, teamId: string, prompt: string) =>
  updateFolder(id, { teamId, prompt });

export const deleteFolder = async (id: string) => {
  return api.delete(`/fs/folders/${id}`);
};

export const deleteTeamSpaceFolder = async (id: string) => {
  return api.delete(`/fs/team-space/folders/${id}`);
};

const deleteFolderDirectContents = async (parentId: string) => {
  const libraryKeys = await library.keys();
  const fileObjs = await Promise.all(
    libraryKeys.map((k) => library.getItem<recordedVideoLibraryEntry>(k))
  );

  return Promise.all(
    fileObjs
      .filter((f) => f?.folderId === parentId)
      .map((f) => f && library.removeItem(f.recordingId))
  );
};

export const deleteFolderArrayDirectContents = async (parentIds: string[]) => {
  await Promise.all(parentIds.map(deleteFolderDirectContents));
};

export const uploadFile = async (
  data: FileTypeExtended,
  blob: Blob,
  thumbBlob?: Blob,
  libraryTab?: string,
  teamId?: string,
  parentId?: string | null
) => {
  const file = new File([blob], data._id, { type: 'video/webm' });
  const thumbImage = new File([thumbBlob], data._id, { type: 'image/jpeg' });
  const formData = new FormData();
  formData.append('file', file);
  if (thumbBlob) {
    formData.append('thumbnail', thumbImage);
  }
  formData.append('name', data.name || '');
  formData.append('description', data.description || '');
  formData.append('duration', data.duration || '');
  if (libraryTab === 'team') {
    formData.append('teamId', teamId);
  }
  formData.append('parentId', parentId);

  return eventChannel((emitter: (input: unknown) => void) => {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: (progressEvent) => {
        setTimeout(
          () =>
            emitter({
              type: 'PROGRESS',
              data: {
                _id: data._id,
                loaded: progressEvent.loaded,
                total: progressEvent.total,
              },
            }),
          0
        );
      },
    };
    api
      .post(`/fs/files/upload`, formData, config)
      .then((res) => {
        setTimeout(() => {
          emitter({
            type: 'SUCCESS',
            data: res,
          });
          emitter(END);
        }, 0);
      })
      .catch((err) => {
        setTimeout(() => {
          emitter({
            type: 'ERROR',
            data: err,
          });
          emitter(END);
        }, 0);
      });
    return () => {}; // it's necessarily. event channel should
    // return unsubscribe function.
  });
};

export const shareItems = async ({
  entityIds,
  teamId,
  spaceId,
  isDownloadEnabled = false,
}: {
  entityIds: string[];
  teamId?: string;
  spaceId?: string;
}) => {
  return api.post('/fs/share', {
    entityIds,
    teamId,
    spaceId,
    isDownloadEnabled,
  });
};

export const sharePlugin = async ({
  id,
  teamId,
  isDownloadEnabled = false,
}: {
  id: string;
  teamId: string;
  isDownloadEnabled?: boolean;
}) => {
  return api.post('/fs/share/plugin', {
    id,
    teamId,
    isDownloadEnabled,
  });
};

export const sharePublicFile = async ({
  entityId,
  category,
}: {
  entityId: string;
  category: string;
}) => {
  return api.post(`/fs/public/share`, { entityId, category });
};

export const unpublishedFile = async (entityId: string) => {
  return api.post('/fs/public/delete', { entityId });
};

export const getSharedPublicFiles = async () => {
  return api.get(`/fs/public`);
};

export const getPublicSharedFile = async (entityId: string) => {
  return api.get(`/fs/public/${entityId}`);
};

export const addView = async (entityId: string) => {
  return api.post(`/fs/public/view/${entityId}`);
};

export const getSharedItems = async (
  shareId: string
): Promise<BaseResponse<ISharedFileDetails>> => {
  return api.get(`/fs/share/${shareId}`);
};

export const getCompanySharedFiles = async (teamId: string) => {
  return api.get(`/fs/company-shared/${teamId}`);
};

export const addRecordingToCompanySharedSpace = async (
  id: string,
  teamId: string
) => {
  return api.post(`/fs/add-to-company-shared`, { fileId: id, teamId });
};

export const shareCompanySharedItems = async (
  entityIds: [],
  teamId: string,
  isDownloadEnabled?: boolean = false
) => {
  return api.post('/fs/share-company-shared', {
    entityIds,
    teamId,
    isDownloadEnabled,
  });
};

export const updateFile = async (
  id?: string,
  changes: {
    name?: string;
    parentId?: string | null;
    description?: string;
    ownerId?: string;
    teamId?: string;
    spaceId?: string;
    selectedTeamId?: string;
  }
) => {
  return api.post(`/fs/files/${id}`, changes);
};

export const moveFile = (id: string, args: { [x: string]: any }) =>
  updateFile(id, args);

export const moveManyFiles = async (fileIds: string[], folderId: string) => {
  return api.post(`/fs/files/move`, { fileIds, folderId });
};

export const deleteFile = async (teamId: string, id: string) => {
  return api.delete(`/fs/files/${teamId}/${id}`);
};

export const uploadThumbnail = async (id: string, data: FormData) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  return api.post(`/fs/thumb/upload/${id}`, data, config);
};

export const transcribe = ({
  id,
  ownerId,
  teamId,
}: {
  id: string;
  teamId: string | undefined;
  ownerId: string | undefined;
}) => {
  if (teamId) {
    return api.post(
      `/fs/files/${id}/transcript?teamId=${teamId}&ownerId=${ownerId}`
    );
  }

  return api.post(`/fs/files/${id}/transcript`);
};

export const updateSpeakerRequest = async (data: IUpdateSpeakerProps) => {
  return api.post(`/fs/files/update-speaker`, data);
};

export const reprocess = async (id: string) => {
  return api.post(`/actions/reprocess`, { id });
};

export const statusCheck = async (id: string) => {
  return api.get(`fs/files/status-check/${id}`);
};

export const addDuration = async ({
  id,
  duration,
}: {
  id: string;
  duration: number;
}) => {
  return api.post(`/fs/files/${id}/duration`, { duration });
};

export const transcode = async ({
  id,
  profile,
  ownerId,
  teamId,
}: {
  id: string;
  profile: string;
  teamId: string | undefined;
  ownerId: string | undefined;
}) => {
  if (teamId) {
    return api.post(
      `/fs/files/${id}/transcode?profile=${profile}&teamId=${teamId}&ownerId=${ownerId}`
    );
  }
  return api.post(`/fs/files/${id}/transcode?profile=${profile}`);
};

export const summarize = async ({
  id,
  force = true,
  ownerId,
  teamId,
}: {
  id: string;
  teamId: string | undefined;
  ownerId: string | undefined;
  force?: boolean;
}) => {
  if (teamId) {
    return api.post(
      `/fs/files/${id}/summary?force=${force}&teamId=${teamId}&ownerId=${ownerId}`
    );
  }
  return api.post(`/fs/files/${id}/summary?force=${force}`);
};

export const chromeExtension = async () => {
  return api.get(`/settings/chrome-extension`);
};

export const deleteManyFiles = async (fileIds: string[]) => {
  return api.post(`/fs/files/delete`, { fileIds });
};

export const getSettings = async () => {
  return api.get(`/fs/settings`);
};

export const updateSettings = async (settings: { cloudSync: boolean }) => {
  return api.post(`/fs/settings`, settings);
};

export const fetchAISuggestions = async ({
  id,
  teamId,
  ownerId,
}: {
  id: string;
  teamId: string;
  ownerId: string;
}) => {
  if (teamId) {
    return api.get(
      `/fs/files/${id}/ask/suggestions?teamId=${teamId}&ownerId=${ownerId}`
    );
  }
  return api.get(`/fs/files/${id}/ask/suggestions`);
};

export const askAI = async ({
  continuation,
  id,
  teamId,
  ownerId,
  sessionId,
}: {
  id: string;
  continuation: { role: 'assistant' | 'user'; content: string }[];
  teamId: string;
  ownerId: string;
  sessionId: string;
}) => {
  if (teamId) {
    return api.post(`/fs/files/${id}/ask?teamId=${teamId}&ownerId=${ownerId}`, {
      continuation,
      sessionId,
    });
  }
  return api.post(`/fs/files/${id}/ask`, { continuation, sessionId });
};

export const getImportFileDetails = async (data: []) => {
  return api.post(`/fs/file-import-details`, data, { responseType: 'blob' });
};

export const recordingCount = async () => {
  return api.post(`/fs/recording/count`);
};

export const getRecordingCount = async () => {
  return api.get(`/fs/recording/count`);
};

export const searchRequest = (data: GlobalSearchQuery) => {
  return api.post('fs/global-search', data);
};

export const trimRemote = async (
  id: string,
  data: {
    begin: number;
    end: number;
    replace?: boolean;
    ownerId?: string | undefined;
    spaceFolderId?: string | undefined;
    teamId?: string | undefined;
  }
) => {
  return api.post(`/trim/${id}`, data);
};
