// Trim Reducder is deprecated. Move all new features to /features/trimmer/trimmerSlice.ts
// TODO: Move existing features to /features/trimmer/trimmerSlice.ts
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { WS_ACTION_TYPE } from '../constants/wsConstant';
import { clearRecordingState } from '../features/recorder/recorderSlice';

export default (state = STORE_INITIATE_VALUE.TRIM_VIDEO_INITIATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.TRIM_BUTTON_CLICK:
      return {
        ...state,
        requestTrim: true,
      };
    case REDUX_ACTIONS.GO_BACK_FUNCTION:
      return {
        ...state,
        requestTrim: false,
      };
    case clearRecordingState.type:
      return {
        ...state,
        requestTrim: false,
      };
    // Trim Reducder is deprecated. Move all new features to /features/trimmer/trimmerSlice.ts
    case WS_ACTION_TYPE.STREAM_WEBM_AVAILABLE:
      return {
        ...state,
        trimEnable: true,
      };
    case REDUX_ACTIONS.ANOTHER_RECORDING:
      return {
        ...state,
        requestTrim: false,
      };
    case REDUX_ACTIONS.TRIM_TO_AVAILABLE_VIDEO:
      return {
        ...state,
        trimEnable: true,
      };
    case REDUX_ACTIONS.USER_VIDEO_TRIM_SUCCESS:
      return {
        ...state,
        requestTrim: false,
      };
    // Trim Reducder is deprecated. Move all new features to /features/trimmer/trimmerSlice.ts
    default:
      return state;
  }
};
