import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import WebcamOverlay from '../../containers/WebcamOverlayContainer';

const useStyles = makeStyles(() =>
  createStyles({
    container: { position: 'relative' },
    webcamVideoPIP: {
      display: 'none',
    },
  })
);

const getCameraVideo = async (constraints) => {
  return navigator.mediaDevices.getUserMedia(constraints || { video: true });
};

const WebcamComponent = ({ onCancel, settings }) => {
  const { webcamDeviceId } = settings?.deviceSettings || '';
  const classes = useStyles();
  const videoRef = useRef(null);
  const [isPIP, setIsPIP] = useState(false);

  useEffect(() => {
    async function setCameraFeed() {
      try {
        if (webcamDeviceId) {
          videoRef.current.srcObject = await getCameraVideo({
            video: { deviceId: webcamDeviceId },
          });
        } else {
          videoRef.current.srcObject = await getCameraVideo({
            video: true,
          });
        }

        videoRef.current.onenterpictureinpicture = () => {
          setIsPIP(true);
        };
        videoRef.current.onleavepictureinpicture = () => {
          setIsPIP(false);
        };
      } catch (error) {
        console.error(error);
        onCancel();
      }
    }

    setCameraFeed();

    return async () => {
      try {
        if (videoRef.current.srcObject) {
          videoRef.current.srcObject
            .getTracks()
            .forEach((track) => track.stop());
        }
        if (document?.pictureInPictureElement) {
          await document.exitPictureInPicture?.();
        }
      } catch (error) {
        console.log(error);
      }
    };
  }, [webcamDeviceId]);

  const tryActivatePIP = async () => {
    try {
      await videoRef.current.requestPictureInPicture();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnablePIP = () => {
    if (!videoRef.current.onloadedmetadata) {
      videoRef.current.onloadedmetadata = () => {
        tryActivatePIP();
      };
    } else {
      tryActivatePIP();
    }
  };

  return (
    <div className={classes.container}>
      <video
        className={isPIP ? classes.webcamVideoPIP : ''}
        width="320"
        height="240"
        preload="auto"
        ref={videoRef}
        autoPlay
        id="webcamVideo"
      >
        <track kind="captions" />
      </video>
      <WebcamOverlay isPIP={isPIP} onClick={handleEnablePIP} />
    </div>
  );
};

WebcamComponent.propTypes = {
  onCancel: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  settings: PropTypes.any.isRequired,
};

export default WebcamComponent;
