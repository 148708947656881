import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

// import { InputLabel } from '@material-ui/core';
import { Form, FormGroup } from 'reactstrap';
// eslint-disable-next-line import/named
import { createStyles, makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { ActionModal } from '../StorybookComponents/Modal';
import { BasicTextField } from '../StorybookComponents/TextField';
import { setRecordingTitleAndDescriptionSuccess } from '../../features/recorder/recorderSlice';
import { RecorderSettings } from '../../types/state';

const useStyles = makeStyles(() =>
  createStyles({
    inputLabel: {
      paddingLeft: '8px',
      color: 'black',
    },
    closeButtonWrapper: {
      position: 'absolute',
      right: 5,
      top: 5,
    },
  })
);

interface ILinkShareModalProps {
  isOpen: boolean;
  settings: RecorderSettings;
  handleClose: Function;
  getStartedButtonOnClick: Function;
  actions: {
    updateRecordSettings: Function;
  };
}

export default function RecordingTitleAndDescriptionModal({
  isOpen,
  handleClose,
  getStartedButtonOnClick,
  settings,
  actions: { updateRecordSettings },
}: ILinkShareModalProps) {
  const classes = useStyles();
  const [title, setTitle] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [hasError, setHasError] = useState<boolean>(false);
  const dispatch = useDispatch();

  return (
    <ActionModal
      id="recording-title-and-description-modal"
      open={isOpen}
      modalTitle="Add title and description to your video"
      maxWidth="sm"
      handleClose={() => handleClose()}
      actinButtonLabel="Record"
      actinButtonLabel2="Skip"
      isSkipButtonInsteadCancelButton
      checkBoxNote="You can update this later in 'Recording Settings'"
      actinButtonOnClick={() => {
        if (title) {
          handleClose();
          setTitle('');
          setDescription('');
          setHasError(false);
          dispatch(
            setRecordingTitleAndDescriptionSuccess({
              title,
              description,
            })
          );
          getStartedButtonOnClick();
        } else {
          setHasError(true);
        }
      }}
      skipButtonOnClick={() => {
        handleClose();
        setTitle('');
        setDescription('');
        setHasError(false);
        dispatch(
          setRecordingTitleAndDescriptionSuccess({
            title,
            description,
          })
        );
        getStartedButtonOnClick();
      }}
      onClickCheckBox={(value) => {
        // eslint-disable-next-line no-param-reassign
        settings.otherSettings.hideTitleAndDescriptionDialog = value;
        const data = {
          type: 'record',
          data: settings,
        };
        updateRecordSettings(data);
      }}
      displayCheckBox
      checkBoxText="Do not show this message again"
    >
      <div className={classes.closeButtonWrapper}>
        <IconButton
          aria-label="close"
          size="medium"
          onClick={() => handleClose()}
        >
          <CloseIcon fontSize="inherit" style={{ color: '#ACACAC' }} />
        </IconButton>
      </div>
      <Form
        onSubmit={() => {
          if (title) {
            handleClose();
            setTitle('');
            setDescription('');
            setHasError(false);
            dispatch(
              setRecordingTitleAndDescriptionSuccess({
                title,
                description,
              })
            );
            getStartedButtonOnClick();
          } else {
            setHasError(true);
          }
        }}
      >
        <FormGroup>
          {/* <InputLabel className={classes.inputLabel}>Title</InputLabel> */}
          <BasicTextField
            id="title"
            label="Title"
            placeholder="Enter your title here"
            value={title}
            error={hasError}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setTitle(e.target.value)
            }
            required
            helperText="Title is required"
          />
        </FormGroup>
        <FormGroup error={hasError} className="mb-0">
          {/* <InputLabel className={classes.inputLabel}>Description</InputLabel> */}
          <BasicTextField
            id="description"
            label="Description"
            placeholder="Write your description here (optional)"
            value={description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(e.target.value)
            }
            rows={3}
            multiline
            recordingTitleAndDescriptionModal
          />
        </FormGroup>
      </Form>
    </ActionModal>
  );
}
