import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { GetUpgradeStatus } from '../../services/subscriptionServices';
import { Package } from '../../constants/userPackage';

let intervalId;

export interface IPostPaymentState {
  isUpgraded: boolean;
  newPackage: Package | null;
}

const initialState: IPostPaymentState = {
  isUpgraded: false,
  newPackage: null,
};

export const checkUpgradeStatusStop = createAsyncThunk(
  'postPayment/checkUpgradeStatusStop',
  () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  }
);

export const checkUpgradeStatusStart = createAsyncThunk(
  'postPayment/checkUpgradeStatusStart',
  async (
    _,
    { dispatch }
  ): Promise<{ isUpgraded: boolean; newPackage: Package | null }> => {
    return new Promise((resolve) => {
      if (intervalId) {
        clearInterval(intervalId);
      }
      intervalId = setInterval(async () => {
        const response = await GetUpgradeStatus();
        const isUpgraded: boolean = response?.data?.data?.isUpgraded || false;
        const newPackage: Package | null =
          response?.data?.data?.newPackage || null;
        if (response.data.success && isUpgraded && newPackage) {
          dispatch(checkUpgradeStatusStop());
          resolve({
            isUpgraded,
            newPackage,
          });
        }
      }, 5000);
    });
  }
);

const postPaymentSlice = createSlice({
  name: 'postPayment',
  initialState,
  reducers: {
    resetUpgradeStatus: () => {
      return {
        isUpgraded: false,
        newPackage: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkUpgradeStatusStart.fulfilled, (state, action) => {
      if (action.payload.isUpgraded) {
        return action.payload;
      }
      return state;
    });
  },
});

export const { resetUpgradeStatus } = postPaymentSlice.actions;
export default postPaymentSlice.reducer;
