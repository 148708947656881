/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { ReactNode } from 'react';
import {
  Dialog,
  DialogContent,
  // eslint-disable-next-line import/named
  DialogProps,
  // eslint-disable-next-line import/named
  Theme,
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/HighlightOff';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import BasicButton from '../../BasicButton';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(0),
    },
    column: {
      margin: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      margin: theme.spacing(2.5),
      fontSize: '2.5rem',
      fontWeight: 'normal',
      maxWidth: '50%',
      textAlign: 'center',
    },
    content: {
      margin: theme.spacing(1),
      justifyContent: 'center',
      color: '#0000008f',
    },
    dialogContent: {
      paddingBottom: theme.spacing(1),
      paddingTop: theme.spacing(1),
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
    dialogActions: {
      paddingBottom: '30px',
      paddingTop: '30px',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    iconButton: {
      position: 'absolute',
      top: '10px',
      right: '70px',
      paddingBottom: '12px',
      width: '50px',
      marginTop: '20px',
      marginBottom: '25px',
    },
    button: {
      width: '175px',
      paddingRight: '16px',
    },
    link: {
      color: '#4083cf',
      textTransform: 'inherit',
      marginLeft: '0px',
    },
    image: {
      width: '10%',
      marginBottom: '25px',
      marginTop: '30px',
    },
    largeImage: {
      width: '25%',
    },
  });
};

export interface IModalProps extends WithStyles<DialogProps & typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: (e: any) => void;
  children?: ReactNode;
  modalTitle?: string;
  modalMessage2?: string;
  modalMessage3?: string;
  actionButtons?: ReactNode;
  disableBackdropClick?: boolean;
  actionButtonLabel?: string;
  actionButtonLabel2?: string;
  actionButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  actionButtonOnClick2?: React.MouseEventHandler<HTMLSpanElement>;
  imgSrc?: any;
  largeImgSrc?: any;
}

export const FullScreenModal: React.FC<IModalProps> = ({
  classes,
  id,
  open,
  modalTitle,
  handleClose,
  children,
  actionButtonLabel = 'Upgrade Now',
  actionButtonLabel2 = 'Delete records to free up space',
  actionButtonOnClick,
  actionButtonOnClick2,
  disableBackdropClick = false,
  imgSrc,
  largeImgSrc,
}: IModalProps) => {
  return (
    <Dialog
      fullScreen
      className={classes.root}
      id={id}
      onClose={(e, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        handleClose?.(e);
      }}
      open={open}
    >
      <IconButton
        className={classes.iconButton}
        aria-label="cancel"
        size="medium"
        onClick={handleClose}
      >
        <CancelIcon fontSize="inherit" />
      </IconButton>
      <DialogContent className={classes.dialogContent}>
        {/* <div className={classes.column}> */}
        {imgSrc && <img className={classes.image} src={imgSrc} alt="" />}
        {largeImgSrc && (
          <img className={classes.largeImage} src={largeImgSrc} alt="" />
        )}
        <Typography className={classes.title} variant="h1">
          {modalTitle}
        </Typography>
        {children}

        <div className={classes.dialogActions}>
          {actionButtonOnClick && (
            <>
              <div className={classes.button}>
                <BasicButton
                  label={actionButtonLabel}
                  id={`${id}-action-button`}
                  filled
                  fullWidth
                  roundBorder
                  onClick={actionButtonOnClick}
                />
              </div>
              <Typography className={classes.content} variant="h6">
                OR
              </Typography>
            </>
          )}
          {actionButtonOnClick2 && (
            <Link
              className={classes.link}
              component="button"
              onClick={actionButtonOnClick2}
            >
              {actionButtonLabel2}
            </Link>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(FullScreenModal);
