import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (
  state: string | null = STORE_INITIAL_VALUE.SHARE_LINK,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.SHARE_FILES_SUCCESS:
      return action.data;
    case REDUX_ACTIONS.SHARE_LINK_CLEAR:
      return null;
    default:
      return state;
  }
};
