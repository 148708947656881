// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.FILTERED_TEAMMATES, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FILTER_TEAM_MATES:
      return {
        ...state,
        status: action.data.status,
        role: action.data.role,
      };

    default:
      return state;
  }
};
