type Profile = {
  /**
   * Extension used in downloaded files, e.g., can be used to achieve a fake mp4
   */
  extension: string;
  /**
   * What the extension should really be according to specs
   */
  trueExtension: string;
  /**
   * Format name for tools such as ffmpeg
   */
  format: string;
  /**
   * Codec to be used when screenapp/frontend creates a file with video
   */
  videoCodec: string;
  /**
   * Codec to be used when screenapp/frontend creates a file with audio
   */
  audioCodec: string;
  /**
   * Video encoder to be used with ffmpeg
   */
  videoCodecFfmpeg: string;
  /**
   * Audio encoder to be used with ffmpeg
   */
  audioCodecFfmpeg: string;
  /**
   * MIME. Check relevant RFCs etc. when defining this
   */
  mime: string;
};

export type ProfileType = 'webm' | 'mp4' | 'mkv' | 'mp3';

/**
 * This file answers the question "what do you mean mp4?".
 *
 * We need to have a convention for combinations of different formats
 * (containers + codecs) since there are a zillion different combinations of
 * them and we cannot support everything generally without increasing the abuse
 * potential.
 *
 * This is a map of format name used by convention vs. the actual codecs etc.
 * used. If some parameter (e.g., frame rate, video size), wouldn't practically
 * matter or allowed to be dynamic as far as screenapp is concerned, it doesn't
 * need to be specified here.
 *
 * This map only applies to files output by ScreenApp tools (i.e., transcoding,
 * recording, ...) as far as the codecs are concerned; we cannot control files
 * created by importing via the UI and CLI because they can be anything. But
 * they still have to be any of the allowed formats here and ffmpeg must
 * validate them. Always run ffprobe to identify input file codecs.
 */
const profiles: { [humanReadableName in ProfileType]: Profile } = {
  webm: {
    // https://www.webmproject.org/docs/container/
    extension: '.webm',
    trueExtension: '.webm',
    format: 'webm',
    videoCodec: 'vp8',
    audioCodec: 'opus',
    videoCodecFfmpeg: 'libvpx',
    audioCodecFfmpeg: 'libopus',
    mime: 'video/webm',
  },
  mkv: {
    // https://www.matroska.org/what_is_matroska.html
    extension: '.mkv',
    trueExtension: '.mkv',
    format: 'matroska',
    videoCodec: 'h264',
    audioCodec: 'opus',
    videoCodecFfmpeg: 'libx264',
    audioCodecFfmpeg: 'libopus',
    mime: 'video/x-matroska',
  },
  mp4: {
    extension: '.mp4',
    trueExtension: '.mp4',
    format: 'mp4',
    videoCodec: 'h264',
    audioCodec: 'aac',
    videoCodecFfmpeg: 'libx264',
    audioCodecFfmpeg: 'aac',
    mime: 'video/mp4',
  },
  mp3: {
    extension: '.mp3',
    trueExtension: '.mp3',
    format: 'mp3',
    videoCodec: '',
    audioCodec: 'mp3',
    videoCodecFfmpeg: '',
    audioCodecFfmpeg: 'libmp3lame',
    mime: 'audio/mpeg',
  },
  // If more formats are needed, just add 'betterMkv': {...} and so on.
};

export default profiles;

export function getExtension(profileId: ProfileType): string | undefined {
  return profiles[profileId]?.extension || undefined;
}

export function getMime(profileId: ProfileType): string | undefined {
  return profiles[profileId]?.mime || undefined;
}

/**
 * Returns strings like "video/webm;codecs=h264,opus"
 * @param profileId
 * @param param1.video
 * @param param1.audio
 * @returns
 */
export function getMimeWithCodecs(
  profileId: ProfileType,
  { audio, video }: { audio: boolean; video: boolean }
) {
  const videoCodec = video ? profiles[profileId]?.videoCodec : '';
  const audioCodec = audio ? profiles[profileId]?.audioCodec : '';
  return `${profiles[profileId]?.mime};codecs=${[videoCodec, audioCodec]
    .filter(Boolean)
    .join(',')}`;
}

export function getFormat(profileId: ProfileType): string | undefined {
  return profiles[profileId]?.format || undefined;
}

export function guessProfileFromMime(mime: string) {
  return Object.keys(profiles).find(
    (key): key is ProfileType => profiles[key as ProfileType].mime === mime
  );
}
