import debug from 'debug';

const log = debug('app:hubspot');

interface TrackPageViewOptions {
  path: string;
}

interface SetupIdentityOptions {
  email: string;
}

let _hsq;

export const setupIdentity = ({ email }: SetupIdentityOptions) => {
  return Promise.resolve().then(() => {
    try {
      // eslint-disable-next-line no-multi-assign
      _hsq = window._hsq = window._hsq || [];
      _hsq.push([
        'identify',
        {
          email,
        },
      ]);
    } catch (error) {
      log('Hubspot not configured');
    }
    return _hsq;
  });
};

export const trackPageView = ({ path }: TrackPageViewOptions) => {
  if (!_hsq) return;
  _hsq.push(['setPath', path]);
  _hsq.push(['trackPageView']);
};
