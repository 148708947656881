// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.PLUGIN, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.DEACTIVATE_PLUGIN_REQUEST_SUCCESS:
      return {
        ...state,
        success: true,
        pluginMode: false,
      };
    case REDUX_ACTIONS.DEACTIVATE_PLUGIN_REQUEST_FAILURE:
      return {
        ...state,
        success: false,
      };
    default:
      return state;
  }
};
