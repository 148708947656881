import { v4 as uuid } from 'uuid';
import {
  RECORDING_BUTTON_STATUS,
  PLUGIN_RECORDING_STATUS,
  REQUEST_RECORDING_STATUS,
} from './apiSagaConstant';
import { DEFAULT_PACKAGE_CONFIG } from './userPackage';
import { InitialState } from '../types/state';
import { initialState as RecorderInitialState } from '../features/recorder/recorderSlice';

const STORE_INITIATE_VALUE: InitialState = {
  AUTH_INITIATE: {
    user: {
      success: false,
      message: '',
      accessToken: '',
      data: {
        id: '',
        usage: {
          recordingRequestsCount: 0,
          askAICount: 0,
          transcriptionCount: 0,
          copyTranscriptCount: 0,
          mp4DownloadCount: 0,
          webmDownloadCount: 0,
          mkvDownloadCount: 0,
          mp3DownloadCount: 0,
        },
        isVerified: false,
        isFirstTimeUser: false,
        isOnInitialTrial: false,
        trialActivatedAt: '',
        trialEndedAt: '',
        hasPasswordSet: false,
        email: '',
        role: '',
        package: 'FREE',
        limitedPackage: 'FREE',
        trialsConsumed: [],
        pluginTrialsConsumed: [],
        lastActiveAt: '',
        features: {
          plugin: false,
          sharedSpaceUser: false,
          pluginOwnerIDs: [],
          companySharedSpaceUser: false,
          companySharedSpaceOwnerIDs: [],
        },
        newFeaturesAcknowledged: false,
        recordingCount: 0,
        tracking: {
          $init: false,
          hubspotRecordId: '',
          intercomUserId: '',
          intercomHmac: '',
        },
        profile: {
          firstName: '',
          lastName: '',
          name: '',
          picture: '',
          provider: '',
          providerId: '',
          location: '',
          primaryField: '',
        },
        subscriptionStatus: '',
        subscriptionProvider: '',
        tag: {
          createdAt: null,
          status: null,
          tagId: null,
          title: null,
        },
        createdAt: '',
        downloadCount: {
          count: 0,
          resetMonth: 0,
        },
        transcriptionCount: {},
      },
    },
    loading: 'idle',
    packageConfig: DEFAULT_PACKAGE_CONFIG,
    limitedPackageConfig: DEFAULT_PACKAGE_CONFIG,
    recordingCount: {
      success: false,
      data: 0,
    },
    settings: {
      recorderSettings: {
        audioSettings: {
          browserAudio: true,
          microphoneAudio: true,
          systemAudio: true,
        },
        videoSettings: {
          webCam: false,
          screen: true,
        },
        deviceSettings: {
          webcamDeviceId: '',
          microphoneDeviceId: '',
        },
        recordingControlSettings: {
          startRecordingAuto: false,
          autoDownload: true,
          defaultRecordingFormat: 'mkv',
          maxRecordingTimeMins: 10,
        },
        uiSettings: {
          hideLogo: false,
          recorderButtonText: 'Share Your Screen',
          position: 'BOTTOM_LEFT',
          helpText: 'Feedbacks',
          identificationForm: true,
        },
        otherSettings: {
          webhookUrl: '',
          hideTitleAndDescriptionDialog: true,
          defaultTranscriptionLanguage: 'en',
          formatProfile: 'mkv',
          generatePublicUrl: false,
          downloadSharedVideos: false,
        },
      },
      plugin: {
        audioSettings: {
          browserAudio: true,
          microphoneAudio: true,
          systemAudio: true,
        },
        videoSettings: {
          webCam: false,
          screen: true,
        },
        deviceSettings: {
          webcamDeviceId: '',
          microphoneDeviceId: '',
        },
        recordingControlSettings: {
          startRecordingAuto: false,
          autoDownload: true,
          defaultRecordingFormat: 'mkv',
          maxRecordingTimeMins: 10,
        },
        uiSettings: {
          hideLogo: false,
          recorderButtonText: 'Share Your Screen',
          position: 'BOTTOM_LEFT',
          helpText: 'Feedbacks',
          identificationForm: true,
        },
        otherSettings: {
          webhookUrl: '',
          hideTitleAndDescriptionDialog: true,
          defaultTranscriptionLanguage: 'en',
          formatProfile: 'mkv',
          generatePublicUrl: false,
          downloadSharedVideos: false,
        },
      },
      success: false,
      message: '',
      isAdminLogin: false,
    },
  },
  SPINNER_INITIATE: {
    value: false,
    uploadingProgressBar: false,
  },
  MEDIA: {
    /**
     * It's not a good practice to keep large objects inside the state.
     * Therefor we keep only the availability and we retrive the video from lib
     * */
    isVideoStreamAvailable: false, // availability of the camera stream
    isDesktopStreamAvailable: false, // availability of the desktop stream
    videoSource: '', // this will store the recorded video url to be display on the screen after recording.
    error: '', // this will store the error message if any
    recordingSize: 0, // this will store the current recording size in bytes
  },
  LEFT_PANNEL_STATE: {
    state: {
      messageShowStatus: true,
      page: '',
    },
  },

  RECORDING_BUTTON_INITIATE: {
    status: RECORDING_BUTTON_STATUS.STOPPED,
  },
  RECORDER_INITIATE: RecorderInitialState,
  NOTIFICATION_INITIATE: {
    text: '',
    visibility: false,
  },
  OPEN_BANNERS_INITIATE: {
    limitsBanner: {
      open: false,
      limit: 'librarySize',
    },
    deleteAccountAlertType: null,
  },
  BROWSER_INITIATE: {
    name: 'Unknown',
    version: 'Unknown',
    supported: false,
  },
  APP_INITIATE: {
    uuid: uuid(),
    loading: 'idle',
  },
  REMOTE_VIDEO_INITIATE: {
    currentRemoteOriginalVideo: {},
  },
  TRIM_VIDEO_INITIATE: {
    requestTrim: false,
    trimEnable: false,
  },
  MODAL_INITIATE: {
    isOpen: false,
    status: '',
    user: {},
    color: '',
    title: '',
    message: '',
    isFeedbackModalOpen: false,
  },
  REALTIME_UPLOADER: {
    state: 'offline', // offline, connected, error
    progress: {
      loaded: 0,
      total: 0,
    },
  },
  VIDEO_STORE: {
    downloadableMp4Available: false,
    mp4S3Urls: [],
    downloadableWebmAvailable: false,
    webmS3Urls: [],
    trimmedVideoAvailable: false,
    trimmedData: undefined,
    recordVideoAvailable: false,
    showLibrarySkeleton: true,
  },
  LIBRARY: {
    personal: [],
    plugin: [],
    public: [],
    team: [],
  },
  SEARCH_RECORDINGS: {
    searchVideo: '',
    startDate: null,
    endDate: null,
  },
  GLOBAL_SEARCH: {
    searchQuery: '',
    startDate: null,
    endDate: null,
    results: {},
  },
  OPEN_MENU_APPBAR: {
    openMenuAppbar: false,
  },
  USER_ADMISSION: {
    data: null,
    isLoading: false,
    isSignIn: false,
  },
  TAG: {
    data: [],
    isLoading: false,
    isSuccess: false,
    selectedTag: null,
  },
  SUBSCRIPTION: {
    checkoutSessionId: null,
    customerPortalUrl: null,
    checkoutSessionStatus: false,
    isLoading: false,
    paypalStatus: false,
    stripePackageChanged: false,
    unsubscribeModal: null,
    package: 'FREE',
  },
  PLUGIN_SUBSCRIPTION: {
    pluginCheckoutSessionId: null,
    pluginCustomerPortalUrl: null,
    pluginCheckoutSessionStatus: false,
    isLoading: false,
    pluginpaypalStatus: false,
    pluginStripePackageChanged: false,
    pluginSubscriptionFailed: false,
  },
  PLUGIN_PORTAL_VIEW: {
    pluginPortalUrl: null,
  },
  FREE_RECORDING_LIBRARY: {
    count: 0,
  },

  FOLDERS: {
    personal: {
      selectedFolder: null,
      data: [],
      expanded: [],
    },
    plugin: {
      selectedFolder: null,
      data: [],
      expanded: [],
    },
    public: {
      selectedFolder: null,
      data: [
        {
          type: 'Folder',
          name: 'Discover',
          parentId: null,
          provider: 'static',
          _id: '1',
        },
        {
          type: 'Folder',
          name: 'Education',
          parentId: '1',
          provider: 'static',
          _id: '2',
        },
        {
          type: 'Folder',
          name: 'Entertainment',
          parentId: '1',
          provider: 'static',
          _id: '2',
        },
        {
          type: 'Folder',
          name: 'Gaming',
          parentId: '1',
          provider: 'static',
          _id: '2',
        },
        {
          type: 'Folder',
          name: 'Other',
          parentId: '1',
          provider: 'static',
          _id: '2',
        },
        {
          type: 'Folder',
          name: 'My Recordings',
          parentId: null,
          provider: 'static',
          _id: '3',
        },
      ],
      expanded: [],
    },
    team: {
      selectedFolder: null,
      data: [],
      expanded: [],
    },
    isLoading: false,
    isSuccess: false,
  },

  STORAGE_LIMITATIONS: {
    IDB: { total: Infinity, used: 0 },
    S3: { total: Infinity, used: 0 },
  },

  LIBRARY_TAB: 'team',
  FILESYSTEM_SETTINGS: { cloudSync: false },
  CHECKED_FILES: {},
  FILE_UPLOADS: {},
  FILE_UPLOAD_PROGRESS: {},
  SHARE_LINK: '',
  MOVE_STATUS: false,

  SHARED_CONTENT: {},
  PUBLIC_SHARED_CONTENT: {},

  PUBLIC_SHARED_FILE: {},
  PUBLIC_SHARED_FILES: [],

  COMPANY_SHARED_FILES: [],

  COMPANY_SHARED_REMOVE_FILES: {
    isSuccess: false,
    data: [],
  },

  EMAIL_ROUTE: {
    isLoading: false,
    responseStatus: 'none',
  },

  PLUGIN: {
    website: '',
    _id: '',
    pluginMode: false,
    errorText: '',
    folderId: '',
  },

  RECORDING_REQUEST: {
    generatedId: '',
    navigatedId: '',
    initError: '',
    token: '',
    sendEmail: {},
    sendMultipleEmail: {},
    copyLink: {},
    recorderInfo: {
      status: REQUEST_RECORDING_STATUS.INIT,
      email: '',
      title: '',
      name: '',
      folderId: null,
      teamId: '',
    },
  },

  RECOVERY: {
    populated: false, // whether the file list has been populated for the first time
    failedFileSize: 0, // the size of the failed file to be compared with available space
    files: [],
    completed: [], // ids
  },
  PLUGIN_RECORDING: {
    status: PLUGIN_RECORDING_STATUS.INIT,
    name: '',
    email: '',
    title: '',
  },
  SHARED_SPACE: {
    isRegisteredUser: false,
    sharedSpaceUsers: [],
    sendEmail: {},
  },
  ANALYTICS: {
    data: [],
    isLoading: false,
    isSuccess: false,
    selectedTag: null,
  },
  FEEDBACK: {
    lastUpdatedAt: '',
    lastShowedAt: '',
  },
  COMPANY_SHARED_SPACE: {
    teamSpaces: [],
    selectedTeamSpaceInfo: {
      subscription: {
        checkoutSessionId: null,
        customerPortalUrl: null,
        checkoutSessionStatus: false,
        isLoading: false,
        paypalStatus: false,
        stripePackageChanged: false,
        unsubscribeModal: undefined,
        package: 'FREE',
      },
      _id: '',
      teamId: '',
      name: '',
      ownerId: '',
      ownerPicture: '',
      email: '',
      teamSpaceMembers: [],
      createdAt: '',
      updatedAt: '',
      __v: 0,
      spaceMembers: [],
      ownerName: '',
    },
    createTeamspace: {},
    renameTeamSpace: {},
    isRegisteredUser: false,
    companySharedSpaceUsers: [],
    sendEmail: {},
    createRenameSpace: {},
    EditPromptSpace: {},
  },
  REQUEST_RECORDINGS: {
    allRequests: [],
  },
  FILTERED_RECORDINGS: {
    date: '',
    status: '',
    title: '',
  },
  FILTERED_TEAMMATES: {
    status: '',
    role: '',
  },
  NOTICE_BANNER: {
    noticeText: '',
    buttonLink: '',
  },
  ASK_AI: {},
  IMPORT_FILE: {
    success: false,
    message: '',
  },
  SELECTED_VIDEO: {},
};

export default STORE_INITIATE_VALUE;
