import { call, put, takeEvery } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { surveyUnubscribeReason } from '../../services/analyticsService';

function* sendSurveyUnubscribeReason(action) {
  try {
    const response = yield call(surveyUnubscribeReason, action.data);
    yield put({
      type: REDUX_ACTIONS.SEND_SURVEY_UNSUBSCRIBE_REASON_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SEND_SURVEY_UNSUBSCRIBE_REASON_FAIL,
      data: { message: error.message },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* unsubscribeReasonSaga() {
  yield takeEvery(
    REDUX_ACTIONS.SEND_SURVEY_UNSUBSCRIBE_REASON,
    sendSurveyUnubscribeReason
  );
}
