import { Decoder, tools, Reader } from 'ts-ebml';
import { v4 } from 'uuid';
import debug from 'debug';
import { reportError } from './errorReports';

const log = debug('app:MatroskaCueGenerator');

function readAsArrayBuffer(blob: Blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      if (reader.result !== null && typeof reader.result !== 'string') {
        resolve(reader.result);
      } else {
        reject();
      }
    };
    reader.onerror = () => {
      log(reader.error);
      reject(reader.error);
    };
  });
}

export default class MatroskaCueGenerator {
  private decoder = new Decoder();

  private reader = new Reader();

  private lockId: string;

  private async locked(callback) {
    return navigator.locks.request(`matroska-cue-gen_${this.lockId}`, callback);
  }

  constructor() {
    // Per-instance lock; helps to achieve a "synchronized" block
    this.lockId = v4();
  }

  async add(chunk: Blob) {
    return this.locked(async () => {
      log('meta: add');
      try {
        const ab =
          'arrayBuffer' in chunk
            ? await chunk.arrayBuffer()
            : await readAsArrayBuffer(chunk);
        // TODO find limit? https://stackoverflow.com/a/72124984/3977826
        // TODO can readAsArrayBuffer work with that limit?
        const elements = this.decoder.decode(ab);
        elements.forEach(this.reader.read, this.reader);
      } catch (error) {
        log(error);
        reportError('handled.MatroskaCueGenerator', error);
      }
    });
  }

  async end(): Promise<{
    newMetadata: ArrayBuffer | undefined;
    oldMetadataSize: number;
    duration: number;
  }> {
    return this.locked(async () => {
      this.reader.stop();
      const nanosec = this.reader.duration * this.reader.timestampScale;
      const sec = nanosec / 1000 / 1000 / 1000;
      let seekableMetadata: ArrayBuffer | undefined;
      try {
        // Should be unreachable but we don't trust ts-ebml
        seekableMetadata = tools.makeMetadataSeekable(
          this.reader.metadatas,
          this.reader.duration,
          this.reader.cues
        );
      } catch (error) {
        log(error);
        reportError('handled.MatroskaCueGenerator', error);
      }
      const result = {
        newMetadata: seekableMetadata,
        oldMetadataSize: this.reader.metadataSize,
        duration: sec,
      };

      log('meta: end', result);
      return result;
    });
  }
}
