import { call, put, takeEvery } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  getFeedbackData,
  surveySubscribeFeedback,
  updateFeedbackLastShowedDate,
  updateFeedbackLastUpdatedAndLastShowedDate,
} from '../../services/feedbackService';
import { getUserProfileRequest } from '../../features/auth/authSlice';

function* sendSurveySubscribeFeedback(action) {
  try {
    const response = yield call(surveySubscribeFeedback, action.data);
    yield put({
      type: REDUX_ACTIONS.SEND_SURVEY_SUBSCRIBE_FEEBACK_SUCCESS,
      data: response,
    });
    yield put(getUserProfileRequest());
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SEND_SURVEY_SUBSCRIBE_FEEBACK_FAIL,
      data: { message: error.message },
    });
  }
}

function* getPaidUsersFeedbackData() {
  try {
    const response = yield call(getFeedbackData);
    const updatedDate = response?.data?.data;

    yield put({
      type: REDUX_ACTIONS.GET_FEEDBACK_DATA_SUCCESS,
      data: updatedDate,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_FEEDBACK_DATA_FAIL,
      data: { message: error.message },
    });
  }
}

function* updatePaidUsersFeedbackLastUpdatedAndLastShowedDate() {
  try {
    const response = yield call(updateFeedbackLastUpdatedAndLastShowedDate);
    const updatedDate = response?.data?.data;

    yield put({
      type:
        REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_SUCCESS,
      data: updatedDate,
    });
  } catch (error) {
    yield put({
      type:
        REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_FAIL,
      data: { message: error.message },
    });
  }
}

function* updatePaidUsersFeedbackLastShowedDate() {
  try {
    const response = yield call(updateFeedbackLastShowedDate);
    const updatedDate = response?.data?.data;

    yield put({
      type: REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_SHOWED_DATE_SUCCESS,
      data: updatedDate,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_SHOWED_DATE_FAIL,
      data: { message: error.message },
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* feedbackSaga() {
  yield takeEvery(
    REDUX_ACTIONS.SEND_SURVEY_SUBSCRIBE_FEEBACK,
    sendSurveySubscribeFeedback
  );
  yield takeEvery(REDUX_ACTIONS.GET_FEEDBACK_DATA, getPaidUsersFeedbackData);
  yield takeEvery(
    REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE,
    updatePaidUsersFeedbackLastUpdatedAndLastShowedDate
  );
  yield takeEvery(
    REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_SHOWED_DATE,
    updatePaidUsersFeedbackLastShowedDate
  );
}
