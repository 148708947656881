import api from '../lib/baseAxios';

export const resetPasswordURL = (data) => {
  return api.get(`account/resetpassword/?token=${data}`);
};

export const accountConfirmationURL = (data: {
  token: string;
  ownerId: string;
  sharedSpaceType: string;
  role: string;
  teamId: string;
}) => {
  return api.get(
    `account/verify/?token=${data.token}&ownerId=${data.ownerId}&role=${data.role}&sharedSpaceType=${data.sharedSpaceType}&teamId=${data.teamId}`
  );
};
