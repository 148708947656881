import api from '../lib/baseAxios';

export const userSignUpURL = (data) => {
  return api.post('account/register', data);
};

export const userResendVerificationEmail = (data) => {
  return api.post('account/register/resend-email', data);
};

export const userSignInURL = (data) => {
  return api.post('account/login', data);
};

export const deleteAccountURL = (data) => {
  return api.post('account/delete', data);
};

export const userProfileURL = () => {
  return api.get('account/profile');
};

export const postUserProfileURL = (data) => {
  return api.post('account/profile', data);
};

export const getUsageURL = (teamId) => {
  return api.get(`users/usage?teamId=${teamId}`);
};

export const usageURL = (data, teamId) => {
  return api.post(`users/usage?teamId=${teamId}`, data);
};

export const forgetPasswordURL = (data) => {
  return api.post('account/forgot', data);
};

export const resetPasswordURL = (data) => {
  return api.post(`account/reset/${data.token}`, data);
};

export const refreshAuthTokenURL = () => {
  return api.get(`account/jwt/refresh`);
};

export const clearFirstTimeUserFlag = () => {
  return api.post('account/first-time');
};

export const getSettingsURL = () => {
  return api.get('settings');
};

export const updateSettingsURL = ({ type, data }) => {
  switch (type) {
    case 'plugin':
      return api.post('settings/update/plugin', data);
    case 'record':
      return api.post('settings/update/record-settings', data);
    default:
      return null;
  }
};

export const ackNewFeatures = (data) => {
  return api.post('account/ack-new-features', data);
};

export const getAggregatedData = () => {
  return api.get('aggregated-data');
};
