/**
 * This is the first experment to migrate from redux to redux-toolkit
 */
/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface ITrimmerState {
  isVisible: boolean;
  // TODO: Move this to selected video
  videoDuration: number;
}

const initialState = {
  isVisible: false,
  videoDuration: 0,
};

export const trimmerSlice = createSlice({
  name: 'trimmer',
  initialState,
  reducers: {
    setTrimmerVisibility: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
    setVideoDuration: (state, action: PayloadAction<number>) => {
      // TODO: Move this to selected video
      state.videoDuration = action.payload;
    },
  },
});

export const { setTrimmerVisibility, setVideoDuration } = trimmerSlice.actions;
export default trimmerSlice.reducer;
