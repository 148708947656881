// eslint-disable-next-line import/named
import { createTheme, Theme as MaterialUITheme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    common: {
      black: '#000000DE',
      white: '#FFFFFF',
    },
    // red
    primary: {
      main: '#E83489',
      light: '#FF0547',
      dark: '#912982',
      contrastText: '#FFFFFF',
    },
    background: {
      default:
        'linear-gradient(126.16deg, #912982 -31.12%, #E83489 29.93%, #FF0547 92.03%)',
      paper:
        'linear-gradient(126.16deg, #571661 -31.12%, #BF0067 29.93%, #E40024 92.03%)',
    },
    // text: {
    //   primary: 'linear-gradient(to right, tomato, cyan)',
    // },
    // gray
    secondary: {
      main: '#363739',
      light: '#ebebeb',
      dark: '#5c5c5c',
      contrastText: '#FFFFFF',
    },
    // background: {
    //   default: '#F3F3F3',
    //   paper: '#FFFFFF',
    // },
    warning: {
      main: '#F79804DE',
      contrastText: '#fff',
    },
    info: {
      main: '#3696F3',
      contrastText: '#707070',
    },
    error: {
      main: '#F44336',
      contrastText: '#707070',
    },
    success: {
      main: '#4CAF51',
      contrastText: '#707070',
    },
    text: {
      primary: '#363739',
      hint: '#888282',
    },
  },
  typography: {
    fontFamily: 'poppins',
    h1: {
      fontSize: '3.052rem',
    },
    h2: {
      fontSize: '2.441rem',
    },
    h3: {
      fontSize: '1.953rem',
    },
    h4: {
      fontSize: '1.563rem',
    },
    h5: {
      fontSize: '1.25rem',
    },
    h6: {
      fontSize: '1rem',
    },
    body1: {
      fontSize: '0.8rem',
    },
    body2: {
      fontSize: '0.7rem',
    },
    subtitle1: {
      fontSize: '0.8rem',
      fontFamily: 'Inter',
      fontWeight: 'bold',
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#363739',
    },
    subtitle2: {
      fontSize: '0.8rem',
      fontFamily: 'Inter',
      fontWeight: 'normal',
      lineHeight: '20px',
      letterSpacing: '0em',
      textAlign: 'left',
      color: '#707070',
    },
  },
});

export type Theme = MaterialUITheme;
export default theme;
