import { connect } from 'react-redux';
import PluginAudioSettingComponent from '../../components/Plugin/AudioSetting/PluginAudioSettingComponent';

function mapStateToProps(state) {
  return {
    recorder: state.recorder,
    browser: state.browser,
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(PluginAudioSettingComponent);
