import debug from 'debug';

const log = debug('app:intercom');

interface SetupIdentityOptions {
  appId: string;
  email: string;
  createdAt: number;
  name: string;
  /**
   * ScreenApp-given id
   */
  _id: string;
  intercomHmac: string;
}

interface TrackEventOptions {
  action: string;
  /**
   * A dictionary with upto 10 intercom-supported metadata fields
   */
  data: any;
}

export const setupIdentity = ({
  appId,
  email,
  createdAt,
  name,
  _id,
  intercomHmac,
}: SetupIdentityOptions) => {
  try {
    window.Intercom = window.Intercom || (() => {});
    window.Intercom('boot', {
      app_id: appId,
      email,
      created_at: createdAt,
      name,
      user_id: _id,
      user_hash: intercomHmac,
    });
  } catch (error) {
    log("Couldn't configure intercom", error);
  }
};

export const trackPageView = () => {
  try {
    window.Intercom('update');
  } catch (error) {
    log("IntecomUtils: Couldn't track page view", error);
  }
};

export const trackEvent = (a: TrackEventOptions) => {
  // A module-level variable may sometimes not work properly (e.g, Intercom(..) instead of window.Intercom)
  try {
    window.Intercom('trackEvent', a.action, a.data);
  } catch (error) {
    log("IntecomUtils: Couldn't track event", error);
  }
};

export const cleanup = () => {
  try {
    window.Intercom('shutdown');
    window.Intercom = undefined;
  } catch (error) {
    log("IntecomUtils: Couldn't cleanup", error);
  }
};

export const openChat = () => {
  try {
    if (window.Intercom) {
      window.Intercom('show');
    }
  } catch (error) {
    log("IntecomUtils: Couldn't open chat", error);
  }
};
