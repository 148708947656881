// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.OPEN_MENU_APPBAR, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.OPEN_MENU_APPBAR:
      return {
        ...state,
        openMenuAppbar: action.data,
      };

    default:
      return state;
  }
};
