import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { fetchTeamSpaceInfoSuccess } from '../features/auth/authSlice';
import { authenticatePluginSuccess } from '../features/plugin/pluginSlice';

export default (state = STORE_INITIATE_VALUE.COMPANY_SHARED_SPACE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CHECK_REGISTERED_USER_SUCCESS:
      return { ...state, isRegisteredUser: true };
    case REDUX_ACTIONS.CREATE_FOLDER_ERROR:
    case REDUX_ACTIONS.RENAME_FOLDER_ERROR:
      return {
        ...state,
        createRenameSpace: { success: false, message: action.data.error },
      };
    case REDUX_ACTIONS.CREATE_FOLDER_SUCCESS:
    case REDUX_ACTIONS.RENAME_FOLDER_SUCCESS:
      return {
        ...state,
        createRenameSpace: { success: true, message: '' },
      };
    case REDUX_ACTIONS.CREATE_RENAME_SPACE_CLOSE_NOTIFICATION:
      return {
        ...state,
        createRenameSpace: {},
      };
    case REDUX_ACTIONS.EDIT_FOLDER_PROMPT_SUCCESS:
      return {
        ...state,
        EditPromptSpace: { success: true, message: '' },
      };
    case REDUX_ACTIONS.EDIT_FOLDER_PROMPT_ERROR:
      return {
        ...state,
        EditPromptSpace: { success: false, message: action.data.error },
      };
    case REDUX_ACTIONS.EDIT_PROMPT_SPACE_CLOSE_NOTIFICATION:
      return {
        ...state,
        EditPromptSpace: {},
      };
    case REDUX_ACTIONS.CHECK_REGISTERED_USER_FAIL:
      return { ...state, isRegisteredUser: false };
    case REDUX_ACTIONS.ADD_TO_COMPANY_SHARED_SPACE_SUCCESS:
      return {
        ...state,
        companySharedSpaceUsers: action.data.data.teamSpaceMembers,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          teamSpaceMembers: action.data.data.teamSpaceMembers,
          spaceMembers: action.data.data.spaceMembers,
        },
      };
    case REDUX_ACTIONS.ADD_TO_COMPANY_SHARED_SPACE_FAIL:
      return state;
    case REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS:
    case REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_SEND_FAIL:
      return { ...state, sendEmail: action.data };
    case REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS:
    case REDUX_ACTIONS.COMPANY_SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL:
      return { ...state, sendEmail: action.data };
    case REDUX_ACTIONS.CLOSE_SHARED_SPACE_INVITATION_NOTIFICATION:
      return { ...state, sendEmail: { success: undefined } };
    case REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_SUCCESS:
      return {
        ...state,
        companySharedSpaceUsers: action.data,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          teamSpaceMembers: action.data,
        },
      };
    case REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_STATUS_FAIL:
      return state;
    case REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_SUCCESS:
      return {
        ...state,
        companySharedSpaceUsers: action.data,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          teamSpaceMembers: action.data,
        },
      };
    case REDUX_ACTIONS.REMOVE_FROM_SPACE_SUCCESS:
      return {
        ...state,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          spaceMembers: action.data,
        },
      };
    case REDUX_ACTIONS.CHANGE_SPACE_USER_STATUS_SUCCESS:
      return {
        ...state,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          spaceMembers: action.data,
        },
      };
    case REDUX_ACTIONS.ADD_TO_SPACE_SUCCESS:
      return {
        ...state,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          spaceMembers: action.data,
        },
      };
    case REDUX_ACTIONS.CHANGE_COMPANY_SHARED_SPACE_USER_ROLE_FAIL:
      return state;
    case REDUX_ACTIONS.DELETE_COMPANY_SHARED_SPACE_USER_SUCCESS:
      return {
        ...state,
        companySharedSpaceUsers: action.data,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          teamSpaceMembers: action.data,
        },
      };
    case REDUX_ACTIONS.DELETE_COMPANY_SHARED_SPACE_USER_FAIL:
      return state;
    case REDUX_ACTIONS.CREATE_TEAM_SPACE_SUCCESS:
      return {
        ...state,
        createTeamspace: { success: true, message: '' },
      };
    case REDUX_ACTIONS.CREATE_TEAM_SPACE_ERROR:
      return {
        ...state,
        createTeamspace: action.data,
      };
    case REDUX_ACTIONS.CREATE_TEAM_SPACE_CLOSE_NOTIFICATION:
      return {
        ...state,
        createTeamspace: {},
      };
    case fetchTeamSpaceInfoSuccess.type:
      return {
        ...state,
        selectedTeamSpace: {
          _id: action.payload._id,
          teamId: action.payload.teamId,
          name: action.payload.name,
        },
        selectedTeamSpaceInfo: action.payload,
        companySharedSpaceUsers: action.payload.teamSpaceMembers,
      };
    case REDUX_ACTIONS.FETCH_ALL_TEAM_SPACES_SUCCESS:
      return {
        ...state,
        teamSpaces: action.data,
      };
    case REDUX_ACTIONS.FETCH_LAST_SELECTED_TEAM_SPACE_SUCCESS:
      return {
        ...state,
        selectedTeamSpace: {
          _id: action.data._id,
          teamId: action.data.teamId,
          name: action.data.name,
        },
        selectedTeamSpaceInfo: action.data,
        companySharedSpaceUsers: action.data.teamSpaceMembers,
      };
    case REDUX_ACTIONS.RENAME_TEAM_SPACE_SUCCESS:
      return {
        ...state,
        teamSpaces: action.data.teamSpaces,
        renameTeamSpace: { success: true, message: '' },
      };
    case REDUX_ACTIONS.RENAME_TEAM_SPACE_FAIL:
      return {
        ...state,
        renameTeamSpace: { success: false, message: action.data.error },
      };
    case REDUX_ACTIONS.RENAME_TEAM_SPACE_CLOSE_NOTIFICATION:
      return {
        ...state,
        renameTeamSpace: {},
      };
    case authenticatePluginSuccess.type:
      return {
        ...state,
        selectedTeamSpaceInfo: {
          ...state.selectedTeamSpaceInfo,
          teamId: action.payload.teamId || '',
        },
      };
    default:
      return state;
  }
};
