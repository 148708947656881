// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import { userSignUpResponse } from '../features/auth/authSlice';

export default (state = STORE_INITIATE_VALUE.EMAIL_ROUTE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.API_POST_PASSWORD_RESET_REQUEST:
      return {
        ...state,
        isLoading: true,
        responseStatus: 'none',
      };
    case REDUX_ACTIONS.API_POST_PASSWORD_RESET_RESPONSE:
      return {
        ...state,
        data: action.data.data,
        isLoading: false,
        responseStatus: action.data.success ? 'success' : 'fail',
      };

    case REDUX_ACTIONS.API_POST_ACCOUNT_CONFIRMATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        responseStatus: 'none',
      };
    case userSignUpResponse.type:
      return {
        ...state,
        data: action.payload.data,
        isLoading: false,
        responseStatus: action.payload.success ? 'success' : 'fail',
      };
    case REDUX_ACTIONS.API_POST_ACCOUNT_CONFIRMATION_RESPONSE:
      return {
        ...state,
        data: action.data.data,
        isLoading: false,
        responseStatus: action.data.success ? 'success' : 'fail',
      };

    case REDUX_ACTIONS.API_POST_ACCEPT_SHARED_SPACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        responseStatus: 'none',
      };
    case REDUX_ACTIONS.API_POST_ACCEPT_SHARED_SPACE_RESPONSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        responseStatus: 'success',
      };
    case REDUX_ACTIONS.API_POST_ACCEPT_SHARED_SPACE_RESPONSE_FAIL:
      return {
        ...state,
        isLoading: false,
        responseStatus: 'fail',
      };

    case REDUX_ACTIONS.API_POST_ACCEPT_COMPANY_SHARED_SPACE_REQUEST:
      return {
        ...state,
        isLoading: true,
        responseStatus: 'none',
      };
    case REDUX_ACTIONS.API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        responseStatus: 'success',
      };
    case REDUX_ACTIONS.API_POST_ACCEPT_COMPANY_SHARED_SPACE_RESPONSE_FAIL:
      return {
        ...state,
        isLoading: false,
        responseStatus: 'fail',
      };

    case REDUX_ACTIONS.CLEAR_LOADING_STATE_EMAIL_ROUTE:
      return {
        isLoading: false,
        responseStatus: 'none',
      };

    default:
      return state;
  }
};
