/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { PLUGIN_RECORDING_STATUS } from '../../../constants/apiSagaConstant';
import BasicButton from '../../StorybookComponents/BasicButton';
import { initPluginRecording } from '../../../features/plugin/pluginSlice';

import './styling.scss';

const RecordingInfo = (props) => {
  const { pluginRecording } = props;

  const [title, setTitle] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [errors, setErrors] = useState({
    email: false,
    name: false,
    title: false,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setName(pluginRecording.name);
    setEmail(pluginRecording.email);
    setTitle(pluginRecording.title);
  }, []);

  const validateEmail = (input) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const result = reg.test(input);
    const b1 = input === '';
    const error = !result && !b1;

    return error;
  };

  const handleEmailChange = (value) => {
    const isError = validateEmail(value);
    setEmail(value);
    setErrors({ ...errors, email: isError });
  };

  const startPluginRecordingOnClick = () => {
    const data = {
      email,
      name,
      title,
      status: PLUGIN_RECORDING_STATUS.NOT_READY,
    };
    dispatch(initPluginRecording(data));
  };

  return (
    <div className="root">
      <TextField
        id="recordTitle"
        label="Record Title"
        variant="outlined"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        id="name"
        label="Name"
        variant="outlined"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        id="email"
        label="Email"
        variant="outlined"
        value={email}
        helperText={errors.email ? 'Please enter a correct email address.' : ''}
        error={errors.email}
        onChange={(e) => handleEmailChange(e.target.value)}
      />

      <BasicButton
        label="Start Recording"
        onClick={startPluginRecordingOnClick}
        id="plugin_start_recording"
        roundBorder
      />
    </div>
  );
};

RecordingInfo.propTypes = {
  pluginRecording: PropTypes.shape({
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default RecordingInfo;
