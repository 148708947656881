import React, { memo } from 'react';
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  // eslint-disable-next-line import/named
  TextFieldProps,
  // eslint-disable-next-line import/named
  Theme,
} from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
// import clsx from 'clsx';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      margin: theme.spacing(1),
      fontSize: '14px',
      fontFamily: 'Inter',
      '& em': {
        fontStyle: 'normal !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '13px 18px',
        backgroundColor: theme.palette.common.white,
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        opacity: 38,
      },
      '&:hover': {
        fontStyle: 'normal !important',
        opacity: 38,
      },
      '& .MuiFormLabel-root MuiInputLabel-root': {
        color: 'black !important',
      },
      '& .MuiFormLabel-root.Mui-focused': {
        // color: theme.palette.secondary.light,
        // color: 'black',
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(18px, 13px) scale(1)',
        fontSize: '14px',
        fontFamily: 'Inter',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(14px, -6px) scale(0.75)',
        backgroundColor: theme.palette.common.white,
        padding: '0 5px',
      },
    },
    helperText: {
      margin: theme.spacing(1),
    },
    normalFont: {
      fontStyle: 'normal !important',
    },
    borderBottomInMenu: {
      borderBottom: '1px solid #717572',
    },
    centerInputLabel: {
      padding: '0.3rem 0.5rem !important',
      marginLeft: '-13px !important',
    },
    select: {
      '& .MuiChip-root': {
        height: 'auto',
        padding: '3px 0',
        marginRight: '3px',
      },
      '& .MuiChip-label': {
        fontSize: '14px',
        lineHeight: 1.1,
        fontFamily: 'Inter',
      },
    },
    listItemText: {
      margin: 0,
      '& span': {
        fontSize: '14px',
        lineHeight: 1.1,
        fontFamily: 'Inter',
      },
    },
  });
};

export interface ISelectTextFieldProps
  extends WithStyles<TextFieldProps & typeof styles> {
  id?: string;
  onChange?: any;
  value: any;
  label?: string;
  selectItems: Array<any>;
  error?: boolean;
  normalFontInMenuItems?: boolean;
  dividerInMenuItems?: boolean;
  contentBelowDropDownBtn?: boolean;
  centerTextInputLabel?: boolean;
  width?: string;
  multiple?: boolean;
  checkBoxChecked?: any;
  renderValue?: any;
}

export const SelectTextField: React.FC<ISelectTextFieldProps> = ({
  classes,
  id,
  label,
  value,
  onChange,
  selectItems,
  error,
  // normalFontInMenuItems,
  dividerInMenuItems,
  contentBelowDropDownBtn,
  centerTextInputLabel,
  width,
  multiple,
  checkBoxChecked,
  renderValue,
}: ISelectTextFieldProps) => {
  return (
    <FormControl
      variant="outlined"
      className={classes.root}
      error={error}
      style={{ width: `${width || '100%'}` }}
    >
      {centerTextInputLabel ? (
        <InputLabel className={classes.centerInputLabel} id={id} shrink={false}>
          {value ? '' : label}
        </InputLabel>
      ) : (
        <InputLabel id={id}>{label}</InputLabel>
      )}

      <Select
        className={classes.select}
        defaultValue={value}
        onChange={onChange}
        placeholder={label}
        multiple={multiple}
        renderValue={renderValue}
        MenuProps={
          contentBelowDropDownBtn
            ? {
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }
            : undefined
        }
      >
        {selectItems.map((data) => {
          return (
            <MenuItem
              value={data}
              {...(dividerInMenuItems ? { divider: true } : { divider: false })}
            >
              {multiple && checkBoxChecked && (
                <Checkbox checked={checkBoxChecked.indexOf(data) > -1} />
              )}
              <ListItemText className={classes.listItemText} primary={data} />
              {/* <em className={clsx(normalFontInMenuItems && classes.normalFont)}>
                {data}
              </em> */}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>This is required!</FormHelperText>}
    </FormControl>
  );
};

export default memo(withStyles(styles)(SelectTextField));
