// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import {
  stopRecordingButtonClick,
  stopRecordingFailure,
  stopRecordingSuccess,
} from '../features/recorder/recorderSlice';

export default (state = STORE_INITIATE_VALUE.SPINNER_INITIATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FETCHING:
    case REDUX_ACTIONS.TRIM_VIDEO:
      return { ...state, value: true };
    case REDUX_ACTIONS.FETCHING_SUCCESS:
    case REDUX_ACTIONS.FETCHING_FAILURE:
    case REDUX_ACTIONS.COMPLETE_TRIM_VIDEO:
    case REDUX_ACTIONS.USER_VIDEO_TRIM_SUCCESS:
    case REDUX_ACTIONS.USER_VIDEO_TRIM_FAIL:
    case REDUX_ACTIONS.TRIM_VIDEO_FAIL:
    case REDUX_ACTIONS.TRIM_VIDEO_SUCCESS:
      return { ...state, value: false };
    case stopRecordingButtonClick.type:
      return { ...state, value: true };
    case stopRecordingSuccess.type:
      return { ...state, value: false, uploadingProgressBar: false };
    case stopRecordingFailure.type:
      return { ...state, value: false, uploadingProgressBar: false };
    case REDUX_ACTIONS.UPLOAD_PROGRESS_BAR_START:
      return { ...state, value: false, uploadingProgressBar: true };
    case REDUX_ACTIONS.UPLOAD_PROGRESS_BAR_STOP:
      return { ...state, value: false, uploadingProgressBar: false };
    default:
      return state;
  }
};
