/* eslint-disable import/named */
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogActions,
  DialogContent,
  // eslint-disable-next-line import/named
  DialogProps,
  // eslint-disable-next-line import/named
  Theme,
  // eslint-disable-next-line import/named
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line import/named
import { makeStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { MaxWidth } from '../types';
import BasicButton from '../BasicButton';

const ErrorIcon = require('../../../assets/icons/Error.svg');
const WarningIcon = require('../../../assets/icons/Warning.svg');
const InformationIcon = require('../../../assets/icons/Information.svg');
const SuccessIcon = require('../../../assets/icons/Success.svg');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiPaper-rounded': {
        borderRadius: '12px',
      },
      '& .MuiPaper-root': {
        backgroundColor: '#fff',
        padding: theme.spacing(4),
        maxWidth: 575,
        boxShadow: '0px 3px 10px 0px rgba(0, 0, 0, 0.16)',
      },
    },
    title: {
      fontWeight: 700,
      fontFamily: 'Inter',
      fontSize: '24px',
      color: '#363739',
    },
    dialogContent: {
      padding: 0,
      fontFamily: 'Inter',
      fontSize: '16px',
      color: '#707070',
      margin: '32px 0',
    },
    dialogContentWithoutAction: {
      paddingBottom: theme.spacing(2),
      margin: '32px 0',
      fontFamily: 'Inter',
      fontSize: '16px',
      color: '#707070',
      padding: 0,
    },
    dialogActions: {
      padding: 0,
    },
    icon: {
      position: 'absolute',
      right: '16px',
      top: '15px',
      color: '#888282',
      '& .MuiSvgIcon-fontSizeInherit': {
        fontSize: 30,
      },
      '& .MuiIconButton-root:hover': {
        color: 'black',
      },
    },
    alertIcon: {
      width: 44,
      height: 44,
      marginRight: 15,
    },
    actionButtonsContainer: {
      // margin: theme.spacing(1),
    },
    actionButtonsCenter: {
      justifyContent: 'center',
    },
    leftButton: {
      marginRight: 14,
    },
  })
);

export type AlertType = 'Error' | 'Warning' | 'Information' | 'Success';

export interface IAlertComponentProps extends DialogProps {
  id?: string;
  handleClose?: React.MouseEventHandler<HTMLButtonElement>;
  isTimeSet?: boolean;
  type: AlertType;
  title?: string;
  description?: any;
  fullWidth?: boolean;
  maxWidth?: MaxWidth;
  alertActions?: boolean;
  buttonsCenter?: boolean;
  isSingleButton?: boolean;
  actinButtonLabel?: string;
  actinButtonLabel2?: string;
  actinButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default function AlertComponent({
  id,
  description,
  handleClose,
  isTimeSet = true,
  type,
  title,
  fullWidth = true,
  maxWidth = 'xs',
  alertActions = true,
  buttonsCenter = false,
  isSingleButton = false,
  actinButtonLabel = 'Action',
  actinButtonLabel2 = 'Cancel',
  actinButtonOnClick,
}: IAlertComponentProps) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (isTimeSet) {
      const timeId = setTimeout(() => {
        setShow(false);
      }, 60000); // 1min

      return () => {
        clearTimeout(timeId);
      };
    }
    return undefined;
  }, []);

  if (!show) {
    return null;
  }

  const getIcon = () => {
    if (type === 'Error') {
      return (
        <img className={classes.alertIcon} src={ErrorIcon} alt="error-icon" />
      );
    }
    if (type === 'Warning') {
      return (
        <img
          className={classes.alertIcon}
          src={WarningIcon}
          alt="warning-icon"
        />
      );
    }
    if (type === 'Information') {
      return (
        <img
          className={classes.alertIcon}
          src={InformationIcon}
          alt="info-icon"
        />
      );
    }
    if (type === 'Success') {
      return (
        <img
          className={classes.alertIcon}
          src={SuccessIcon}
          alt="success-icon"
        />
      );
    }
    return undefined;
  };

  const closeDialog = (e) => {
    if (handleClose) {
      handleClose(e);
    }
    setOpen(false);
  };

  return (
    <Dialog
      id={id}
      onClose={closeDialog}
      open={open}
      className={classes.root}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <div className={classes.icon}>
        <IconButton
          aria-label="close"
          color="inherit"
          size="small"
          onClick={closeDialog}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </div>
      {title ? (
        <Typography className={classes.title} variant="h4">
          {getIcon()} {title}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h4">
          {getIcon()} {type}!
        </Typography>
      )}
      <DialogContent
        className={
          alertActions
            ? classes.dialogContent
            : classes.dialogContentWithoutAction
        }
      >
        {description}
      </DialogContent>
      {alertActions && (
        <DialogActions
          className={clsx(
            classes.dialogActions,
            buttonsCenter && classes.actionButtonsCenter
          )}
        >
          <div className={classes.actionButtonsContainer}>
            {!isSingleButton && (
              <BasicButton
                label={actinButtonLabel2}
                id={`${id}-action-button`}
                filled={false}
                noMargin
                roundBorder
                onClick={closeDialog}
                customClass={classes.leftButton}
              />
            )}
            <BasicButton
              label={actinButtonLabel}
              id={`${id}-action-button-2`}
              noMargin
              filled
              roundBorder
              onClick={actinButtonOnClick}
            />
          </div>
        </DialogActions>
      )}
    </Dialog>
  );
}
