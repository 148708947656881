/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import uuid from 'uuidv4';
import { clearRecordingState } from '../recorder/recorderSlice';
import STORE_INITIATE_VALUE from '../../constants/initialstore';

const appSlice = createSlice({
  name: 'app',
  initialState: STORE_INITIATE_VALUE.APP_INITIATE,
  reducers: {
    getAggregatedDataRequest: (state) => {
      state.loading = 'pending';
    },
    getAggregatedDataResponse: (
      state,
      acion: PayloadAction<{ success: boolean }>
    ) => {
      const { success } = acion.payload;
      state.loading = success ? 'succeeded' : 'failed';
    },
  },
  extraReducers: {
    [clearRecordingState.type]: (state) => {
      state.uuid = uuid();
    },
  },
});

export const {
  getAggregatedDataRequest,
  getAggregatedDataResponse,
} = appSlice.actions;

export default appSlice.reducer;
