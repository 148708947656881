/* eslint-disable import/prefer-default-export */

export class BufferManager {
  private _total = 0;

  public get total() {
    return this._total;
  }

  private _sent = 0;

  public get sent() {
    return this._sent;
  }

  public get remaining() {
    return this._total - this._sent;
  }

  private queue: Blob[] = [];

  add(data: Blob): void {
    this._total += data.size;
    this.queue.push(data);
  }

  consolidate(chunkSize: number = 0): void {
    if (this.queue.length === 0) {
      return;
    }

    if (chunkSize === 0) {
      this.queue = [new Blob(this.queue)];
      return;
    }

    const i0 = this.queue.findIndex((blob) => blob.size !== chunkSize);
    if (
      i0 < 0 || // Not found
      (i0 >= this.queue.length - 1 &&
        this.queue[this.queue.length - 1].size < chunkSize) // It's the last one and it's smaller than chunk size
    ) {
      return;
    }

    const blob = new Blob(this.queue.slice(i0));
    const newQueue: Blob[] = [];
    for (let start = 0; start < blob.size; start += chunkSize) {
      const chunk = blob.slice(start, start + chunkSize);
      newQueue.push(chunk);
    }
    this.queue.splice(i0, this.queue.length - i0, ...newQueue);
  }

  getAll(): Blob[] {
    return [...this.queue];
  }

  confirm(bytesSent: number): void {
    if (bytesSent + this._sent > this._total) {
      throw new Error('Bytes sent exceeds total.');
    }

    let confirmed = bytesSent;

    for (let i = 0; i < this.queue.length; i += 1) {
      if (this.queue[i].size <= confirmed) {
        confirmed -= this.queue[i].size;
        this.queue.splice(i, 1);
        i -= 1;
      } else {
        this.queue[i] = this.queue[i].slice(confirmed);
        break;
      }
    }

    this._sent += bytesSent;
  }

  isEmpty(): boolean {
    return this.queue.length === 0;
  }
}
