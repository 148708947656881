import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.OPEN_BANNERS_INITIATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.OPEN_BANNER:
      return {
        ...state,
        ...action.data,
      };
    case REDUX_ACTIONS.CLOSE_BANNER:
      return STORE_INITIATE_VALUE.OPEN_BANNERS_INITIATE;
    default:
      return state;
  }
};
