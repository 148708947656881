import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.NOTICE_BANNER, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FETCH_NOTICE_BANNER_SUCCESS:
      return {
        ...state,
        noticeText: action.data.noticeText,
        buttonLink: action.data.buttonLink,
      };

    default:
      return state;
  }
};
