import debug from 'debug';
import { put, takeLatest, call, select, takeEvery } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  userSignUpURL,
  userSignInURL,
  userProfileURL,
  forgetPasswordURL,
  resetPasswordURL,
  postUserProfileURL,
  clearFirstTimeUserFlag,
  getSettingsURL,
  updateSettingsURL,
  ackNewFeatures,
  refreshAuthTokenURL,
  userResendVerificationEmail,
  usageURL,
  deleteAccountURL,
  getUsageURL,
  getAggregatedData,
} from '../../services/userAdmissionServices';
import { setToken } from '../../lib/localStorageHandler';
import { setHeader } from '../../lib/baseAxios';
import { updateLastActiveAt } from '../../services/otherServices';
import {
  getSettingsFail,
  getSettingsSuccess as getSettingsSuccessRecorder,
  updateRecordSettingsSuccess as updateRecordSettingsSuccessRecorder,
} from '../../features/recorder/recorderSlice';
import {
  acknowledgeNewFeaturesSuccess,
  clearFirstTimeUserFlagSuccess,
  getUserProfileResponse,
  updatePackageConfig,
  updateLastActiveDateSuccess,
  updateSettingsFail,
  getSettingsSuccess as getSettingsSuccessAuth,
  userSignUpResponse,
  userResendEmailVerificationResponse,
  updateUsageSuccess,
  deleteAccountSuccess,
  getRecordingCountSuccess,
  updateRecordSettingsSuccess as updateRecordSettingsSuccessAuth,
  updateRecordSettingsFail,
  getUserProfileRequest,
  updateSettings,
  updateSettingsSuccess,
} from '../../features/auth/authSlice';
import {
  getAggregatedDataRequest,
  getAggregatedDataResponse,
} from '../../features/app/appSlice';

const log = debug('app:src:saga:Common:userAdmissionSaga.js');

// const isFirstTimeUser = (state) => state?.auth?.user?.data?.isFirstTimeUser;

function* userSignUp(action) {
  try {
    const response = yield call(userSignUpURL, action.data);
    if (response.status === 201) {
      yield call(setToken, response.data.data.accessToken);
      yield call(setHeader, response.data.data.accessToken);
      yield put(userSignUpResponse(response.data));
      yield put(getAggregatedDataRequest());
    } else {
      yield put(
        userSignUpResponse(
          response.data || {
            success: false,
            data: null,
            message: 'Registration Failed. Please try again later.',
          }
        )
      );
    }
  } catch (e) {
    yield put(
      userSignUpResponse(
        e.response.data || {
          success: false,
          data: null,
          message: 'Registration Failed. Please try again later.',
        }
      )
    );
  }
}

function* deleteAccount(action) {
  try {
    const response = yield call(deleteAccountURL, action.data);
    if (response.status === 200) {
      yield put(deleteAccountSuccess());
    } else {
      yield put({
        type: REDUX_ACTIONS.DELETE_ACCOUNT_FAIL,
        data: response.data || {
          success: false,
          data: null,
          message: 'Delete account failed. Please try again later.',
        },
      });
      yield put({
        type: REDUX_ACTIONS.OPEN_BANNER,
        data: {
          deleteAccountAlertType: 'deleteAccountFail',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.DELETE_ACCOUNT_FAIL,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Delete account failed. Please try again later.',
      },
    });
    yield put({
      type: REDUX_ACTIONS.OPEN_BANNER,
      data: {
        deleteAccountAlertType: 'deleteAccountFail',
      },
    });
  }
}

function* userResendEmail(action) {
  try {
    const response = yield call(userResendVerificationEmail, action.data);
    if (response.status === 200) {
      yield call(setToken, response.data.data.accessToken);
      yield call(setHeader, response.data.data.accessToken);
      yield put(userResendEmailVerificationResponse(response.data));
    } else {
      yield put(
        userResendEmailVerificationResponse(
          response.data || {
            success: false,
            data: null,
            message: 'Registration Failed. Please try again later.',
          }
        )
      );
    }
  } catch (e) {
    yield put(
      userResendEmailVerificationResponse(
        e.response.data || {
          success: false,
          data: null,
          message: 'Registration Failed. Please try again later.',
        }
      )
    );
  }
}

function* userSignIn(action) {
  try {
    const response = yield call(userSignInURL, action.data);
    if (response.status === 200) {
      yield call(setToken, response.data.data.token);
      yield call(setHeader, response.data.data.token);
      yield put({
        type: REDUX_ACTIONS.USER_SIGN_IN_RESPONSE,
        data: response.data,
      });
      yield put(getAggregatedDataRequest());
    } else {
      yield put({
        type: REDUX_ACTIONS.USER_SIGN_IN_RESPONSE,
        data: response.data || {
          success: false,
          data: null,
          message: 'Something went wrong. Please try again later.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.USER_SIGN_IN_RESPONSE,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Something went wrong. Please try again later.',
      },
    });
  }
}

function* userAuthTokenRefresh() {
  try {
    const response = yield call(refreshAuthTokenURL);
    if (response.status === 200) {
      yield call(setToken, response.data.data.token);
      yield call(setHeader, response.data.data.token);
    }
  } catch (e) {
    log(`Unable to refresh token: ${e.message}`);
  }
}

function* forgotPassword(action) {
  try {
    const response = yield call(forgetPasswordURL, action.data);
    if (response.status === 201) {
      yield put({
        type: REDUX_ACTIONS.FORGET_PASSWORD_RESPONSE,
        data: response.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.FORGET_PASSWORD_RESPONSE,
        data: response.data || {
          success: false,
          data: null,
          message: 'Something went wrong. Please try again later.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.FORGET_PASSWORD_RESPONSE,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Something went wrong. Please try again later.',
      },
    });
  }
}

function* resetPassword(action) {
  try {
    const response = yield call(resetPasswordURL, action.data);
    if (response.status === 201) {
      yield put({
        type: REDUX_ACTIONS.RESET_PASSWORD_RESPONSE,
        data: response.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.RESET_PASSWORD_RESPONSE,
        data: response.data || {
          success: false,
          data: null,
          message: 'Something went wrong. Please try again later.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.RESET_PASSWORD_RESPONSE,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Something went wrong. Please try again later.',
      },
    });
  }
}

function* getProfile() {
  try {
    const response = yield call(userProfileURL);
    if (response.status === 200) {
      const currentUserPackage = response.data?.data?.packageConfig; // TODO: Make this fail safe
      if (response?.data?.data?.features?.plugin) {
        currentUserPackage.plugin = true;
      }
      yield put({
        type: REDUX_ACTIONS.GET_SETTINGS,
      });

      // XXX Since we don't show a pricing page on first load now, clear the
      // first time user flag here.
      if (response?.data?.data?.isFirstTimeUser) {
        yield put({ type: REDUX_ACTIONS.CLEAR_FIRST_TIME_USER_FLAG });
      }

      yield put(getUserProfileResponse(response.data));
    } else {
      yield put(
        getUserProfileResponse(
          response.data || {
            success: false,
            data: { package: 'FREE', id: '' },
            message: 'Something went wrong. Please try again later.',
          }
        )
      );
    }
  } catch (e) {
    yield put(
      getUserProfileResponse(
        e.response.data || {
          success: false,
          data: { package: 'FREE', id: '' },
          message: 'Something went wrong. Please try again later.',
        }
      )
    );
  }
}

function* loadInitialData() {
  try {
    const aggregatedDataResponse = yield call(getAggregatedData);
    if (aggregatedDataResponse.status === 200) {
      const data = aggregatedDataResponse?.data?.data || {};
      const {
        profile: profileResponseData,
        settings: settingsResponseData,
        recordingCount: recordingCountResponseData,
        unsubscribeModal: unsubscribeModalResponseData,
        lastActiveAt: lastActiveAtResponseData,
        selectedTeamspace: selectedTeamspaceResponseData,
        teamspaces: teamspacesResponseData,
        teamspaceFiles: teamspaceFilesResponseData,
      } = data;
      // This is an intermediate step to move to the new API response format.
      const profileResponse = {
        data: {
          success: profileResponseData.success,
          data: profileResponseData?.data,
          message: profileResponseData.message,
        },
      };

      const currentUserPackage = profileResponse.data?.data?.packageConfig; // TODO: Make this fail safe
      const limitedPackage = currentUserPackage;
      if (profileResponse?.data?.data?.features?.plugin) {
        currentUserPackage.plugin = true;
        if (currentUserPackage.libraryTabs.indexOf('plugin') === -1) {
          // currentUserPackage.libraryTabs.push('plugin');
        }
      }
      // Settings
      if (settingsResponseData && settingsResponseData.data) {
        yield put(getSettingsSuccessAuth(settingsResponseData?.data?.settings));
        yield put(
          getSettingsSuccessRecorder(settingsResponseData?.data?.settings)
        );
      }

      if (currentUserPackage) {
        yield put(
          updatePackageConfig({
            packageConfig: currentUserPackage,
            limitedPackageConfig: limitedPackage,
          })
        );
      }

      // XXX Since we don't show a pricing page on first load now, clear the
      // first time user flag here.
      if (profileResponse?.data?.data?.isFirstTimeUser) {
        yield put({ type: REDUX_ACTIONS.CLEAR_FIRST_TIME_USER_FLAG });
      }

      yield put(getRecordingCountSuccess({ data: recordingCountResponseData }));
      if (unsubscribeModalResponseData.success) {
        yield put({
          type: REDUX_ACTIONS.GET_UNSUBSCRIBE_MODAL_SUCCESS,
          data: unsubscribeModalResponseData.data,
        });
      }
      yield put({
        type: REDUX_ACTIONS.UPDATE_LAST_ACTIVE_DATE_SUCCESS,
        data: { data: lastActiveAtResponseData },
      });
      updateLastActiveDateSuccess(lastActiveAtResponseData);
      // Teamspaces
      if (
        selectedTeamspaceResponseData &&
        selectedTeamspaceResponseData.data &&
        selectedTeamspaceResponseData.data.teamId
      ) {
        yield put({
          type: REDUX_ACTIONS.FETCH_LAST_SELECTED_TEAM_SPACE_SUCCESS,
          data: selectedTeamspaceResponseData.data,
        });
      }
      yield put({
        type: REDUX_ACTIONS.FETCH_ALL_TEAM_SPACES_SUCCESS,
        data: teamspacesResponseData?.data,
      });
      // FileSystem
      if (
        teamspaceFilesResponseData &&
        teamspaceFilesResponseData.data &&
        teamspaceFilesResponseData.data.fileSystem
      ) {
        yield put({
          type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES_SUCCESS,
          data: {
            fileSystem: teamspaceFilesResponseData.data.fileSystem?.filter(
              (f) => f.type === 'File'
            ),
          },
        });
        yield put({
          type: REDUX_ACTIONS.FETCH_TEAMSPACE_FOLDERS_SUCCESS,
          data: {
            teamFolders: teamspaceFilesResponseData.data.fileSystem?.filter(
              (f) => f.type === 'Folder'
            ),
          },
        });
      }

      yield put(getUserProfileResponse(profileResponse.data));
      yield put(getAggregatedDataResponse({ success: true }));
      yield put(
        getAggregatedDataResponse({
          success: true,
        })
      );
    } else {
      yield put(
        getUserProfileResponse({
          success: false,
          data: { package: 'FREE', id: '' },
          message: 'Something went wrong. Please try again later.',
        })
      );
    }
  } catch (e) {
    yield put(
      getUserProfileResponse(
        e.response.data || {
          success: false,
          data: { package: 'FREE', id: '' },
          message: 'Something went wrong. Please try again later.',
        }
      )
    );
    yield put(
      getAggregatedDataResponse({
        success: false,
      })
    );
  }
}

function* clearFirstTimeUserFlagGenerator() {
  yield put(clearFirstTimeUserFlagSuccess());
  // This call takes a bit of time so do it after setting status
  yield call(clearFirstTimeUserFlag);
}

function* postProfile(action) {
  try {
    const response = yield call(postUserProfileURL, action.data);
    if (response.status === 200) {
      yield put({
        type: REDUX_ACTIONS.POST_USER_PROFILE_RESPONSE,
        data: response.data,
      });
      yield put(getUserProfileRequest(response.data));
    } else {
      yield put({
        type: REDUX_ACTIONS.POST_USER_PROFILE_RESPONSE,
        data: response.data || {
          success: false,
          data: null,
          message: 'User profile update faild. Please try again later.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.POST_USER_PROFILE_RESPONSE,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'User profile update faild. Please try again later.',
      },
    });
  }
}

function* updateUsage(action) {
  try {
    const teamId = yield select(
      (state) => state.companySharedSpace.selectedTeamSpace.teamId
    );
    const response = yield call(usageURL, action.data, teamId); // action.data - {property1: increment, property2: increment, ...}
    if (response.status === 200) {
      yield put(updateUsageSuccess(response.data));
    } else {
      yield put({
        type: REDUX_ACTIONS.UPDATE_USAGE_FAILURE,
        data: response.data || {
          success: false,
          data: null,
          message: 'update usage failed.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.UPDATE_USAGE_FAILURE,
      data: {
        success: false,
        data: null,
        message: 'update usage failed.',
      },
    });
  }
}

function* getUsage() {
  try {
    const teamId = yield select(
      (state) => state.companySharedSpace.selectedTeamSpace.teamId
    );
    const response = yield call(getUsageURL, teamId);
    if (response.status === 200) {
      yield put(updateUsageSuccess(response.data));
    } else {
      yield put({
        type: REDUX_ACTIONS.GET_USAGE_FAILURE,
        data: response.data || {
          success: false,
          data: null,
          message: 'Get usage failed.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.GET_USAGE_FAILURE,
      data: {
        success: false,
        data: null,
        message: 'Get usage failed.',
      },
    });
  }
}

function* getSettings() {
  try {
    const response = yield call(getSettingsURL);
    if (response.status === 200) {
      yield put(getSettingsSuccessAuth(response.data?.data?.settings));
      yield put(getSettingsSuccessRecorder(response.data?.data?.settings));
    } else {
      yield put(
        getSettingsFail(
          response.data || {
            success: false,
            data: null,
          }
        )
      );
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.POST_USER_PROFILE_RESPONSE,
      data: e.response?.data || {
        success: false,
        data: null,
      },
    });
  }
}

function* updateSettingsGenerator(action) {
  try {
    const response = yield call(updateSettingsURL, action.payload);
    if (response.status === 200) {
      const data = {
        settings: response.data?.data?.settings?.recorderSettings || {},
        type: response.data?.data?.type,
        message: 'Record setting has been updated',
        notChange: true,
      };
      yield put(updateSettingsSuccess(data));
    } else {
      yield put(
        updateSettingsFail({
          message: 'Something went wrong!',
          notChange: false,
        })
      );
    }
  } catch (e) {
    yield put(
      updateSettingsFail(
        e.response.data || {
          message: 'Something went wrong!',
        }
      )
    );
  }
}

function* updateRecordSettings(action) {
  try {
    // XXX Avoid persisting devices temporarily
    // Achieves the same as MR !1168
    const userId = yield select((state) => state.auth?.user?.data?.id);
    if (!userId || action.data.source === 'device-change') {
      yield put(
        updateRecordSettingsSuccessAuth({
          settings: action.data?.data,
          message: 'Record setting has been updated',
          notChange: true,
        })
      );
      yield put(
        updateRecordSettingsSuccessRecorder({
          recorderSettings: action.data.data,
          message: 'Record setting has been updated',
          notChange: true,
        })
      );
      return;
    }
    const response = yield call(updateSettingsURL, action.data);
    if (response.data.success) {
      yield put(
        updateRecordSettingsSuccessAuth({
          settings: response.data?.data?.settings,
          message: 'Record setting has been updated',
          notChange: true,
        })
      );
      yield put(
        updateRecordSettingsSuccessRecorder({
          message: 'Record setting has been updated',
          notChange: true,
          recorderSettings: response.data?.data?.settings.recorderSettings,
        })
      );
    } else {
      updateRecordSettingsFail({ message: 'Something went wrong!' });
    }
  } catch (e) {
    console.log(action, e);
    updateRecordSettingsFail({ message: e.message });
  }
}

function* acknowledgeNewFeatures(action) {
  try {
    const response = yield call(ackNewFeatures, action.data);
    if (response.status === 200) {
      yield put(acknowledgeNewFeaturesSuccess(response.data));
    } else {
      yield put({
        type: REDUX_ACTIONS.ACKNOWLEDGE_NEW_FEATURES_ERROR,
        data: response.data || {
          success: false,
          data: null,
          message: 'Something went wrong. Please try again later.',
        },
      });
    }
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.ACKNOWLEDGE_NEW_FEATURES_ERROR,
      data: e.response.data || {
        success: false,
        data: null,
        message: 'Something went wrong. Please try again later.',
      },
    });
  }
}

function* updatingLastActiveAt() {
  try {
    const response = yield call(updateLastActiveAt);
    yield put({
      type: REDUX_ACTIONS.UPDATE_LAST_ACTIVE_DATE_SUCCESS,
      data: response,
    });
    updateLastActiveDateSuccess(response);
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.UPDATE_LAST_ACTIVE_DATE_SUCCESS,
      data: { message: error.message },
    });
    updateLastActiveDateSuccess(error.data);
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* takeLatestUserProfileSaga() {
  yield takeLatest(REDUX_ACTIONS.USER_SIGN_UP_REQUEST, userSignUp);
  yield takeLatest(REDUX_ACTIONS.DELETE_ACCOUNT_REQUEST, deleteAccount);
  yield takeLatest(
    REDUX_ACTIONS.USER_RESEND_EMAIL_VERIFICATION_REQUEST,
    userResendEmail
  );
  yield takeLatest(getUserProfileRequest.type, getProfile);
  yield takeLatest(REDUX_ACTIONS.POST_USER_PROFILE_REQUEST, postProfile);
  yield takeEvery(REDUX_ACTIONS.UPDATE_USAGE_REQUEST, updateUsage);
  yield takeEvery(REDUX_ACTIONS.GET_USAGE_REQUEST, getUsage);
  yield takeLatest(REDUX_ACTIONS.FORGET_PASSWORD_REQUEST, forgotPassword);
  yield takeLatest(REDUX_ACTIONS.USER_SIGN_IN_REQUEST, userSignIn);
  yield takeLatest(
    REDUX_ACTIONS.USER_REFRESH_TOKEN_REQUEST,
    userAuthTokenRefresh
  );
  yield takeLatest(REDUX_ACTIONS.RESET_PASSWORD_REQUEST, resetPassword);
  yield takeLatest(
    REDUX_ACTIONS.CLEAR_FIRST_TIME_USER_FLAG,
    clearFirstTimeUserFlagGenerator
  );
  yield takeLatest(REDUX_ACTIONS.GET_SETTINGS, getSettings);
  yield takeLatest(updateSettings.type, updateSettingsGenerator);
  yield takeLatest(
    REDUX_ACTIONS.ACKNOWLEDGE_NEW_FEATURES_REQUEST,
    acknowledgeNewFeatures
  );
  yield takeLatest(REDUX_ACTIONS.UPDATE_RECORD_SETTINGS, updateRecordSettings);
  yield takeLatest(REDUX_ACTIONS.UPDATE_LAST_ACTIVE_DATE, updatingLastActiveAt);
  yield takeLatest(getAggregatedDataRequest.type, loadInitialData);
}
