/* eslint-disable import/named */
import React, { ReactNode } from 'react';
import {
  DialogContent,
  Theme,
  ModalProps,
  Popover,
  PopoverProps,
} from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      '& .MuiPaper-rounded': {
        borderRadius: '12px',
      },
    },
    dialogContent: {
      paddingBottom: theme.spacing(2),
      paddingRight: theme.spacing(4),
    },
  });
};

export interface IModalProps extends WithStyles<PopoverProps & typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: ModalProps['onClose'];
  children?: ReactNode;
  modalTitle?: string;
  actionButtons?: ReactNode;
  disableBackdropClick?: boolean;
  anchorE1?: any;
}

export const BasicModal: React.FC<IModalProps> = ({
  classes,
  id,
  open,
  children,
  handleClose,
  anchorE1,
  disableBackdropClick = false,
}: IModalProps) => {
  return (
    <Popover
      id={id}
      onClose={(e, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        handleClose?.(e, reason);
      }}
      open={open}
      className={classes.root}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'center',
        horizontal: 'center',
      }}
      anchorEl={anchorE1}
    >
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Popover>
  );
};

export default withStyles(styles)(BasicModal);
