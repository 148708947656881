import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import {
  unsupportedBrowserDetected,
  getDesktopMediaFailure,
  stopRecordingFailure,
} from '../features/recorder/recorderSlice';

export default (state = STORE_INITIATE_VALUE.NOTIFICATION_INITIATE, action) => {
  switch (action.type) {
    case getDesktopMediaFailure.type: {
      return {
        ...state,
        text: 'Could not access your screen. Please try again !',
        visibility: true,
      };
    }
    case stopRecordingFailure.type: {
      return {
        ...state,
        text:
          action.data && action.payload
            ? action.payload
            : 'Could not stop media stream. Please reload !',
        visibility: true,
      };
    }
    case unsupportedBrowserDetected.type: {
      return {
        ...state,
        text: action.data.message,
        visibility: true,
      };
    }
    case REDUX_ACTIONS.MACOS_PERMISSION_DENIED: {
      return {
        ...state,
        text: 'Macos permission denied',
        visibility: false,
      };
    }
    default:
      return {
        ...state,
        text: '',
        visibility: false,
      };
  }
};
