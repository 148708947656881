/* eslint-disable import/prefer-default-export */
import { put, takeLatest, call, select } from 'redux-saga/effects';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  deleteChunksByRecordingId,
  deleteRawVideo,
  deleteUnfinishedVideoRecord,
  downloadFile,
  listUnfinishedVideos,
  recoverFromRawVideo,
  recoverUnfinishedVideoFromChunks,
  saveRecording,
} from '../../lib/storageUtils';
import { getThumbnail } from '../../lib/ffmpegWrapper';

export function* listRecoverableFiles() {
  yield put({
    type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
  });

  const userId = yield select((state: any) => state?.auth?.user?.data?.id);
  const unfinishedVideos = (yield call(listUnfinishedVideos)).filter(
    (v: { userId?: string }) => !v.userId || v.userId === userId
  );
  unfinishedVideos.sort(
    (a: { start: number }, b: { start: number }) =>
      (b.start || 0) - (a.start || 0)
  );

  yield put({
    type: REDUX_ACTIONS.RECOVER_FILES_LIST_RESPONSE,
    data: unfinishedVideos,
  });
}

export function* recoverVideo(action) {
  const recordingId = action.data?.recordingId;
  const userId = yield select((state: any) => state?.auth?.user?.data?.id);

  const blob: Blob = action.data?.end
    ? // Video corrupted while finishing
      yield call(recoverFromRawVideo, action.data)
    : // Accidentally closed the browser etc.
      yield call(recoverUnfinishedVideoFromChunks, recordingId);

  const url = window.URL.createObjectURL(blob);
  yield call(downloadFile, url);

  try {
    const timestamp = Date.now();
    const thumbnail = yield call(getThumbnail, blob, 500); // generate thumbnail
    yield call(saveRecording, {
      recordingId,
      video: blob,
      userId,
      title: `Recovered_${timestamp}`,
      timestamp,
      ...(thumbnail && { thumbnail }),
    });
    yield put({
      type: REDUX_ACTIONS.RECOVER_FILE_RESPONSE,
      data: { ...action.data, failedFileSize: 0 },
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.RECOVER_FILE_RESPONSE,
      data: { ...action.data, failedFileSize: blob?.size },
    });
  }
}

export function* deleteRecoveryEntry(action) {
  if (action.data?.end) {
    yield call(deleteRawVideo, action.data?.recordingId);
  } else {
    yield call(deleteChunksByRecordingId, action.data?.recordingId);
  }
  yield call(deleteUnfinishedVideoRecord, action.data?.recordingId);
  yield put({
    type: REDUX_ACTIONS.RECOVER_DELETE_RESPONSE,
    data: action.data,
  });
}

export function* recoverSaga() {
  yield takeLatest(
    REDUX_ACTIONS.RECOVER_FILES_LIST_REQUEST,
    listRecoverableFiles
  );
  yield takeLatest(REDUX_ACTIONS.RECOVER_FILE_REQUEST, recoverVideo);
  yield takeLatest(REDUX_ACTIONS.RECOVER_DELETE_REQUEST, deleteRecoveryEntry);
}
