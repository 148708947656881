/**
 * Author :Buddhika Jayawardhana
 * This file contains the functions for media tranformation
 */

import { Decoder, tools, Reader } from 'ts-ebml';
import debug from 'debug';

const log = debug('app:mediaOperationUtils');

/**
 * Reads blob as an Array Buffer
 * @param {Blob} blob
 */
function readAsArrayBuffer(blob): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsArrayBuffer(blob);
    reader.onloadend = () => {
      if (reader.result !== null && typeof reader.result !== 'string') {
        resolve(reader.result);
      } else {
        reject();
      }
    };
    reader.onerror = () => {
      log(reader.error);
      reject(reader.error);
    };
  });
}

const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd']; // This is from elm type of the lib

/**
 * Returns seekable video blob(with end time)
 * @param {NonseekableWebmBlob} blob
 */
// eslint-disable-next-line import/prefer-default-export
export async function getSeekableVideo(blob) {
  const decoder = new Decoder();
  const reader = new Reader();
  reader.logging = true;
  reader.logGroup = 'Raw WebM file';
  reader.drop_default_duration = false;
  const webMBuf = await readAsArrayBuffer(blob);
  let elms = decoder.decode(webMBuf);
  // Why: https://gitlab.com/meetrix/products/screenapp/online-screen-recorder/-/merge_requests/1016#note_1103033390
  elms = elms?.filter((elm) => validEmlType.includes(elm.type));

  elms.forEach((elm) => {
    reader.read(elm);
  });
  reader.stop();
  const refinedMetadataBuf = tools.makeMetadataSeekable(
    reader.metadatas,
    reader.duration,
    reader.cues
  );
  const body = webMBuf.slice(reader.metadataSize);
  // eslint-disable-next-line no-undef
  const refinedWebM = new Blob([refinedMetadataBuf, body], {
    type: 'video/webm',
  });

  // const refinedVideo = document.getElementById('localVideo');
  // eslint-disable-next-line no-undef
  // refinedVideo.src = URL.createObjectURL(refinedWebM);
  // refinedVideo.controls = true;
  // document.body.appendChild(refinedVideo);

  // Log the refined WebM file structure.
  const refinedDecoder = new Decoder();
  const refinedReader = new Reader();
  refinedReader.logging = true;
  refinedReader.logGroup = 'Refined WebM file';
  const refinedBuf = await readAsArrayBuffer(refinedWebM);
  const refinedElms = refinedDecoder.decode(refinedBuf);
  refinedElms.forEach((elm) => {
    refinedReader.read(elm);
  });
  refinedReader.stop();
  return refinedWebM;
}

// count number of available media input devices
export const countMediaDevices = async (
  deviceType: 'videoinput' | 'audioinput'
): Promise<number> => {
  let deviceCount = 0;
  await navigator.mediaDevices.enumerateDevices().then((allDevices) => {
    allDevices.forEach((device) => {
      if (device.kind === deviceType && device.label) {
        deviceCount += 1;
      }
    });
  });
  return deviceCount;
};
