import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.RECOVERY, action: any) => {
  switch (action.type) {
    case REDUX_ACTIONS.RECOVER_FILES_LIST_RESPONSE:
      return { ...state, files: action.data, populated: true };
    case REDUX_ACTIONS.RECOVER_CLEAR_FAILED_FLAG:
      return { ...state, failedFileSize: 0 };
    case REDUX_ACTIONS.RECOVER_FILE_RESPONSE:
      return {
        ...state,
        failedFileSize: action?.data?.failedFileSize,
        completed: [...state.completed, action.data?.recordingId],
      };
    case REDUX_ACTIONS.RECOVER_DELETE_RESPONSE:
      return {
        ...state,
        completed: state.completed.filter(
          (id) => id !== action.data?.recordingId
        ),
        files: state.files.filter(
          (f: any) => f.recordingId !== action.data?.recordingId
        ),
      };
    default:
      return state;
  }
};
