/**
 * Created by supun on 10/01/18.
 */
import {
  ACTION_KEY as KEYS,
  REDUX_ACTIONS as ACTIONS,
  URLS,
  HTTP_METHODS,
} from '../../constants/apiSagaConstant';

const metadata = {};

metadata[KEYS.TRIM_VIDEO] = {
  url: URLS.TRIM,
  options: {
    method: HTTP_METHODS.POST,
  },
  failureAction: ACTIONS.USER_VIDEO_TRIM_FAIL,
  successAction: ACTIONS.USER_VIDEO_TRIM_SUCCESS,
};

metadata[KEYS.SEND_FEEDBACK] = {
  url: URLS.FEEDBACK,
  options: {
    method: HTTP_METHODS.POST,
  },
  failureAction: ACTIONS.SEND_FEEDBACK_FAIL,
  successAction: ACTIONS.SEND_FEEDBACK_SUCCESS,
};

metadata[KEYS.TRACK_RECORDING] = {
  url: URLS.TRACK,
  options: {
    method: HTTP_METHODS.POST,
  },
  failureAction: ACTIONS.TRACK_RECORDING_FAIL,
  successAction: ACTIONS.TRACK_RECORDING_SUCCESS,
};

export default metadata;
