import { store } from '../store';
import { timmerUpdate, timmerReset } from '../actions/timmerAction';

let timerID;
let isTimerRunning = false;

export function startTimer() {
  if (!isTimerRunning) {
    timerID = setInterval(() => {
      store.dispatch(timmerUpdate());
    }, 1000);
    isTimerRunning = true;
  }
}

export function pauseTimer() {
  clearInterval(timerID);
  isTimerRunning = false;
}

export function resetTimer() {
  store.dispatch(timmerReset());
  clearInterval(timerID);
  isTimerRunning = false;
}

export async function toggleTimer(action) {
  if (action.payload.pause) {
    pauseTimer();
  } else {
    startTimer();
  }
}
