import { bindActionCreators } from 'redux';
import { actionCreateStoreUpdateFactory } from './actionCreator';
import {
  ACTION_ATTR as ATTRS,
  REDUX_ACTIONS as ACTION_TYPES,
} from '../constants/apiSagaConstant';
import { IUsage } from '../constants/userPackage';

export type UsageActionType = (data: Partial<IUsage>) => void;

export const getUpdateUsageAction = (dispatch): UsageActionType =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPDATE_USAGE_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );
