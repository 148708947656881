// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { beforeTrim, trimSuccess } from '../constants/appConstant';
import { clearRecordingState } from '../features/recorder/recorderSlice';
import { setCurrentUserDetails } from '../features/auth/authSlice';

export default (state = STORE_INITIATE_VALUE.MODAL_INITIATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.TOGGLE_MODAL:
      return { ...state, isOpen: !state.isOpen };
    case REDUX_ACTIONS.GOOGLE_AUTHENTICATION_REQUEST:
      return {
        ...state,
        ...beforeTrim,
        isOpen: true,
      };
    case setCurrentUserDetails.type:
      return {
        ...state,
        isOpen: false,
      };
    case REDUX_ACTIONS.USER_VIDEO_TRIM_SUCCESS:
      return {
        ...state,
        ...trimSuccess,
        isOpen: true,
      };
    case REDUX_ACTIONS.GO_BACK_FUNCTION:
      return {
        ...state,
        isOpen: false,
      };
    case REDUX_ACTIONS.TRIM_RE_TRY:
      return {
        ...state,
        isOpen: false,
      };
    case clearRecordingState.type:
      return {
        ...state,
        isOpen: false,
      };
    case REDUX_ACTIONS.SET_FEEDBACK_MODAL_OPEN:
      return {
        ...state,
        isFeedbackModalOpen: action.data,
      };
    default:
      return state;
  }
};
