/* eslint no-param-reassign: "error" */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import STORE_INITIATE_VALUE from '../../constants/initialstore';
import { GlobalSearchQuery } from '../../types';
import { searchRequest } from '../../services/fileSystemService';

export const globalSearchRequest = createAsyncThunk(
  'globalSearch/request',
  async (data: GlobalSearchQuery) => {
    const response = await searchRequest(data);
    return {
      ...data,
      results: response.data.data,
    };
  }
);

export const globalSearchSlice = createSlice({
  name: 'globalSearch',
  initialState: STORE_INITIATE_VALUE.GLOBAL_SEARCH,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(globalSearchRequest.fulfilled, (state, action) => {
      state.searchQuery = action.payload.searchQuery;
      state.startDate = action.payload?.startDate || null;
      state.endDate = action.payload?.endDate || null;
      state.results = action.payload.results;
    });
    builder.addCase(globalSearchRequest.rejected, (state) => {
      state.searchQuery = '';
      state.startDate = null;
      state.endDate = null;
      state.results = {};
    });
  },
});

export default globalSearchSlice.reducer;
