import { END, eventChannel } from '@redux-saga/core';
import MultipartUploadManager, {
  SetMetatdataArgsType,
} from './Uploader/MultipartUploadManager';
import MultipartUploader, { RecordingArgs } from './Uploader/MultipartUploader';
import api from './baseAxios';
import { reportError } from './errorReports';

let uploaderManager: MultipartUploadManager | null = null;

interface UploaderEvent {
  type: 'PROGRESS' | 'SUCCESS' | 'ERROR' | 'CONNECTED';
  data?: any; // Define more specifically if possible, e.g., ProgressEvent or Error
}

export type RecordingArgsWithToken = RecordingArgs & { token: string };

export const hasUploader = () => !!uploaderManager;

export const createUploaderEventChannel = () => {
  return eventChannel<UploaderEvent>((emit) => {
    if (!uploaderManager) {
      // Early return a no-operation unsubscribe function to satisfy TypeScript's requirement
      return () => {};
    }

    const onProgress = (progress: any) => {
      emit({ type: 'PROGRESS', data: progress });
    };
    const onClose = () => {
      emit({ type: 'SUCCESS' });
      emit(END);
    };
    const onError = (err: Error) => {
      emit({ type: 'ERROR', data: err });
    };

    uploaderManager.on('progress', onProgress);
    uploaderManager.on('close', onClose);
    uploaderManager.on('error', onError);

    setTimeout(() => emit({ type: 'CONNECTED' }), 0);

    return () => {
      uploaderManager?.removeListener('progress', onProgress);
      uploaderManager?.removeListener('close', onClose);
      uploaderManager?.removeListener('error', onError);
      uploaderManager = null;
    };
  });
};

export const createUploader = (data: RecordingArgsWithToken) => {
  const {
    token,
    recorderName = '',
    recorderEmail = '',
    title,
    recordingId,
    folderId,
    teamId,
    profile,
    newMetadata = undefined,
    oldMetadataSize,
  } = data || {};

  if (!token) {
    throw new Error('Create uploader failed: token is missing.');
  }

  uploaderManager = new MultipartUploadManager(
    new MultipartUploader(api, recordingId, token),
    {
      recorderEmail,
      recorderName,
      title,
      recordingId,
      folderId,
      teamId,
      profile,
      newMetadata,
      oldMetadataSize,
    },
    {
      chunkSize: 10 * 2 ** 20,
      backoffFactor: 2,
      errorReporter: reportError,
    }
  );

  return createUploaderEventChannel();
};

export async function sendBlobStream(blob) {
  if (!hasUploader()) {
    return;
  }

  uploaderManager?.send(blob);
}

export const tryFinishRecord = async (args: SetMetatdataArgsType) => {
  if (!hasUploader()) {
    return;
  }

  uploaderManager?.setMetadata(args);
  await uploaderManager?.end();
};
