import { config } from '../../config';
import { LibraryTabType } from '../types';

export type Package =
  | 'PREMIUM'
  | 'STANDARD'
  | 'FREE_LOGGEDIN'
  | 'BUSINESS'
  | 'GROWTH'
  | 'FREE';

export interface IUsage {
  recordingRequestsCount: number;
  askAICount: number;
  transcriptionCount: number;
  copyTranscriptCount: number;
  mp4DownloadCount: number;
  webmDownloadCount: number;
  mkvDownloadCount: number;
  mp3DownloadCount: number;
}

export interface NumericLimits extends IUsage {
  recordingLength: number;
  localFileCount: number;
  cloudFileCount: number;
  librarySize: number;
  cloudStorageLimit: number;
}

export type NumericLimitsKeys = keyof NumericLimits;

export interface PackageLimits extends NumericLimits {
  isTreeViewEnabled: Boolean;
  libraryTabs: Array<String>;
  trimmer: Boolean;
  canUpload: Boolean;
  canShare: Boolean;
  canCloudSync: Boolean;
  plugin: Boolean;
  canRequest: Boolean;
}

export interface PackageProperties extends PackageLimits {
  id: string;
  title: string;
}

export const LIBRARY_TABS: LibraryTabType[] = ['team'];

export const USER_PACKAGE: { [key in Package]: Package } = {
  PREMIUM: 'PREMIUM',
  STANDARD: 'STANDARD',
  FREE_LOGGEDIN: 'FREE_LOGGEDIN',

  BUSINESS: 'BUSINESS',
  GROWTH: 'GROWTH',

  FREE: 'FREE',
};

// This is about enabling main routing/views for each package.
export const PERMISSION = {
  DASHBOARD: 'DASHBOARD',
  SIGN_IN: 'SIGN_IN',
  SIGN_UP: 'SIGN_UP',
  LIBRARY: 'LIBRARY',
  SHARE: 'SHARE',
  EDITOR: 'EDITOR',
  RECORDER: 'RECORDER',
};

export const PACKAGE_PERMISSION = {
  PREMIUM: ['DASHBOARD', 'SIGN_IN', 'SIGN_UP', 'LIBRARY', 'EDITOR', 'RECORDER'],
  STANDARD: [
    'DASHBOARD',
    'SIGN_IN',
    'SIGN_UP',
    'LIBRARY',
    'EDITOR',
    'RECORDER',
  ],
  FREE_LOGGEDIN: ['DASHBOARD', 'SIGN_IN', 'SIGN_UP', 'LIBRARY', 'RECORDER'],
  GROWTH: ['DASHBOARD', 'SIGN_IN', 'SIGN_UP', 'LIBRARY', 'EDITOR', 'RECORDER'],
  BUSINESS: [
    'DASHBOARD',
    'SIGN_IN',
    'SIGN_UP',
    'LIBRARY',
    'EDITOR',
    'RECORDER',
  ],
  FREE: ['SIGN_IN', 'SIGN_UP'],
};

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;

export const DEFAULT_PACKAGE_CONFIG: PackageProperties = {
  id: '2',
  title: 'Free Registered',
  recordingLength: 1 * HOUR,
  librarySize: 10,
  recordingRequestsCount: 5,
  askAICount: 5,
  transcriptionCount: 3,
  copyTranscriptCount: 1,
  localFileCount: 100,
  cloudFileCount: config.enableCloudSync ? Infinity : 10,
  webmDownloadCount: 3,
  mkvDownloadCount: 3,
  mp4DownloadCount: 0,
  mp3DownloadCount: 0,
  cloudStorageLimit: Infinity,
  isTreeViewEnabled: true,
  libraryTabs: ['team'], // Combined local, cloud, shared
  trimmer: true,
  canUpload: true,
  canShare: true,
  canCloudSync: true,
  plugin: false,
  canRequest: true,
};

export const packageOrder = ['FREE', 'FREE_LOGGEDIN', 'STANDARD', 'PREMIUM'];
export const gen2PackageOrder = ['FREE', 'FREE_LOGGEDIN', 'GROWTH', 'BUSINESS'];

const getBetterPackageGeneric = (packages) => (package1, package2) => {
  return packages.indexOf(package1) > packages.indexOf(package2)
    ? package1
    : package2;
};

export const getPackageGeneration = (pkg) =>
  packageOrder.includes(pkg) ? 1 : 2;

export const getHighestPackage = (p1, p2): Package => {
  if (p1 === p2) {
    return p1;
  }

  if (p1 === 'FREE') {
    return p2;
  }

  if (p2 === 'FREE') {
    return p1;
  }

  if (p1 === 'FREE_LOGGEDIN') {
    return p2;
  }

  if (p2 === 'FREE_LOGGEDIN') {
    return p1;
  }

  const currentPricingGeneration = config.enableGen2Pricing ? 2 : 1;

  const p1gen = getPackageGeneration(p1);
  const p2gen = getPackageGeneration(p2);
  const pricingGeneration = p1gen === p2gen ? p1gen : currentPricingGeneration;

  return getBetterPackageGeneric(
    pricingGeneration === 1 ? packageOrder : gen2PackageOrder
  )(p1, p2);
};

export const isAnUpgrade = (from: Package, to: Package) => {
  return (
    getPackageGeneration(from) > getPackageGeneration(to) ||
    (getHighestPackage(from, to) === to && from !== to)
  );
};

export const UPGRADE_TO_PREMIUM_MESSAGE_CLOUD = config.enableCloudSync
  ? 'Upgrade to a paid account to disable cloud backups'
  : 'Upgrade to a premium account and enjoy unlimited cloud backups';

export const UPGRADE_TO_PREMIUM_MESSAGE_COMMON =
  'Please upgrade your plan to enjoy this feature';

export const CANCEL_PLAN_FORM_URL = 'https://view.forms.app/screenapp/cancel';
