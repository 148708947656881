/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint no-param-reassign: "error" */
import { v4 as uuid } from 'uuid';
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import { CLOCK_STATUS, RECORDER_STATUS } from '../../constants/apiSagaConstant';
import {
  getMediaRecorderSupportedFormatProfile,
  defaultFormatProfilePreference,
} from '../../lib/browserCompatibility';
import { Media, RecorderSettings } from '../../types/state';
import { RecordingArgsWithToken } from '../../lib/streamUpload';
import { IUpdateSettingsSuccessData } from '../auth/authSlice';

export interface Recorder {
  settings: RecorderSettings;
  muteRecorderMic: boolean;
  muteRecorderBrowserAudio: boolean;
  description: string;
  displaySurface: any | null;
  isBrowserShareAudioEnable: boolean;
  openRecorder: boolean;
  audioRecorderEnable: boolean;
  pause: boolean;
  recordingId: string;
  recordTimeout: boolean;
  status: string;
  title: string;
  displayRecording: boolean;
  showFloatingRecorder: boolean;
  RecordingTimerStatus: string; // could be changed to CLOCK_STATUS once it's defined
  RecordingTimerTime: number;
  media: Media;
}

export const initialState: Recorder = {
  settings: {
    audioSettings: {
      browserAudio: false,
      microphoneAudio: false,
      systemAudio: false,
    },
    videoSettings: {
      webCam: false,
      screen: true,
    },
    deviceSettings: {
      webcamDeviceId: '',
      microphoneDeviceId: '',
    },
    recordingControlSettings: {
      startRecordingAuto: false,
      autoDownload: true,
      defaultRecordingFormat: 'mkv',
      maxRecordingTimeMins: 10,
    },
    uiSettings: {
      hideLogo: false,
      recorderButtonText: 'Share Your Screen',
      position: 'BOTTOM_LEFT',
      helpText: 'Feedbacks',
      identificationForm: true,
    },
    otherSettings: {
      webhookUrl: '',
      hideTitleAndDescriptionDialog: true,
      defaultTranscriptionLanguage: 'en',
      formatProfile: 'mkv',
      generatePublicUrl: false,
      downloadSharedVideos: false,
    },
  },
  muteRecorderMic: false,
  muteRecorderBrowserAudio: false,
  status: RECORDER_STATUS.NOT_READY,
  openRecorder: false,
  audioRecorderEnable: false,
  displaySurface: null,
  pause: false,
  recordingId: uuid(),
  title: '',
  description: '',
  recordTimeout: false, // The recording time is longer than the user package time duration
  isBrowserShareAudioEnable: false,
  displayRecording: true,
  showFloatingRecorder: false,
  RecordingTimerStatus: CLOCK_STATUS.STOPPED,
  RecordingTimerTime: 0,
  media: {
    isVideoStreamAvailable: false, // availability of the camera stream
    isDesktopStreamAvailable: false, // availability of the desktop stream
    videoSource: '', // this will store the recorded video url to be display on the screen after recording.
    error: '', // this will store the error message if any
    recordingSize: 0, // this will store the current recording size in bytes
  },
};

interface IUpdateRecordSettingsSuccessPayload {
  recorderSettings: RecorderSettings;
  message: string;
  notChange: boolean;
}

export interface IGoToRecorderData {
  uuid?: string;
}
export interface IToggleRecordingData {
  pause?: boolean;
}
interface IGetDesktopMediaSuccessData {
  audioSettings: {
    browserAudio: boolean;
    microphoneAudio: boolean;
  };
  videoSettings: {
    screen: boolean;
    webCam: boolean;
  };
  deviceSettings: {
    microphoneDeviceId;
    webcamDeviceId;
  };
  displaySurface: boolean;
  isBrowserShareAudioEnable: boolean;
  audioRecorderEnable: boolean;
}
interface IGetSettingsSuccessData {
  recorderSettings: RecorderSettings;
}
interface ISetAudioMuteActionData {
  microphoneAudio: boolean;
  browserAudio: boolean;
  muteRecorderMic: boolean;
  muteRecorderBrowserAudio: boolean;
}
interface IAudioSelectData {
  browserAudio?: boolean;
  microphoneAudio?: boolean;
}
interface IVideoSelectData {
  webCam: boolean;
  screen: boolean;
}
interface IWebcamSelectData {
  webcamEnable: boolean;
  webcamOnlyEnable: boolean;
}
interface IAudioOnlySelectData {
  audioRecorderEnable: boolean;
}
interface ISetRecordingTitleAndDescriptionSuccessData {
  title: string;
  description: string;
}
interface IRecordingErrorData {
  message: string;
}
interface ISetVideoDurationData {
  endTime: number;
}

const assignSettingsToState = (
  state: Recorder,
  recorderSettings: RecorderSettings
) => {
  if (!state || !state) return;
  const {
    videoSettings,
    audioSettings,
    recordingControlSettings,
    otherSettings,
  } = recorderSettings || {};
  const { screen, webCam } = videoSettings || {};
  const { browserAudio, microphoneAudio } = audioSettings || {};
  const { defaultRecordingFormat } = recordingControlSettings || {};
  const webcamOnlyEnable = !screen && webCam;
  Object.assign(state, {
    ...state,
    // ...record,
    settings: {
      ...state.settings,
      ...recorderSettings,
      otherSettings: {
        ...otherSettings,
        formatProfile: getMediaRecorderSupportedFormatProfile(
          [defaultRecordingFormat, ...defaultFormatProfilePreference],
          {
            video: true,
            audio: (browserAudio && !webcamOnlyEnable) || microphoneAudio,
          }
        ),
      },
    },
  });
};

const recorderSlice = createSlice({
  name: 'recorder',
  initialState,
  reducers: {
    unsupportedBrowserDetected(state) {
      state.status = RECORDER_STATUS.UNSUPPORTED;
    },
    getDesktopMediaSuccess(
      state,
      action: PayloadAction<IGetDesktopMediaSuccessData>
    ) {
      const {
        audioSettings,
        videoSettings,
        deviceSettings,
        displaySurface,
        isBrowserShareAudioEnable,
        audioRecorderEnable,
      } = action.payload;
      const { browserAudio, microphoneAudio } = audioSettings;
      Object.assign(state, {
        ...state,
        settings: {
          ...state.settings,
          audioSettings: {
            ...state.settings.audioSettings,
            ...audioSettings,
          },
          videoSettings,
          deviceSettings,
          otherSettings: {
            ...state.settings.otherSettings,
            formatProfile: getMediaRecorderSupportedFormatProfile(
              [
                state.settings.recordingControlSettings.defaultRecordingFormat,
                ...defaultFormatProfilePreference,
              ],
              {
                video: true,
                audio: browserAudio || microphoneAudio,
              }
            ),
          },
        },
        media: { ...state.media, isDesktopStreamAvailable: true },
        status: RECORDER_STATUS.READY,
        displaySurface,
        isBrowserShareAudioEnable,
        audioRecorderEnable,
      });
    },
    getSettingsSuccess(state, action: PayloadAction<IGetSettingsSuccessData>) {
      assignSettingsToState(state, action.payload?.recorderSettings || {});
    },
    updateRecordSettingsSuccess(
      state,
      action: PayloadAction<IUpdateRecordSettingsSuccessPayload>
    ) {
      // Adjust the state updates according to the payload structure provided in the action
      assignSettingsToState(state, action.payload?.recorderSettings || {});
    },
    getSettingsFail() {
      noop();
    },
    startRecordingButtonClick(
      state,
      action: PayloadAction<RecordingArgsWithToken>
    ) {
      state.status = RECORDER_STATUS.RUNNING;
      state.RecordingTimerStatus = CLOCK_STATUS.RUNNING;
    },
    stopRecordingButtonClick(state) {
      state.status = RECORDER_STATUS.STOPPED;
      state.displaySurface = null;
      state.RecordingTimerStatus = CLOCK_STATUS.STOPPED;
      state.showFloatingRecorder = false;
      state.muteRecorderBrowserAudio = false;
      state.muteRecorderMic = false;
    },
    setAudioMuteAction(state, action: PayloadAction<ISetAudioMuteActionData>) {
      state.muteRecorderMic = action.payload?.muteRecorderMic;
      state.muteRecorderBrowserAudio = action.payload?.muteRecorderBrowserAudio;
    },
    updateMicrophoneDeviceSuccess(state, action: PayloadAction<string>) {
      state.settings.deviceSettings.microphoneDeviceId = action.payload;
    },
    updateMicrophoneDeviceFail() {
      noop();
    },
    updateWebcamDeviceSuccess(state, action: PayloadAction<string>) {
      state.settings.deviceSettings.webcamDeviceId = action.payload;
    },
    updateWebcamDeviceFail() {
      noop();
    },
    goToRecorder(state, action: PayloadAction<IGoToRecorderData>) {
      state.openRecorder = true;
    },
    audioSelect(state, action: PayloadAction<IAudioSelectData>) {
      state.settings.audioSettings = {
        ...state.settings.audioSettings,
        ...(action.payload || {}),
      };
      state.settings.audioSettings.browserAudio =
        action.payload.browserAudio || false;
      state.settings.audioSettings.microphoneAudio =
        action.payload.microphoneAudio || false;
    },
    videoSelect(state, action: PayloadAction<IVideoSelectData>) {
      state.settings.videoSettings = action.payload;
    },
    clearRecordingState(state) {
      state.status = RECORDER_STATUS.NOT_READY;
      state.pause = false;
      state.recordTimeout = false;
      state.media.videoSource = '';
      state.media.recordingSize = 0;
    },
    toggleRecording(state, action: PayloadAction<IToggleRecordingData>) {
      state.pause = !state.pause;
    },
    toggleDisplayRecording(state) {
      state.displayRecording = !state.displayRecording;
    },
    webcamSelect(state, action: PayloadAction<IWebcamSelectData>) {
      const { webcamEnable, webcamOnlyEnable } = action.payload;
      state.settings.videoSettings.screen = !webcamOnlyEnable;
      state.settings.videoSettings.webCam = webcamEnable || webcamOnlyEnable;
    },
    audioOnlySelect(state, action: PayloadAction<IAudioOnlySelectData>) {
      const { audioRecorderEnable } = action.payload;
      state.audioRecorderEnable = audioRecorderEnable;
    },
    showFloatingRecorder(state) {
      state.showFloatingRecorder = true;
    },
    cancelRecording(state) {
      state.status = RECORDER_STATUS.NOT_READY;
      state.displaySurface = null;
      state.recordTimeout = false;
    },
    recordTimeout(state) {
      state.recordTimeout = true;
    },
    recordTimeoutAlertClose(state) {
      state.recordTimeout = false;
    },
    setRecordingTitleAndDescriptionSuccess(
      state,
      action: PayloadAction<ISetRecordingTitleAndDescriptionSuccessData>
    ) {
      state.title = action.payload.title;
      state.description = action.payload.description;
    },
    setRecordingTitleAndDescriptionFail() {
      noop();
    },
    timerUpdate(state) {
      state.RecordingTimerTime += 1000;
    },
    timerPause() {
      noop();
    },
    timerReset(state) {
      state.RecordingTimerTime = 0;
    },
    setVideoDuration(state, action: PayloadAction<ISetVideoDurationData>) {
      noop();
    },
    getDesktopMediaFailure: (state) => {
      state.media.isDesktopStreamAvailable = false;
    },
    stopRecordingSuccess: (
      state,
      action: {
        payload: {
          url: string;
        };
      }
    ) => {
      state.media.videoSource = action.payload.url;
      state.media.isDesktopStreamAvailable = false;
    },
    stopRecordingFailure: (state) => {
      state.media.isDesktopStreamAvailable = false;
    },
    recordingError: (state, action: PayloadAction<IRecordingErrorData>) => {
      state.media.error = action.payload?.message;
    },
    recordingErrorReset: (state) => {
      state.media.error = '';
    },
    recordingSizeChange: (state, action: PayloadAction<number>) => {
      state.media.recordingSize = action.payload;
    },
  },
});

// Export actions for use elsewhere
export const {
  unsupportedBrowserDetected,
  getDesktopMediaSuccess,
  getSettingsSuccess,
  updateRecordSettingsSuccess,
  getSettingsFail,
  startRecordingButtonClick,
  stopRecordingButtonClick,
  setAudioMuteAction,
  updateMicrophoneDeviceSuccess,
  updateMicrophoneDeviceFail,
  updateWebcamDeviceSuccess,
  updateWebcamDeviceFail,
  goToRecorder,
  audioSelect,
  videoSelect,
  webcamSelect,
  audioOnlySelect,
  showFloatingRecorder,
  cancelRecording,
  clearRecordingState,
  toggleRecording,
  toggleDisplayRecording,
  recordTimeout,
  recordTimeoutAlertClose,
  setRecordingTitleAndDescriptionSuccess,
  setRecordingTitleAndDescriptionFail,
  timerUpdate,
  timerPause,
  timerReset,
  setVideoDuration,
  getDesktopMediaFailure,
  stopRecordingSuccess,
  stopRecordingFailure,
  recordingError,
  recordingErrorReset,
  recordingSizeChange,
  // Add other exported actions here...
} = recorderSlice.actions;

// Export the reducer
export default recorderSlice.reducer;
