// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.LEFT_PANNEL_STATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.LEFT_PANNEL_STATUS:
      return {
        state: {
          ...state.state,
          messageShowStatus: false,
        },
      };

    case REDUX_ACTIONS.STAY_PAGE:
      return {
        state: {
          ...state.state,
          messageShowStatus: true,
        },
      };

    case REDUX_ACTIONS.LEAVE_PAGE:
      return {
        state: {
          ...state.state,
          messageShowStatus: true,
        },
      };
    case REDUX_ACTIONS.GOTO_CLICK_PAGE:
      return {
        state: {
          ...state.state,
          page: action.data,
        },
      };
    default:
      return state;
  }
};
