import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';

import { goToPricingUrl, goToSignupUrl } from '../../../lib/routingUtils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      height: '4rem',
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #E0E0E0',
      borderRadius: '7px',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '1rem',
      marginTop: '1rem',
      fontFamily: 'Inter',
    },
    leftDiv: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    packageName: {
      margin: 0,
      color: '#FF4757',
      fontSize: '1.2rem',
      fontWeight: 600,
    },
    description: {
      margin: 0,
      color: '#606571',
      fontSize: '0.7rem',
    },
    viewPlan: {
      margin: 0,
      color: '#FF4757',
      fontSize: '0.7rem',
      fontWeight: 600,
      '&:hover': {
        color: '#C52F31',
        borderColor: '#C52F31',
      },
    },
    viewPlanButton: {
      border: 'none',
      backgroundColor: 'transparent',
    },
    anchor: {
      '&:hover': {
        textDecoration: 'none',
      },
    },
    price: {
      fontSize: '1.2rem',
      fontWeight: 600,
      color: '#363739',
    },
    pricePara: {
      fontSize: '0.7rem',
      margin: 0,
      color: '#989898',
    },
  })
);

interface Item {
  plan: PlanType;
  description: string;
  price: string;
}

export type PlanType = 'Growth' | 'Business';

interface UpgradeBannerItemsProps {
  upgradePlans: PlanType[];
  isFreeUser: boolean;
}

const UpgradeBannerItem = (props: UpgradeBannerItemsProps) => {
  const classes = useStyles();
  const { upgradePlans, isFreeUser } = props;

  // only business plan for transcribe
  const isTranscribe = upgradePlans.length === 1;

  const plans: Item[] = [
    {
      plan: 'Growth',
      description: 'Create & organize content',
      price: '$7',
    },
    {
      plan: 'Business',
      description: 'Manage a content portfolio',
      price: '$15',
    },
  ];

  return (
    <>
      {plans
        .filter(({ plan }) => upgradePlans.includes(plan))
        .map(({ plan, description, price }) => {
          return (
            <div key={plan} className={classes.root}>
              <div>
                <p className={classes.packageName}>{plan}</p>
                <p className={classes.description}>{description}</p>
              </div>
              <p className={classes.pricePara}>
                us<span className={classes.price}>{price}</span>/Month
              </p>
              <button
                type="button"
                onClick={() => {
                  if (isFreeUser && !isTranscribe && plan === 'Business') {
                    return goToSignupUrl();
                  }

                  return goToPricingUrl();
                }}
                className={classes.viewPlanButton}
              >
                <p className={classes.viewPlan}>
                  {isFreeUser && !isTranscribe && plan === 'Business'
                    ? 'Try for Free'
                    : 'View plan'}
                </p>
              </button>
            </div>
          );
        })}
    </>
  );
};

export default UpgradeBannerItem;
