import { put, takeLatest, call } from 'redux-saga/effects';
import {
  enablePlugin,
  getSharedSpaceUsersURL,
  checkRegisteredUsersURL,
  sendEmailURL,
  sendEmailToNotRegisteredURL,
  changePluginStatusURL,
  deleteSharedSpaceUsersURL,
} from '../../services/pluginService';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';

function* checkRegisteredUser(action) {
  try {
    const response = yield call(
      checkRegisteredUsersURL,
      action.data.toLowerCase()
    );
    yield put({
      type: REDUX_ACTIONS.CHECK_REGISTERED_USER_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CHECK_REGISTERED_USER_FAIL,
      data: { message: error.message },
    });
  }
}
function* addToSharedSpace(action) {
  try {
    const response = yield call(enablePlugin, action.data);

    yield put({
      type: REDUX_ACTIONS.ADD_TO_SHARED_SPACE_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.ADD_TO_SHARED_SPACE_FAIL,
      data: { message: error.message },
    });
  }
}
function* getSharedSpaceUsers() {
  try {
    const response = yield call(getSharedSpaceUsersURL);
    yield put({
      type: REDUX_ACTIONS.GET_SHARED_USER_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.GET_SHARED_USER_FAIL,
      data: { message: error.message },
    });
  }
}
function* sendEmail(action) {
  try {
    const response = yield call(sendEmailURL, action.data);
    if (response?.data?.success) {
      yield put({
        type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_FAIL,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_FAIL,
      data: { success: false },
    });
  }
}
function* sendSharedSpaceEmailToNotRegistered(action) {
  try {
    const response = yield call(sendEmailToNotRegisteredURL, action.data);
    if (response?.data?.success) {
      yield put({
        type:
          REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_SUCCESS,
        data: response?.data,
      });
    } else {
      yield put({
        type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND_FAIL,
        data: { success: false },
      });
    }
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND_FAIL,
      data: { success: false },
    });
  }
}
function* changePluginStatus(action) {
  try {
    const response = yield call(changePluginStatusURL, action.data);

    yield put({
      type: REDUX_ACTIONS.CHANGE_PLUGIN_STATUS_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.CHANGE_PLUGIN_STATUS_FAIL,
      data: { message: error.message },
    });
  }
}
function* deleteSharedSpaceUsers(action) {
  try {
    const response = yield call(deleteSharedSpaceUsersURL, action.data);

    yield put({
      type: REDUX_ACTIONS.DELETE_SHARED_SPACE_MEMBER_SUCCESS,
      data: response.data?.data,
    });
  } catch (error) {
    yield put({
      type: REDUX_ACTIONS.DELETE_SHARED_SPACE_MEMBER_FAIL,
      data: { message: error.message },
    });
  }
}
// eslint-disable-next-line import/prefer-default-export
export function* sharedSpaceSaga() {
  yield takeLatest(REDUX_ACTIONS.CHECK_REGISTERED_USER, checkRegisteredUser);
  yield takeLatest(REDUX_ACTIONS.ADD_TO_SHARED_SPACE, addToSharedSpace);
  yield takeLatest(REDUX_ACTIONS.GET_SHARED_USER, getSharedSpaceUsers);
  yield takeLatest(REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_SEND, sendEmail);
  yield takeLatest(
    REDUX_ACTIONS.SHARED_SPACE_ADD_EMAIL_TO_NOT_REGISTERED_SEND,
    sendSharedSpaceEmailToNotRegistered
  );
  yield takeLatest(REDUX_ACTIONS.CHANGE_PLUGIN_STATUS, changePluginStatus);
  yield takeLatest(
    REDUX_ACTIONS.DELETE_SHARED_SPACE_MEMBER,
    deleteSharedSpaceUsers
  );
}
