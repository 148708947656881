import axios from 'axios';
import type { AxiosResponse } from 'axios';
import { getToken } from './localStorageHandler';
import { config as appConfig } from '../../config';

const baseURL = appConfig.authBaseUrl;
const token = getToken();

export interface BaseData<T> {
  success: boolean;
  data: T;
  message?: string;
}

export type BaseResponse<T> = AxiosResponse<BaseData<T>>;

const api = axios.create({
  baseURL,
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json',
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (!error || !error.response) {
      const er = {
        ...error,
        response: {
          ...error.response,
          data: {
            success: false,
            data: null,
            message: 'Something went wrong. Please try again later.',
          },
        },
      };
      return Promise.reject(er);
    }
    if (error.response.status === 401) {
      if (typeof error.response.data !== 'object') {
        const er = {
          ...error,
          response: {
            ...error.response,
            data: {
              success: false,
              data: null,
              message: 'User unauthorized',
            },
          },
        };
        return Promise.reject(er);
      }
    } else if (error.response.status === 404) {
      if (typeof error.response.data !== 'object') {
        const er = {
          ...error,
          response: {
            ...error.response,
            data: {
              success: false,
              data: null,
              message: 'Something went wrong. Please try again later.',
            },
          },
        };

        return Promise.reject(er);
      }
    }

    return Promise.reject(error);
  }
);

export const setHeader = (accessToken) => {
  api.interceptors.request.use((config) => {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
    return config;
  });
};

export default api;
