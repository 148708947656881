/* eslint-disable import/named */
import React, { ReactNode } from 'react';
import {
  DialogActions,
  DialogContent,
  Theme,
  ModalProps,
  Typography,
  Popover,
  PopoverProps,
} from '@material-ui/core';
import clsx from 'clsx';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import BasicButton from '../BasicButton';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      '& .MuiPaper-rounded': {
        borderRadius: '12px',
      },
    },
    title: {
      margin: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontWeight: 'bold',
    },
    dialogContent: {
      padding: '8px 10px',
    },
    dialogActions: {
      // paddingBottom: theme.spacing(1),
      // paddingRight: theme.spacing(4),
      // paddingTop: theme.spacing(0.5),
    },
  });
};

export interface IModalProps extends WithStyles<PopoverProps & typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: ModalProps['onClose'];
  children?: ReactNode;
  modalTitle?: string;
  actionButtons?: ReactNode;
  disableBackdropClick?: boolean;
  anchorE1?: any;
  isSingleButton?: boolean;
  actinButtonLabel?: string;
  actinButtonLabel2?: string;
  actinButtonOnClick?: React.MouseEventHandler<HTMLButtonElement>;
  customStyles?: any;
}

export const BasicModal: React.FC<IModalProps> = ({
  classes,
  id,
  open,
  modalTitle,
  children,
  handleClose,
  anchorE1,
  actinButtonLabel = 'Search',
  actinButtonOnClick,
  disableBackdropClick = false,
  customStyles,
}: IModalProps) => {
  return (
    <Popover
      id={id}
      onClose={(e, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        handleClose?.(e, reason);
      }}
      open={open}
      className={clsx(classes.root, customStyles)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorEl={anchorE1}
    >
      {modalTitle && (
        <Typography className={classes.title} variant="h4">
          {modalTitle}
        </Typography>
      )}
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>

      <DialogActions className={classes.dialogActions}>
        <BasicButton
          label={actinButtonLabel}
          id={`${id}-action-button`}
          filled
          roundBorder
          onClick={actinButtonOnClick}
        />
      </DialogActions>
    </Popover>
  );
};

export default withStyles(styles)(BasicModal);
