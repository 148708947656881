import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import './ScreenComponent.scss';
import { RECORDER_STATUS } from '../../../constants/apiSagaConstant';
import { getPreviewStream } from '../../../lib/RecorderWrapper';

const ScreenContainer = (props) => {
  const videoRef = React.createRef();

  const { recorder, videoStore } = props;

  const updateVideoStream = async () => {
    switch (recorder.status) {
      case RECORDER_STATUS.READY:
        if (
          recorder.media.isDesktopStreamAvailable &&
          videoRef.current.srcObject !== getPreviewStream()
        ) {
          videoRef.current.srcObject = getPreviewStream();
        }
        break;
      case RECORDER_STATUS.RUNNING:
        if (
          recorder.media.isDesktopStreamAvailable &&
          videoRef.current.srcObject !== getPreviewStream()
        ) {
          videoRef.current.srcObject = getPreviewStream();
        }
        break;
      /** if recorder has been stopeed, display recorded video file */
      case RECORDER_STATUS.STOPPED:
        if (videoStore.trimmedVideoAvailable) {
          videoRef.current.src = videoStore.trimmedData.url;
          videoRef.current.srcObject = null;
        } else {
          videoRef.current.src = recorder.media.videoSource;
          videoRef.current.srcObject = null;
        }
        break;
      case RECORDER_STATUS.NOT_READY:
        videoRef.current.src = '';
        videoRef.current.srcObject = null;
        break;
      default:
        if (
          recorder.media.isDesktopStreamAvailable &&
          videoRef.current.srcObject !== getPreviewStream()
        ) {
          videoRef.current.srcObject = getPreviewStream();
        }
        break;
    }
  };

  useEffect(() => {
    updateVideoStream();
  }, []);

  useEffect(() => {
    updateVideoStream();
  });

  return (
    <div className="online-screen-recorder-screen">
      <video
        key="localVideo"
        ref={videoRef}
        controls={recorder.status === RECORDER_STATUS.STOPPED} // Only enable for the playback
        muted={recorder.status !== RECORDER_STATUS.STOPPED} // Only enable audio for the playback
        autoPlay
        id="localVideo"
        controlsList="nodownload"
      >
        <track kind="captions" />
      </video>
    </div>
  );
};
function mapStateToProps(state) {
  return {
    recorder: state.recorder,
    videoStore: state.videoStore,
  };
}

ScreenContainer.propTypes = {
  recorder: PropTypes.shape({
    status: PropTypes.string.isRequired,
    openRecorder: PropTypes.bool.isRequired,
    media: PropTypes.shape().isRequired,
  }).isRequired,
  videoStore: PropTypes.shape().isRequired,
};

export default connect(mapStateToProps)(ScreenContainer);
