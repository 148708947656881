// constant
import { WS_ACTION_TYPE } from '../constants/wsConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import {
  clearRecordingState,
  stopRecordingSuccess,
} from '../features/recorder/recorderSlice';

export default (state = STORE_INITIATE_VALUE.VIDEO_STORE, action) => {
  switch (action.type) {
    case WS_ACTION_TYPE.STREAM_WEBM_AVAILABLE:
      return {
        ...state,
        downloadableWebmAvailable: true,
        webmS3Urls: [...state.webmS3Urls, action.payload.url],
      };
    case WS_ACTION_TYPE.STREAM_MP4_AVAILABLE:
      return {
        ...state,
        downloadableMp4Available: true,
        mp4S3Urls: [...state.mp4S3Urls, action.payload.url],
      };
    case REDUX_ACTIONS.USER_VIDEO_TRIM_SUCCESS:
      return {
        ...state,
        trimmedData: action.payload.trimmedData,
        trimmedVideoAvailable: true,
      };
    case stopRecordingSuccess.type:
      return {
        ...state,
        recordVideoAvailable: true,
      };
    case clearRecordingState.type:
      return {
        ...state,
        ...STORE_INITIATE_VALUE.VIDEO_STORE,
        showLibrarySkeleton: false,
      };
    // display skeleton in the library
    case REDUX_ACTIONS.CLOUD_RECORDINGS_FETCH_SUCCESS:
      return {
        ...state,
        showLibrarySkeleton: false,
      };
    case REDUX_ACTIONS.PAST_RECORDINGS_FETCH_SUCCESS:
      return state;
    case REDUX_ACTIONS.SHOW_LIBRARY_SKELETON:
      return {
        ...state,
        showLibrarySkeleton: true,
      };
    case REDUX_ACTIONS.HIDE_LIBRARY_SKELETON:
      return {
        ...state,
        showLibrarySkeleton: false,
      };
    default:
      return state;
  }
};
