import { connect, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';

// eslint-disable-next-line import/named
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { MicNone } from '@material-ui/icons';
import { createStyles, makeStyles } from '@material-ui/core';
import debug from 'debug';
import { audioSelect } from '../../../features/recorder/recorderSlice';

const log = debug('app:containers:MicrophoneDeviceContainer');

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '15px',
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      margin: '0 0 10px',
      marginLeft: '0.3rem',
      color: '#000000',
    },
    select: {
      fontSize: '12px',
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
      },
    },
    menuItemIcon: {
      fontSize: '15px',
      paddingRight: '4px',
    },
    para: {
      margin: 0,
    },
  })
);

export interface PluginMicrophoneDeviceProps {
  recorder;
  auth: {
    settings: { record };
  };
}

const PluginMicrophoneDeviceContainer = (
  props: PluginMicrophoneDeviceProps
) => {
  const {
    recorder: { microphoneDeviceId },
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();

  const [microphoneDevice, setMicrophoneDevice] = useState<string>('default');
  const [microphoneInputs, setMicrophoneInputs] = useState<
    { label: string; deviceId: string }[]
  >([]);

  const fetchDevices = async () => {
    if (
      !navigator.mediaDevices ||
      !navigator.mediaDevices.enumerateDevices ||
      !navigator.mediaDevices.getUserMedia
    ) {
      log('GUM not supported.');
      return;
    }

    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioDevices = devices
        .filter((device) => device.kind === 'audioinput')
        .map(({ label, deviceId }) => ({ label, deviceId }));
      setMicrophoneInputs(audioDevices);

      // check whether selected device available. if available set the device otherwise set the first available device
      const selectedDevice = audioDevices?.filter(
        (device) => device.deviceId === microphoneDeviceId
      );
      if (selectedDevice && selectedDevice.length > 0) {
        setMicrophoneDevice(selectedDevice[0].deviceId);
      } else {
        setMicrophoneDevice(
          audioDevices.length > 0 ? audioDevices[0].deviceId : 'default'
        );
      }
    } catch (error) {
      dispatch(audioSelect({ microphoneAudio: false, browserAudio: false }));
      log('Cannot set audio input devices', error);
    }
  };

  navigator.mediaDevices.ondevicechange = (event) => {
    log(event);
    fetchDevices();
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  const handleChange = (event) => {
    setMicrophoneDevice(event.target.value);
  };

  return (
    <div className={classes.root} style={{ marginTop: '0.5em' }}>
      <FormControl variant="outlined">
        <Select
          className={classes.select}
          value={microphoneDevice}
          onChange={handleChange}
        >
          {microphoneInputs.map((device) => {
            return (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                <MicNone className={classes.menuItemIcon} />
                <p className={classes.para}>{device.label}</p>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    recorder: state.recorder,
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(
  React.memo(PluginMicrophoneDeviceContainer)
);
