/* eslint-disable import/prefer-default-export */
import { put, takeLatest, call, select } from 'redux-saga/effects';
import debug from 'debug';

import { trimAB } from '../../lib/ffmpegWrapper';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { getVideoFile, replaceRecording } from '../../lib/storageUtils';
import { trimRemote } from '../../services/fileSystemService';
import { selectVideoFromLibrary } from '../../features/selectedVideo/selectedVideoSlice';

const log = debug('app:trimmerSaga');

interface ITrimAction {
  type: string;
  data: {
    recordingId: string;
    begin: number;
    end: number;
    isLocal: boolean;
    teamId?: string | undefined;
    ownerId?: string | undefined;
    spaceFolderId?: string | undefined;
    replace?: boolean | undefined;
  };
}

function* trimVideoGenerator(action: ITrimAction) {
  try {
    if (action.data.isLocal) {
      const videoFile = yield call(getVideoFile, action.data.recordingId);
      const trimmedVideoFile = yield call(
        trimAB,
        videoFile,
        action.data.begin,
        action.data.end
      );

      yield call(replaceRecording, action.data.recordingId, trimmedVideoFile);

      yield put({
        type: REDUX_ACTIONS.FETCH_PAST_RECORDINGS,
      });
    } else {
      // cloud, plugin, team etc.
      // Can pass replace: false to save trimmed file separately
      const { recordingId, ...args } = action.data;
      yield call(trimRemote, recordingId, args);

      if (args.teamId) {
        yield put({
          type: REDUX_ACTIONS.GET_COMPANY_SHARED_FILES,
          data: args.teamId,
        });
      } else {
        yield put({
          type: REDUX_ACTIONS.FETCH_ALL_FOLDERS_REQUEST,
        });
      }
    }

    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'trim',
        data: {
          local: action.data.isLocal,
        },
      },
    });
    yield put({
      type: REDUX_ACTIONS.TRIM_VIDEO_SUCCESS,
    });

    const videoFileDescriptor = action.data.teamId
      ? yield select((state) =>
          state.library?.team?.find(
            (x) => x.recordingId === action.data.recordingId
          )
        )
      : yield select((state) =>
          state.library?.personal?.find(
            (x) => x._id === action.data.recordingId
          )
        );

    if (videoFileDescriptor) {
      yield put({
        type: REDUX_ACTIONS.USER_VIDEO_TRIM_SUCCESS,
        payload: {
          trimmedData: videoFileDescriptor,
        },
      });

      yield put(selectVideoFromLibrary(videoFileDescriptor));
    }
  } catch (error) {
    log(error);
    yield put({
      type: REDUX_ACTIONS.TRIM_VIDEO_FAIL,
      data: { message: error.message },
    });
  }
}

export function* TrimVideoSaga() {
  yield takeLatest(REDUX_ACTIONS.TRIM_VIDEO, trimVideoGenerator);
}
