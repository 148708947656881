/* eslint-disable no-param-reassign */
/**
 * This is the first experment to migrate from redux to redux-toolkit
 */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Profile } from '../../types/state';
import { FileTypeExtended, requestStatusType } from '../../types';
import { getSharedItems } from '../../services/fileSystemService';

type Speaker = Pick<Profile, 'name' | 'picture'>;
export interface ISharedFileViewState {
  requestStatus: requestStatusType;
  owner: Speaker;
  isDownloadEnabled: boolean;
  file: FileTypeExtended;
  speakers: Speaker[];
}

const initialState = {
  requestStatus: 'idle',
} as ISharedFileViewState;

export const getSharedFile = createAsyncThunk(
  'sharedFileView/getSharedFile',
  async (id: string): Promise<ISharedFileViewState> => {
    const response = await getSharedItems(id);
    const {
      owner,
      isDownloadEnabled,
      sharedFiles: [file],
      speakers,
    } = response.data.data;
    return {
      requestStatus: 'succeeded',
      owner,
      isDownloadEnabled,
      file,
      speakers,
    };
  }
);

const sharedFileViewSlice = createSlice({
  name: 'sharedFileView',
  initialState,
  reducers: {
    setSharedFileView: (state, action: PayloadAction<ISharedFileViewState>) => {
      state = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSharedFile.pending, (state) => {
      state.requestStatus = 'pending';
    });
    builder.addCase(getSharedFile.rejected, (state) => {
      state.requestStatus = 'failed';
    });
    builder.addCase(getSharedFile.fulfilled, (state, action) => {
      const { owner, isDownloadEnabled, file, speakers } = action?.payload;
      state.owner = owner;
      state.isDownloadEnabled = isDownloadEnabled;
      state.file = file;
      state.speakers = speakers;
      state.requestStatus = 'succeeded';
    });
  },
});

export const { setSharedFileView } = sharedFileViewSlice.actions;
export default sharedFileViewSlice.reducer;
