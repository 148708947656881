/* eslint-disable import/named */
import React, { useState } from 'react';
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
  Theme,
} from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      width: '100%',
      '& .MuiOutlinedInput-input': {
        padding: '13px 18px !important',
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderWidth: 1,
      },
      '&:hover': {
        // borderColor: theme.palette.secondary.dark,
        opacity: 38,
      },
      '& .MuiFormLabel-root.Mui-focused': {
        // color: theme.palette.secondary.dark,
      },
      '& .MuiInputLabel-outlined': {
        transform: 'translate(18px, 13px) scale(1)',
        fontSize: '14px',
        fontFamily: 'Inter',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(16px, -4px) scale(0.75)',
      },
      '.MuiOutlinedInput-root': {
        borderRadius: '5px',
      },
      '& legend > span': {
        paddingRight: '10px',
      },
      margin: theme.spacing(1),
      marginRight: '0',
    },
  });
};

export interface IPasswordTextField
  extends WithStyles<OutlinedInputProps & typeof styles> {
  id?: string;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value?: unknown;
  label?: React.ReactNode;
  helperText?: React.ReactNode;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
}

export const PasswordTextField: React.FC<IPasswordTextField> = ({
  classes,
  id,
  label,
  placeholder,
  value,
  onChange,
  // helperText,
  error = false,
  disabled = false,
  required = false,
}: IPasswordTextField) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <FormControl id={id} className={classes.root} variant="outlined">
      <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
      <OutlinedInput
        id="outlined-adornment-password"
        label={label}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        error={error}
        disabled={disabled}
        required={required}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              edge="end"
              size="small"
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
    </FormControl>
  );
};

export default withStyles(styles)(PasswordTextField);
