function isDebugModeSet() {
  try {
    return window.localStorage.getItem('debug') === 'true';
  } catch (e) {
    return false;
  }
}

export const config = {
  appVersion: process.env.APP_VERSION || '5.0.0',
  appEnv: process.env.APP_ENV || 'staging',
  buildTime: process.env.BUILD_TIME || '2020-01-01T00:00:00.000Z',
  version: process.env.VERSION,
  commit: process.env.COMMIT,
  reportErrors: process.env.REPORT_ERRORS === 'true',
  adminPanelUrl:
    process.env.SCREENAPP_ADMIN_DASHBOARD_DOMAIN || 'http://localhost:3000',
  extensionId: 'jchodkkgldoachhmohljjgmiailkbdlb',
  wsUrl: process.env.WS_URL,
  trimmer: process.env.TRIMMER,
  s3BucketUrl: process.env.S3_BUCKET_URL,
  stripePublishableKey: process.env.STRIPE_PUBLISHABLE_KEY || '',
  stripePricingTableId: process.env.STRIPE_PRICING_TABLE_ID || '',
  recordPath: 'record/',
  trimmerPath: 'trim/',
  authBaseUrl: process.env.AUTH_BASE_URL || 'http://localhost:8081/v1',
  appBaseUrl: process.env.APP_BASE_URL || 'http://localhost:8080',
  landingBaseUrl: process.env.LANDING_BASE_URL || '',
  landingPageUrl: process.env.LANDING_PAGE_URL || 'https://dev.screenapp.io',
  ENABLE_STREAM_RECORDER:
    process.env.ENABLE_STREAM_RECORDER === 'true' || false,
  disablePlugin: process.env.DISABLE_PLUGIN === 'true' || false,
  videoChunkRecovery: process.env.VIDEO_CHUNK_RECOVERY !== 'false',
  tests: {
    downloadDir: '/tmp/screenapp/downloads',
    recordingTime: 10000, // Milliseconds
  },
  maxLocalRecordings: 10,
  paypalClientId: process.env.PAYPAL_CLIENT_ID,
  maintenanceNotice: process.env.MAINTENANCE_NOTICE,
  maintenanceNoticeEnabled: process.env.MAINTENANCE_NOTICE_ENABLED === 'true',
  disableSubscribableTrialPlans:
    process.env.DISABLE_SUBSCRIBABLE_TRIAL_PLANS !== 'false', // If null or empty, will fallback to true
  enable14DayFreeTrial: process.env.ENABLE_14_DAY_FREE_TRIAL,
  jwtExpiration: 1000 * 60 * 60 * 24 * 7, // a week
  enableGen2Pricing: process.env.ENABLE_GEN2_PRICING,
  intercomAppId: process.env.INTERCOM_APP_ID || '',
  permanentUnifiedFs: process.env.PERMANENT_UNIFIED_FS === 'true',
  growthBookClientKey: process.env.GROWTHBOOK_CLIENT_KEY,
  enableProductionDebugLogs:
    process.env.ENABLE_PRODUCTION_DEBUG_LOGS === 'true',
  enableExternalFileImport: process.env.ENABLE_EXTERNAL_FILE_IMPORT === 'true',
  debug: process.env.DEBUG === 'true' || isDebugModeSet(),
  enableCloudSync: process.env.ENABLE_CLOUD_SYNC === 'true',
  enableThumbnailGeneration: process.env.ENABLE_THUMBNAIL_GENERATION,
  useWebflowPricingPage: process.env.USE_WEBFLOW_PRICING_PAGE === 'true',
  posthogApiKey: process.env.POSTHOG_API_KEY || '',
  posthogApiHost: process.env.POSTHOG_API_HOST || 'https://app.posthog.com',
  churnkeyAppId: process.env.CHURNKEY_APP_ID || '',
  churunkeyMode: process.env.CHURNKEY_MODE || 'test',
};

export const PACKAGE_TO_PAYPAL_PLAN = {
  yearly: {
    STANDARD: [
      process.env.PAYPAL_STANDARD_PLAN,
      process.env.PAYPAL_STANDARD_TRIAL_PLAN,
    ],
    PREMIUM: [
      process.env.PAYPAL_PREMIUM_PLAN,
      process.env.PAYPAL_PREMIUM_TRIAL_PLAN,
    ],
    // Gen 2 -- no trial plans, just duplicated
    GROWTH: [process.env.PAYPAL_GROWTH_PLAN, process.env.PAYPAL_GROWTH_PLAN],
    BUSINESS: [
      process.env.PAYPAL_BUSINESS_PLAN,
      process.env.PAYPAL_BUSINESS_PLAN,
    ],
  },
  monthly: {
    STANDARD: [
      process.env.PAYPAL_STANDARD_MONTHLY_PLAN,
      process.env.PAYPAL_STANDARD_MONTHLY_TRIAL_PLAN,
    ],
    PREMIUM: [
      process.env.PAYPAL_PREMIUM_MONTHLY_PLAN,
      process.env.PAYPAL_PREMIUM_MONTHLY_TRIAL_PLAN,
    ],
    // Gen 2 -- no trial plans, just duplicated
    GROWTH: [
      process.env.PAYPAL_GROWTH_MONTHLY_PLAN,
      process.env.PAYPAL_GROWTH_MONTHLY_PLAN,
    ],
    BUSINESS: [
      process.env.PAYPAL_BUSINESS_MONTHLY_PLAN,
      process.env.PAYPAL_BUSINESS_MONTHLY_PLAN,
    ],
  },
};

export const GOOGLE_ANALYTICS_TRACIKNG_CODE = 'UA-118777783-4';
export const MEDIA_RESOLUTIONS = [
  { value: 'default', label: 'Default resolutions' },
  { value: '1920x1080', label: '1080p' },
  { value: '1280x720', label: '720p' },
  { value: '640x480', label: '480p' },
  { value: '3840x2160', label: '4K Ultra HD (3840x2160)' },
];
export const MEDIA_CONSTRANTS = {
  mediaResolutions: [
    { value: 'default', label: 'Default resolutions' },
    { value: '1920x1080', label: '1080p' },
    { value: '1280x720', label: '720p' },
    { value: '640x480', label: '480p' },
    { value: '3840x2160', label: '4K Ultra HD (3840x2160)' },
  ],
  mediaFramerates: [
    { value: 'default', label: 'Default resolutions' },
    { value: '5', label: '5 fps' },
    { value: '15', label: '15 fps' },
    { value: '24', label: '24 fps' },
    { value: '30', label: '30 fps' },
    { value: '60', label: '60 fps' },
  ],
  mediaBitrates: [
    { value: 'default', label: 'Default resolutions' },
    { value: '8000000', label: '1 MB bps' },
    { value: '800000', label: '100 KB bps' },
    { value: '8000', label: '1 KB bps' },
    { value: '800', label: '100 Bytes bps' },
  ],
};
export const GOOGLE_APP_ID =
  '388502563544-sb8ee8hc555o24aqvj1985pebnaumuoo.apps.googleusercontent.com';
