import { put, takeEvery, call } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { getNoticeBanner } from '../../services/otherServices';

function* _getNoticeBanner() {
  try {
    const response = yield call(getNoticeBanner);
    yield put({
      type: REDUX_ACTIONS.FETCH_NOTICE_BANNER_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({ type: REDUX_ACTIONS.FETCH_NOTICE_BANNER_FAIL });
  }
}
export default function* noticeBannerSaga() {
  yield takeEvery(REDUX_ACTIONS.FETCH_NOTICE_BANNER, _getNoticeBanner);
}
