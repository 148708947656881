// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.LIBRARY_TAB, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CHANGE_LIBRARY_TAB:
      return action.data;
    default:
      return state;
  }
};
