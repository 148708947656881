// To do: Replace with auth reducer

/* eslint no-param-reassign: "error" */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { noop } from 'lodash';
import STORE_INITIATE_VALUE from '../../constants/initialstore';
import { FileSystemSettings, RecorderSettings } from '../../types/state';
import { updateSettingsURL } from '../../services/userAdmissionServices';
import { IUsage, PackageProperties } from '../../constants/userPackage';
import { PackageType } from '../../types';

export interface IUpdatePluginSettingsData {
  type: 'plugin' | 'record';
  data: RecorderSettings;
  source?: string;
}

interface IGetUserProfileResponseData {
  success: boolean;
  data: any;
  message: string;
}

interface IAcknowledgeNewFeaturesSuccessData {
  data: boolean;
}

interface IUpdatePackageConfigData {
  packageConfig: PackageProperties;
  limitedPackageConfig: PackageProperties;
}

interface IRequestRecordingInitSuccessData {
  data: {
    ownerPackageConfig: PackageProperties;
  };
}
export interface IUpdateSettingsSuccessData {
  settings: RecorderSettings;
  type: 'record' | 'plugin';
  message: string;
  notChange: boolean;
}
interface IUpdateRecordSettingsSuccessData {
  settings: {
    plugin: RecorderSettings;
    recorderSettings: RecorderSettings;
  };
  message: string;
  notChange: boolean;
}
interface IUpdateSettingsFailData {
  message: string;
  notChange: boolean;
}
interface IUpdateRecordSettingsFailData {
  message: string;
}
interface IGetPluginDetailsSuccessData {
  settings: {
    recorderSettings: RecorderSettings;
  };
}
interface IGetSettingsSuccessData {
  plugin: {
    recorderSettings: RecorderSettings;
  };
  isAdminLogin: boolean;
  recorderSettings: RecorderSettings;
}
interface IGetRecordingCountSuccessData {
  data: {
    success: boolean;
    data: number;
  };
}
interface IIncreaseRecordingCountFailData {
  message: string;
}
interface IUpdateLastActiveDateSuccessData {
  data: {
    lastActiveAt: string;
  };
}
interface IUpdateFsSettingsSuccessData {
  data: FileSystemSettings;
}
interface IUserSignUpResponseData {
  data: string;
}
interface IUserResendEmailVerificationResponseData {
  data: string;
}
interface IUpdateUsageSuccessData {
  data: IUsage;
}
interface IFetchTeamSpaceInfoSuccessData {
  subscription: {
    package: PackageType;
    provider: string;
  };
}

export const updatePluginSettings = createAsyncThunk(
  'auth/updatePluginSettings',
  async (data: IUpdatePluginSettingsData): Promise<RecorderSettings> => {
    const response = await updateSettingsURL(data);
    if (response?.status === 200) {
      return {
        ...response.data?.data?.settings?.plugin,
      };
    }
    throw new Error();
  }
);

const initialState = STORE_INITIATE_VALUE.AUTH_INITIATE;

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUserDetails: () => {
      noop();
    },
    getUserProfileRequest: (state) => {
      state.loading = 'pending';
    },
    getUserProfileResponse: (
      state,
      action: PayloadAction<IGetUserProfileResponseData>
    ) => {
      state.user = {
        ...state.user,
        ...(action.payload || {}),
      };
      state.loading = 'succeeded';
    },
    clearFirstTimeUserFlagSuccess: (state) => {
      state.user.data.isFirstTimeUser = false;
      state.user.message = 'Clear first time user flag success';
    },
    acknowledgeNewFeaturesSuccess: (
      state,
      action: PayloadAction<IAcknowledgeNewFeaturesSuccessData>
    ) => {
      state.user.data.newFeaturesAcknowledged = action.payload.data;
    },
    updatePackageConfig: (
      state,
      action: PayloadAction<IUpdatePackageConfigData>
    ) => {
      state.packageConfig = action.payload.packageConfig;
      state.limitedPackageConfig = action.payload.limitedPackageConfig;
    },
    // change recordingLength in packageConfig to the limit of the owner of the requested recording
    requestRecordingInitSuccess: (
      state,
      action: PayloadAction<IRequestRecordingInitSuccessData>
    ) => {
      const { ownerPackageConfig } = action?.payload?.data || {};
      const recordingLength = ownerPackageConfig?.recordingLength;
      if (recordingLength) {
        state.packageConfig.recordingLength = recordingLength;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    updateSettings: (state, action) => {
      state.settings.success = false;
      state.settings.message = '';
    },
    updateSettingsSuccess: (
      state,
      action: PayloadAction<IUpdateSettingsSuccessData>
    ) => {
      const { settings, type, message } = action.payload;
      state.settings.success = true;
      state.settings.message = message;
      if (type === 'record') {
        state.settings.recorderSettings = settings || {};
      } else if (type === 'plugin') {
        state.settings.plugin = settings || {};
      }
    },
    updateSettingsFail: (
      state,
      action: PayloadAction<IUpdateSettingsFailData>
    ) => {
      state.settings.message = action.payload?.message || '';
      state.settings.success = false;
    },
    updateRecordSettingsSuccess: (
      state,
      action: PayloadAction<IUpdateRecordSettingsSuccessData>
    ) => {
      state.settings.success = true;
      state.settings.recorderSettings = {
        ...state.settings.recorderSettings,
        ...(action.payload?.settings?.recorderSettings || {}),
      };
      state.settings.message = action.payload?.message || '';
    },
    updateRecordSettingsFail: (
      state,
      action: PayloadAction<IUpdateRecordSettingsFailData>
    ) => {
      state.settings.message = action.payload?.message || '';
      state.settings.success = false;
    },
    getSettingsSuccess: (
      state,
      action: PayloadAction<IGetSettingsSuccessData>
    ) => {
      state.settings.isAdminLogin = action.payload?.isAdminLogin || false;
      state.settings.plugin = action.payload?.plugin?.recorderSettings || {};
      state.settings.recorderSettings = {
        ...state.settings.recorderSettings,
        ...(action.payload?.recorderSettings || {}),
      };
    },
    increaseRecordingCountSuccess: (
      state,
      action: PayloadAction<IGetRecordingCountSuccessData>
    ) => {
      state.recordingCount = action.payload?.data;
    },
    increaseRecordingCountFail: (
      state,
      action: PayloadAction<IIncreaseRecordingCountFailData>
    ) => {
      state.user.message = action.payload?.message || '';
    },
    getRecordingCountSuccess: (
      state,
      action: PayloadAction<IGetRecordingCountSuccessData>
    ) => {
      state.recordingCount = action.payload?.data;
    },
    updateLastActiveDateSuccess: (
      state,
      action: PayloadAction<IUpdateLastActiveDateSuccessData>
    ) => {
      return { ...state, lastActiveAt: action.payload?.data };
    },
    updateFsSettingsSuccess: (
      state,
      action: PayloadAction<IUpdateFsSettingsSuccessData>
    ) => {
      return { ...state, fileSystemSettings: action.payload?.data };
    },
    userSignUpResponse: (
      state,
      action: PayloadAction<IUserSignUpResponseData>
    ) => {
      state.user.accessToken = action.payload?.data;
      state.user.success = true;
    },
    userResendEmailVerificationResponse: (
      state,
      action: PayloadAction<IUserResendEmailVerificationResponseData>
    ) => {
      state.user.accessToken = action.payload?.data;
      state.user.success = true;
    },
    updateUsageSuccess: (
      state,
      action: PayloadAction<IUpdateUsageSuccessData>
    ) => {
      state.user.data.usage = {
        ...state.user.data.usage,
        ...(action.payload?.data || {}),
      };
    },
    deleteAccountSuccess: () => {
      return initialState;
    },
    fetchTeamSpaceInfoSuccess: (
      state,
      action: PayloadAction<IFetchTeamSpaceInfoSuccessData>
    ) => {
      // React to teamspace change and set package info
      state.user.data = {
        ...state.user.data,
        package: action.payload?.subscription?.package || 'FREE_LOGGEDIN',
        limitedPackage:
          action.payload?.subscription?.package || 'FREE_LOGGEDIN',
        subscriptionProvider: action.payload?.subscription?.provider || '',
        subscriptionStatus:
          action.payload.subscription.package === 'FREE_LOGGEDIN'
            ? 'pending'
            : 'active',
      };
    },
    getPluginDetailsSuccess: (
      state,
      action: PayloadAction<IGetPluginDetailsSuccessData>
    ) => {
      state.settings.plugin = action.payload?.settings?.recorderSettings || {};
    },
    clearMessage: (state) => {
      state.settings.message = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePluginSettings.fulfilled, (state, action) => {
      state.settings.recorderSettings = action.payload || {};
      state.settings.message = 'Record setting has been updated';
      state.settings.success = true;
    });
    builder.addCase(updatePluginSettings.rejected, (state) => {
      state.settings.message = 'Something went wrong!';
      state.settings.success = false;
    });
    // TODO: remove these when sign in is migrated to RTK
    builder.addCase(
      'API_POST_ACCOUNT_CONFIRMATION_RESPONSE',
      (state, action) => {
        state.user.success = true;
        // @ts-ignore
        state.user.accessToken = action?.data?.data?.accessToken;
      }
    );
    builder.addCase('USER_SIGN_IN_RESPONSE', (state) => {
      state.user.success = true;
    });
  },
});

export const {
  setCurrentUserDetails,
  getUserProfileRequest,
  // setIsLoading,
  getUserProfileResponse,
  requestRecordingInitSuccess,
  clearFirstTimeUserFlagSuccess,
  acknowledgeNewFeaturesSuccess,
  updatePackageConfig,
  updateSettings,
  updateLastActiveDateSuccess,
  updateSettingsSuccess,
  updateSettingsFail,
  updateFsSettingsSuccess,
  getSettingsSuccess,
  userSignUpResponse,
  increaseRecordingCountSuccess,
  increaseRecordingCountFail,
  userResendEmailVerificationResponse,
  updateUsageSuccess,
  deleteAccountSuccess,
  fetchTeamSpaceInfoSuccess,
  getPluginDetailsSuccess,
  getRecordingCountSuccess,
  updateRecordSettingsSuccess,
  updateRecordSettingsFail,
  clearMessage,
} = authSlice.actions;

export default authSlice.reducer;
