import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.REQUEST_RECORDINGS, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FETCH_ALL_REQUEST_RECORDINGS_SUCCESS:
      return {
        ...state,
        allRequests: action.data.data.allRequests,
      };
    case REDUX_ACTIONS.CHANGE_REQUEST_RECORDING_STATUS_SUCCESS:
      return {
        ...state,
        allRequests: action.data,
      };
    case REDUX_ACTIONS.CHANGE_REQUEST_RECORDING_STATUS_FAIL:
      return state;
    case REDUX_ACTIONS.DELETE_REQUEST_RECORDING_SUCCESS:
      return {
        ...state,
        allRequests: action.data,
      };
    case REDUX_ACTIONS.DELETE_REQUEST_RECORDING_FAIL:
      return state;
    default:
      return state;
  }
};
