/* eslint-disable import/prefer-default-export */
import { CompanySharedSpaceUserProperties } from '../types';
import api from '../lib/baseAxios';

// Enable shared space
export const enableSharedSpace = async ({
  companySharedSpaceUsers,
  teamId,
  spaceIds,
}: {
  companySharedSpaceUsers: CompanySharedSpaceUserProperties[];
  teamId: string;
  spaceIds?: [string];
}) => {
  return api.post(`/company-shared-space/enable-company-shared-space`, {
    companySharedSpaceUsers,
    teamId,
    spaceIds,
  });
};

// Deprecated. This endpoint NOT IN USE. New Endpoint -> getTeamSpaceDetails
// Get shared space users
export const getCompanySharedSpaceUsersURL = async () => {
  return api.get(`/company-shared-space/shared-space-users`);
};

// Send emails to shared space users
export const sendEmailURL = (emails: string[], teamId: string) => {
  return api.post(`/company-shared-space/send-email-to-team-space-users`, {
    emails,
    teamId,
  });
};

export const sendEmailToNotRegisteredURL = (
  data: [],
  ownerId: string,
  teamId: string
) => {
  return api.post(
    `/company-shared-space/send-email-to-not-registered-team-space-users`,
    {
      data,
      ownerId,
      teamId,
    }
  );
};

// Get admin's plugin videos
// export const getPluginFiles = async () => {
//   return api.get(`/plugin/plugin-video`);
// };

// toggle company shared space user status
export const toggleCompanySharedSpaceUserStatus = (
  userId: string,
  teamId: string
) => {
  return api.post(`/company-shared-space/toggle-shared-space-user-status`, {
    userId,
    teamId,
  });
};

// Delete company shared space user
export const deleteCompanySharedSpaceUser = (data: {
  userId: string;
  teamId: string;
}) => {
  return api.post(`/company-shared-space/delete-shared-space-user`, {
    userId: data.userId,
    teamId: data.teamId,
  });
};

// Change role of company shared space user
export const changeCompanySharedSpaceUserRole = (data: {
  userId: string;
  role: string;
  teamId: string;
}) => {
  return api.post(`/company-shared-space/change-shared-space-user-role`, {
    userId: data.userId,
    role: data.role,
    teamId: data.teamId,
  });
};

export const acceptCompanySharedSpaceInvitationLink = (
  fromUserId,
  toUserId,
  teamId
) => {
  return api.post(`/company-shared-space/accept-company-shared-space-user`, {
    adminId: fromUserId,
    userId: toUserId,
    teamId,
  });
};

export const getTeamSpaces = async () => {
  return api.get(`/company-shared-space/team-space`);
};

export const getTeamSpaceDetails = async (teamId: string) => {
  return api.get(`/company-shared-space/team-space/${teamId}`);
};

export const getTeamSpaceDeletionStatusURL = async () => {
  return api.get(`/company-shared-space/team-space-deletion-status`);
};

export const createTeamSpace = async (teamName: string) => {
  return api.post(`/company-shared-space/team-space`, { name: teamName });
};

export const getLastSelectedTeamSpace = async () => {
  return api.get(`/company-shared-space/selected-team-space`);
};

export const saveLastSelectedTeamSpace = async (teamId: string) => {
  return api.post(`/company-shared-space/selected-team-space`, { teamId });
};

// Add members to space
export const addToSpace = (data) => {
  return api.post(`/company-shared-space/add-to-space`, data);
};

// Remove members from space
export const removeFromSpace = (data) => {
  return api.post(`/company-shared-space/remove-from-space`, data);
};

// toggle space user status
export const changeSpaceUserStatus = (data) => {
  return api.post(`/company-shared-space/enable-space-member`, data);
};

export const renameTeam = (data) => {
  return api.post(`/company-shared-space/rename-team`, data);
};
