/* eslint-disable import/named */
import React from 'react';
import { FormControl, MenuItem, Select, SelectProps } from '@material-ui/core';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

const styles = () => {
  return createStyles({
    root: {
      marginTop: '1em',
      marginLeft: 0,
      display: 'flex',
      justifyContent: 'center',
    },
    select: {
      fontFamily: 'Inter',
      fontSize: '16px',
      height: '36px',
    },
    menu: {
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    icon: {
      marginRight: '10px',
      fontSize: '1.2rem !important',
    },
  });
};

export interface ISelectProps extends WithStyles<SelectProps & typeof styles> {
  id?: string;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement>;
  menuItems: {
    id?: string;
    label?: string;
    icon?: React.ReactNode;
  }[];
}

export const SelectComponent: React.FC<ISelectProps> = ({
  classes,
  id,
  value,
  onChange,
  menuItems,
}: ISelectProps) => {
  return (
    <div className={classes.root}>
      <FormControl variant="outlined">
        <Select
          id={id}
          value={value}
          onChange={onChange}
          variant="outlined"
          IconComponent={KeyboardArrowDown}
          className={classes.select}
        >
          {menuItems.map((item) => {
            return (
              <MenuItem key={item.id} value={item.id}>
                <div className={classes.menu}>
                  <div className={classes.icon}>{item.icon}</div>
                  {item.label}
                </div>
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default withStyles(styles)(SelectComponent);
