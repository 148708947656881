// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.FEEDBACK, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.GET_FEEDBACK_DATA_SUCCESS:
      return {
        ...state,
        lastUpdatedAt: action.data.updatedAt,
        lastShowedAt: action.data.showedAt,
      };
    case REDUX_ACTIONS.GET_FEEDBACK_DATA_FAIL:
      return state;
    case REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_SUCCESS:
      return {
        ...state,
        lastUpdatedAt: action.data.updatedAt,
        lastShowedAt: action.data.showedAt,
      };
    case REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_UPDATED_AND_LAST_SHOWED_DATE_FAIL:
      return state;
    case REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_SHOWED_DATE_SUCCESS:
      return {
        ...state,
        lastShowedAt: action.data,
      };
    case REDUX_ACTIONS.UPDATE_FEEDBACK_LAST_SHOWED_DATE_FAIL:
      return state;
    default:
      return state;
  }
};
