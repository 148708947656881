import api from '../lib/baseAxios';

// Called by recording requester
export const create = (data: any) => {
  // TODO UFS2 remove data.folderId later when things are settled
  if (data.folderId) {
    return api.post(`recording-request/create/${data.folderId}`, data);
  }
  return api.post(`recording-request/create`, data);
};

// Called by end user who uses the link
export const initialize = (key: string) => {
  return api.post(`recording-request/init/${key}`);
};

// Called by end user who uses the link
export const undo = (token: string) => {
  return api.post(`recording-request/undo?token=${token}`);
};

// Called by end user who uses the link
export const finalize = (token: string) => {
  return api.post(`recording-request/finish?token=${token}`);
};

export const sendEmail = (data: {
  key: string;
  emailAddresses: [];
  isMultipleRecAllowed: Boolean;
  recordingInfo: { title: string };
}) => {
  return api.post('recording-request/sendemail', data);
};

export const sendMultipleEmail = (data: {
  key: string;
  emailAddress: string;
  isMultipleRecAllowed: Boolean;
  recordingInfo: { title: string };
}) => {
  return api.post('recording-request/send-multiple-email', data);
};

export const copyLink = (data: {
  key: string;
  isMultipleRecAllowed: Boolean;
}) => {
  return api.post('recording-request/copy-link', data);
};

export const getAllRequests = async () => {
  return api.get(`/recording-request/`);
};

export const toggleRequestRecordingStatus = (data: { requestId: string }) => {
  return api.post(`/recording-request/${data}/status`, {
    data,
  });
};

export const deleteRequestRecording = (data: { requestId: string }) => {
  return api.delete(`/recording-request/${data}`);
};
