import api from '../lib/baseAxios';

export const CustomerPortalSessionURL = (data) => {
  return api.get('subscription/customerportalurl', data);
};

export const ResetUnsubscribeModal = () => {
  return api.post('subscription/resetUnsubscribeModal');
};

export const GetUnsubscribeModal = () => {
  return api.get('subscription/unsubscribeModal');
};

export const GetUpgradeStatus = () => {
  return api.get('subscription/upgrade-status');
};

export const ChurnkeyCancelPlan = () => {
  return api.get('subscription/cancel-plan');
};
