import { bindActionCreators } from 'redux';
import { actionCreateStoreUpdateFactory } from './actionCreator';
import {
  ACTION_ATTR as ATTRS,
  REDUX_ACTIONS as ACTION_TYPES,
  ANALYTICS_PAGE_VIEW_ACTIONS,
} from '../constants/apiSagaConstant';
import { LibraryTabType } from '../types';

export type TranscodeActionType = (data: {
  id: string;
  profile: string;
}) => void;

export type ChangeLibraryActionType = (tab: LibraryTabType) => void;

// Set the selected tab in redux
export const getSetSelectedTabAction = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.CHANGE_LIBRARY_TAB, ATTRS.DATA),
    dispatch
  );
};

// Set the selected folder in redux
export const getSetSelectedFolderAction = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPDATE_SELECTED_FOLDER,
      ATTRS.DATA
    ),
    dispatch
  );
};

export const getFetchCompanySharedRecordingsAction = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.GET_COMPANY_SHARED_FILES,
      ATTRS.DATA
    ),
    dispatch
  );
};

export const getFetchRecordingsAction = (dispatch) => {
  return bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.FETCH_PAST_RECORDINGS,
      ATTRS.PAYLOAD
    ),
    dispatch
  );
};

export const getResetStripePackageChangedAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.RESET_STRIPE_PACKAGE_CHANGED,
      ATTRS.DATA
    ),
    dispatch
  );

export const getRemoveFreeRecordingsAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.REMOVE_FREE_RECORDINGS,
      ATTRS.PAYLOAD
    ),
    dispatch
  );
export const getKeepFreeRecordingsAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.KEEP_FREE_RECORDINGS,
      ATTRS.DATA
    ),
    dispatch
  );
export const getUploadFileAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPLOAD_FILE_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getShareFileAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.SHARE_FILE_REQUEST, ATTRS.DATA),
    dispatch
  );

export const getSharePluginVideoAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.SHARE_PLUGIN_VIDEO, ATTRS.DATA),
    dispatch
  );

export const getDeleteDirectoryAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.DELETE_FOLDER_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getDeleteTeamspaceDirectoryAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.DELETE_TEAMSPACE_FOLDER_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getRenameFolderAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.RENAME_FOLDER_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getToggleCheckFileAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.TOGGLE_CHECK_FILE, ATTRS.DATA),
    dispatch
  );

export const getUncheckAllFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.UNCHECK_ALL_FILES, ATTRS.DATA),
    dispatch
  );

export const getCheckAllFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.CHECK_ALL_FILES, ATTRS.DATA),
    dispatch
  );

export const getMoveCheckedFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.MOVE_CHECKED_FILES, ATTRS.DATA),
    dispatch
  );
export const getDeleteCheckedFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.DELETE_CHECKED_FILES,
      ATTRS.DATA
    ),
    dispatch
  );

export const getUploadCheckedFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPLOAD_CHECKED_FILES,
      ATTRS.DATA
    ),
    dispatch
  );

export const getShareCheckedFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.SHARE_CHECKED_FILES,
      ATTRS.DATA
    ),
    dispatch
  );

export const getDownloadCheckedFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.DOWNLOAD_CHECKED_FILES,
      ATTRS.DATA
    ),
    dispatch
  );

export const getGetSharedPublicFilesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.GET_SHARE_PUBLIC_FILES,
      ATTRS.DATA
    ),
    dispatch
  );

export const getUnpublishAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.UNPUBLISH, ATTRS.DATA),
    dispatch
  );

export const getUpdateFileSystemSettingsAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.UPDATE_FS_SETTINGS_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getAcknowledgeNewFeaturesAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.ACKNOWLEDGE_NEW_FEATURES_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getAddDurationToCloudVideoAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(ACTION_TYPES.ADD_DURATION, ATTRS.DATA),
    dispatch
  );

export const getAnalyticsPageViewAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ANALYTICS_PAGE_VIEW_ACTIONS.DASHBOARD_PAGE_VIEW,
      ATTRS.PAYLOAD
    ),
    dispatch
  );

export const getSaveUserProfileAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.POST_USER_PROFILE_REQUEST,
      ATTRS.DATA
    ),
    dispatch
  );

export const getAddToCompanySharedAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.ADD_VIDEO_TO_COMPANY_SHARED_SPACE,
      ATTRS.DATA
    ),
    dispatch
  );

export const getGetNoticeBannerAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.FETCH_NOTICE_BANNER,
      ATTRS.DATA
    ),
    dispatch
  );

export const getResetUnsubscribeModalAction = (dispatch) =>
  bindActionCreators(
    actionCreateStoreUpdateFactory(
      ACTION_TYPES.RESET_UNSUBSCRIBE_MODAL,
      ATTRS.DATA
    ),
    dispatch
  );
