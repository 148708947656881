import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
// eslint-disable-next-line import/named
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
// import CloseIcon from '@material-ui/icons/Close';
// import DragIndicator from '@material-ui/icons/DragIndicator';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faMicrophoneSlash,
  faVolumeUp,
  faVolumeMute,
  faPlay,
  faPause,
} from '@fortawesome/free-solid-svg-icons';
import { RECORDER_STATUS } from '../../constants/apiSagaConstant';
import WebCam from '../../components/Webcam/Webcam';
import ScreenContainer from './Screen/ScreenContainer';
import RecordingTimerContainer from './timer/RecordingTimerContainer';
import {
  getStopScreenSharingAction,
  increaseRecordingCountAction,
} from '../../actions/videoActions';
import {
  audioOnlySelect,
  // clearRecordingState,
  setAudioMuteAction,
  startRecordingButtonClick,
  stopRecordingButtonClick,
  toggleDisplayRecording,
  toggleRecording,
} from './recorderSlice';
import { useRecorderOptions } from './RecorderOptionsProvider';

import { getGetStartedButtonClickAction } from '../../actions/pluginActions';
import Countdown from '../../components/Countdown/Countdown';
import { getUpdateRecordSettingsAction } from '../../actions/settingsActions';

const recStop = require('../../assets/icons/recording-stop.svg');
const sound = require('../../assets/audio/recording-start-sound.mp3');

export const KeyContext = React.createContext('');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      width: '305px',
      maxHeight: '212px',
      bottom: '5vw',
      borderRadius: '15px',
      boxShadow: '0px 0px 15px -5px rgba(0,0,0,0.8)',
      backgroundColor: '#fff',
      color: '#000',
      opacity: 1,
      zIndex: 1000,
      right: '2vw',
      fontFamily: 'Poppins, sans-serif !important',
    },
    closeButton: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
    screenContainer: {
      margin: theme.spacing(1),
      borderRadius: '5px',
      marginBottom: 0,
      '& .online-screen-recorder-screen .img-container-start .video-wrapper': {
        width: '263px',
        height: '148px',
      },
    },
    actionButtons: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '305px',
      alignContent: 'center',
      justifyContent: 'space-evenly',
      alignItems: 'center',
      margin: theme.spacing(1),
    },
    muteMicAudioPauseButtons: {
      border: 'none',
      outline: 'none',
      boxShadow: 'none',
      margin: '7px',
      background: theme.palette.common.white,
    },
    timer: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      textAlign: 'center',
      color: '#707070',
      padding: '0px 2px',
    },
    pauseButton: {
      margin: '7px',
      fontSize: '1.2vw',
      fontFamily: 'Roboto Mono',
      color: '#363739',
      background: theme.palette.common.white,
      borderRadius: '30px',
      border: 'none',
      transition: '0.3s all',
      outline: 'none',
      boxShadow: 'none',
      '&:focus': {
        outline: 'none',
        boxShadow: 'none',
      },
    },
    startStopButtons: {
      width: '24px',
      height: '24px',
    },
    webCamWrapper: {
      position: 'fixed',
      bottom: '35px',
      left: '20px',
    },
  })
);

const NewRecorder = () => {
  const thisButtonInitiatedRecording = useRef(false);
  const {
    settings,
    token,
    recordingArgs,
    recorderParams,
  } = useRecorderOptions();
  const { maxRecordingTimeMins } = settings.recordingControlSettings;
  const { browserAudio, microphoneAudio } = settings.audioSettings;
  const { webCam } = settings.videoSettings;
  // const [isOpen, setIsOpen] = useState(true);
  const [showCountDown, setShowCountDown] = useState(false);
  const classes = useStyles();

  const recStartAudio = useRef<HTMLAudioElement>(
    null
  ) as React.MutableRefObject<HTMLAudioElement>;
  if (recStartAudio.current === null) {
    recStartAudio.current = new Audio(sound);
  }

  const {
    status,
    pause,
    displayRecording,
    media,
    muteRecorderMic,
    muteRecorderBrowserAudio,
    audioRecorderEnable = false,
  } = recorderParams;

  const dispatch = useDispatch();
  const increaseRecordingCount = increaseRecordingCountAction(dispatch);
  const stopScreenSharing = getStopScreenSharingAction(dispatch);
  const onClickStartedButton = getGetStartedButtonClickAction(dispatch);
  const updateRecordSettings = getUpdateRecordSettingsAction(dispatch);

  // close button
  // const handleClose = async () => {
  //   dispatch(clearRecordingState());
  //   setIsOpen(false);
  // };

  const getStopFunction = () => {
    if ([RECORDER_STATUS.RUNNING, RECORDER_STATUS.PAUSED].includes(status)) {
      dispatch(stopRecordingButtonClick());
      increaseRecordingCount();
      return;
    }
    dispatch(audioOnlySelect({ audioRecorderEnable: false }));
  };

  useEffect(() => {
    thisButtonInitiatedRecording.current = true;
    if (status !== RECORDER_STATUS.RUNNING) {
      const actionData = {
        callback: stopScreenSharing,
        settings,
        audioRecorderEnable,
      };
      onClickStartedButton(actionData);
    }
  }, []);

  useEffect(() => {
    if (!thisButtonInitiatedRecording.current) {
      return;
    }

    if (status === RECORDER_STATUS.READY) {
      setShowCountDown(true);
    }
    if (status === RECORDER_STATUS.STOPPED) {
      thisButtonInitiatedRecording.current = false;
    }
  }, [status]);

  const handleCountdownEnd = () => {
    setShowCountDown(false);
    recStartAudio.current.play();
    dispatch(startRecordingButtonClick({ token, ...recordingArgs }));
  };

  return (
    <>
      <div
        id="recorder-portal-root"
        className={classes.root}
        style={{ borderRadius: `${audioRecorderEnable && '30px'}` }}
        draggable
      >
        {/* {!(
          status === RECORDER_STATUS.READY || status === RECORDER_STATUS.RUNNING
        ) && (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            size="medium"
            onClick={() => handleClose()}
            disabled={
              status === RECORDER_STATUS.READY ||
              status === RECORDER_STATUS.RUNNING
            }
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        )} */}

        {displayRecording && !audioRecorderEnable && (
          <div className={classes.screenContainer}>
            <ScreenContainer />
          </div>
        )}

        <div className={classes.actionButtons}>
          {/* <DragIndicator fontSize="inherit" /> */}
          {microphoneAudio &&
          media.isDesktopStreamAvailable &&
          status === RECORDER_STATUS.RUNNING ? (
            <button
              type="button"
              className={classes.muteMicAudioPauseButtons}
              onClick={() =>
                dispatch(
                  setAudioMuteAction({
                    microphoneAudio,
                    browserAudio,
                    muteRecorderMic: !muteRecorderMic,
                    muteRecorderBrowserAudio,
                  })
                )
              }
            >
              {!muteRecorderMic && <FontAwesomeIcon icon={faMicrophone} />}
              {muteRecorderMic && <FontAwesomeIcon icon={faMicrophoneSlash} />}
            </button>
          ) : (
            <button
              type="button"
              disabled
              className={classes.muteMicAudioPauseButtons}
            >
              <FontAwesomeIcon icon={faMicrophone} />
            </button>
          )}

          {browserAudio &&
          media.isDesktopStreamAvailable &&
          status === RECORDER_STATUS.RUNNING ? (
            <button
              type="button"
              className={classes.muteMicAudioPauseButtons}
              onClick={() =>
                dispatch(
                  setAudioMuteAction({
                    microphoneAudio,
                    browserAudio,
                    muteRecorderMic,
                    muteRecorderBrowserAudio: !muteRecorderBrowserAudio,
                  })
                )
              }
            >
              {!muteRecorderBrowserAudio && (
                <FontAwesomeIcon icon={faVolumeUp} />
              )}
              {muteRecorderBrowserAudio && (
                <FontAwesomeIcon icon={faVolumeMute} />
              )}
            </button>
          ) : (
            <button
              type="button"
              className={classes.muteMicAudioPauseButtons}
              disabled
            >
              <FontAwesomeIcon icon={faVolumeUp} />
            </button>
          )}

          <div className={classes.timer}>
            {(status === RECORDER_STATUS.READY ||
              status === RECORDER_STATUS.RUNNING) && (
              <RecordingTimerContainer
                maxRecordingLengthMs={maxRecordingTimeMins * 60 * 1000}
              />
            )}
          </div>
          {status === RECORDER_STATUS.RUNNING && (
            <button
              type="button"
              className={classes.muteMicAudioPauseButtons}
              onClick={() => dispatch(toggleRecording({ pause: !pause }))}
            >
              {pause && <FontAwesomeIcon icon={faPlay} />}
              {!pause && <FontAwesomeIcon icon={faPause} />}
            </button>
          )}
          {status === RECORDER_STATUS.RUNNING && (
            <IconButton
              aria-label="stop-recording"
              size="medium"
              onClick={() => getStopFunction()}
            >
              <img
                className={classes.startStopButtons}
                src={recStop}
                alt="recording-stop-icon"
              />
            </IconButton>
          )}
          {!audioRecorderEnable && (
            <IconButton
              size="medium"
              onClick={() => dispatch(toggleDisplayRecording())}
            >
              {displayRecording ? (
                <KeyboardArrowDown fontSize="inherit" />
              ) : (
                <KeyboardArrowUp fontSize="inherit" />
              )}
            </IconButton>
          )}
        </div>
        {showCountDown && <Countdown onCountdownEnd={handleCountdownEnd} />}
      </div>
      <div className={classes.webCamWrapper}>
        {webCam && !audioRecorderEnable && (
          <WebCam
            settings={settings}
            onCancel={() =>
              updateRecordSettings({
                type: 'record',
                data: {
                  ...settings,
                  videoSettings: {
                    webCam: false,
                    screen: true,
                  },
                },
              })
            }
          />
        )}
      </div>
    </>
  );
};

export default NewRecorder;
