import {
  timerPause,
  timerReset,
  timerUpdate,
} from '../features/recorder/recorderSlice';

export const timmerUpdate = () => ({
  type: timerUpdate.type,
});
export const timmerPause = () => ({
  type: timerPause.type,
});
export const timmerReset = () => ({
  type: timerReset.type,
});
