/* eslint-disable import/prefer-default-export */

import {
  call,
  put,
  select,
  take,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import debug from 'debug';

import { config } from '../../../config';

import {
  RECORDER_STATUS,
  REDUX_ACTIONS,
  PLUGIN_RECORDING_STATUS,
} from '../../constants/apiSagaConstant';
import { resetTimer, toggleTimer } from '../../lib/recorderTimer';
import {
  createRecorder,
  buildVideo,
  downloadRecording,
  start,
  stop,
  stopAllStreams,
  setAudioMute,
  toggleRecoding,
  getRecordingId,
  BuildVideoArgsData,
} from '../../lib/RecorderWrapper';
import {
  uploadThumbnail,
  recordingCount,
  getRecordingCount,
} from '../../services/fileSystemService';
import { estimateStorageSpace } from '../../lib/storageUtils';
import { getThumbnail } from '../../lib/ffmpegWrapper';
import { reportError } from '../../lib/errorReports';
import {
  cancelRecording,
  getDesktopMediaSuccess,
  setAudioMuteAction,
  startRecordingButtonClick,
  stopRecordingButtonClick,
  toggleRecording,
  setVideoDuration,
  getDesktopMediaFailure,
  recordingError,
  recordingSizeChange,
  stopRecordingFailure,
  stopRecordingSuccess,
} from '../../features/recorder/recorderSlice';
import {
  getRecordingCountSuccess,
  increaseRecordingCountFail,
  increaseRecordingCountSuccess,
} from '../../features/auth/authSlice';
import { initPluginRecording } from '../../features/plugin/pluginSlice';
import { realtimeUploaderInit } from '../../features/realtimeUploader/realtimeUploaderSlice';
import { RecordingArgsWithToken } from '../../lib/streamUpload';
import { RecorderFeatures, SettingsType, State, User } from '../../types/state';
import { ProfileType } from '../../constants/mediaFormatProfiles';

const log = debug('app:recorderSaga');

const getcloudFileCount = (state) => state?.auth?.packageConfig?.cloudFileCount;

const getSyncedFiles = (state) => state.fileUploads;

const getStopScreenSharingCallbackData = (state) => {
  const uuid = state?.app?.uuid;
  const userId = state?.auth?.user?.data?.id || '';
  const status = state?.recorder?.status;
  return { uuid, userId, status };
};

function* getStartedGenerator(action) {
  const recorderFeatures: RecorderFeatures = action.data;
  const { settings, audioRecorderEnable } = recorderFeatures;
  const { browserAudio, microphoneAudio } = settings.audioSettings || {};
  const { webCam, screen } = settings?.videoSettings || {};
  const { webcamDeviceId, microphoneDeviceId } = settings?.deviceSettings || {};
  const webCamOnly = webCam && !screen;

  try {
    // Receive events from recorder and streams
    const channel = yield call(createRecorder, recorderFeatures);

    const syncedFiles = yield select(getSyncedFiles);
    const uploadedOrUploadingFiles = Object.keys(syncedFiles).filter((f) =>
      ['pending', 'working', 'complete'].includes(syncedFiles[f])
    );
    const currentCloudFileCount = uploadedOrUploadingFiles.length;
    const cloudFileCount = yield select(getcloudFileCount);
    // Since premium members have direct cloud upload, no need to check for storage errors
    // Check for storage errors for standard users because their uploads are invisible
    const enableStorageErrors = currentCloudFileCount >= cloudFileCount;

    log('Waiting for events');
    while (true) {
      // take(END) will cause the saga to terminate by jumping to the finally block
      const ev = yield take(channel);
      log(ev);

      switch (ev.type) {
        case 'INIT':
          yield put(
            getDesktopMediaSuccess({
              audioSettings: {
                browserAudio,
                microphoneAudio,
              },
              videoSettings: {
                screen: !webCamOnly,
                webCam: webCam || webCamOnly,
              },
              deviceSettings: {
                microphoneDeviceId,
                webcamDeviceId,
              },
              displaySurface: ev.data.displaySurface,
              isBrowserShareAudioEnable: ev.data.isBrowserShareAudioEnable,
              audioRecorderEnable,
            })
          );
          break;
        case 'DATA':
          // Count file size and stop the recording if there will not be enough space to save it
          {
            const currentFileSize = yield select(
              (state) => state.recorder.media.recordingSize
            );
            const newFileSize = currentFileSize + (ev.data.size || 0);
            // Estimated file size when saved (includes recovery + seek metadata)
            const fileSizeWhenSaved = newFileSize * 2.1;
            const { usage, quota } = yield call(estimateStorageSpace);
            const freeSpace = quota - usage;

            if (enableStorageErrors && freeSpace < fileSizeWhenSaved) {
              yield put(
                recordingError({
                  message: `Your local storage space is almost full.
                You need to free up some space in order to continue recording. Videos are stored locally in case the internet goes out, but you can delete any unnecessary files to free up space.`,
                })
              );
              yield put({
                type: REDUX_ACTIONS.STOP_SCREEN_SHARING,
              });
            } else {
              yield put(recordingSizeChange(newFileSize));
            }
          }
          break;
        case 'STREAM_END':
          yield put({
            type: REDUX_ACTIONS.STOP_SCREEN_SHARING,
          });
          break;
        case 'ERROR':
          if (ev.name === 'PermissionDeniedError') {
            yield put(getDesktopMediaFailure(ev.error));

            yield put({
              type: REDUX_ACTIONS.STOP_SCREEN_SHARING,
            });
          } else if (ev.name === 'NotEnoughStorage') {
            if (enableStorageErrors) {
              yield put(recordingError({ message: ev.error }));
            }

            // ^ Do not stop the recording
          } else {
            yield put(recordingError({ message: ev.error }));

            yield put({
              type: REDUX_ACTIONS.STOP_SCREEN_SHARING,
            });
          }

          break;
        default:
          break;
      }
    }
  } catch (error) {
    log('Recorder error: ', error);
    if (String(error) === 'NotAllowedError: Permission denied by system') {
      yield put({
        type: REDUX_ACTIONS.MACOS_PERMISSION_DENIED,
      });
    } else if (String(error) === 'NotAllowedError: Permission denied') {
      // User denied permissions
    } else {
      yield call(reportError, 'handled.recorderSaga', error);
    }
  } finally {
    log('End recording session');
  }
}

function* startRecordingGenerator(action) {
  const data: RecordingArgsWithToken = action.payload;
  const {
    profile,
    token,
    folderId,
    recorderEmail,
    recorderName,
    title,
    recordingId,
    teamId,
  } = data;

  try {
    yield put(
      realtimeUploaderInit({
        token,
        recordingId,
        teamId,
        folderId: folderId || null,
        recorderEmail,
        recorderName,
        title,
        profile,
      })
    );

    yield call(start, { data });

    yield put({ type: REDUX_ACTIONS.START_RECORDING_SUCCESS });
  } catch (e) {
    yield put({ type: REDUX_ACTIONS.START_RECORDING_FAILURE });
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

function* toggleRecordingGenerator(action) {
  try {
    yield call(toggleRecoding, action);
    yield call(toggleTimer, action);
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.TOGGLE_RECORDING_FAILURE,
      data: { message: e.message },
    });
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

function* setAudioMuteGenerator(action) {
  try {
    yield call(setAudioMute, action);
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.SET_AUDIO_MUTE_FAILURE,
      data: { message: e.message },
    });
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

function* cancelRecordingGenerator() {
  try {
    yield call(stopAllStreams);
    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'recording-cancel',
      },
    });
  } catch (e) {
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

function* downloadRecordingGenerator(action: {
  data: { uuid: string; title: string; formatProfile: ProfileType };
  type: string;
}) {
  const { title, formatProfile } = action.data;
  try {
    yield call(downloadRecording, { action, title, formatProfile });
  } catch (e) {
    yield put({
      type: REDUX_ACTIONS.DOWNLOAD_RECORDING_FAILURE,
      data: { message: e.message },
    });
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

const getStateForStopRecording = (state: State) => {
  const {
    app: { uuid },
    plugin: { pluginMode },
    libraryTab = 'personal',
    folders,
    fileSystemSettings: { cloudSync } = { cloudSync: false },
    recorder: {
      title,
      description,
      recordingId,
      audioRecorderEnable,
      settings: {
        otherSettings: { formatProfile },
      },
    },
    recordingRequest: {
      navigatedId: requestMode,
      recorderInfo: { title: reqRecTitle, name, folderId },
    },
    fileUploads,
    auth: {
      packageConfig: { cloudFileCount, canCloudSync },
      user: { data: { id: userId = '' } = { id: '' } } = { data: { id: '' } },
    },
    pluginRecording: { name: pluginName, title: pluginTitle },
  } = state;

  let resultTitle = '';

  if (requestMode) {
    resultTitle = reqRecTitle || '';
  } else if (pluginMode) {
    resultTitle = pluginTitle || '';
  } else {
    resultTitle = title || '';
  }

  const uploadedOrUploadingFiles = Object.keys(fileUploads).filter((f) =>
    ['pending', 'working', 'complete'].includes(fileUploads[f])
  );
  const currentCloudFileCount =
    uploadedOrUploadingFiles.length - (fileUploads[recordingId] ? 1 : 0); // exclude this file

  const saveLocally =
    !pluginMode &&
    !requestMode &&
    !(
      config.ENABLE_STREAM_RECORDER &&
      userId.length > 0 &&
      canCloudSync &&
      cloudSync &&
      currentCloudFileCount < cloudFileCount
    );

  return {
    selectedLibraryTab: libraryTab,
    folderId: requestMode
      ? folderId
      : folders[libraryTab].selectedFolder?._id || null,
    cloudSync,
    saveLocally,
    title: resultTitle,
    recorderName: pluginMode ? pluginName : name,
    description,
    pluginMode,
    formatProfile: audioRecorderEnable ? 'mp3' : formatProfile,
    uuid,
  };
};

function* stopRecordingGenerator() {
  try {
    // If a folder is selected (cloud or otherwise), the file should be put into
    // it. At this point, the actual recording (most of it) should already be in
    // the correct storage provider: e.g: cloud/S3 or local/IDB.

    const {
      selectedLibraryTab,
      folderId,
      cloudSync,
      saveLocally,
      title,
      recorderName,
      description,
      pluginMode,
      formatProfile,
      uuid,
    } = yield select(getStateForStopRecording);
    // N.B.: This folderId is used to save a file to IDB only. For realtime upload, check startRecordingGenerator.

    const args: BuildVideoArgsData = {
      folderId,
      saveLocally,
      title,
      recorderName,
      description,
    };
    yield call(stop);

    const { videoUrl, duration, blob } = yield call(buildVideo, args);

    const id = yield call(getRecordingId);

    // The thumbnail generation functionality has been added to the Python services as well.
    // In the Python services, thumbnails are generated specifically for videos that have been requested for transcription.
    // This function can be configured through the 'ENABLE_THUMBNAIL_GENERATION' environment variable
    if (
      ((cloudSync && blob) || pluginMode) &&
      config.enableThumbnailGeneration
    ) {
      try {
        const thumbBlob = yield call(getThumbnail, blob, 500); // generate thumbnail
        if (thumbBlob) {
          const thumbImage = new File([thumbBlob], id, { type: 'image/jpeg' });
          const formData = new FormData();
          formData.append('thumbnail', thumbImage);
          yield call(uploadThumbnail, id, formData);
        }
      } catch (e) {
        log('Uploading Thumbnail failed.', e);
      }
    }

    yield call(stopAllStreams);
    yield put(stopRecordingSuccess({ url: videoUrl }));
    yield put(
      initPluginRecording({ title: '', status: PLUGIN_RECORDING_STATUS.INIT })
    );

    yield put(setVideoDuration({ endTime: duration }));

    const {
      browserAudio,
      microphoneAudio: mic,
      webcamEnable: webcam,
    } = yield select((state) => state.recorder);

    const { navigatedId } = yield select((state) => state.recordingRequest);
    const {
      settings,
      user,
    }: { settings: SettingsType; user: User } = yield select(
      (state) => state.auth
    );

    // Track video duration for analysis
    yield put({
      type: REDUX_ACTIONS.ANALYTICS,
      data: {
        action: 'recording-end',
        data: {
          tab:
            (pluginMode && 'plugin') ||
            (navigatedId && 'request') ||
            selectedLibraryTab,
          folder: Boolean(folderId),
          sync: cloudSync,
          duration,
          features: {
            browserAudio,
            mic,
            webcam,
          },
        },
      },
    });

    if (
      (!pluginMode &&
        user?.data?.package !== 'FREE' &&
        (user?.data?.package !== 'FREE_LOGGEDIN' ||
          user?.data?.isOnInitialTrial) &&
        settings?.recorderSettings?.recordingControlSettings?.autoDownload) ||
      (pluginMode && settings?.plugin?.recordingControlSettings.autoDownload)
    )
      yield* downloadRecordingGenerator({
        type: REDUX_ACTIONS.DOWNLOAD_BUTTON_CLICKED,
        data: { uuid, title, formatProfile },
      });

    if (cloudSync || navigatedId) {
      yield put({
        type: REDUX_ACTIONS.UPLOAD_PROGRESS_BAR_START,
      });
    }
    resetTimer();
  } catch (e) {
    yield call(stopAllStreams);
    yield put(stopRecordingFailure(e.message));
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

function* stopScreenSharing() {
  try {
    const data = yield select(getStopScreenSharingCallbackData);
    if (data.status === RECORDER_STATUS.RUNNING) {
      yield put(stopRecordingButtonClick(data));
    } else if (data.status === RECORDER_STATUS.READY) {
      yield put(cancelRecording());
    }
  } catch (e) {
    yield put(cancelRecording());
    yield call(reportError, 'handled.recorderSaga', e);
  }
}

export function* tryAgainGenerator(action) {
  const data = yield select(getStopScreenSharingCallbackData);

  // Need to make sure the 2 steps are sequential

  // 1
  if (data.status === RECORDER_STATUS.RUNNING) {
    yield put(stopRecordingButtonClick());
    yield* stopRecordingGenerator();
  } else if (data.status === RECORDER_STATUS.READY) {
    yield put(cancelRecording());
    yield* cancelRecordingGenerator();
  }

  // 2
  yield* getStartedGenerator(action.data);
}

function* increaseRecordingCount() {
  try {
    const response = yield call(recordingCount);
    yield put(increaseRecordingCountSuccess(response));
    yield put(increaseRecordingCountSuccess(response));
  } catch (error) {
    yield put(increaseRecordingCountFail({ message: error.message }));
  }
}

function* gettingRecordingCount() {
  try {
    const response = yield call(getRecordingCount);
    yield put(getRecordingCountSuccess(response));
  } catch (error) {
    yield put(increaseRecordingCountFail({ message: error.message }));
  }
}

// Share screen and media
export function* getStartedSaga() {
  yield takeLatest(REDUX_ACTIONS.GET_STARTED_BUTTON_CLICK, getStartedGenerator);
}

// Start recording
export function* startRecordingSaga() {
  yield takeLatest(startRecordingButtonClick.type, startRecordingGenerator);
}

// Pause/resume recording
export function* toggleRecordingSaga() {
  yield takeLatest(toggleRecording.type, toggleRecordingGenerator);
}

// Toggle microphone or browser audio
export function* toggleMicrophoneSaga() {
  yield takeLatest(setAudioMuteAction.type, setAudioMuteGenerator);
}

// Stop or cancel the recording
export function* stopRecordingSaga() {
  yield takeLatest(stopRecordingButtonClick.type, stopRecordingGenerator);
  yield takeLatest(cancelRecording.type, cancelRecordingGenerator);

  // Cancel or properly stop the recording depending on the status
  yield takeLatest(REDUX_ACTIONS.STOP_SCREEN_SHARING, stopScreenSharing);
  yield takeEvery(
    REDUX_ACTIONS.INCREASE_RECORDING_COUNT,
    increaseRecordingCount
  );
  yield takeLatest(REDUX_ACTIONS.GET_RECORDING_COUNT, gettingRecordingCount);
}

// Try recording again in case the user forgot to share the audio
export function* tryAgainSaga() {
  yield takeLatest(REDUX_ACTIONS.TRY_RECORDING_AGAIN, tryAgainGenerator);
}

// Download the recording manually
export function* downloadRecordingSaga() {
  yield takeLatest(
    REDUX_ACTIONS.DOWNLOAD_BUTTON_CLICKED,
    downloadRecordingGenerator
  );
}

// Analytics only
function* trackMediaCaptureDenialGenerator() {
  yield put({
    type: REDUX_ACTIONS.ANALYTICS,
    data: {
      action: 'media-denied',
    },
  });
}

// Analytics only
export function* trackMediaCaptureDenialSaga() {
  yield takeLatest(
    getDesktopMediaFailure.type,
    trackMediaCaptureDenialGenerator
  );
}
