/* eslint-disable import/prefer-default-export */
import { call, takeLatest } from 'redux-saga/effects';
import { setupIdentity } from '../../lib/clarityUtils';
import { getUserProfileResponse } from '../../features/auth/authSlice';

function* setupIdentityGenerator(action) {
  const { email, _id } = action?.data?.data || {};
  if (email) {
    yield call(setupIdentity, { email, _id });
  }
}

export function* setupClaritySaga() {
  yield takeLatest(getUserProfileResponse.type, setupIdentityGenerator);
}
