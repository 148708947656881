// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import './AudioSettingComponent.scss';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import {
  MicNone,
  SurroundSound,
  VolumeMute,
  VolumeUp,
} from '@material-ui/icons';
import { AudioIndicatorWave } from '../../StorybookComponents/AudioIndicatorWave';
import {
  Recorder,
  audioSelect,
} from '../../../features/recorder/recorderSlice';
import { Browser } from '../../../types/state';

const AudioWave = require('../../../assets/icons/audio-wave.svg');

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: '10px 15px',
      display: 'flex',
      flexDirection: 'column',
    },
    heading: {
      fontFamily: 'Poppins, Helvetica, sans-serif',
      fontSize: '16px',
      fontWeight: 600,
      margin: '0 0 10px',
      marginLeft: '0.3rem',
      color: '#000000',
    },
    select: {
      fontSize: '12px',
      '& .MuiSelect-outlined.MuiSelect-outlined': {
        padding: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'black',
      },
    },
    menuItemIcon: {
      fontSize: '15px',
      paddingRight: '4px',
    },
  })
);

interface PluginAudioSettingComponentProps {
  recorder: Recorder;
  browser: Browser;
}

const PluginAudioSettingComponent = (
  props: PluginAudioSettingComponentProps
) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { browser, recorder } = props;
  const { microphoneAudio, browserAudio } = recorder.settings.audioSettings;
  const { screen, webCam } = recorder.settings.videoSettings;
  const webcamOnlyEnable = !screen && webCam;

  const [select, setSelect] = useState<any>('no_audio');

  const handleChange = (inputValue) => {
    setSelect(inputValue);
    const microphoneEnable = ['microphone_audio', 'sysMic_audio'].includes(
      inputValue
    );
    const browserAudioEnable = ['browser_audio', 'sysMic_audio'].includes(
      inputValue
    );

    dispatch(
      audioSelect({
        microphoneAudio: microphoneEnable,
        browserAudio: browserAudioEnable,
      })
    );
  };

  useEffect(() => {
    if (microphoneAudio && browserAudio && !webcamOnlyEnable)
      handleChange('sysMic_audio');
    else if (microphoneAudio || (webcamOnlyEnable && browserAudio))
      handleChange('microphone_audio');
    else if (browserAudio && !webcamOnlyEnable) handleChange('browser_audio');
    else handleChange('no_audio');
  }, [microphoneAudio, browserAudio, webcamOnlyEnable]);

  return (
    <div className={classes.root}>
      {/* <div className={classes.heading}>Recording Settings</div> */}
      <FormControl variant="outlined">
        <Select
          className={classes.select}
          value={select}
          onChange={(event) => {
            handleChange(event.target.value);
          }}
        >
          {browser.name !== 'Firefox' && !webcamOnlyEnable && (
            <MenuItem value="sysMic_audio">
              <VolumeUp className={classes.menuItemIcon} />
              System Audio + Microphone Audio
              {microphoneAudio && browserAudio && (
                <AudioIndicatorWave paddingLeftValue="5px" />
              )}
            </MenuItem>
          )}
          <MenuItem value="microphone_audio">
            <MicNone className={classes.menuItemIcon} />
            Microphone Audio
            {microphoneAudio && !browserAudio && (
              <AudioIndicatorWave paddingLeftValue="90px" />
            )}
          </MenuItem>
          {browser.name !== 'Firefox' && !webcamOnlyEnable && (
            <MenuItem value="browser_audio">
              <SurroundSound className={classes.menuItemIcon} />
              <span style={{ paddingRight: '110px' }}>Browser Audio</span>
              {browserAudio && !microphoneAudio && (
                <img
                  className="dropdown-icon"
                  src={AudioWave}
                  alt="wave-icon"
                />
              )}
            </MenuItem>
          )}
          <MenuItem value="no_audio">
            <VolumeMute className={classes.menuItemIcon} />
            No Audio
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default PluginAudioSettingComponent;
