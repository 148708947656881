// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

export default (state = STORE_INITIATE_VALUE.BROWSER_INITIATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.CHECK_BROWSER_COMPATIBILITY_SUCCESS:
      return {
        ...state,
        name: action.data.name,
        version: action.data.version,
        supported: action.data.supported,
      };
    default:
      return state;
  }
};
