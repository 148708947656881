/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import debug from 'debug';
import EnablePermissionsModal, {
  selectedOptionType,
} from './EnablePermissionsModal';
import { countMediaDevices } from '../../lib/mediaOperationUtils';
import { Recorder } from '../../features/recorder/recorderSlice';

const log = debug('app:components:WebcamSelection');

const screenOnlyImg = require('../../assets/webcamSelectionImages/screen-only.png');
const webcamOnlyImg = require('../../assets/webcamSelectionImages/webcam-only.png');
const screenAndWebcamImg = require('../../assets/webcamSelectionImages/Screen+Webcam.png');

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      marginBottom: '8px',
    },
    div: {
      textAlign: 'center',
      cursor: 'pointer',
      padding: '1.5rem',
    },
    selectedDiv: {
      borderWidth: 1,
      borderStyle: 'solid',
      border: theme.palette.primary.main,
      borderRadius: '5px',
    },
    unSelectedDiv: {
      '&:hover': {
        backgroundColor: '#f0f0f0',
      },
    },
    image: {
      width: '100%',
      marginBottom: '1rem',
      aspectRatio: '465/311',
      borderRadius: '5px',
    },
    text: {
      margin: '0',
      fontWeight: 600,
      fontFamily: 'Inter',
      fontSize: '16px',
    },
  })
);

type selectionType = 'screen_only' | 'screen_and_webcam' | 'webcam_only';

interface SelectionItem {
  selection: selectionType;
  image: any;
  title: string;
}

const items: SelectionItem[] = [
  {
    selection: 'screen_only',
    image: screenOnlyImg,
    title: 'Screen only',
  },
  {
    selection: 'screen_and_webcam',
    image: screenAndWebcamImg,
    title: 'Screen + webcam',
  },
  {
    selection: 'webcam_only',
    image: webcamOnlyImg,
    title: 'Webcam only',
  },
];

interface WebcamSelectionProps {
  onClick: Function;
  recorder: Recorder;
  customClasses?: any;
  asPopOver?: boolean;
}

const WebcamSelection = (props: WebcamSelectionProps) => {
  const classes = useStyles();
  const { onClick, recorder, customClasses, asPopOver } = props;
  const { webCam, screen } = recorder.settings.videoSettings;
  const webcamOnlyEnable = webCam && !screen;
  const [selected, setSelected] = useState<selectionType>('screen_only');
  const [
    showEnablePermissionsModal,
    setShowEnablePermissionsModal,
  ] = useState<boolean>(false);
  const [webCamsCount, setWebCamsCount] = useState<number>(0);
  const [
    enablePermissionsModalText,
    setEnablePermissionsModalText,
  ] = useState<selectedOptionType>();

  useEffect(() => {
    if (webCam && !webcamOnlyEnable) {
      setSelected('screen_and_webcam');
    } else if (webcamOnlyEnable) {
      setSelected('webcam_only');
    } else {
      setSelected('screen_only');
    }
  }, [recorder.settings.videoSettings]);

  // get number of video devices
  const getDeviceCount = async () => {
    setWebCamsCount(await countMediaDevices('videoinput'));
  };

  navigator.mediaDevices.ondevicechange = (event) => {
    log(event);
    getDeviceCount();
  };

  useEffect(() => {
    getDeviceCount();
  }, []);

  const onClickHandle = async (selection: selectionType) => {
    if (selection === 'screen_only') {
      onClick(selection);
      setSelected(selection);
      return;
    }

    if (webCamsCount === 0) {
      setEnablePermissionsModalText(selection);
      setShowEnablePermissionsModal(true);
    } else {
      onClick(selection);
      setSelected(selection);
    }
  };

  return (
    <Grid
      className={
        customClasses && customClasses.root ? customClasses.root : classes.root
      }
      container
      spacing={asPopOver ? 1 : 7}
    >
      {items.map(({ selection, image, title }) => {
        return (
          <Grid item xs={4} key={title}>
            <div
              id={selection}
              className={
                selected === selection
                  ? clsx(
                      classes.selectedDiv,
                      customClasses && customClasses.div
                        ? customClasses.div
                        : classes.div
                    )
                  : clsx(
                      classes.unSelectedDiv,
                      customClasses && customClasses.div
                        ? customClasses.div
                        : classes.div
                    )
              }
              style={{ padding: asPopOver ? '0.5rem' : '1.5rem' }}
              onClick={() => onClickHandle(selection)}
            >
              <img
                className={
                  customClasses && customClasses.image
                    ? customClasses.image
                    : classes.image
                }
                src={image}
                alt={title}
              />
              <p
                className={
                  customClasses && customClasses.text
                    ? customClasses.text
                    : classes.text
                }
                style={{ fontSize: asPopOver ? '13px' : '16px' }}
              >
                {title}
              </p>
            </div>
          </Grid>
        );
      })}
      {showEnablePermissionsModal && (
        <EnablePermissionsModal
          onClickClose={() => {
            setShowEnablePermissionsModal(false);
          }}
          selectedOption={enablePermissionsModalText}
        />
      )}
    </Grid>
  );
};

export default WebcamSelection;
