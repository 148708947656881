import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { ActionModal } from '../StorybookComponents/Modal';

const image = require('../../assets/recording/enable-permissions.png');

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontFamily: 'Inter',
      '& .MuiDialogContent-root': {
        padding: '1rem 2.5rem',
      },
      '& .MuiDialog-paperWidthSm': {
        width: '28rem',
      },
      '& .MuiButton-contained': {
        background:
          'linear-gradient(126.16deg, #912982 -31.12%, #E83489 29.93%, #FF0547 92.03%)',
        width: '100%',
        height: '2.5rem',
      },
      '& .MuiDialogActions-root': {
        padding: 0,
        margin: '2rem',
      },
      '& .MuiButton-label': {
        fontFamily: 'Poppins',
        fontWeight: 500,
        color: theme.palette.grey[50],
        fontSize: '0.9rem',
      },
    },
    buttonClass: {
      float: 'none',
    },
    titleDiv: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '1.5rem',
      alignItems: 'center',
    },
    title: {
      fontFamily: 'Inter',
      color: theme.palette.text.primary,
      fontWeight: 700,
      fontSize: '1.5rem',
      marginBottom: '1rem',
    },
    description: {
      fontFamily: 'Inter',
      color: theme.palette.text.hint,
      marginBottom: 0,
    },
    imageDiv: {
      width: '100%',
      marginTop: '1.5rem',
    },
    image: {
      width: '100%',
    },
    link: {
      color: '#DD2F4D',
      '&:hover': {
        color: '#167495',
      },
    },
  })
);

export type selectedOptionType =
  | 'sysMic_audio'
  | 'microphone_audio'
  | 'webcam_only'
  | 'screen_and_webcam';

const EnablePermissionsModal = ({
  onClickClose,
  selectedOption,
}: {
  onClickClose: React.MouseEventHandler<HTMLButtonElement>;
  selectedOption?: selectedOptionType;
}) => {
  const classes = useStyles();

  const options = {
    sysMic_audio: 'System Audio + Microphone Audio',
    microphone_audio: 'Microphone Audio',
    webcam_only: 'Webcam Only',
    screen_and_webcam: 'Screen and Webcam',
  };

  const title = 'Enable permissions';
  const defaultDescription =
    'Maximize the potential of ScreenApp and enhance the quality of your screen recordings by enabling browser permissions to access your webcam and microphone.';
  const des = `Maximize the potential of ScreenApp and enhance the quality of your screen recordings by enabling browser permissions to grant ScreenApp`;

  const description = () => {
    return !selectedOption ? (
      <p className={classes.description}>{defaultDescription}</p>
    ) : (
      <p className={classes.description}>
        {des}{' '}
        <span>
          <a
            className={classes.link}
            target="_blank"
            href="https://support.google.com/chrome/answer/2693767?hl=en&co=GENIE.Platform%3DDesktop"
            rel="noreferrer"
          >
            access to Microphone and Camera
          </a>
        </span>{' '}
        to record with <span>{selectedOption && options[selectedOption]}</span>
      </p>
    );
  };

  return (
    <div className={classes.root}>
      <ActionModal
        id="enable-permissions-modal"
        open
        isSingleButton
        actinButtonLabel="Got it"
        fullWidth={false}
        closeIcon
        basicButtonNoMargin
        classes={{
          customClass: classes.root,
          buttonClass: classes.buttonClass,
        }}
        handleClose={onClickClose}
        actinButtonOnClick={onClickClose}
      >
        <p className={classes.title}>{title}</p>
        {description()}
        <div className={classes.imageDiv}>
          <img
            className={classes.image}
            src={image}
            alt="enable-browser-permissions"
          />
        </div>
      </ActionModal>
    </div>
  );
};

export default EnablePermissionsModal;
