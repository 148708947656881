// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { ANALYTICS_PAGE_VIEW_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.ANALYTICS, action) => {
  switch (action.type) {
    case ANALYTICS_PAGE_VIEW_ACTIONS.SIGN_IN_PAGE_VIEW:
    case ANALYTICS_PAGE_VIEW_ACTIONS.SIGN_UP_PAGE_VIEW:
    case ANALYTICS_PAGE_VIEW_ACTIONS.DASHBOARD_PAGE_VIEW:
    case ANALYTICS_PAGE_VIEW_ACTIONS.CHECKOUT_PAGE_VIEW:
    case ANALYTICS_PAGE_VIEW_ACTIONS.EDITOR_PAGE_VIEW:
    default:
      return state;
  }
};
