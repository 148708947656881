import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';

const emptyState = {
  loadingSuggestions: false,
  suggestionsError: null,
  suggestions: [],
  loadingAskAI: false,
  askAIError: null,
  continuation: [],
};

export default (state = STORE_INITIATE_VALUE.ASK_AI, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FETCH_AI_SUGGESTIONS:
      return {
        ...state,
        [action.data.id]: {
          ...emptyState,
          loadingSuggestions: true,
        },
      };
    case REDUX_ACTIONS.FETCH_AI_SUGGESTIONS_SUCCESS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          loadingSuggestions: false,
          suggestions: action.data.suggestions,
          sessionId: action.data.sessionId,
        },
      };
    case REDUX_ACTIONS.FETCH_AI_SUGGESTIONS_FAIL:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          loadingSuggestions: false,
          suggestionsError: action.data.error,
        },
      };
    case REDUX_ACTIONS.ASK_AI:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          continuation: action.data.continuation,
          loadingAskAI: true,
          askAIError: null,
        },
      };
    case REDUX_ACTIONS.ASK_AI_SUCCESS:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          continuation: [
            ...state[action.data.id].continuation,
            action.data.message,
          ],
          loadingAskAI: false,
          sessionId: action.data.sessionId,
        },
      };
    case REDUX_ACTIONS.ASK_AI_FAIL:
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          loadingAskAI: false,
          askAIError: action.data.error,
        },
      };
    default:
      return state;
  }
};
