import { call, takeEvery, takeLatest } from 'redux-saga/effects';
import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import { setupIdentity, trackPageView } from '../../lib/hubspotUtils';
import { getUserProfileResponse } from '../../features/auth/authSlice';

function* setupIdentityGenerator(action) {
  const email = action?.data?.data?.email;
  if (email) {
    yield call(setupIdentity, { email });
  }
}

function* trackPageViewGenerator(action) {
  if (action?.payload?.path) {
    yield call(trackPageView, { path: action.payload.path });
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* setupHubspotSaga() {
  yield takeEvery(REDUX_ACTIONS.TRACK_PAGE_VIEW, trackPageViewGenerator);
  yield takeLatest(getUserProfileResponse.type, setupIdentityGenerator);
}
