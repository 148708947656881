import { call, select, takeEvery, takeLatest } from 'redux-saga/effects';
import debug from 'debug';
import { config } from '../../../config';

import { REDUX_ACTIONS } from '../../constants/apiSagaConstant';
import {
  setupIdentity,
  trackEvent,
  trackPageView,
} from '../../lib/intercomUtils';
import { getUserProfileResponse } from '../../features/auth/authSlice';

const log = debug('app:intercomSaga');

function* setupIdentityGenerator(action) {
  const {
    email,
    id: _id,
    createdAt,
    tracking: { intercomHmac } = { intercomHmac: '' },
    profile: { name } = { name: '' },
  } = action?.data?.data || {};

  if (config.intercomAppId && intercomHmac) {
    yield call(setupIdentity, {
      email,
      createdAt,
      _id,
      appId: config.intercomAppId,
      intercomHmac,
      name,
    });
  } else {
    log(
      'Failed to setup intercom:',
      action,
      config.intercomAppId,
      intercomHmac
    );
  }
}

function* trackPageViewGenerator() {
  yield call(trackPageView);
}

const getUserDetails = (state) => {
  return {
    userId: state?.auth?.user?.data?.id,
    pkg: state?.auth?.user?.data?.package,
  };
};

const flattenObject = (obj, prefix = '') =>
  Object.keys(obj).reduce((acc, k) => {
    const pre = prefix.length ? `${prefix}_` : '';
    if (typeof obj[k] === 'object')
      Object.assign(acc, flattenObject(obj[k], pre + k));
    else acc[pre + k] = obj[k];
    return acc;
  }, {});

function* trackEventGenerator(action) {
  try {
    const { pkg } = yield select(getUserDetails);

    yield call(trackEvent, {
      action: action.data.action,
      data: flattenObject({ plan: pkg || 'FREE', ...(action.data.data || {}) }),
    });
    // No response processing
  } catch (e) {
    log(e);
    // Fail silently
  }
}

// eslint-disable-next-line import/prefer-default-export
export function* setupIntercomSaga() {
  yield takeEvery(REDUX_ACTIONS.TRACK_PAGE_VIEW, trackPageViewGenerator);
  yield takeEvery(REDUX_ACTIONS.ANALYTICS, trackEventGenerator);
  yield takeLatest(getUserProfileResponse.type, setupIdentityGenerator);
}
