/* eslint-disable import/named */
import React, { ReactNode } from 'react';
import clsx from 'clsx';
import {
  Dialog,
  DialogActions,
  DialogContent,
  // eslint-disable-next-line import/named
  DialogProps,
  // eslint-disable-next-line import/named
  Theme,
  // eslint-disable-next-line import/named
  Typography,
} from '@material-ui/core';
// eslint-disable-next-line import/named
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { MaxWidth } from '../types';

const styles = (theme: Theme) => {
  return createStyles({
    root: {
      margin: theme.spacing(1),
      '& .MuiPaper-rounded': {
        borderRadius: '12px',
        backgroundColor: '#fff',
      },
    },
    title: {
      paddingTop: theme.spacing(4),
      margin: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      fontWeight: 700,
      fontStyle: 'normal',
      fontFamily: 'Inter',
      color: '#363739',
    },
    pricingPlanModalTitle: {
      // margin: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      fontWeight: 'bold',
      fontSize: '2em',
      textAlign: 'center',
      width: '65vw',
      alignSelf: 'center',
      '@media (min-width: 1367px)': {
        width: '55vw',
        fontSize: '2.4em',
        marginTop: '1vh',
      },
      '@media (min-width: 1537px)': {
        width: '50vw',
        fontSize: '3em',
        marginTop: '1.5vh',
      },
      '@media (min-width: 1921px)': {
        fontSize: '4em',
      },
    },
    topTitle: {
      color: 'grey',
      textAlign: 'center',
      marginTop: '1em',
      marginBottom: 0,
      fontSize: '1em',
      '@media (min-width: 1537px)': {
        fontSize: '1.5em',
      },
      '@media (min-width: 1921px)': {
        fontSize: '2.5em',
      },
    },
    dialogContent: {
      paddingRight: theme.spacing(5),
    },
    dialogActions: {
      paddingBottom: theme.spacing(6),
      paddingRight: theme.spacing(2),
    },
    icon: {
      position: 'absolute',
      right: '7px',
      top: '7px',
      color: '#888282',
      '& .MuiSvgIcon-fontSizeInherit': {
        fontSize: 30,
      },
      '& .MuiIconButton-root:hover': {
        color: 'black',
      },
    },
    actionButtonsCenter: {
      justifyContent: 'center',
    },
    checkBoxNote: {
      paddingBottom: '12px',
      paddingLeft: '30px',
      fontSize: '12px',
      color: '#989898',
    },
  });
};

export interface IModalProps
  extends Omit<DialogProps, 'classes'>,
    WithStyles<typeof styles> {
  id?: string;
  open: boolean;
  handleClose?: (e: {}) => void;
  children?: ReactNode;
  modalTitle?: any;
  actionButtons?: ReactNode;
  fullWidth?: boolean;
  maxWidth?: MaxWidth;
  disableBackdropClick?: boolean;
  titleSize?: '24px' | '1.5em';
  closeIcon?: boolean;
  buttonsCenter?: boolean;
  customClass?: string;
  customClasses?: Partial<typeof styles>;
  customTitleClass?: string;
  displayCheckBox?: boolean;
  checkBoxValue?: boolean;
  checkBoxNote?: string;
  topTitle?: string;
  enableActionButtons?: boolean;
}

const BasicModal: React.FC<IModalProps> = ({
  classes: defaultClasses,
  id,
  open,
  modalTitle,
  children,
  actionButtons,
  handleClose,
  fullWidth = true,
  maxWidth = 'sm',
  disableBackdropClick = false,
  titleSize = '24px',
  closeIcon = false,
  buttonsCenter = false,
  customClass,
  customClasses,
  customTitleClass,
  checkBoxValue = false,
  displayCheckBox = false,
  checkBoxNote,
  topTitle,
  enableActionButtons = true,
}: IModalProps) => {
  const classes = {
    ...defaultClasses,
    ...customClasses,
  };
  return (
    <Dialog
      id={id}
      onClose={(e, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          return;
        }
        handleClose?.(e);
      }}
      open={open}
      className={clsx(classes.root, customClass)}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      {closeIcon && (
        <div className={classes.icon}>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={handleClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </div>
      )}
      {topTitle && <p className={classes.topTitle}>{topTitle}</p>}
      <Typography
        className={clsx(customTitleClass || classes.title, 'title')}
        variant="h4"
        style={{ fontSize: titleSize }}
      >
        {modalTitle}
      </Typography>
      <DialogContent
        className={clsx(classes.dialogContent, 'basicModal')}
        style={{ overflowY: 'clip' }}
      >
        {children}
      </DialogContent>
      {enableActionButtons && (
        <DialogActions
          className={clsx(
            classes.dialogActions,
            buttonsCenter && classes.actionButtonsCenter
          )}
        >
          {actionButtons}
        </DialogActions>
      )}
      {displayCheckBox && checkBoxValue && (
        <div className={classes.checkBoxNote}>{checkBoxNote}</div>
      )}
    </Dialog>
  );
};

export default withStyles(styles)(BasicModal);
