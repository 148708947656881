// constant
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';
import { WS_ACTION_TYPE } from '../constants/wsConstant';
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { setVideoDuration } from '../features/recorder/recorderSlice';

// TODO REMOVE: THIS REDUCER IS PROBABLY NOT USED
export default (state = STORE_INITIATE_VALUE.REMOTE_VIDEO_INITIATE, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.SET_CURRENT_RECORDINGS:
      return {
        ...state,
        currentRemoteOriginalVideo: action.payload.data,
      };

    case WS_ACTION_TYPE.STREAM_WEBM_AVAILABLE:
      return {
        ...state,
        currentRemoteOriginalVideo: {
          ...state.currentRemoteOriginalVideo,
          Location: action.payload.url,
          Key: action.payload.providerKey,
        },
      };
    case setVideoDuration.type:
      return {
        ...state,
        currentRemoteOriginalVideo: {
          ...state.currentRemoteOriginalVideo,
          endTime: action.payload.endTime,
        },
      };
    case REDUX_ACTIONS.TRIM_TO_AVAILABLE_VIDEO:
      return {
        ...state,
        currentRemoteOriginalVideo: {
          ...state.currentRemoteOriginalVideo,
          Key: action.payload.trimmedData.providerKey,
        },
      };
    default:
      return state;
  }
};
