import { v4 as uuid } from 'uuid';
import { config } from '../../config';
import { FeatureFlagsType } from '../constants/featureFlags';

const FEATURE_FLAGS_KEY = 'featureFlags';
const TOKEN_KEY = 'screenapp_token';
const TOKEN_EXPIRY_KEY = 'screenapp_token_expiry';
// Previously registered user
export const getPRU = () => {
  return localStorage.getItem('pru');
};

// Previously registered user
export const setPRU = (data) => {
  window.localStorage.setItem('pru', data);
};

export const getToken = () => {
  return localStorage.getItem(TOKEN_KEY);
};

export const setFeatureFlags = (data: FeatureFlagsType) => {
  localStorage.setItem(FEATURE_FLAGS_KEY, JSON.stringify(data));
};
export const loadFeatureFlags = (): FeatureFlagsType => {
  const featureFlags = localStorage.getItem(FEATURE_FLAGS_KEY);
  return featureFlags ? JSON.parse(featureFlags) : {};
};

const MINUTE = 1000 * 60;
const DAY = MINUTE * 60 * 24;

export const shouldRefreshToken = () => {
  const now = Date.now();
  const expiry = window.localStorage.getItem(TOKEN_EXPIRY_KEY);
  // Refresh tokens expiring within the next day, but not the already-expired ones.
  return expiry ? +expiry <= now + DAY && +expiry > now : false;
};

export const setToken = (token) => {
  if (token) {
    window.localStorage.setItem(TOKEN_KEY, token);
    window.localStorage.setItem(
      TOKEN_EXPIRY_KEY,
      (Date.now() + config.jwtExpiration).toString()
    );
    setPRU(true); // Previously registered user
  }
};

export const removeToken = () => {
  window.localStorage.removeItem(TOKEN_KEY);
  window.localStorage.removeItem(TOKEN_EXPIRY_KEY);
};

export const getOrCreateLocalTrackingId = () => {
  const ltidStored = localStorage.getItem('ltid');
  if (ltidStored === null) {
    const ltid = uuid();
    localStorage.setItem('ltid', ltid);
    return ltid;
  }

  return ltidStored;
};

export const setLastFeedbacksubmittedTime = () => {
  const currentTime = Date.now();
  return localStorage.setItem('lfbt', currentTime.toString());
};

export const isEnableFeedbackSubmission = () => {
  const lfbt = Number(localStorage.getItem('lfbt'));

  if (!lfbt || Date.now() - lfbt > DAY * 7) return true;

  return false;
};

export const isEnableFeedbackSubmissionForPaidUsers = (
  { lastUpdatedAt, lastShowedAt },
  recordingCount
) => {
  // if (!lastUpdatedAt) return true;
  if (recordingCount.data === 5) return true;
  const lastUpdatedAtInMilliSeconds = new Date(lastUpdatedAt);
  const lastShowedAtInMilliSeconds = new Date(lastShowedAt);
  const updatedTimeDifference =
    Date.now() - lastUpdatedAtInMilliSeconds.getTime();
  const showedTimeDifference =
    Date.now() - lastShowedAtInMilliSeconds.getTime();
  if (showedTimeDifference > DAY * 7) {
    if (lastUpdatedAt === lastShowedAt) {
      if (updatedTimeDifference > DAY * 14) {
        return true;
      }
      return false;
    }
    return true;
  }
  return false;
};

// Users from marketing landing page
export const setUserFromLandingPage = (data) => {
  localStorage.setItem('landing', data);
};

export const getUserFromLandingPage = () => {
  return localStorage.getItem('landing');
};

export const removeUserFromLandingPage = () => {
  localStorage.removeItem('landing');
};

// Users from marketing landing page source
export const setLandingPageSource = (data) => {
  localStorage.setItem('utm-source', data);
};

export const getLandingPageSource = () => {
  return localStorage.getItem('utm-source');
};

export const removeLandingPageSource = () => {
  localStorage.removeItem('utm-source');
};

// Users from marketing landing page
export const setUserFromUpgradeReminderEmail = (data) => {
  localStorage.setItem('upgrade-reminder-email', data);
};

export const getUserFromUpgradeReminderEmail = () => {
  return localStorage.getItem('upgrade-reminder-email');
};

export const removeUserFromUpgradeReminderEmail = () => {
  localStorage.removeItem('upgrade-reminder-email');
};
