/* eslint no-param-reassign: "error" */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

// Define the state type
export interface ImportFileState {
  success: Boolean;
  message: string;
}

// Define the payload type for the importFile action
/**
 *           userId: user?.data?.id,
          // @ts-ignore
          localFiles: files.filter((f) => f.file.url === undefined),
          // @ts-ignore
          files: files.filter((f) => f.file.url !== undefined),
          libraryTab,
          parentId: folders[libraryTab]?.selectedFolder?._id || null,
 *
 */
interface ImportFileRequestPayload {
  userId: string;
  localFiles: any[];
  files: any[];
  libraryTab: string;
  parentId: string | null;
  token: string;
}

// Define the payload type for the error action
interface ErrorMessagePayload {
  message?: string;
}

// Initial state
const initialState: ImportFileState = { success: false, message: '' };

// Create the slice
const importFileSlice = createSlice({
  name: 'importFile',
  initialState,
  reducers: {
    importFileRequest: (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<ImportFileRequestPayload>
    ) => {
      state.success = false;
      state.message = '';
    },
    importFileSuccess: (state) => {
      state.success = true;
      state.message = 'file imported successfully';
    },
    importFileError: (state, action: PayloadAction<ErrorMessagePayload>) => {
      state.success = false;
      state.message = action.payload.message || '';
    },
  },
  // ExtraReducers can be used if you have other actions that should affect this slice but are not defined in this slice
});

// Export the action creators
export const {
  importFileRequest,
  importFileSuccess,
  importFileError,
} = importFileSlice.actions;

// Export the reducer
export default importFileSlice.reducer;
