// constant
import STORE_INITIATE_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (state = STORE_INITIATE_VALUE.TAG, action) => {
  switch (action.type) {
    case REDUX_ACTIONS.FETCH_TAG_REQUEST:
      return {
        ...state,
        isLoading: true,
        isSuccess: false,
      };
    case REDUX_ACTIONS.FETCH_TAG_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLoading: false,
        isSuccess: true,
      };
    case REDUX_ACTIONS.FETCH_TAG_ERROR:
      return {
        ...state,
        data: [],
        isLoading: false,
        isSuccess: false,
      };
    case REDUX_ACTIONS.UPDATE_SELECTED_TAG:
      return {
        ...state,
        selectedTag: action.data,
      };
    default:
      return state;
  }
};
