/* eslint-disable import/prefer-default-export */
import api from '../lib/baseAxios';

// Called by plugin end user
export const initialize = (key: string) => {
  return api.get(`plugin/init/${key}`);
};

// Called by plugin owner
export const setup = (website: string) => {
  return api.post('plugin/setup', { website });
};

// Called by plugin owner
export const getPlugin = () => {
  return api.get('plugin/setup');
};

// Check whether the user is registered before adding to shared space
export const checkRegisteredUsersURL = async (email: string) => {
  return api.post(`/plugin/check-registered-users`, { email });
};

// Enable plugin shared space
export const enablePlugin = (data: { email: string[]; ownerId: string }) => {
  return api.post(`/plugin/enable-shared-space`, { data });
};

// Get shared space users
export const getSharedSpaceUsersURL = async () => {
  return api.get(`/plugin/shared-space-users`);
};

// Send emails to shared space users
export const sendEmailURL = (email: string) => {
  return api.post(`/plugin/send-email-to-shared-space-users`, { email });
};

export const sendEmailToNotRegisteredURL = (data: {
  email: string;
  ownerId: string;
}) => {
  return api.post(`/plugin/send-email-to-not-registered-shared-space-users`, {
    data,
  });
};

// Get admin's plugin videos
export const getPluginFiles = async () => {
  return api.get(`/plugin/plugin-video`);
};

// Change plugin status of shared members
export const changePluginStatusURL = (email: string) => {
  return api.post(`/plugin/change-plugin-status`, { email });
};

// Delete shared space members
export const deleteSharedSpaceUsersURL = (userId: string) => {
  return api.post(`/plugin/delete-shared-space-user`, { userId });
};

export const PluginDeativate = async () => {
  return api.post(`plugin-subscription/deactivate`);
};
