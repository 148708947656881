import STORE_INITIAL_VALUE from '../constants/initialstore';
import { REDUX_ACTIONS } from '../constants/apiSagaConstant';

export default (
  state: { [x: string]: any } = STORE_INITIAL_VALUE.CHECKED_FILES,
  action
) => {
  switch (action.type) {
    case REDUX_ACTIONS.UNCHECK_ALL_FILES:
      return {};
    case REDUX_ACTIONS.CHECK_ALL_FILES:
      return {
        ...action.data,
      };
    case REDUX_ACTIONS.TOGGLE_CHECK_FILE:
      return {
        ...state,
        [action.data._id]: state[action.data._id] !== true,
      };
    default:
      return state;
  }
};
