import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SpinnerComponent from '../../components/Spinner/SpinnerComponent';

import PluginModal, { KeyContext } from '../../components/Plugin';
import { State } from '../../types/state';
import { goToRecorder } from '../../features/recorder/recorderSlice';
import { authenticatePlugin } from '../../features/plugin/pluginSlice';

interface PluginRecordingContainerProps {
  onClose: () => void;
  finishRecordingCallback: () => void;
}

const RecordingContainer = (props: PluginRecordingContainerProps) => {
  const { onClose, finishRecordingCallback } = props;
  const { recorder, app, auth, plugin } = useSelector((state: State) => ({
    recorder: state.recorder,
    app: state.app,
    auth: state.auth,
    plugin: state.plugin,
  }));
  const key = useContext(KeyContext);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(goToRecorder({ uuid: app.uuid }));
  }, []);

  useEffect(() => {
    if (key) {
      dispatch(authenticatePlugin({ key }));
    }
  }, [key]);

  if (!plugin?.pluginMode && plugin.errorText && plugin.errorText !== '') {
    return (
      <PluginModal
        onClose={onClose}
        finishRecordingCallback={finishRecordingCallback}
      />
    );
  }

  if (
    !plugin?.pluginMode ||
    !auth?.settings?.recorderSettings ||
    Object.keys(auth?.settings?.recorderSettings).length === 0
  )
    return null;

  if (recorder.openRecorder)
    return (
      <PluginModal
        onClose={onClose}
        finishRecordingCallback={finishRecordingCallback}
      />
    );
  return (
    <div className="overlay-on">
      <div className="spinner-middle">
        <SpinnerComponent />
        <div>Loading</div>
      </div>
    </div>
  );
};

export default RecordingContainer;
